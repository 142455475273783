import { BoqSubCategory } from "../BoqSubCategory/BoqSubCategory.type";
import { ApiCallState, PagedData } from "../Utils";

export type BoqCategory = {
  id?: number;
  key: number;
  name: string;

  boq_sub_categories: BoqSubCategory[]
};

export type BoqCategoryStateTypes = {
  fetchAll: ApiCallState<BoqCategory[]>;
  fetchOne: ApiCallState<BoqCategory | {}>;
  fetchPaged: ApiCallState<PagedData<BoqCategory[]>>;
};

export const BoqCategoryActionTypes = {
  FETCH_ALL_BOQ_CATEGORY: "FETCH_ALL_BOQ_CATEGORY",
  FETCH_ALL_BOQ_CATEGORY_RESET: "FETCH_ALL_BOQ_CATEGORY_RESET",
  FETCH_ALL_BOQ_CATEGORY_FAILURE: "FETCH_ALL_BOQ_CATEGORY_FAILURE",
  FETCH_ALL_BOQ_CATEGORY_SUCCESS: "FETCH_ALL_BOQ_CATEGORY_SUCCESS",

  FETCH_PAGED_BOQ_CATEGORY: "FETCH_PAGED_BOQ_CATEGORY",
  FETCH_PAGED_BOQ_CATEGORY_RESET: "FETCH_PAGED_BOQ_CATEGORY_RESET",
  FETCH_PAGED_BOQ_CATEGORY_FAILURE: "FETCH_PAGED_BOQ_CATEGORY_FAILURE",
  FETCH_PAGED_BOQ_CATEGORY_SUCCESS: "FETCH_PAGED_BOQ_CATEGORY_SUCCESS",

  FETCH_ONE_BOQ_CATEGORY: "FETCH_ONE_BOQ_CATEGORY",
  FETCH_ONE_BOQ_CATEGORY_RESET: "FETCH_ONE_BOQ_CATEGORY_RESET",
  FETCH_ONE_BOQ_CATEGORY_FAILURE: "FETCH_ONE_BOQ_CATEGORY_FAILURE",
  FETCH_ONE_BOQ_CATEGORY_SUCCESS: "FETCH_ONE_BOQ_CATEGORY_SUCCESS",
};
