import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PerformanceEvaluationForm = {
  id: number;
  date: string;
  form_name: string;
  performance_evaluation_form_items: PerformanceEvaluationFormItem[];
  perpared_by_id: number;
  prepared_by:User;
  user_id: number;
};

export type PerformanceEvaluationFormItem = {
  id: number;
  performance_evaluation_form_id: number;
  evaluating_point: string;
  weight: number;
}



export type PerformanceEvaluationFormStateTypes = {
  fetchAll: ApiCallState<PerformanceEvaluationForm[]>;
  fetchOne: ApiCallState<PerformanceEvaluationForm | {}>;
};

export const PerformanceEvaluationFormActionTypes = {
  FETCH_ALL_PERFORMANCE_EVALUATION_FORM: "FETCH_ALL_PERFORMANCE_EVALUATION_FORM",
  FETCH_ALL_PERFORMANCE_EVALUATION_FORM_RESET: "FETCH_ALL_PERFORMANCE_EVALUATION_FORM_RESET",
  FETCH_ALL_PERFORMANCE_EVALUATION_FORM_FAILURE: "FETCH_ALL_PERFORMANCE_EVALUATION_FORM_FAILURE",
  FETCH_ALL_PERFORMANCE_EVALUATION_FORM_SUCCESS: "FETCH_ALL_PERFORMANCE_EVALUATION_FORM_SUCCESS",

  FETCH_ONE_PERFORMANCE_EVALUATION_FORM: "FETCH_ONE_PERFORMANCE_EVALUATION_FORM",
  FETCH_ONE_PERFORMANCE_EVALUATION_FORM_RESET: "FETCH_ONE_PERFORMANCE_EVALUATION_FORM_RESET",
  FETCH_ONE_PERFORMANCE_EVALUATION_FORM_FAILURE: "FETCH_ONE_PERFORMANCE_EVALUATION_FORM_FAILURE",
  FETCH_ONE_PERFORMANCE_EVALUATION_FORM_SUCCESS: "FETCH_ONE_PERFORMANCE_EVALUATION_FORM_SUCCESS",
};
