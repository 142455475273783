import { PaymentStateTypes, PaymentActionTypes } from "./Payment.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PaymentStateTypes = {
  fetchAll: resetApiCallState([]),
};

const PaymentReducer = (
  state: PaymentStateTypes = INITIAL_STATE,
  action: any
): PaymentStateTypes => {
  switch (action.type) {
    case PaymentActionTypes.FETCH_ALL_PAYMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PaymentActionTypes.FETCH_ALL_PAYMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PaymentActionTypes.FETCH_ALL_PAYMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PaymentActionTypes.FETCH_ALL_PAYMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PaymentReducer;
