import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SummaryResultForSkilledCandidateActionTypes } from "./SummaryResultForSkilledCandidates.type";
import { formatQuery } from "../Utils";

export function* fetchAllSummaryResultForSkilledCandidates(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/summary-result-for-skilled-candidate?${formatQuery(action)}`);
    yield put({
      type: SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSummaryResultForSkilledCandidates(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/summary-result-for-skilled-candidate/${action.payload}`
    );
    yield put({
      type: SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryResultForSkilledCandidateActionTypes.FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSummaryResultForSkilledCandidates() {
  yield takeLatest(SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE, fetchAllSummaryResultForSkilledCandidates);
}

export function* watcherFetchOneSummaryResultForSkilledCandidates() {
  yield takeLatest(SummaryResultForSkilledCandidateActionTypes.FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE, fetchOneSummaryResultForSkilledCandidates);
}
