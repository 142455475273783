import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AccountActionTypes } from "./Account.type";

export function* fetchAllAccounts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/account`);
    yield put({
      type: AccountActionTypes.FETCH_ALL_ACCOUNT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountActionTypes.FETCH_ALL_ACCOUNT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAccounts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/account/${action.payload}`
    );
    yield put({
      type: AccountActionTypes.FETCH_ONE_ACCOUNT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountActionTypes.FETCH_ONE_ACCOUNT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAccounts() {
  yield takeLatest(AccountActionTypes.FETCH_ALL_ACCOUNT, fetchAllAccounts);
}

export function* watcherFetchOneAccounts() {
  yield takeLatest(AccountActionTypes.FETCH_ONE_ACCOUNT, fetchOneAccounts);
}
