import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ReplenishmentTransactionActionTypes } from "./ReplenishmentTransaction.type";

export function* fetchAllReplenishmentTransactions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-transaction`
    );
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneReplenishmentTransactions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/replenishment-transaction/${action.payload}`
    );
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllReplenishmentTransactions() {
  yield takeLatest(
    ReplenishmentTransactionActionTypes.FETCH_ALL_REPLENISHMENT_TRANSACTION,
    fetchAllReplenishmentTransactions
  );
}

export function* watcherFetchOneReplenishmentTransactions() {
  yield takeLatest(
    ReplenishmentTransactionActionTypes.FETCH_ONE_REPLENISHMENT_TRANSACTION,
    fetchOneReplenishmentTransactions
  );
}
