import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { EmployeeAccommodationPlanActionTypes } from "./EmployeeAccommodationPlan.type";

export function* fetchAllEmployeeAccommodationPlan(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee_accommodation_plan?project_id=${action.payload?.project_id}&date=${action.payload?.date}&date_type=${action.payload?.date_type}`
    );
    yield put({
      type: EmployeeAccommodationPlanActionTypes.FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeAccommodationPlanActionTypes.FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeeAccommodationPlan(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee_accommodation_plan/${action.payload}`
    );
    yield put({
      type: EmployeeAccommodationPlanActionTypes.FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeAccommodationPlanActionTypes.FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeAccommodationPlan() {
  yield takeLatest(
    EmployeeAccommodationPlanActionTypes.FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN,
    fetchAllEmployeeAccommodationPlan
  );
}

export function* watcherFetchOneEmployeeAccommodationPlan() {
  yield takeLatest(
    EmployeeAccommodationPlanActionTypes.FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN,
    fetchOneEmployeeAccommodationPlan
  );
}
