import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { PartnerMessageActionTypes } from "./PartnerMessage.type";

export function* fetchAllPartnerMessages(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/partner-message`);
    yield put({
      type: PartnerMessageActionTypes.FETCH_ALL_PARTNER_MESSAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PartnerMessageActionTypes.FETCH_ALL_PARTNER_MESSAGE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePartnerMessages(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/partner-message/${action.payload}`
    );
    yield put({
      type: PartnerMessageActionTypes.FETCH_ONE_PARTNER_MESSAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PartnerMessageActionTypes.FETCH_ONE_PARTNER_MESSAGE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPartnerMessages() {
  yield takeLatest(
    PartnerMessageActionTypes.FETCH_ALL_PARTNER_MESSAGE,
    fetchAllPartnerMessages
  );
}

export function* watcherFetchOnePartnerMessages() {
  yield takeLatest(
    PartnerMessageActionTypes.FETCH_ONE_PARTNER_MESSAGE,
    fetchOnePartnerMessages
  );
}
