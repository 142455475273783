import { ContractAgreement } from "../ContractAgreement/ContractAgreement.type";
import { ApiCallState } from "../Utils";

export type SalesPayment = {
  id: number;

  user_id: number;
  buyer_id: number;

  date: string;
  payment_type: string;

  payment_amount: number;
  inflation_adjustment: number;
  deduction_amount: number;
  deduction_reason: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  contract_agreement: ContractAgreement;
};

export type SalesPaymentStateTypes = {
  fetchAll: ApiCallState<SalesPayment[]>;
  fetchOne: ApiCallState<SalesPayment | {}>;
};

export const SalesPaymentActionTypes = {
  FETCH_ALL_SALES_PERSON: "FETCH_ALL_SALES_PERSON",
  FETCH_ALL_SALES_PERSON_RESET: "FETCH_ALL_SALES_PERSON_RESET",
  FETCH_ALL_SALES_PERSON_FAILURE: "FETCH_ALL_SALES_PERSON_FAILURE",
  FETCH_ALL_SALES_PERSON_SUCCESS: "FETCH_ALL_SALES_PERSON_SUCCESS",

  FETCH_ONE_SALES_PERSON: "FETCH_ONE_SALES_PERSON",
  FETCH_ONE_SALES_PERSON_RESET: "FETCH_ONE_SALES_PERSON_RESET",
  FETCH_ONE_SALES_PERSON_FAILURE: "FETCH_ONE_SALES_PERSON_FAILURE",
  FETCH_ONE_SALES_PERSON_SUCCESS: "FETCH_ONE_SALES_PERSON_SUCCESS",
};
