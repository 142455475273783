import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TaskMeetingActionTypes } from "./TaskMeeting.type";

export function* fetchAllTaskMeetings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/task_meeting?${query}`);
    yield put({
      type: TaskMeetingActionTypes.FETCH_ALL_TASK_MEETING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskMeetingActionTypes.FETCH_ALL_TASK_MEETING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTaskMeetings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/task_meeting/${action.payload}`
    );
    yield put({
      type: TaskMeetingActionTypes.FETCH_ONE_TASK_MEETING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskMeetingActionTypes.FETCH_ONE_TASK_MEETING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTaskMeetings() {
  yield takeLatest(TaskMeetingActionTypes.FETCH_ALL_TASK_MEETING, fetchAllTaskMeetings);
}

export function* watcherFetchOneTaskMeetings() {
  yield takeLatest(TaskMeetingActionTypes.FETCH_ONE_TASK_MEETING, fetchOneTaskMeetings);
}
