import { Account } from "../Account/Account.type";
import { Invoice, Item } from "../Invoice/Invoice.type";
import { Payment } from "../Payment/Payment.type";
import {
  PaymentCertificate,
  Project,
  ProjectPayment,
} from "../Project/Project.type";
import { Service } from "../Service/Service.type";
import { ApiCallState } from "../Utils";

export type PaymentRetention = {
  id: number;
  name: string;
  payment_certificates: PaymentCertificate[];
  payments: Payment[];
  project_payment: ProjectPayment;
};

export type PaymentRetentionStateTypes = {
  fetchAll: ApiCallState<PaymentRetention[]>;
};

export const PaymentRetentionActionTypes = {
  FETCH_ALL_PAYMENT_RETENTION: "FETCH_ALL_PAYMENT_RETENTION",
  FETCH_ALL_PAYMENT_RETENTION_RESET: "FETCH_ALL_PAYMENT_RETENTION_RESET",
  FETCH_ALL_PAYMENT_RETENTION_FAILURE: "FETCH_ALL_PAYMENT_RETENTION_FAILURE",
  FETCH_ALL_PAYMENT_RETENTION_SUCCESS: "FETCH_ALL_PAYMENT_RETENTION_SUCCESS",
};
