import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffSalaryAdjustmentActionTypes } from "./StaffSalaryAdjustment.type";

export function* fetchAllStaffSalaryAdjustments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-salary-adjustment?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffSalaryAdjustmentActionTypes.FETCH_ALL_STAFF_SALARY_ADJUSTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffSalaryAdjustmentActionTypes.FETCH_ALL_STAFF_SALARY_ADJUSTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffSalaryAdjustments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-salary-adjustment/${action.payload}`
    );
    yield put({
      type: StaffSalaryAdjustmentActionTypes.FETCH_ONE_STAFF_SALARY_ADJUSTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffSalaryAdjustmentActionTypes.FETCH_ONE_STAFF_SALARY_ADJUSTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffSalaryAdjustments() {
  yield takeLatest(StaffSalaryAdjustmentActionTypes.FETCH_ALL_STAFF_SALARY_ADJUSTMENT, fetchAllStaffSalaryAdjustments);
}

export function* watcherFetchOneStaffSalaryAdjustments() {
  yield takeLatest(StaffSalaryAdjustmentActionTypes.FETCH_ONE_STAFF_SALARY_ADJUSTMENT, fetchOneStaffSalaryAdjustments);
}
