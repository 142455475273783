import {
  AreaProgressStateTypes,
  AreaProgressActionTypes,
} from "./AreaProgress.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AreaProgressStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AreaProgressReducer = (
  state: AreaProgressStateTypes = INITIAL_STATE,
  action: any
): AreaProgressStateTypes => {
  switch (action.type) {
    case AreaProgressActionTypes.FETCH_ALL_AREA_PROGRESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AreaProgressActionTypes.FETCH_ALL_AREA_PROGRESS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AreaProgressActionTypes.FETCH_ALL_AREA_PROGRESS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AreaProgressActionTypes.FETCH_ALL_AREA_PROGRESS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AreaProgressActionTypes.FETCH_ONE_AREA_PROGRESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AreaProgressActionTypes.FETCH_ONE_AREA_PROGRESS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AreaProgressActionTypes.FETCH_ONE_AREA_PROGRESS_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AreaProgressActionTypes.FETCH_ONE_AREA_PROGRESS_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AreaProgressReducer;
