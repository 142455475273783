import { ApiCallState } from "../Utils";

export type MasterScheduleList = {
  id: number;
  user_id: number;
  project_id: number;
  contract_id: number;
  description: string;
  date: Date;
  type: string;
  type_of_work: string;
  contractor: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type MasterScheduleListStateTypes = {
  fetchAll: ApiCallState<MasterScheduleList[]>;
  fetchOne: ApiCallState<MasterScheduleList | {}>;
};

export const MasterScheduleListActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE_LIST: "FETCH_ALL_MASTER_SCHEDULE_LIST",
  FETCH_ALL_MASTER_SCHEDULE_LIST_RESET: "FETCH_ALL_MASTER_SCHEDULE_LIST_RESET",
  FETCH_ALL_MASTER_SCHEDULE_LIST_FAILURE:
    "FETCH_ALL_MASTER_SCHEDULE_LIST_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_LIST_SUCCESS:
    "FETCH_ALL_MASTER_SCHEDULE_LIST_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE_LIST: "FETCH_ONE_MASTER_SCHEDULE_LIST",
  FETCH_ONE_MASTER_SCHEDULE_LIST_RESET: "FETCH_ONE_MASTER_SCHEDULE_LIST_RESET",
  FETCH_ONE_MASTER_SCHEDULE_LIST_FAILURE:
    "FETCH_ONE_MASTER_SCHEDULE_LIST_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_LIST_SUCCESS:
    "FETCH_ONE_MASTER_SCHEDULE_LIST_SUCCESS",
};
