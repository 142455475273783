import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AdvanceSalaryActionTypes } from "./AdvanceSalary.type";

export function* fetchAllAdvanceSalarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/advance-salary`);
    yield put({
      type: AdvanceSalaryActionTypes.FETCH_ALL_ADVANCE_SALARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AdvanceSalaryActionTypes.FETCH_ALL_ADVANCE_SALARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAdvanceSalarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/advance-salary/${action.payload}`
    );
    yield put({
      type: AdvanceSalaryActionTypes.FETCH_ONE_ADVANCE_SALARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AdvanceSalaryActionTypes.FETCH_ONE_ADVANCE_SALARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAdvanceSalarys() {
  yield takeLatest(AdvanceSalaryActionTypes.FETCH_ALL_ADVANCE_SALARY, fetchAllAdvanceSalarys);
}

export function* watcherFetchOneAdvanceSalarys() {
  yield takeLatest(AdvanceSalaryActionTypes.FETCH_ONE_ADVANCE_SALARY, fetchOneAdvanceSalarys);
}
