import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ExternaVacancyAnnouncementActionTypes } from "./ExternaVacancyAnnouncement.type";

export function* fetchAllExternaVacancyAnnouncements(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/external-vacancy-announcement`);
    yield put({
      type: ExternaVacancyAnnouncementActionTypes.FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExternaVacancyAnnouncementActionTypes.FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneExternaVacancyAnnouncements(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/external-vacancy-announcement/${action.payload}`
    );
    yield put({
      type: ExternaVacancyAnnouncementActionTypes.FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExternaVacancyAnnouncementActionTypes.FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllExternaVacancyAnnouncements() {
  yield takeLatest(ExternaVacancyAnnouncementActionTypes.FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT, fetchAllExternaVacancyAnnouncements);
}

export function* watcherFetchOneExternaVacancyAnnouncements() {
  yield takeLatest(ExternaVacancyAnnouncementActionTypes.FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT, fetchOneExternaVacancyAnnouncements);
}
