import { ApartmentBuilding } from "../ApartmentBuilding/ApartmentBuilding/ApartmentBuilding.type";
import { ApartmentUnit } from "../ApartmentBuilding/ApartmentUnit/ApartmentUnit.type";
import { Lead } from "../Lead/Lead.type";
import { ApiCallState } from "../Utils";

export type OpportunityV2 = {
  id: number;

  user_id: number;
  lead_id: number;
  apartment_id: number;
  apartment_unit_id: number;

  date: string;
  probability: number;
  // amount: number;
  status: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  apartment_building: ApartmentBuilding;
  apartment_unit: ApartmentUnit;
  lead: Lead;
};

export type OpportunityV2StateTypes = {
  fetchAll: ApiCallState<OpportunityV2[]>;
  fetchOne: ApiCallState<OpportunityV2 | {}>;
};

export const OpportunityV2ActionTypes = {
  FETCH_ALL_OPPORTUNITY_V2: "FETCH_ALL_OPPORTUNITY_V2",
  FETCH_ALL_OPPORTUNITY_V2_RESET: "FETCH_ALL_OPPORTUNITY_V2_RESET",
  FETCH_ALL_OPPORTUNITY_V2_FAILURE: "FETCH_ALL_OPPORTUNITY_V2_FAILURE",
  FETCH_ALL_OPPORTUNITY_V2_SUCCESS: "FETCH_ALL_OPPORTUNITY_V2_SUCCESS",

  FETCH_ONE_OPPORTUNITY_V2: "FETCH_ONE_OPPORTUNITY_V2",
  FETCH_ONE_OPPORTUNITY_V2_RESET: "FETCH_ONE_OPPORTUNITY_V2_RESET",
  FETCH_ONE_OPPORTUNITY_V2_FAILURE: "FETCH_ONE_OPPORTUNITY_V2_FAILURE",
  FETCH_ONE_OPPORTUNITY_V2_SUCCESS: "FETCH_ONE_OPPORTUNITY_V2_SUCCESS",
};
