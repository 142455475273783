import { ApiCallState } from "../Utils";

export const ApplicationActionTypes = {
  FETCH_ALL_APPLICATION: "FETCH_ALL_APPLICATION",
  FETCH_ALL_APPLICATION_FAILURE: "FETCH_ALL_APPLICATION_FAILURE",
  FETCH_ALL_APPLICATION_RESET: "FETCH_ALL_APPLICATION_RESET",
  FETCH_ALL_APPLICATION_SUCCESS: "FETCH_ALL_APPLICATION_SUCCESS",

  FETCH_ONE_APPLICATION: "FETCH_ONE_APPLICATION",
  FETCH_ONE_APPLICATION_RESET: "FETCH_ONE_APPLICATION_RESET",
  FETCH_ONE_APPLICATION_FAILURE: "FETCH_ONE_APPLICATION_FAILURE",
  FETCH_ONE_APPLICATION_SUCCESS: "FETCH_ONE_APPLICATION_SUCCESS",
};

export type Application = {
  id: number;
  data: string;
  status: string;
};

export type ApplicationStateTypes = {
  fetchAll: ApiCallState<Application[]>;
  fetchOne: ApiCallState<Application | {}>;
};
