import { ApiCallState } from "../Utils";

export type SalesSchedule = {
  id: number;
  user_id: number;

  month: string;
  no_of_sales: number;
  total_sales_amount: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type SalesScheduleStateTypes = {
  fetchAll: ApiCallState<SalesSchedule[]>;
  fetchOne: ApiCallState<SalesSchedule | {}>;
};

export const SalesScheduleActionTypes = {
  FETCH_ALL_SALES_SCHEDULE: "FETCH_ALL_SALES_SCHEDULE",
  FETCH_ALL_SALES_SCHEDULE_RESET: "FETCH_ALL_SALES_SCHEDULE_RESET",
  FETCH_ALL_SALES_SCHEDULE_FAILURE: "FETCH_ALL_SALES_SCHEDULE_FAILURE",
  FETCH_ALL_SALES_SCHEDULE_SUCCESS: "FETCH_ALL_SALES_SCHEDULE_SUCCESS",

  FETCH_ONE_SALES_SCHEDULE: "FETCH_ONE_SALES_SCHEDULE",
  FETCH_ONE_SALES_SCHEDULE_RESET: "FETCH_ONE_SALES_SCHEDULE_RESET",
  FETCH_ONE_SALES_SCHEDULE_FAILURE: "FETCH_ONE_SALES_SCHEDULE_FAILURE",
  FETCH_ONE_SALES_SCHEDULE_SUCCESS: "FETCH_ONE_SALES_SCHEDULE_SUCCESS",
};
