import {
  EmployeeDocumentStateTypes,
  EmployeeDocumentActionTypes,
} from "./EmployeeDocument.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EmployeeDocumentStateTypes = {
  fetchAll: resetApiCallState([]),
};

const EmployeeDocumentReducer = (
  state: EmployeeDocumentStateTypes = INITIAL_STATE,
  action: any
): EmployeeDocumentStateTypes => {
  switch (action.type) {
    case EmployeeDocumentActionTypes.FETCH_ALL_EMPLOYEE_DOCUMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeDocumentActionTypes.FETCH_ALL_EMPLOYEE_DOCUMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeDocumentActionTypes.FETCH_ALL_EMPLOYEE_DOCUMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeDocumentActionTypes.FETCH_ALL_EMPLOYEE_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EmployeeDocumentReducer;
