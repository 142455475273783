import { Document } from "../Document/Document.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EmployeeDocument = {
  id: number | null;
  staff: Staff;
  document: Document;
};

export type EmployeeDocumentStateTypes = {
  fetchAll: ApiCallState<EmployeeDocument[]>;
};

export const EmployeeDocumentActionTypes = {
  FETCH_ALL_EMPLOYEE_DOCUMENT: "FETCH_ALL_EMPLOYEE_DOCUMENT",
  FETCH_ALL_EMPLOYEE_DOCUMENT_RESET: "FETCH_ALL_EMPLOYEE_DOCUMENT_RESET",
  FETCH_ALL_EMPLOYEE_DOCUMENT_FAILURE: "FETCH_ALL_EMPLOYEE_DOCUMENT_FAILURE",
  FETCH_ALL_EMPLOYEE_DOCUMENT_SUCCESS: "FETCH_ALL_EMPLOYEE_DOCUMENT_SUCCESS",
};
