import { Expense } from "../Expense/Expense.type";
import { Payment } from "../Payment/Payment.type";
import { ApiCallState } from "../Utils";

export type Account = {
  id: number;
  name: string;
  type: string;
  balance: number;
  expenses: Expense[];
  payments: Payment[];
  createdAt: Date;
};

export type AccountStateTypes = {
  fetchAll: ApiCallState<Account[]>;
  fetchOne: ApiCallState<Account | {}>;
};

export const AccountActionTypes = {
  FETCH_ALL_ACCOUNT: "FETCH_ALL_ACCOUNT",
  FETCH_ALL_ACCOUNT_RESET: "FETCH_ALL_ACCOUNT_RESET",
  FETCH_ALL_ACCOUNT_FAILURE: "FETCH_ALL_ACCOUNT_FAILURE",
  FETCH_ALL_ACCOUNT_SUCCESS: "FETCH_ALL_ACCOUNT_SUCCESS",

  FETCH_ONE_ACCOUNT: "FETCH_ONE_ACCOUNT",
  FETCH_ONE_ACCOUNT_RESET: "FETCH_ONE_ACCOUNT_RESET",
  FETCH_ONE_ACCOUNT_FAILURE: "FETCH_ONE_ACCOUNT_FAILURE",
  FETCH_ONE_ACCOUNT_SUCCESS: "FETCH_ONE_ACCOUNT_SUCCESS",
};
