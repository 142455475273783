import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { FixedAssetOutActionTypes } from "./FixedAssetOut.type";

export function* fetchAllFixedAssetOut(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-out?${formatQuery(action)}`
    );
    yield put({
      type: FixedAssetOutActionTypes.FETCH_ALL_FIXED_ASSET_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetOutActionTypes.FETCH_ALL_FIXED_ASSET_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFixedAssetOut(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-out?${action.payload}`
    );
    yield put({
      type: FixedAssetOutActionTypes.FETCH_ONE_FIXED_ASSET_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetOutActionTypes.FETCH_ONE_FIXED_ASSET_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportFixedAssetOut(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-out/report?${formatQuery(action)}`
    );
    yield put({
      type: FixedAssetOutActionTypes.FETCH_REPORT_FIXED_ASSET_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetOutActionTypes.FETCH_REPORT_FIXED_ASSET_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFixedAssetOut() {
  yield takeLatest(
    FixedAssetOutActionTypes.FETCH_ALL_FIXED_ASSET_OUT,
    fetchAllFixedAssetOut
  );
}

export function* watcherFetchOneFixedAssetOut() {
  yield takeLatest(
    FixedAssetOutActionTypes.FETCH_ONE_FIXED_ASSET_OUT,
    fetchOneFixedAssetOut
  );
}

export function* watcherFetchReportFixedAssetOut() {
  yield takeLatest(
    FixedAssetOutActionTypes.FETCH_REPORT_FIXED_ASSET_OUT,
    fetchReportFixedAssetOut
  );
}
