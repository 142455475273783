import { LeaveStateTypes, LeaveActionTypes } from "./Leave.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: LeaveStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllFromTo: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const LeaveReducer = (
  state: LeaveStateTypes = INITIAL_STATE,
  action: any
): LeaveStateTypes => {
  switch (action.type) {
    case LeaveActionTypes.FETCH_ALL_LEAVE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case LeaveActionTypes.FETCH_ALL_LEAVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LeaveActionTypes.FETCH_ALL_LEAVE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LeaveActionTypes.FETCH_ALL_LEAVE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case LeaveActionTypes.FETCH_ALL_FROM_TO_LEAVE:
      return {
        ...state,
        fetchAllFromTo: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case LeaveActionTypes.FETCH_ALL_FROM_TO_LEAVE_RESET:
      return {
        ...state,
        fetchAllFromTo: resetApiCallState([]),
      };
    case LeaveActionTypes.FETCH_ALL_FROM_TO_LEAVE_FAILURE:
      return {
        ...state,
        fetchAllFromTo: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LeaveActionTypes.FETCH_ALL_FROM_TO_LEAVE_SUCCESS:
      return {
        ...state,
        fetchAllFromTo: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case LeaveActionTypes.FETCH_ONE_LEAVE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case LeaveActionTypes.FETCH_ONE_LEAVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LeaveActionTypes.FETCH_ONE_LEAVE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LeaveActionTypes.FETCH_ONE_LEAVE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default LeaveReducer;
