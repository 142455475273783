import { SickLeaveStateTypes, SickLeaveActionTypes } from "./SickLeave.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SickLeaveStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SickLeaveReducer = (
  state: SickLeaveStateTypes = INITIAL_STATE,
  action: any
): SickLeaveStateTypes => {
  switch (action.type) {
    case SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SickLeaveActionTypes.FETCH_ONE_SICK_LEAVE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SickLeaveActionTypes.FETCH_ONE_SICK_LEAVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SickLeaveActionTypes.FETCH_ONE_SICK_LEAVE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SickLeaveActionTypes.FETCH_ONE_SICK_LEAVE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SickLeaveReducer;
