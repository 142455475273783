import { ApiCallState } from "../Utils";

export type Attendance = {
  id: number,
  data: AttendanceItem[]
};

export type AttendanceItem = {
  id?: number,
  name: string,
  staff_id: number,
  absent: number,
  attendance_id?: number,
}

export type AttendanceStateTypes = {
  fetchAll: ApiCallState<Attendance | {}>;
  fetchOne: ApiCallState<Attendance | {}>;
};

export const AttendanceActionTypes = {
  FETCH_ALL_ATTENDANCE: "FETCH_ALL_ATTENDANCE",
  FETCH_ALL_ATTENDANCE_RESET: "FETCH_ALL_ATTENDANCE_RESET",
  FETCH_ALL_ATTENDANCE_FAILURE: "FETCH_ALL_ATTENDANCE_FAILURE",
  FETCH_ALL_ATTENDANCE_SUCCESS: "FETCH_ALL_ATTENDANCE_SUCCESS",

  FETCH_ONE_ATTENDANCE: "FETCH_ONE_ATTENDANCE",
  FETCH_ONE_ATTENDANCE_RESET: "FETCH_ONE_ATTENDANCE_RESET",
  FETCH_ONE_ATTENDANCE_FAILURE: "FETCH_ONE_ATTENDANCE_FAILURE",
  FETCH_ONE_ATTENDANCE_SUCCESS: "FETCH_ONE_ATTENDANCE_SUCCESS",
};
