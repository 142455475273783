import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Document = {
  id: number;
  name: string;
  type: string;
  module: string;
  date: string;
  url: string;
  size: number;
  project: Project;
  is_private: boolean;
  document_of: string;
  contract_amount: number;
  is_public: boolean;
  shared_documents?: {
    user_id: number;
    document_id: number;
    shared_by_id: number;
    user: User;
    id: number;
  }[];
  shared_by: {
    full_name: string;
    id: number;
  };
  user_id: number;
  createdAt: string;
};

export type DocumentStateTypes = {
  fetchAll: ApiCallState<Document[]>;
};

export const DocumentActionTypes = {
  FETCH_ALL_DOCUMENT: "FETCH_ALL_DOCUMENT",
  FETCH_ALL_DOCUMENT_RESET: "FETCH_ALL_DOCUMENT_RESET",
  FETCH_ALL_DOCUMENT_FAILURE: "FETCH_ALL_DOCUMENT_FAILURE",
  FETCH_ALL_DOCUMENT_SUCCESS: "FETCH_ALL_DOCUMENT_SUCCESS",
};
