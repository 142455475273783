import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type LabourBudget = {
  id: number;
  budget_month: string;
  project_id: number;
  labour_type: string;
  project: Project;
  user: User;
  labour_budget_items: LabourBudgetItem[];
};

export type LabourBudgetItem = {
  id: number;
  labour_budget_id: number;
  name: string;
  quantity: number;
  actual_quantity: number;
  unit_price: number;
  remark: string;
};

export type LabourBudgetStateTypes = {
  fetchAll: ApiCallState<LabourBudget[]>;
  fetchOne: ApiCallState<LabourBudget | {}>;
};

export const LabourBudgetActionTypes = {
  FETCH_ALL_LABOUR_BUDGET: "FETCH_ALL_LABOUR_BUDGET",
  FETCH_ALL_LABOUR_BUDGET_RESET: "FETCH_ALL_LABOUR_BUDGET_RESET",
  FETCH_ALL_LABOUR_BUDGET_FAILURE: "FETCH_ALL_LABOUR_BUDGET_FAILURE",
  FETCH_ALL_LABOUR_BUDGET_SUCCESS: "FETCH_ALL_LABOUR_BUDGET_SUCCESS",

  FETCH_ONE_LABOUR_BUDGET: "FETCH_ONE_LABOUR_BUDGET",
  FETCH_ONE_LABOUR_BUDGET_RESET: "FETCH_ONE_LABOUR_BUDGET_RESET",
  FETCH_ONE_LABOUR_BUDGET_FAILURE: "FETCH_ONE_LABOUR_BUDGET_FAILURE",
  FETCH_ONE_LABOUR_BUDGET_SUCCESS: "FETCH_ONE_LABOUR_BUDGET_SUCCESS",
};
