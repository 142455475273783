import { resetApiCallState } from "../Utils";
import {
  MaterialTransferActions,
  MaterialTransferStateTypes,
} from "./MaterialTransfer.type";

const INITIAL_STATE: MaterialTransferStateTypes = {
  fetchAll: resetApiCallState([]),
};

const MaterialTransferReducer = (
  state: MaterialTransferStateTypes = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case MaterialTransferActions.FETCH_MATERIAL_TRANSFER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialTransferActions.FETCH_MATERIAL_TRANSFER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: action.payload,
          isPending: false,
          isSuccessful: true,
        },
      };

    case MaterialTransferActions.FETCH_MATERIAL_TRANSFER_ERROR:
      return {
        ...state,
        fetchAll: {
          error: action.payload,
          payload: [],
          isPending: false,
          isSuccessful: false,
        },
      };
    default:
      return state;
  }
};

export default MaterialTransferReducer;
