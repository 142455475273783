import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeAllowanceActionTypes } from "./EmployeeAllowance.type";

export function* fetchAllEmployeeAllowances(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      query +=
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action?.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/employee-allowance${query}`
    );
    yield put({
      type: EmployeeAllowanceActionTypes.FETCH_ALL_EMPLOYEE_ALLOWANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeAllowanceActionTypes.FETCH_ALL_EMPLOYEE_ALLOWANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeeAllowances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee-allowance/${action.payload}`
    );
    yield put({
      type: EmployeeAllowanceActionTypes.FETCH_ONE_EMPLOYEE_ALLOWANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeAllowanceActionTypes.FETCH_ONE_EMPLOYEE_ALLOWANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeAllowances() {
  yield takeLatest(
    EmployeeAllowanceActionTypes.FETCH_ALL_EMPLOYEE_ALLOWANCE,
    fetchAllEmployeeAllowances
  );
}

export function* watcherFetchOneEmployeeAllowances() {
  yield takeLatest(
    EmployeeAllowanceActionTypes.FETCH_ONE_EMPLOYEE_ALLOWANCE,
    fetchOneEmployeeAllowances
  );
}
