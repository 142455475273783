import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllowableScaleActionTypes } from "./AllowableScale.type";

export function* fetchAllAllowableScales(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allowable-scale`);
    yield put({
      type: AllowableScaleActionTypes.FETCH_ALL_ALLOWABLE_SCALE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllowableScaleActionTypes.FETCH_ALL_ALLOWABLE_SCALE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllowableScales(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allowable-scale/${action.payload}`
    );
    yield put({
      type: AllowableScaleActionTypes.FETCH_ONE_ALLOWABLE_SCALE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllowableScaleActionTypes.FETCH_ONE_ALLOWABLE_SCALE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllowableScales() {
  yield takeLatest(AllowableScaleActionTypes.FETCH_ALL_ALLOWABLE_SCALE, fetchAllAllowableScales);
}

export function* watcherFetchOneAllowableScales() {
  yield takeLatest(AllowableScaleActionTypes.FETCH_ONE_ALLOWABLE_SCALE, fetchOneAllowableScales);
}
