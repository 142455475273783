import { Moment } from "moment";
import React from "react";
import { EquipmentPlan } from "../EquipmentPlan/EquipmentPlan.type";
import { ApiCallState } from "../Utils";

export type EquipmentUsagePlan = {
  id?: number;
  equipment_plan_id?: number;
  avi: number;
  operational_hour: number;
  std_fuel_consumption: number;
  fuel_rate: number;
  rental_rate: number;
  date_type?: string;
  date: any;
  project_id?: number;
  equipment_plan: {
    required: number;
  };
};

export type EquipmentUsagePlanStateTypes = {
  fetchAll: ApiCallState<EquipmentUsagePlan[]>;
};

export type EquipmentUsagePlanActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const EquipmentUsagePlanActionTypes = {
  FETCH_ALL_EQUIPMENT_USAGE_PLAN: "FETCH_ALL_EQUIPMENT_USAGE_PLAN",
  FETCH_ALL_EQUIPMENT_USAGE_PLAN_RESET: "FETCH_ALL_EQUIPMENT_USAGE_PLAN_RESET",
  FETCH_ALL_EQUIPMENT_USAGE_PLAN_FAILURE:
    "FETCH_ALL_EQUIPMENT_USAGE_PLAN_FAILURE",
  FETCH_ALL_EQUIPMENT_USAGE_PLAN_SUCCESS:
    "FETCH_ALL_EQUIPMENT_USAGE_PLAN_SUCCESS",
};
