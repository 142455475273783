import {
  EquipmentWOStateTypes,
  EquipmentWOActionTypes,
} from "./EquipmentWO.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EquipmentWOStateTypes = {
  fetchAll: resetApiCallState([]),
};

const EquipmentWOReducer = (
  state: EquipmentWOStateTypes = INITIAL_STATE,
  action: any
): EquipmentWOStateTypes => {
  switch (action.type) {
    case EquipmentWOActionTypes.FETCH_ALL_EQUIPMENT_WO:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentWOActionTypes.FETCH_ALL_EQUIPMENT_WO_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EquipmentWOActionTypes.FETCH_ALL_EQUIPMENT_WO_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EquipmentWOActionTypes.FETCH_ALL_EQUIPMENT_WO_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EquipmentWOReducer;
