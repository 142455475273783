import { ApiCallState } from "../Utils";

export type StaffAttendance = {
  id: number;
  name: string;
  description: string;
  unique_uuid: string;
  type: string;
  user_id: number;
  date: string;
  in_time: string;
  out_time: string;
  in_format: string;
  out_format: string;
  scope: string;
};

export type StaffAttendanceStateTypes = {
  fetchAll: ApiCallState<StaffAttendance[]>;
};

export const StaffAttendanceActionTypes = {
  FETCH_ALL_STAFF_ATTENDANCE: "FETCH_ALL_STAFF_ATTENDANCE",
  FETCH_ALL_STAFF_ATTENDANCE_RESET: "FETCH_ALL_STAFF_ATTENDANCE_RESET",
  FETCH_ALL_STAFF_ATTENDANCE_FAILURE: "FETCH_ALL_STAFF_ATTENDANCE_FAILURE",
  FETCH_ALL_STAFF_ATTENDANCE_SUCCESS: "FETCH_ALL_ATTENDANCE_SUCCESS",
};
