import { Material } from "./../Material/Material.type";
import { ApiCallState } from "../Utils";

export type ProcurementPlan = {
  id: number;
  date: string;
  type: "total" | "month";
  project_id: number;
  procurement_plan_items: ProcurementPlanItem[];
};

export type ProcurementPlanItem = {
  material_id: any;
  material?: Material;
  quantity: number;

  key: number;
  id: any;
};

export type ProcurementPlanStateTypes = {
  fetchAll: ApiCallState<ProcurementPlan[]>;
  fetchOne: ApiCallState<ProcurementPlan | {}>;
};

export const ProcurementPlanActionTypes = {
  FETCH_ALL_PROCUREMENT_PLAN: "FETCH_ALL_PROCUREMENT_PLAN",
  FETCH_ALL_PROCUREMENT_PLAN_RESET: "FETCH_ALL_PROCUREMENT_PLAN_RESET",
  FETCH_ALL_PROCUREMENT_PLAN_FAILURE: "FETCH_ALL_PROCUREMENT_PLAN_FAILURE",
  FETCH_ALL_PROCUREMENT_PLAN_SUCCESS: "FETCH_ALL_PROCUREMENT_PLAN_SUCCESS",

  FETCH_ONE_PROCUREMENT_PLAN: "FETCH_ONE_PROCUREMENT_PLAN",
  FETCH_ONE_PROCUREMENT_PLAN_RESET: "FETCH_ONE_PROCUREMENT_PLAN_RESET",
  FETCH_ONE_PROCUREMENT_PLAN_FAILURE: "FETCH_ONE_PROCUREMENT_PLAN_FAILURE",
  FETCH_ONE_PROCUREMENT_PLAN_SUCCESS: "FETCH_ONE_PROCUREMENT_PLAN_SUCCESS",
};
