import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentUsagePlanActionTypes } from "./EquipmentUsagePlan.type";

export function* fetchAllEquipmentUsagePlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment_usage_plan?project_id=${action.payload.project_id}&date_type=${action.payload.date_type}&date=${action.payload.date}`
    );
    yield put({
      type:
        EquipmentUsagePlanActionTypes.FETCH_ALL_EQUIPMENT_USAGE_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type:
        EquipmentUsagePlanActionTypes.FETCH_ALL_EQUIPMENT_USAGE_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentUsagePlans() {
  yield takeLatest(
    EquipmentUsagePlanActionTypes.FETCH_ALL_EQUIPMENT_USAGE_PLAN,
    fetchAllEquipmentUsagePlans
  );
}
