import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { VariationFileActionTypes } from "./VariationFile.type";

export function* fetchAllVariationFiles(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/variation-file?project_id=${action.payload.project_id}`
    );
    yield put({
      type: VariationFileActionTypes.FETCH_ALL_VARIATION_FILE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VariationFileActionTypes.FETCH_ALL_VARIATION_FILE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneVariationFiles(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/variation-file/${action.payload}`
    );
    yield put({
      type: VariationFileActionTypes.FETCH_ONE_VARIATION_FILE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VariationFileActionTypes.FETCH_ONE_VARIATION_FILE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllVariationFiles() {
  yield takeLatest(
    VariationFileActionTypes.FETCH_ALL_VARIATION_FILE,
    fetchAllVariationFiles
  );
}

export function* watcherFetchOneVariationFiles() {
  yield takeLatest(
    VariationFileActionTypes.FETCH_ONE_VARIATION_FILE,
    fetchOneVariationFiles
  );
}
