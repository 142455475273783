import {
  MasterScheduleStateTypes,
  MasterScheduleActionTypes,
} from "./MasterSchedule.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MasterScheduleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchMain:resetApiCallState([]),
};

const MasterScheduleReducer = (
  state: MasterScheduleStateTypes = INITIAL_STATE,
  action: any
): MasterScheduleStateTypes => {
  switch (action.type) {
    case MasterScheduleActionTypes.FETCH_ALL_MASTER_SCHEDULE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleActionTypes.FETCH_ALL_MASTER_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleActionTypes.FETCH_ALL_MASTER_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MasterScheduleActionTypes.FETCH_ONE_MASTER_SCHEDULE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterScheduleActionTypes.FETCH_ONE_MASTER_SCHEDULE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterScheduleActionTypes.FETCH_ONE_MASTER_SCHEDULE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterScheduleActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };



      case MasterScheduleActionTypes.FETCH_MAIN_MASTER_SCHEDULE:
        return {
          ...state,
          fetchMain: {
            error: null,
            payload: [],
            isPending: true,
            isSuccessful: false,
          },
        };
      case MasterScheduleActionTypes.FETCH_MAIN_MASTER_SCHEDULE_RESET:
        return {
          ...state,
          fetchMain: resetApiCallState([]),
        };
      case MasterScheduleActionTypes.FETCH_MAIN_MASTER_SCHEDULE_FAILURE:
        return {
          ...state,
          fetchMain: {
            payload:[],
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case MasterScheduleActionTypes.FETCH_MAIN_MASTER_SCHEDULE_SUCCESS:
        return {
          ...state,
          fetchMain: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };
  

    default:
      return state;
  }
};

export default MasterScheduleReducer;
