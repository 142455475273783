import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Absence = {
  id: number;
  staff: Staff;
  date: string;
  staff_id: number;
};

export type AbsenceStateTypes = {
  fetchAll: ApiCallState<Absence[]>;
  fetchOne: ApiCallState<Absence | {}>;
};

export const AbsenceActionTypes = {
  FETCH_ALL_ABSENCE: "FETCH_ALL_ABSENCE",
  FETCH_ALL_ABSENCE_RESET: "FETCH_ALL_ABSENCE_RESET",
  FETCH_ALL_ABSENCE_FAILURE: "FETCH_ALL_ABSENCE_FAILURE",
  FETCH_ALL_ABSENCE_SUCCESS: "FETCH_ALL_ABSENCE_SUCCESS",

  FETCH_ONE_ABSENCE: "FETCH_ONE_ABSENCE",
  FETCH_ONE_ABSENCE_RESET: "FETCH_ONE_ABSENCE_RESET",
  FETCH_ONE_ABSENCE_FAILURE: "FETCH_ONE_ABSENCE_FAILURE",
  FETCH_ONE_ABSENCE_SUCCESS: "FETCH_ONE_ABSENCE_SUCCESS",
};
