import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type PerformanceEvaluationRecord = {
  id: number;
  report_date: string;
  performance_evaluation_record_items: PerformanceEvaluationRecordItem[];
  year: string;
  department_id: number;
  prepared_by_id: number;
  prepared_by: User;
  staff: Staff;
  user_id: number;
};

export type PerformanceEvaluationRecordItem = {
  id: number;
  performance_evaluation_record_id: number;
  employee_id: number;
  position: string;
  staff: Staff;
  first_half_year: number;
  second_half_year: number;
  remark: string;
}

export type PerformanceEvaluationRecordStateTypes = {
  fetchAll: ApiCallState<PerformanceEvaluationRecord[]>;
  fetchOne: ApiCallState<PerformanceEvaluationRecord | {}>;
  fetchPaged: ApiCallState<PagedData<PerformanceEvaluationRecord[]>>
};

export const PerformanceEvaluationRecordActionTypes = {
  FETCH_ALL_PERFORMANCE_EVALUATION_RECORD: "FETCH_ALL_PERFORMANCE_EVALUATION_RECORD",
  FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_RESET: "FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_RESET",
  FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_FAILURE: "FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_FAILURE",
  FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_SUCCESS: "FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_SUCCESS",

  FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD: "FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD",
  FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_RESET: "FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_RESET",
  FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_FAILURE: "FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_FAILURE",
  FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_SUCCESS: "FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_SUCCESS",

  FETCH_ONE_PERFORMANCE_EVALUATION_RECORD: "FETCH_ONE_PERFORMANCE_EVALUATION_RECORD",
  FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_RESET: "FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_RESET",
  FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_FAILURE: "FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_FAILURE",
  FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_SUCCESS: "FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_SUCCESS",
};
