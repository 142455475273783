import _ from "lodash";

class BuildingBudgetType {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    const previous_type = [];
    let start = -1;
    this.data.forEach((col: any[], index) => {
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[2] && col[3]) {
          this.parsed.push({
            budget_item: col[2],
            budget_type: col[3],
            key: this.parsed.length,
          });
        }
      }
      if (this.type) previous_type.push(this.type);
    });
    return this.parsed;
  }

  
  private isTableState(col: any[]) {
    return (
      _.isString(col[0]) &&
      _.isString(col[1]) &&
      _.isString(col[2]) &&
      _.isString(col[3]) &&
      _.isString(col[4]) &&
      _.isString(col[5]) &&
      _.isString(col[6]) &&
      _.isString(col[7]) &&
      _.isString(col[8]) &&
      _.isString(col[9]) &&
      _.isString(col[10]) &&
      _.isString(col[11])
    );
  }

}
export default BuildingBudgetType;
