import { LabourUsage } from "../LabourUsage/LabourUsage.type";
import { ManpowerOvertime } from "../ManpowerOvertime/ManpowerOvertime.type";
import { Project } from "../Project/Project.type";
import { EmergencyContact } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Labour = {
  id: number;
  type: string;
  name: string;
  description: string;
  salary: number;
  overtime_salary: number;
  address: string;
  project_id: number;
  document_id: number;
  start_date: string;
  manpower_overtimes: ManpowerOvertime[];
  labour_usages: LabourUsage[];
  createdAt: Date;
  emergency_contract: EmergencyContact;
  phone_number: string;
  unique_uuid: string;
  project: Project;
};

export type LabourStateTypes = {
  fetchAll: ApiCallState<Labour[]>;
};

export const LabourActionTypes = {
  FETCH_ALL_LABOUR: "FETCH_ALL_LABOUR",
  FETCH_ALL_LABOUR_RESET: "FETCH_ALL_LABOUR_RESET",
  FETCH_ALL_LABOUR_FAILURE: "FETCH_ALL_LABOUR_FAILURE",
  FETCH_ALL_LABOUR_SUCCESS: "FETCH_ALL_LABOUR_SUCCESS",
};
