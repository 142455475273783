import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeDocumentActionTypes } from "./EmployeeDocument.type";

export function* fetchAllEmployeeDocuments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/employee_document`);
    yield put({
      type: EmployeeDocumentActionTypes.FETCH_ALL_EMPLOYEE_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeDocumentActionTypes.FETCH_ALL_EMPLOYEE_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeDocuments() {
  yield takeLatest(
    EmployeeDocumentActionTypes.FETCH_ALL_EMPLOYEE_DOCUMENT,
    fetchAllEmployeeDocuments
  );
}
