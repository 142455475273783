import {
  ReplenishmentStateTypes,
  ReplenishmentActionTypes,
} from "./Replenishment.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ReplenishmentStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ReplenishmentReducer = (
  state: ReplenishmentStateTypes = INITIAL_STATE,
  action: any
): ReplenishmentStateTypes => {
  switch (action.type) {
    case ReplenishmentActionTypes.FETCH_ALL_REPLENISHMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ReplenishmentActionTypes.FETCH_ALL_REPLENISHMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ReplenishmentActionTypes.FETCH_ALL_REPLENISHMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ReplenishmentActionTypes.FETCH_ALL_REPLENISHMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ReplenishmentActionTypes.FETCH_ONE_REPLENISHMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ReplenishmentActionTypes.FETCH_ONE_REPLENISHMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ReplenishmentActionTypes.FETCH_ONE_REPLENISHMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ReplenishmentActionTypes.FETCH_ONE_REPLENISHMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ReplenishmentReducer;
