import {
  EquipmentPlanStateTypes,
  EquipmentPlanActionTypes,
} from "./EquipmentPlan.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EquipmentPlanStateTypes = {
  fetchAll: resetApiCallState([]),
};

const EquipmentPlanReducer = (
  state: EquipmentPlanStateTypes = INITIAL_STATE,
  action: any
): EquipmentPlanStateTypes => {
  switch (action.type) {
    case EquipmentPlanActionTypes.FETCH_ALL_EQUIPMENT_PLAN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentPlanActionTypes.FETCH_ALL_EQUIPMENT_PLAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EquipmentPlanActionTypes.FETCH_ALL_EQUIPMENT_PLAN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EquipmentPlanActionTypes.FETCH_ALL_EQUIPMENT_PLAN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EquipmentPlanReducer;
