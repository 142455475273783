import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Store } from "../Store/Store.type";

import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type InterGoodTransferOut = {
  id: number;
  key?: any;
  date: string;
  to_id: number;
  from_id: number;
  good_out_dispatching_warehouse: Store;
  good_out_from_project: Store;
  good_out_receiving_warehouse: Store;
  good_out_to_project: Store;
  user_id: number;
  driver: string;
  plate_no: string;
  reason: string;
  from_project_id: number;
  inter_good_transfer_out_items: InterGoodTransferOutItem[];
  go_prepared_by?: User;
  is_done: boolean;
  material_requisition_id: number;
  inter_good_transfer_out_statuses: InterGoodTransferOutStatus[];
  status: InterGoodTransferOutStatus[];
  received_by: string;
  approved_by: string;
  pad_ref: string;
  go_received_by?: User;
  go_approved_by?: User;
  go_issued_by?: User;
  go_inspected_by?: User;
  go_checked_by?: User;
  updatedAt: string;
  is_void: boolean;
};

export type InterGoodTransferOutReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: any;
  pad_ref: string;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type InterGoodTransferOutStatus = {
  inter_good_transfer_out_id:number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type InterGoodTransferOutItem = {
  id: number;
  inter_good_transfer_out_id:number;
  material_requisition_id: number;
  material_id: number;
  material: Material;
  material_requisition: MaterialRequisition;

  quantity: number;
  identification_number: number;
  unit: string;
};

export type InterGoodTransferOutStateTypes = {
  fetchAll: ApiCallState<InterGoodTransferOut[]>;
  fetchReport: ApiCallState<InterGoodTransferOutReport[]>;
  fetchOne: ApiCallState<InterGoodTransferOut | {}>;
};

export const InterGoodTransferOutActionTypes = {
  FETCH_ALL_INTER_GOOD_TRANSFER_OUT: "FETCH_ALL_INTER_GOOD_TRANSFER_OUT",
  FETCH_ALL_INTER_GOOD_TRANSFER_OUT_RESET:
    "FETCH_ALL_INTER_GOOD_TRANSFER_OUT_RESET",
  FETCH_ALL_INTER_GOOD_TRANSFER_OUT_FAILURE:
    "FETCH_ALL_INTER_GOOD_TRANSFER_OUT_FAILURE",
  FETCH_ALL_INTER_GOOD_TRANSFER_OUT_SUCCESS:
    "FETCH_ALL_INTER_GOOD_TRANSFER_OUT_SUCCESS",

  FETCH_ONE_INTER_GOOD_TRANSFER_OUT: "FETCH_ONE_INTER_GOOD_TRANSFER_OUT",
  FETCH_ONE_INTER_GOOD_TRANSFER_OUT_RESET:
    "FETCH_ONE_INTER_GOOD_TRANSFER_OUT_RESET",
  FETCH_ONE_INTER_GOOD_TRANSFER_OUT_FAILURE:
    "FETCH_ONE_INTER_GOOD_TRANSFER_OUT_FAILURE",
  FETCH_ONE_INTER_GOOD_TRANSFER_OUT_SUCCESS:
    "FETCH_ONE_INTER_GOOD_TRANSFER_OUT_SUCCESS",

  FETCH_REPORT_INTER_GOOD_TRANSFER_OUT: "FETCH_REPORT_INTER_GOOD_TRANSFER_OUT",
  FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_RESET:
    "FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_RESET",
  FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_FAILURE:
    "FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_FAILURE",
  FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_SUCCESS:
    "FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_SUCCESS",
};
