import {
  ProformaComparisonStateTypes,
  ProformaComparisonActionTypes,
} from "./ProformaComparison.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProformaComparisonStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProformaComparisonReducer = (
  state: ProformaComparisonStateTypes = INITIAL_STATE,
  action: any
): ProformaComparisonStateTypes => {
  switch (action.type) {
    case ProformaComparisonActionTypes.FETCH_ALL_PROFORMA_COMPARISON:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProformaComparisonActionTypes.FETCH_ALL_PROFORMA_COMPARISON_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProformaComparisonActionTypes.FETCH_ALL_PROFORMA_COMPARISON_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProformaComparisonActionTypes.FETCH_ALL_PROFORMA_COMPARISON_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProformaComparisonActionTypes.FETCH_ONE_PROFORMA_COMPARISON:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProformaComparisonActionTypes.FETCH_ONE_PROFORMA_COMPARISON_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProformaComparisonActionTypes.FETCH_ONE_PROFORMA_COMPARISON_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProformaComparisonActionTypes.FETCH_ONE_PROFORMA_COMPARISON_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProformaComparisonReducer;
