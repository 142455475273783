import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractBudgetActionTypes } from "./SubContractBudget.type";
import { formatQuery } from "../Utils";

export function* fetchAllSubContractBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-budget?${formatQuery(action)}`
    );
    yield put({
      type: SubContractBudgetActionTypes.FETCH_ALL_SUB_CONTRACT_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractBudgetActionTypes.FETCH_ALL_SUB_CONTRACT_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-budget/${action.payload}`
    );
    yield put({
      type: SubContractBudgetActionTypes.FETCH_ONE_SUB_CONTRACT_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractBudgetActionTypes.FETCH_ONE_SUB_CONTRACT_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractBudgets() {
  yield takeLatest(
    SubContractBudgetActionTypes.FETCH_ALL_SUB_CONTRACT_BUDGET,
    fetchAllSubContractBudgets
  );
}

export function* watcherFetchOneSubContractBudgets() {
  yield takeLatest(
    SubContractBudgetActionTypes.FETCH_ONE_SUB_CONTRACT_BUDGET,
    fetchOneSubContractBudgets
  );
}
