import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MaterialBudget = {
  id: number;
  budget_month: string;
  project_id: number;
  cost_type: string;
  project: Project;
  user: User;
  material_budget_items: MaterialBudgetItem[];
};

export type MaterialBudgetItem = {
  id: number;
  material_budget_id: number;
  material_id: number;
  unit_price: number;
  scheduled_for_this_month: number;
  actual_consumption:number;
  material_onsite: number;
  material: Material;
};

export type MaterialBudgetStateTypes = {
  fetchAll: ApiCallState<MaterialBudget[]>;
  fetchOne: ApiCallState<MaterialBudget | {}>;
};

export const MaterialBudgetActionTypes = {
  FETCH_ALL_MATERIAL_BUDGET: "FETCH_ALL_MATERIAL_BUDGET",
  FETCH_ALL_MATERIAL_BUDGET_RESET: "FETCH_ALL_MATERIAL_BUDGET_RESET",
  FETCH_ALL_MATERIAL_BUDGET_FAILURE: "FETCH_ALL_MATERIAL_BUDGET_FAILURE",
  FETCH_ALL_MATERIAL_BUDGET_SUCCESS: "FETCH_ALL_MATERIAL_BUDGET_SUCCESS",

  FETCH_ONE_MATERIAL_BUDGET: "FETCH_ONE_MATERIAL_BUDGET",
  FETCH_ONE_MATERIAL_BUDGET_RESET: "FETCH_ONE_MATERIAL_BUDGET_RESET",
  FETCH_ONE_MATERIAL_BUDGET_FAILURE: "FETCH_ONE_MATERIAL_BUDGET_FAILURE",
  FETCH_ONE_MATERIAL_BUDGET_SUCCESS: "FETCH_ONE_MATERIAL_BUDGET_SUCCESS",
};
