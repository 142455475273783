import { resetApiCallState } from "../Utils";
import {
  MaterialInventoryActions,
  MaterialInventoryStateTypes,
} from "./MaterialInventory.type";

const INITIAL_STATE: MaterialInventoryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAnalysis: resetApiCallState({}),
};

const MaterialInventoryReducer = (
  state: MaterialInventoryStateTypes = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case MaterialInventoryActions.FETCH_MATERIAL_INVENTORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: action.payload,
          isPending: false,
          isSuccessful: true,
        },
      };
    case MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ERROR:
      return {
        ...state,
        fetchAll: {
          error: action.payload,
          payload: [],
          isPending: false,
          isSuccessful: false,
        },
      };

    case MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ANALYSIS:
      return {
        ...state,
        fetchAnalysis: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ANALYSIS_SUCCESS:
      return {
        ...state,
        fetchAnalysis: {
          error: null,
          payload: action.payload,
          isPending: false,
          isSuccessful: true,
        },
      };
    case MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ANALYSIS_ERROR:
      return {
        ...state,
        fetchAnalysis: {
          error: action.payload,
          payload: {},
          isPending: false,
          isSuccessful: false,
        },
      };
    case MaterialInventoryActions.FETCH_MATERIAL_INVENTORY_ANALYSIS_RESET:
      return {
        ...state,
        fetchAnalysis: resetApiCallState({}),
      };

    default:
      return state;
  }
};

export default MaterialInventoryReducer;
