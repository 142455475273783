import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffTransferActionTypes } from "./StaffTransfer.type";

export function* fetchAllStaffTransfers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-transfer?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffTransferActionTypes.FETCH_ALL_STAFF_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffTransferActionTypes.FETCH_ALL_STAFF_TRANSFER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffTransfers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-transfer/${action.payload}`
    );
    yield put({
      type: StaffTransferActionTypes.FETCH_ONE_STAFF_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffTransferActionTypes.FETCH_ONE_STAFF_TRANSFER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffTransfers() {
  yield takeLatest(StaffTransferActionTypes.FETCH_ALL_STAFF_TRANSFER, fetchAllStaffTransfers);
}

export function* watcherFetchOneStaffTransfers() {
  yield takeLatest(StaffTransferActionTypes.FETCH_ONE_STAFF_TRANSFER, fetchOneStaffTransfers);
}
