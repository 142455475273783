import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LeaveStartingBalanceActionTypes } from "./LeaveStartingBalance.type";

export function* fetchAllLeaveStartingBalances(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/leave-starting-balance${query}`);
    yield put({
      type: LeaveStartingBalanceActionTypes.FETCH_ALL_LEAVE_STARTING_BALANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeaveStartingBalanceActionTypes.FETCH_ALL_LEAVE_STARTING_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLeaveStartingBalances(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/leave-starting-balance/${action.payload}`);
    yield put({
      type: LeaveStartingBalanceActionTypes.FETCH_ONE_LEAVE_STARTING_BALANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeaveStartingBalanceActionTypes.FETCH_ONE_LEAVE_STARTING_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLeaveStartingBalances() {
  yield takeLatest(LeaveStartingBalanceActionTypes.FETCH_ALL_LEAVE_STARTING_BALANCE, fetchAllLeaveStartingBalances);
}



export function* watcherFetchOneLeaveStartingBalances() {
  yield takeLatest(LeaveStartingBalanceActionTypes.FETCH_ONE_LEAVE_STARTING_BALANCE, fetchOneLeaveStartingBalances);
}
