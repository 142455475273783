import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PerformanceEvaluationRecordActionTypes } from "./PerformanceEvaluationRecord.type";

export function* fetchAllPerformanceEvaluationRecords(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/performance-evaluation-record`);
    yield put({
      type: PerformanceEvaluationRecordActionTypes.FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PerformanceEvaluationRecordActionTypes.FETCH_ALL_PERFORMANCE_EVALUATION_RECORD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedPerformanceEvaluationRecords(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `key=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/performance-evaluation-record?${query}`);
    yield put({
      type: PerformanceEvaluationRecordActionTypes.FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PerformanceEvaluationRecordActionTypes.FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePerformanceEvaluationRecords(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/performance-evaluation-record/${action.payload}`
    );
    yield put({
      type: PerformanceEvaluationRecordActionTypes.FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PerformanceEvaluationRecordActionTypes.FETCH_ONE_PERFORMANCE_EVALUATION_RECORD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPerformanceEvaluationRecords() {
  yield takeLatest(PerformanceEvaluationRecordActionTypes.FETCH_ALL_PERFORMANCE_EVALUATION_RECORD, fetchAllPerformanceEvaluationRecords);
}

export function* watcherFetchPagedPerformanceEvaluationRecords() {
  yield takeLatest(PerformanceEvaluationRecordActionTypes.FETCH_PAGED_PERFORMANCE_EVALUATION_RECORD, fetchPagedPerformanceEvaluationRecords);
}

export function* watcherFetchOnePerformanceEvaluationRecords() {
  yield takeLatest(PerformanceEvaluationRecordActionTypes.FETCH_ONE_PERFORMANCE_EVALUATION_RECORD, fetchOnePerformanceEvaluationRecords);
}
