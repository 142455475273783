import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EstimatedBuildingCostActionTypes } from "./EstimatedBuildingCost.type";

export function* fetchAllEstimatedBuildingCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/estimated-building-cost?building_id=${action.payload?.building_id}`);
    yield put({
      type: EstimatedBuildingCostActionTypes.FETCH_ALL_ESTIMATED_BUILDING_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EstimatedBuildingCostActionTypes.FETCH_ALL_ESTIMATED_BUILDING_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEstimatedBuildingCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/estimated-building-cost/${action.payload}`
    );
    yield put({
      type: EstimatedBuildingCostActionTypes.FETCH_ONE_ESTIMATED_BUILDING_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EstimatedBuildingCostActionTypes.FETCH_ONE_ESTIMATED_BUILDING_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEstimatedBuildingCosts() {
  yield takeLatest(EstimatedBuildingCostActionTypes.FETCH_ALL_ESTIMATED_BUILDING_COST, fetchAllEstimatedBuildingCosts);
}

export function* watcherFetchOneEstimatedBuildingCosts() {
  yield takeLatest(EstimatedBuildingCostActionTypes.FETCH_ONE_ESTIMATED_BUILDING_COST, fetchOneEstimatedBuildingCosts);
}
