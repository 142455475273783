import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type ExamSummary= {
  id: number;
  date:string;
  job_position:string;
  salary:number;
  committee_proposal:string;
  committee_member:string;
  prepared_by:number;
  exam_summary_items:ExamSummaryItem[];
};

export type ExamSummaryItem= {
    id:number;
    exam_summary_id:number;
    staff_id:number;
    qualification:number;
    interview:number;
    practical:number
    performance_evaluation:number; 
    breach_of_discipline:number;
    converted_average:number;
    rank:number;
    remark:string;
    staff:Staff;
}

export type ExamSummaryStateTypes = {
  fetchAll: ApiCallState<ExamSummary[]>;
  fetchOne: ApiCallState<ExamSummary | {}>;
};

export const ExamSummaryActionTypes = {
  FETCH_ALL_EXAM_SUMMARY: "FETCH_ALL_EXAM_SUMMARY",
  FETCH_ALL_EXAM_SUMMARY_RESET: "FETCH_ALL_EXAM_SUMMARY_RESET",
  FETCH_ALL_EXAM_SUMMARY_FAILURE: "FETCH_ALL_EXAM_SUMMARY_FAILURE",
  FETCH_ALL_EXAM_SUMMARY_SUCCESS: "FETCH_ALL_EXAM_SUMMARY_SUCCESS",

  FETCH_ONE_EXAM_SUMMARY: "FETCH_ONE_EXAM_SUMMARY",
  FETCH_ONE_EXAM_SUMMARY_RESET: "FETCH_ONE_EXAM_SUMMARY_RESET",
  FETCH_ONE_EXAM_SUMMARY_FAILURE: "FETCH_ONE_EXAM_SUMMARY_FAILURE",
  FETCH_ONE_EXAM_SUMMARY_SUCCESS: "FETCH_ONE_EXAM_SUMMARY_SUCCESS",
};
