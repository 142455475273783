import { ApiCallState } from "../Utils";

export type AllowancePolicy = {
  id: number;
  policy_id: number;
  name: string;
  type: string;
  percentage: number | null;
  amount: number | null;
  is_default: number;
};

export type AllowancePolicyStateTypes = {
  fetchAll: ApiCallState<AllowancePolicy[]>;
  fetchOne: ApiCallState<AllowancePolicy | {}>;
};

export const AllowancePolicyActionTypes = {
  FETCH_ALL_ALLOWANCE_POLICY: "FETCH_ALL_ALLOWANCE_POLICY",
  FETCH_ALL_ALLOWANCE_POLICY_RESET: "FETCH_ALL_ALLOWANCE_POLICY_RESET",
  FETCH_ALL_ALLOWANCE_POLICY_FAILURE: "FETCH_ALL_ALLOWANCE_POLICY_FAILURE",
  FETCH_ALL_ALLOWANCE_POLICY_SUCCESS: "FETCH_ALL_ALLOWANCE_POLICY_SUCCESS",

  FETCH_ONE_ALLOWANCE_POLICY: "FETCH_ONE_ALLOWANCE_POLICY",
  FETCH_ONE_ALLOWANCE_POLICY_RESET: "FETCH_ONE_ALLOWANCE_POLICY_RESET",
  FETCH_ONE_ALLOWANCE_POLICY_FAILURE: "FETCH_ONE_ALLOWANCE_POLICY_FAILURE",
  FETCH_ONE_ALLOWANCE_POLICY_SUCCESS: "FETCH_ONE_ALLOWANCE_POLICY_SUCCESS",
};
