import { ApiCallState, PagedData } from "../Utils";

export type CommissionPayment = {
  id?: number;
  date:Date,
  buyer_name: string;
  total_contract_amount: string;
  this_payment_amount: string;
  payment_form: CommissionPaymentForm[];
  commission_setting_id:number;
  sales_payment_id:number
};
export type CommissionPaymentForm = {
  key: number;
  agent_name: string;
  role: string;
  commission_percentage: string;
  current_commission_amount: string;
  total_commission_paid_percentage: string;
  tax_deduction_percentage: string;
  tax_deduction_amount: string;
  net_commission_amount: string;
  total_commission_paid_upto_now: string;
  total_commission_amount_upto_now: string;
};

export type CommissionPaymentStateTypes = {
  fetchAll: ApiCallState<CommissionPayment[]>;
  fetchOne: ApiCallState<CommissionPayment| {}>;
  fetchPaged:ApiCallState<PagedData<CommissionPayment[]>>
};

export const CommissionPaymentActionTypes = {
  FETCH_ALL_COMMISSION_PAYMENT: "FETCH_ALL_COMMISSION_PAYMENT",
  FETCH_ALL_COMMISSION_PAYMENT_RESET: "FETCH_ALL_COMMISSION_PAYMENT_RESET",
  FETCH_ALL_COMMISSION_PAYMENT_FAILURE: "FETCH_ALL_COMMISSION_PAYMENT_FAILURE",
  FETCH_ALL_COMMISSION_PAYMENT_SUCCESS: "FETCH_ALL_COMMISSION_PAYMENT_SUCCESS",

  FETCH_PAGED_COMMISSION_PAYMENT: "FETCH_PAGED_COMMISSION_PAYMENT",
  FETCH_PAGED_COMMISSION_PAYMENT_RESET: "FETCH_PAGED_COMMISSION_PAYMENT_RESET",
  FETCH_PAGED_COMMISSION_PAYMENT_FAILURE: "FETCH_PAGED_COMMISSION_PAYMENT_FAILURE",
  FETCH_PAGED_COMMISSION_PAYMENT_SUCCESS: "FETCH_PAGED_COMMISSION_PAYMENT_SUCCESS",

  FETCH_ONE_COMMISSION_PAYMENT: "FETCH_ONE_COMMISSION_PAYMENT",
  FETCH_ONE_COMMISSION_PAYMENT_RESET: "FETCH_ONE_COMMISSION_PAYMENT_RESET",
  FETCH_ONE_COMMISSION_PAYMENT_FAILURE: "FETCH_ONE_COMMISSION_PAYMENT_FAILURE",
  FETCH_ONE_COMMISSION_PAYMENT_SUCCESS: "FETCH_ONE_COMMISSION_PAYMENT_SUCCESS",
};

