import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { Store } from "../Store/Store.type";
import { ApiCallState } from "../Utils";

export interface Inventory extends Material {
  project?: Project;
  store?: Store;
  quantity: number;
  key: number;
  unit_price: number;
  is_edited?: boolean;
}

export type StockMovement = {
  description: string;
  requested_by: string;
  requested_date: string;
  unit: string;
  requested_quantity: number;
  purchased_quantity: number;
  unit_price: number;
  issued_quantity: number;
  issued_date: string;
  issue_requested_by: string;
  material_requisition_id: number;
  purchase_order_id: number;
  good_out_id: number;
};
export type InventoryStateTypes = {
  fetchAll: ApiCallState<Inventory[]>;
  fetchOne: ApiCallState<Inventory | {}>;
  fetchStockMovement: ApiCallState<StockMovement[]>;
};

export const InventoryActionTypes = {
  FETCH_ALL_INVENTORY: "FETCH_ALL_INVENTORY",
  FETCH_ALL_INVENTORY_RESET: "FETCH_ALL_INVENTORY_RESET",
  FETCH_ALL_INVENTORY_FAILURE: "FETCH_ALL_INVENTORY_FAILURE",
  FETCH_ALL_INVENTORY_SUCCESS: "FETCH_ALL_INVENTORY_SUCCESS",

  FETCH_ONE_INVENTORY: "FETCH_ONE_INVENTORY",
  FETCH_ONE_INVENTORY_RESET: "FETCH_ONE_INVENTORY_RESET",
  FETCH_ONE_INVENTORY_FAILURE: "FETCH_ONE_INVENTORY_FAILURE",
  FETCH_ONE_INVENTORY_SUCCESS: "FETCH_ONE_INVENTORY_SUCCESS",

  FETCH_STOCK_MOVEMENT: "FETCH_STOCK_MOVEMENT",
  FETCH_STOCK_MOVEMENT_RESET: "FETCH_STOCK_MOVEMENT_RESET",
  FETCH_STOCK_MOVEMENT_FAILURE: "FETCH_STOCK_MOVEMENT_FAILURE",
  FETCH_STOCK_MOVEMENT_SUCCESS: "FETCH_STOCK_MOVEMENT_SUCCESS",
};
