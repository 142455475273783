import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffExperienceActionTypes } from "./StaffExperience.type";
import { formatQuery } from "../Utils";

export function* fetchAllStaffExperiences(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-experience?${formatQuery(action)}`
    );
    yield put({
      type: StaffExperienceActionTypes.FETCH_ALL_STAFF_EXPERIENCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffExperienceActionTypes.FETCH_ALL_STAFF_EXPERIENCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffExperiences(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-experience/${action.payload}`
    );
    yield put({
      type: StaffExperienceActionTypes.FETCH_ONE_STAFF_EXPERIENCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffExperienceActionTypes.FETCH_ONE_STAFF_EXPERIENCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffExperiences() {
  yield takeLatest(
    StaffExperienceActionTypes.FETCH_ALL_STAFF_EXPERIENCE,
    fetchAllStaffExperiences
  );
}

export function* watcherFetchOneStaffExperiences() {
  yield takeLatest(
    StaffExperienceActionTypes.FETCH_ONE_STAFF_EXPERIENCE,
    fetchOneStaffExperiences
  );
}
