import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DepartmentActionTypes } from "./Department.type";

export function* fetchAllDepartments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/department`);
    yield put({
      type: DepartmentActionTypes.FETCH_ALL_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DepartmentActionTypes.FETCH_ALL_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDepartments() {
  yield takeLatest(
    DepartmentActionTypes.FETCH_ALL_DEPARTMENT,
    fetchAllDepartments
  );
}
