import { WorkOvertimeStateTypes, WorkOvertimeActionTypes } from "./WorkOvertime.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: WorkOvertimeStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const WorkOvertimeReducer = (
  state: WorkOvertimeStateTypes = INITIAL_STATE,
  action: any
): WorkOvertimeStateTypes => {
  switch (action.type) {
    case WorkOvertimeActionTypes.FETCH_ALL_WORK_OVERTIME:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkOvertimeActionTypes.FETCH_ALL_WORK_OVERTIME_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkOvertimeActionTypes.FETCH_ALL_WORK_OVERTIME_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkOvertimeActionTypes.FETCH_ALL_WORK_OVERTIME_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WorkOvertimeActionTypes.FETCH_ONE_WORK_OVERTIME:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkOvertimeActionTypes.FETCH_ONE_WORK_OVERTIME_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkOvertimeActionTypes.FETCH_ONE_WORK_OVERTIME_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkOvertimeActionTypes.FETCH_ONE_WORK_OVERTIME_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WorkOvertimeReducer;
