import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CostSharingActionTypes } from "./CostSharing.type";

export function* fetchAllCostSharings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/cost-sharing?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: CostSharingActionTypes.FETCH_ALL_COST_SHARING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CostSharingActionTypes.FETCH_ALL_COST_SHARING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCostSharings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/cost-sharing/${action.payload}`
    );
    yield put({
      type: CostSharingActionTypes.FETCH_ONE_COST_SHARING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CostSharingActionTypes.FETCH_ONE_COST_SHARING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCostSharings() {
  yield takeLatest(CostSharingActionTypes.FETCH_ALL_COST_SHARING, fetchAllCostSharings);
}

export function* watcherFetchOneCostSharings() {
  yield takeLatest(CostSharingActionTypes.FETCH_ONE_COST_SHARING, fetchOneCostSharings);
}
