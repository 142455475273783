import { AccountChart } from "../AccountChart/AccountChart.type";
import { BankAccount } from "../BankAccount/BankAccount.type";
import { CRVPayment } from "../CRVPayment/CRVPayment.type";
import { PostCheck } from "../PostCheck/PostCheck.type";
import { Project } from "../Project/Project.type";
import { Replenishment } from "../Replenish/Replenishment.type";
import { ApiCallState } from "../Utils";

export type AccountTransaction = {
  id: number;
  user_id: number;
  bank_account_id: number;
  from_bank_account_id: number;
  project_id: number;

  crv_payment_id: number; // +
  post_check_id: number; // -
  replenishment_id: number; // -

  payee: string;
  pv_crv_number: string;
  check_number: string;
  transaction_type: string;
  amount: number;
  account_chart_id: number;
  description: string;
  date: Date;

  bank_account: BankAccount;
  from_bank_account: BankAccount;
  project: Project;
  crv_payment: CRVPayment;
  post_check: PostCheck;
  replenishment: Replenishment;
  account_transaction_check: AccountTransactionCheck | null;
  account_chart: AccountChart;
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AccountTransactionCheck = {
  id: number;
  user_id: number;
  account_transaction_id: number;

  receipt_number: string;
  receipt_url: string;

  grv_number: string;

  with_hold_number: string;
  with_hold_amount: number;

  customer_name: string;

  vat: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AccountTransactionAnalysis = {
  total_duration_deposit: number;
  total_this_year_deposit: number;
  total_last_year_deposit: number;
  total_duration_withdraw: number;
  total_this_year_withdraw: number;
  total_last_year_withdraw: number;
  annual_month_graph_data: {
    month: string;
    total_deposit: number;
    total_withdraw: number;
  }[];
};

export type AccountTransactionStateTypes = {
  fetchAll: ApiCallState<AccountTransaction[]>;
  fetchAllAll: ApiCallState<AccountTransaction[]>;
  fetchAnalysis: ApiCallState<AccountTransactionAnalysis | null>;
};

export const AccountTransactionActionTypes = {
  FETCH_ALL_ACCOUNT_TRANSACTION: "FETCH_ALL_ACCOUNT_TRANSACTION",
  FETCH_ALL_ACCOUNT_TRANSACTION_RESET: "FETCH_ALL_ACCOUNT_TRANSACTION_RESET",
  FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE:
    "FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE",
  FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS:
    "FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS",

  FETCH_ALL_ALL_ACCOUNT_TRANSACTION: "FETCH_ALL_ALL_ACCOUNT_TRANSACTION",
  FETCH_ALL_ALL_ACCOUNT_TRANSACTION_RESET:
    "FETCH_ALL_ALL_ACCOUNT_TRANSACTION_RESET",
  FETCH_ALL_ALL_ACCOUNT_TRANSACTION_FAILURE:
    "FETCH_ALL_ALL_ACCOUNT_TRANSACTION_FAILURE",
  FETCH_ALL_ALL_ACCOUNT_TRANSACTION_SUCCESS:
    "FETCH_ALL_ALL_ACCOUNT_TRANSACTION_SUCCESS",

  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS: "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS",
  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_RESET:
    "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_RESET",
  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_FAILURE:
    "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_FAILURE",
  FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_SUCCESS:
    "FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_SUCCESS",
};
