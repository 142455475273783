import { ApiCallState } from "../../Utils";
import { Document } from "../../Document/Document.type";
import { ApartmentUnit } from "../../ApartmentBuilding/ApartmentUnit/ApartmentUnit.type";

export type Booking = {
  id: number;
  date: Date;
  name: string;
  booking_amount: number;
  booking_percentage: number;
  apartment_unit_id: number;
  id_document_id: number;
  cpo_document_id: number;
  square_meter: string;
  unit_price: string;
  contract_value: string;
  discount_percentage: string;
  discount_amount: string;
  discount_reason: string;
  total_contract_value: string;
  contract_termination_amount: string;
  second_payment_amount: string;
  second_payment_percentage: string;
  apartment_unit: ApartmentUnit;
  ID: Document;
  CPO: Document;
  city: string;
  sub_city: string;
  woreda: string;
  house_number: string;
  phone_number: string;
};

export type BookingStateTypes = {
  fetchAll: ApiCallState<Booking[]>;
  fetchOne: ApiCallState<Booking | {}>;
};

export const BookingActionTypes = {
  FETCH_ALL_BOOKINGS: "FETCH_ALL_BOOKINGS",
  FETCH_ALL_BOOKINGS_RESET: "FETCH_ALL_BOOKINGS_RESET",
  FETCH_ALL_BOOKINGS_FAILURE: "FETCH_ALL_BOOKINGS_FAILURE",
  FETCH_ALL_BOOKINGS_SUCCESS: "FETCH_ALL_BOOKINGS_SUCCESS",

  FETCH_ONE_BOOKING: "FETCH_ONE_BOOKING",
  FETCH_ONE_BOOKING_RESET: "FETCH_ONE_BOOKING_RESET",
  FETCH_ONE_BOOKING_FAILURE: "FETCH_ONE_BOOKING_FAILURE",
  FETCH_ONE_BOOKING_SUCCESS: "FETCH_ONE_BOOKING_SUCCESS",
};
