import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { ApartmentBuildingActionTypes } from "./ApartmentBuilding.type";

export function* fetchAllApartmentBuildings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/apartment-building/apartment?${query}`);
    yield put({
      type: ApartmentBuildingActionTypes.FETCH_ALL_APARTMENT_BUILDINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentBuildingActionTypes.FETCH_ALL_APARTMENT_BUILDINGS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApartmentBuilding(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/apartment-building/apartment/${action.payload}`
    );
    yield put({
      type: ApartmentBuildingActionTypes.FETCH_ONE_APARTMENT_BUILDING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentBuildingActionTypes.FETCH_ONE_APARTMENT_BUILDING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApartmentBuildings() {
  yield takeLatest(ApartmentBuildingActionTypes.FETCH_ALL_APARTMENT_BUILDINGS, fetchAllApartmentBuildings);
}

export function* watcherFetchOneApartmentBuilding() {
  yield takeLatest(ApartmentBuildingActionTypes.FETCH_ONE_APARTMENT_BUILDING, fetchOneApartmentBuilding);
}
