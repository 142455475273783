import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type StaffExperience= {
  id: number;
  staff_id:number;
  company_name:string;
  job_position:string;
  duration:string;
  document:Document;
};

export type StaffExperienceStateTypes = {
  fetchAll: ApiCallState<StaffExperience[]>;
  fetchOne: ApiCallState<StaffExperience | {}>;
};

export const StaffExperienceActionTypes = {
  FETCH_ALL_STAFF_EXPERIENCE: "FETCH_ALL_STAFF_EXPERIENCE",
  FETCH_ALL_STAFF_EXPERIENCE_RESET: "FETCH_ALL_STAFF_EXPERIENCE_RESET",
  FETCH_ALL_STAFF_EXPERIENCE_FAILURE: "FETCH_ALL_STAFF_EXPERIENCE_FAILURE",
  FETCH_ALL_STAFF_EXPERIENCE_SUCCESS: "FETCH_ALL_STAFF_EXPERIENCE_SUCCESS",

  FETCH_ONE_STAFF_EXPERIENCE: "FETCH_ONE_STAFF_EXPERIENCE",
  FETCH_ONE_STAFF_EXPERIENCE_RESET: "FETCH_ONE_STAFF_EXPERIENCE_RESET",
  FETCH_ONE_STAFF_EXPERIENCE_FAILURE: "FETCH_ONE_STAFF_EXPERIENCE_FAILURE",
  FETCH_ONE_STAFF_EXPERIENCE_SUCCESS: "FETCH_ONE_STAFF_EXPERIENCE_SUCCESS",
};
