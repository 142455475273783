import {
  PerformancePointPolicyStateTypes,
  PerformancePointPolicyActionTypes,
} from "./PerformancePointPolicy.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PerformancePointPolicyStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PerformancePointPolicyReducer = (
  state: PerformancePointPolicyStateTypes = INITIAL_STATE,
  action: any
): PerformancePointPolicyStateTypes => {
  switch (action.type) {
    case PerformancePointPolicyActionTypes.FETCH_ALL_PERFORMANCE_POINT_POLICY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PerformancePointPolicyActionTypes.FETCH_ALL_PERFORMANCE_POINT_POLICY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PerformancePointPolicyActionTypes.FETCH_ALL_PERFORMANCE_POINT_POLICY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PerformancePointPolicyActionTypes.FETCH_ALL_PERFORMANCE_POINT_POLICY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PerformancePointPolicyActionTypes.FETCH_ONE_PERFORMANCE_POINT_POLICY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PerformancePointPolicyActionTypes.FETCH_ONE_PERFORMANCE_POINT_POLICY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PerformancePointPolicyActionTypes.FETCH_ONE_PERFORMANCE_POINT_POLICY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PerformancePointPolicyActionTypes.FETCH_ONE_PERFORMANCE_POINT_POLICY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PerformancePointPolicyReducer;
