import { ScaleStateTypes, ScaleActionTypes } from "./Scale.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ScaleStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ScaleReducer = (
  state: ScaleStateTypes = INITIAL_STATE,
  action: any
): ScaleStateTypes => {
  switch (action.type) {
    case ScaleActionTypes.FETCH_ALL_SCALE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ScaleActionTypes.FETCH_ALL_SCALE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ScaleActionTypes.FETCH_ALL_SCALE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ScaleActionTypes.FETCH_ALL_SCALE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ScaleActionTypes.FETCH_ONE_SCALE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ScaleActionTypes.FETCH_ONE_SCALE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ScaleActionTypes.FETCH_ONE_SCALE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ScaleActionTypes.FETCH_ONE_SCALE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ScaleReducer;
