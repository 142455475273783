import { ApiCallState } from "../Utils";

export type BoqSummary = {
  id?: number;
  description: string;
  amount: number;
  sheet_name: string;
  project_id: number;
  key: number;
  contract_id: number;
};

export type BoqSummaryStateTypes = {
  fetchAll: ApiCallState<BoqSummary[]>;
  fetchOne: ApiCallState<BoqSummary | {}>;
};

export const BoqSummaryActionTypes = {
  FETCH_ALL_BOQ_SUMMARY: "FETCH_ALL_BOQ_SUMMARY",
  FETCH_ALL_BOQ_SUMMARY_RESET: "FETCH_ALL_BOQ_SUMMARY_RESET",
  FETCH_ALL_BOQ_SUMMARY_FAILURE: "FETCH_ALL_BOQ_SUMMARY_FAILURE",
  FETCH_ALL_BOQ_SUMMARY_SUCCESS: "FETCH_ALL_BOQ_SUMMARY_SUCCESS",

  FETCH_ONE_BOQ_SUMMARY: "FETCH_ONE_BOQ_SUMMARY",
  FETCH_ONE_BOQ_SUMMARY_RESET: "FETCH_ONE_BOQ_SUMMARY_RESET",
  FETCH_ONE_BOQ_SUMMARY_FAILURE: "FETCH_ONE_BOQ_SUMMARY_FAILURE",
  FETCH_ONE_BOQ_SUMMARY_SUCCESS: "FETCH_ONE_BOQ_SUMMARY_SUCCESS",
};
