import { SummaryResultForSkilledCandidateStateTypes, SummaryResultForSkilledCandidateActionTypes } from "./SummaryResultForSkilledCandidates.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SummaryResultForSkilledCandidateStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SummaryResultForSkilledCandidateReducer = (
  state: SummaryResultForSkilledCandidateStateTypes = INITIAL_STATE,
  action: any
): SummaryResultForSkilledCandidateStateTypes => {
  switch (action.type) {
    case SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SummaryResultForSkilledCandidateActionTypes.FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SummaryResultForSkilledCandidateActionTypes.FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SummaryResultForSkilledCandidateActionTypes.FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SummaryResultForSkilledCandidateActionTypes.FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SummaryResultForSkilledCandidateActionTypes.FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SummaryResultForSkilledCandidateReducer;
