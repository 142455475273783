import { FC, useEffect } from "react";
import { connect } from "react-redux";
import {
  NextTour,
  StartTour,
  ResetTour,
  RestartTour,
  StopTour,
} from "../../redux/Tour/Tour.action";
import { TourPropType } from "./Tour.util";
import JoyRide, { ACTIONS, STATUS, EVENTS } from "react-joyride";
const TourComponent: FC<TourPropType> = ({
  Next,
  Reset,
  Restart,
  Start,
  tour,
  Stop,
}) => {
  const callback = (data: any) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tour.run) ||
      status === STATUS.FINISHED
    ) {
      localStorage.setItem(tour.route, "true");

      Stop();
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      Next({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    }
  };
  

  useEffect(() => {
    if (!localStorage.getItem("tour")) {
      Start();
    }
  }, [Start]);

  return (
    <>
      <JoyRide
        {...tour}
        callback={callback}
        showSkipButton={false}
        continuous
        styles={{
          options: {
            // backgroundColor: "#fff",
            // primaryColor: "#fff",
            // textColor: "#000",
            // overlayColor: "rgba(0, 0, 0, 0.5)",
          },
          tooltipContainer: {
            // textAlign: "left",
          },

          buttonBack: {
            marginRight: 10,
          },
        }}
        locale={{
          last: "End Tour",
        }}
      />
    </>
  );
};
/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  tour: state.tour,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  Start: (action: string) => dispatch(StartTour(action)),
  Next: (action: string) => dispatch(NextTour(action)),
  Reset: (action: string) => dispatch(ResetTour(action)),
  Restart: (action: string) => dispatch(RestartTour(action)),
  Stop: (action: string) => dispatch(StopTour(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TourComponent);
