import {
  PriceEscalationStateTypes,
  PriceEscalationActionTypes,
} from "./PriceEscalation.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PriceEscalationStateTypes = {
  fetchAll: resetApiCallState([]),
};

const PriceEscalationReducer = (
  state: PriceEscalationStateTypes = INITIAL_STATE,
  action: any
): PriceEscalationStateTypes => {
  switch (action.type) {
    case PriceEscalationActionTypes.FETCH_ALL_PRICE_ESCALATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PriceEscalationActionTypes.FETCH_ALL_PRICE_ESCALATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PriceEscalationActionTypes.FETCH_ALL_PRICE_ESCALATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PriceEscalationActionTypes.FETCH_ALL_PRICE_ESCALATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PriceEscalationReducer;
