import { ApartmentBuilding } from "../ApartmentBuilding/ApartmentBuilding/ApartmentBuilding.type";
import { ApartmentUnit } from "../ApartmentBuilding/ApartmentUnit/ApartmentUnit.type";
import { Lead } from "../Lead/Lead.type";
import { ApiCallState } from "../Utils";

export type Reciept = {
  id: number;
  date: string;
  depositor_name: string;
  floor: number;
  discount_percentage: number;
  booking_payment_etb: number;
  booking_payment_usd: number;
  exchange_rate: number;
  usd_price: number;
  unit_id: number;
  buyer_id: number;
  building_id: number;
  user_id: number;
  reciept_payments: RecieptPayment[];
  apartment_building: ApartmentBuilding;
  apartment_unit: ApartmentUnit;
  lead: Lead;
  reciept_statuses: {
    id: number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
  }[];
};

export type RecieptStatus = {
  id: number;
  user_id: number;
  status: number;
  type: string;
  assigned_by: string;
};

export type RecieptPayment = {
  id?: number;
  description: string;
  percentage: number;
  exchange_rate: number;
};

export type RecieptStateTypes = {
  fetchAll: ApiCallState<Reciept[]>;
  fetchOne: ApiCallState<Reciept | {}>;
};

export const RecieptActionTypes = {
  FETCH_ALL_RECIEPT: "FETCH_ALL_RECIEPT",
  FETCH_ALL_RECIEPT_RESET: "FETCH_ALL_RECIEPT_RESET",
  FETCH_ALL_RECIEPT_FAILURE: "FETCH_ALL_RECIEPT_FAILURE",
  FETCH_ALL_RECIEPT_SUCCESS: "FETCH_ALL_RECIEPT_SUCCESS",

  FETCH_ONE_RECIEPT: "FETCH_ONE_RECIEPT",
  FETCH_ONE_RECIEPT_RESET: "FETCH_ONE_RECIEPT_RESET",
  FETCH_ONE_RECIEPT_FAILURE: "FETCH_ONE_RECIEPT_FAILURE",
  FETCH_ONE_RECIEPT_SUCCESS: "FETCH_ONE_RECIEPT_SUCCESS",
};
