import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { ApartmentUnitActionTypes } from "./ApartmentUnit.type";

export function* fetchAllApartmentUnits(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/apartment-building/unit?${query}`
    );
    yield put({
      type: ApartmentUnitActionTypes.FETCH_ALL_APARTMENT_UNITS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentUnitActionTypes.FETCH_ALL_APARTMENT_UNITS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApartmentUnit(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/apartment-building/unit/${action.payload}`
    );
    yield put({
      type: ApartmentUnitActionTypes.FETCH_ONE_APARTMENT_UNIT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentUnitActionTypes.FETCH_ONE_APARTMENT_UNIT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApartmentUnits() {
  yield takeLatest(
    ApartmentUnitActionTypes.FETCH_ALL_APARTMENT_UNITS,
    fetchAllApartmentUnits
  );
}

export function* watcherFetchOneApartmentUnit() {
  yield takeLatest(
    ApartmentUnitActionTypes.FETCH_ONE_APARTMENT_UNIT,
    fetchOneApartmentUnit
  );
}
