import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SalesScheduleActionTypes } from "./SalesSchedule.type";
import { formatQuery } from "../Utils";

export function* fetchAllSalesSchedules(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(`${API_BASE_URI}/sales-schedule?${query}`);
    yield put({
      type: SalesScheduleActionTypes.FETCH_ALL_SALES_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SalesScheduleActionTypes.FETCH_ALL_SALES_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSalesSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sales-schedule/${action.payload}`
    );
    yield put({
      type: SalesScheduleActionTypes.FETCH_ONE_SALES_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SalesScheduleActionTypes.FETCH_ONE_SALES_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSalesSchedules() {
  yield takeLatest(
    SalesScheduleActionTypes.FETCH_ALL_SALES_SCHEDULE,
    fetchAllSalesSchedules
  );
}

export function* watcherFetchOneSalesSchedules() {
  yield takeLatest(
    SalesScheduleActionTypes.FETCH_ONE_SALES_SCHEDULE,
    fetchOneSalesSchedules
  );
}
