import { resetApiCallState } from "../Utils";
import {
  EmployeeHistoryActions,
  EmployeeHistoryStateTypes,
} from "./EmployeeHistory.type";

const INITIAL_STATE: EmployeeHistoryStateTypes = {
  fetchAll: resetApiCallState([]),
};

const EmployeeHistoryReducer = (
  state: EmployeeHistoryStateTypes = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case EmployeeHistoryActions.FETCH_EMPLOYEE_HISTORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeHistoryActions.FETCH_EMPLOYEE_HISTORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: action.payload,
          isPending: false,
          isSuccessful: true,
        },
      };

    case EmployeeHistoryActions.FETCH_EMPLOYEE_HISTORY_ERROR:
      return {
        ...state,
        fetchAll: {
          error: action.payload,
          payload: [],
          isPending: false,
          isSuccessful: false,
        },
      };
    default:
      return state;
  }
};

export default EmployeeHistoryReducer;
