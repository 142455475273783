import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LabourUsageActionTypes } from "./LabourUsage.type";

export function* fetchAllLabourUsage(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/labour_usage?project_id=${action.payload.project_id}`
    );
    yield put({
      type: LabourUsageActionTypes.FETCH_ALL_LABOUR_USAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourUsageActionTypes.FETCH_ALL_LABOUR_USAGE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLabourUsage() {
  yield takeLatest(
    LabourUsageActionTypes.FETCH_ALL_LABOUR_USAGE,
    fetchAllLabourUsage
  );
}
