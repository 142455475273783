import { ApiCallState } from "../Utils";

export type Contact= {
  id: number;
  name: string;
  account: string;
  address: string;
  phone_number: string;
  secondary_phone_number: string;
  email: string;
};

export type ContactStateTypes = {
  fetchAll: ApiCallState<Contact[]>;
  fetchOne: ApiCallState<Contact | {}>;
};

export const ContactActionTypes = {
  FETCH_ALL_CONTACT: "FETCH_ALL_CONTACT",
  FETCH_ALL_CONTACT_RESET: "FETCH_ALL_CONTACT_RESET",
  FETCH_ALL_CONTACT_FAILURE: "FETCH_ALL_CONTACT_FAILURE",
  FETCH_ALL_CONTACT_SUCCESS: "FETCH_ALL_CONTACT_SUCCESS",

  FETCH_ONE_CONTACT: "FETCH_ONE_CONTACT",
  FETCH_ONE_CONTACT_RESET: "FETCH_ONE_CONTACT_RESET",
  FETCH_ONE_CONTACT_FAILURE: "FETCH_ONE_CONTACT_FAILURE",
  FETCH_ONE_CONTACT_SUCCESS: "FETCH_ONE_CONTACT_SUCCESS",
};
