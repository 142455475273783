import {
  SubContractPlanStateTypes,
  SubContractPlanActionTypes,
} from "./SubContractPlan.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubContractPlanStateTypes = {
  fetchAll: resetApiCallState([]),
};

const SubContractPlanReducer = (
  state: SubContractPlanStateTypes = INITIAL_STATE,
  action: any
): SubContractPlanStateTypes => {
  switch (action.type) {
    case SubContractPlanActionTypes.FETCH_ALL_SUB_CONTRACT_PLAN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractPlanActionTypes.FETCH_ALL_SUB_CONTRACT_PLAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractPlanActionTypes.FETCH_ALL_SUB_CONTRACT_PLAN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractPlanActionTypes.FETCH_ALL_SUB_CONTRACT_PLAN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubContractPlanReducer;
