import { StaffBioStateTypes, StaffBioActionTypes } from "./StaffBio.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StaffBioStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const StaffBioReducer = (
  state: StaffBioStateTypes = INITIAL_STATE,
  action: any
): StaffBioStateTypes => {
  switch (action.type) {
    case StaffBioActionTypes.FETCH_ALL_STAFF_BIO:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffBioActionTypes.FETCH_ALL_STAFF_BIO_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffBioActionTypes.FETCH_ALL_STAFF_BIO_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffBioActionTypes.FETCH_ALL_STAFF_BIO_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case StaffBioActionTypes.FETCH_ONE_STAFF_BIO:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffBioActionTypes.FETCH_ONE_STAFF_BIO_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffBioActionTypes.FETCH_ONE_STAFF_BIO_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffBioActionTypes.FETCH_ONE_STAFF_BIO_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StaffBioReducer;
