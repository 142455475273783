import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RentContractActionTypes } from "./RentContract.type";

export function* fetchAllRentContracts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/contract`);
    yield put({
      type: RentContractActionTypes.FETCH_ALL_RENT_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RentContractActionTypes.FETCH_ALL_RENT_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRentContracts() {
  yield takeLatest(
    RentContractActionTypes.FETCH_ALL_RENT_CONTRACT,
    fetchAllRentContracts
  );
}
