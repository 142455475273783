import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TeamActionTypes } from "./Team.type";

export function* fetchAllTeams(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/crm/team?${query}`);
    yield put({
      type: TeamActionTypes.FETCH_ALL_TEAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TeamActionTypes.FETCH_ALL_TEAM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllTeamWithMembers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/crm/team/detailed?${query}`
    );
    yield put({
      type: TeamActionTypes.FETCH_ALL_TEAM_WITH_MEMBERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TeamActionTypes.FETCH_ALL_TEAM_WITH_MEMBERS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedTeams(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/crm/team?${query}`);
    yield put({
      type: TeamActionTypes.FETCH_PAGED_TEAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TeamActionTypes.FETCH_PAGED_TEAM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTeams(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/team/${action.payload}`
    );
    yield put({
      type: TeamActionTypes.FETCH_ONE_TEAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TeamActionTypes.FETCH_ONE_TEAM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTeams() {
  yield takeLatest(TeamActionTypes.FETCH_ALL_TEAM, fetchAllTeams);
}

export function* watcherFetchAllTeamWithMembers() {
  yield takeLatest(
    TeamActionTypes.FETCH_ALL_TEAM_WITH_MEMBERS,
    fetchAllTeamWithMembers
  );
}

export function* watcherFetchPagedTeams() {
  yield takeLatest(TeamActionTypes.FETCH_PAGED_TEAM, fetchPagedTeams);
}

export function* watcherFetchOneTeams() {
  yield takeLatest(TeamActionTypes.FETCH_ONE_TEAM, fetchOneTeams);
}
