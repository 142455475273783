import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SalesPaymentActionTypes } from "./SalesPayment.type";
import { formatQuery } from "../Utils";

export function* fetchAllSalesPayments(action: any): any {
  try {
    let query = formatQuery(action);
    const response = yield axios.get(`${API_BASE_URI}/sales-payment?${query}`);
    yield put({
      type: SalesPaymentActionTypes.FETCH_ALL_SALES_PERSON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SalesPaymentActionTypes.FETCH_ALL_SALES_PERSON_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSalesPayments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sales-payment/${action.payload}`
    );
    yield put({
      type: SalesPaymentActionTypes.FETCH_ONE_SALES_PERSON_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SalesPaymentActionTypes.FETCH_ONE_SALES_PERSON_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSalesPayments() {
  yield takeLatest(
    SalesPaymentActionTypes.FETCH_ALL_SALES_PERSON,
    fetchAllSalesPayments
  );
}

export function* watcherFetchOneSalesPayments() {
  yield takeLatest(
    SalesPaymentActionTypes.FETCH_ONE_SALES_PERSON,
    fetchOneSalesPayments
  );
}
