import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PettyCashActionTypes } from "./PettyCash.type";

export function* fetchAllPettyCashes(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/petty-cash`);
    yield put({
      type: PettyCashActionTypes.FETCH_ALL_PETTY_CASH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PettyCashActionTypes.FETCH_ALL_PETTY_CASH_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePettyCashes(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/petty-cash/${action.payload}`
    );
    yield put({
      type: PettyCashActionTypes.FETCH_ONE_PETTY_CASH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PettyCashActionTypes.FETCH_ONE_PETTY_CASH_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPettyCashes() {
  yield takeLatest(
    PettyCashActionTypes.FETCH_ALL_PETTY_CASH,
    fetchAllPettyCashes
  );
}

export function* watcherFetchOnePettyCashes() {
  yield takeLatest(
    PettyCashActionTypes.FETCH_ONE_PETTY_CASH,
    fetchOnePettyCashes
  );
}
