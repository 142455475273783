import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { StorePhaseActionTypes } from "./StorePhase.type";

export function* fetchAllStorePhases(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-phase?${formatQuery(action)}`
    );
    yield put({
      type: StorePhaseActionTypes.FETCH_ALL_STORE_PHASE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StorePhaseActionTypes.FETCH_ALL_STORE_PHASE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStorePhases(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-phase/${action.payload}`
    );
    yield put({
      type: StorePhaseActionTypes.FETCH_ONE_STORE_PHASE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StorePhaseActionTypes.FETCH_ONE_STORE_PHASE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStorePhases() {
  yield takeLatest(
    StorePhaseActionTypes.FETCH_ALL_STORE_PHASE,
    fetchAllStorePhases
  );
}

export function* watcherFetchOneStorePhases() {
  yield takeLatest(
    StorePhaseActionTypes.FETCH_ONE_STORE_PHASE,
    fetchOneStorePhases
  );
}
