import { ApiCallState, PagedData } from "../Utils";

export type CommissionSetting = {
  id?: number;
  buyer_name: string;
  building: string;
  unit: string;
  total_amount: string;
  agent_form: CommissionSettingAgentForm[];
};
export type CommissionSettingAgentForm = {
  key: number;
  agent_name: string;
  role: string;
  commission_percentage: string;
  advance_commission_percentage: string;
};

export type CommissionSettingStateTypes = {
  fetchAll: ApiCallState<CommissionSetting[]>;
  fetchOne: ApiCallState<CommissionSetting| {}>;
  fetchPaged:ApiCallState<PagedData<CommissionSetting[]>>
};

export const CommissionSettingActionTypes = {
  FETCH_ALL_COMMISSION_SETTING: "FETCH_ALL_COMMISSION_SETTING",
  FETCH_ALL_COMMISSION_SETTING_RESET: "FETCH_ALL_COMMISSION_SETTING_RESET",
  FETCH_ALL_COMMISSION_SETTING_FAILURE: "FETCH_ALL_COMMISSION_SETTING_FAILURE",
  FETCH_ALL_COMMISSION_SETTING_SUCCESS: "FETCH_ALL_COMMISSION_SETTING_SUCCESS",

  FETCH_PAGED_COMMISSION_SETTING: "FETCH_PAGED_COMMISSION_SETTING",
  FETCH_PAGED_COMMISSION_SETTING_RESET: "FETCH_PAGED_COMMISSION_SETTING_RESET",
  FETCH_PAGED_COMMISSION_SETTING_FAILURE: "FETCH_PAGED_COMMISSION_SETTING_FAILURE",
  FETCH_PAGED_COMMISSION_SETTING_SUCCESS: "FETCH_PAGED_COMMISSION_SETTING_SUCCESS",

  FETCH_ONE_COMMISSION_SETTING: "FETCH_ONE_COMMISSION_SETTING",
  FETCH_ONE_COMMISSION_SETTING_RESET: "FETCH_ONE_COMMISSION_SETTING_RESET",
  FETCH_ONE_COMMISSION_SETTING_FAILURE: "FETCH_ONE_COMMISSION_SETTING_FAILURE",
  FETCH_ONE_COMMISSION_SETTING_SUCCESS: "FETCH_ONE_COMMISSION_SETTING_SUCCESS",
};
