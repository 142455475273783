import { Apartment } from "../Apartment/Apartment.type";
import { ApiCallState } from "../Utils";

export type ApartmentExpense= {
  id: number;
  date: string;
  type: string;
  general_specific: string;
  description: string;
  amount: number;
  apartment_id: number | null;
  apartment?: Apartment
};

export type ApartmentExpenseStateTypes = {
  fetchAll: ApiCallState<ApartmentExpense[]>;
  fetchOne: ApiCallState<ApartmentExpense | {}>;
};

export const ApartmentExpenseActionTypes = {
  FETCH_ALL_APARTMENT_EXPENSE: "FETCH_ALL_APARTMENT_EXPENSE",
  FETCH_ALL_APARTMENT_EXPENSE_RESET: "FETCH_ALL_APARTMENT_EXPENSE_RESET",
  FETCH_ALL_APARTMENT_EXPENSE_FAILURE: "FETCH_ALL_APARTMENT_EXPENSE_FAILURE",
  FETCH_ALL_APARTMENT_EXPENSE_SUCCESS: "FETCH_ALL_APARTMENT_EXPENSE_SUCCESS",

  FETCH_ONE_APARTMENT_EXPENSE: "FETCH_ONE_APARTMENT_EXPENSE",
  FETCH_ONE_APARTMENT_EXPENSE_RESET: "FETCH_ONE_APARTMENT_EXPENSE_RESET",
  FETCH_ONE_APARTMENT_EXPENSE_FAILURE: "FETCH_ONE_APARTMENT_EXPENSE_FAILURE",
  FETCH_ONE_APARTMENT_EXPENSE_SUCCESS: "FETCH_ONE_APARTMENT_EXPENSE_SUCCESS",
};
