import { Boq } from "../Boq/Boq.type";
import { Material } from "../Material/Material.type";
import { MaterialInventory } from "../MaterialInventory/MaterialInventory.type";
import { Project } from "../Project/Project.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EquipmentUsage = {
  id: number;
  morning_time: Array<String>;
  afternoon_time: Array<String>;
  night_time: Array<String>;
  total_time: number;
  reading_start: number;
  reading_end: number;
  fuel_consumption: number;
  work_type: string;
  report_type: "Daily" | "Range" | "Down Time" | "Maintenance";
  remark: string;
  date: string;
  end_date: string;
  equipment_inventory: {
    material: Material;
    unit_price: number;
    type: string;
    identification_number: string;
  };
  driver: Staff;
  forman: Staff;
  engineer: Staff;
  project: Project | null;
  boq_id: number;
};

export type EquipmentUsageStateTypes = {
  fetchAll: ApiCallState<EquipmentUsage[]>;
};

export const EquipmentUsageActionTypes = {
  FETCH_ALL_EQUIPMENT_USAGE: "FETCH_ALL_EQUIPMENT_USAGE",
  FETCH_ALL_EQUIPMENT_USAGE_RESET: "FETCH_ALL_EQUIPMENT_USAGE_RESET",
  FETCH_ALL_EQUIPMENT_USAGE_FAILURE: "FETCH_ALL_EQUIPMENT_USAGE_FAILURE",
  FETCH_ALL_EQUIPMENT_USAGE_SUCCESS: "FETCH_ALL_EQUIPMENT_USAGE_SUCCESS",
};
