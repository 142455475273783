import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { OpportunityActionTypes } from "./Opportunity.type";

export function* fetchAllOpportunities(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/crm/opportunity?${query}`
    );

    yield put({
      type: OpportunityActionTypes.FETCH_ALL_OPPORTUNITIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OpportunityActionTypes.FETCH_ALL_OPPORTUNITIES_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOpportunity(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/opportunity/${action.payload}`
    );
    yield put({
      type: OpportunityActionTypes.FETCH_ONE_OPPORTUNITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OpportunityActionTypes.FETCH_ONE_OPPORTUNITY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOpportunities() {
  yield takeLatest(
    OpportunityActionTypes.FETCH_ALL_OPPORTUNITIES,
    fetchAllOpportunities
  );
}

export function* watcherFetchOneOpportunity() {
  yield takeLatest(
    OpportunityActionTypes.FETCH_ONE_OPPORTUNITY,
    fetchOneOpportunity
  );
}
