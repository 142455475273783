import {
  EmployeeUserStateTypes,
  EmployeeUserActionTypes,
} from "./EmployeeUser.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EmployeeUserStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const EmployeeUserReducer = (
  state: EmployeeUserStateTypes = INITIAL_STATE,
  action: any
): EmployeeUserStateTypes => {
  switch (action.type) {
    case EmployeeUserActionTypes.FETCH_ALL_EMPLOYEE_USER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeUserActionTypes.FETCH_ALL_EMPLOYEE_USER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeUserActionTypes.FETCH_ALL_EMPLOYEE_USER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeUserActionTypes.FETCH_ALL_EMPLOYEE_USER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EmployeeUserActionTypes.FETCH_ONE_EMPLOYEE_USER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeUserActionTypes.FETCH_ONE_EMPLOYEE_USER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeUserActionTypes.FETCH_ONE_EMPLOYEE_USER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeUserActionTypes.FETCH_ONE_EMPLOYEE_USER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EmployeeUserReducer;
