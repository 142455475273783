import {
  PaymentRetentionStateTypes,
  PaymentRetentionActionTypes,
} from "./PaymentRetention.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PaymentRetentionStateTypes = {
  fetchAll: resetApiCallState([]),
};

const PaymentRetentionReducer = (
  state: PaymentRetentionStateTypes = INITIAL_STATE,
  action: any
): PaymentRetentionStateTypes => {
  switch (action.type) {
    case PaymentRetentionActionTypes.FETCH_ALL_PAYMENT_RETENTION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PaymentRetentionActionTypes.FETCH_ALL_PAYMENT_RETENTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PaymentRetentionActionTypes.FETCH_ALL_PAYMENT_RETENTION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PaymentRetentionActionTypes.FETCH_ALL_PAYMENT_RETENTION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PaymentRetentionReducer;
