import { ApiCallState } from "../Utils";

export type SickLeave= {
  id: number;
  date: string,
  date_treated: string,
  remark: string,
  leave_granted:number,
  non_refundable_amount:number,
  refundable_amount:number,
  balance:number,
  year:string,
  budget:string,
  user_id:number,
  staff_id:number,
};

export type SickLeaveStateTypes = {
  fetchAll: ApiCallState<SickLeave[]>;
  fetchOne: ApiCallState<SickLeave | {}>;
};

export const SickLeaveActionTypes = {
  FETCH_ALL_SICK_LEAVE: "FETCH_ALL_SICK_LEAVE",
  FETCH_ALL_SICK_LEAVE_RESET: "FETCH_ALL_SICK_LEAVE_RESET",
  FETCH_ALL_SICK_LEAVE_FAILURE: "FETCH_ALL_SICK_LEAVE_FAILURE",
  FETCH_ALL_SICK_LEAVE_SUCCESS: "FETCH_ALL_SICK_LEAVE_SUCCESS",

  FETCH_ONE_SICK_LEAVE: "FETCH_ONE_SICK_LEAVE",
  FETCH_ONE_SICK_LEAVE_RESET: "FETCH_ONE_SICK_LEAVE_RESET",
  FETCH_ONE_SICK_LEAVE_FAILURE: "FETCH_ONE_SICK_LEAVE_FAILURE",
  FETCH_ONE_SICK_LEAVE_SUCCESS: "FETCH_ONE_SICK_LEAVE_SUCCESS",
};
