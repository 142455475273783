import { ApiCallState } from "../../Utils";

export type ProjectSite = {
  id: number;
  name: string;
  location: string;
};

export type ProjectSiteStateTypes = {
  fetchAll: ApiCallState<ProjectSite[]>;
  fetchOne: ApiCallState<ProjectSite | {}>;
};

export const ProjectSiteActionTypes = {
  FETCH_ALL_PROJECT_SITES: "FETCH_ALL_PROJECT_SITES",
  FETCH_ALL_PROJECT_SITES_RESET: "FETCH_ALL_PROJECT_SITES_RESET",
  FETCH_ALL_PROJECT_SITES_FAILURE: "FETCH_ALL_PROJECT_SITES_FAILURE",
  FETCH_ALL_PROJECT_SITES_SUCCESS: "FETCH_ALL_PROJECT_SITES_SUCCESS",

  FETCH_ONE_PROJECT_SITE: "FETCH_ONE_PROJECT_SITE",
  FETCH_ONE_PROJECT_SITE_RESET: "FETCH_ONE_PROJECT_SITE_RESET",
  FETCH_ONE_PROJECT_SITE_FAILURE: "FETCH_ONE_PROJECT_SITE_FAILURE",
  FETCH_ONE_PROJECT_SITE_SUCCESS: "FETCH_ONE_PROJECT_SITE_SUCCESS",
};
