import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialPlanActionTypes } from "./MaterialPlan.type";

export function* fetchAllMaterialPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material_plan?project_id=${action.payload.project_id}&date_type=${action.payload.date_type}&date=${action.payload.date}&material_id${action.payload.material_id}`
    );
    yield put({
      type: MaterialPlanActionTypes.FETCH_ALL_MATERIAL_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialPlanActionTypes.FETCH_ALL_MATERIAL_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialPlans() {
  yield takeLatest(
    MaterialPlanActionTypes.FETCH_ALL_MATERIAL_PLAN,
    fetchAllMaterialPlans
  );
}
