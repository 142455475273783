import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AnnualLeavePaymentActionTypes } from "./AnnualLeavePayment.type";

export function* fetchAllAnnualLeavePayments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/annual-leave-payment?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: AnnualLeavePaymentActionTypes.FETCH_ALL_ANNUAL_LEAVE_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AnnualLeavePaymentActionTypes.FETCH_ALL_ANNUAL_LEAVE_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAnnualLeavePayments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/annual-leave-payment/${action.payload}`
    );
    yield put({
      type: AnnualLeavePaymentActionTypes.FETCH_ONE_ANNUAL_LEAVE_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AnnualLeavePaymentActionTypes.FETCH_ONE_ANNUAL_LEAVE_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAnnualLeavePayments() {
  yield takeLatest(AnnualLeavePaymentActionTypes.FETCH_ALL_ANNUAL_LEAVE_PAYMENT, fetchAllAnnualLeavePayments);
}

export function* watcherFetchOneAnnualLeavePayments() {
  yield takeLatest(AnnualLeavePaymentActionTypes.FETCH_ONE_ANNUAL_LEAVE_PAYMENT, fetchOneAnnualLeavePayments);
}
