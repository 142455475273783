import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ExpenseActionTypes } from "./Expense.type";

export function* fetchAllExpense(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/expense?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: ExpenseActionTypes.FETCH_ALL_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExpenseActionTypes.FETCH_ALL_EXPENSE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllExpense() {
  yield takeLatest(ExpenseActionTypes.FETCH_ALL_EXPENSE, fetchAllExpense);
}
