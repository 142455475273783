import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type LeaveStartingBalance = {
  id: number;
  user_id: number;
  staff_id: number;
  starting_balance: number;
  date:string;
  staff: Staff;
};


export type LeaveStartingBalanceStateTypes = {
  fetchAll: ApiCallState<LeaveStartingBalance[]>;
  fetchOne: ApiCallState<LeaveStartingBalance | {}>;
};

export const LeaveStartingBalanceActionTypes = {
  FETCH_ALL_LEAVE_STARTING_BALANCE: "FETCH_ALL_LEAVE_STARTING_BALANCE",
  FETCH_ALL_LEAVE_STARTING_BALANCE_RESET: "FETCH_ALL_LEAVE_STARTING_BALANCE_RESET",
  FETCH_ALL_LEAVE_STARTING_BALANCE_FAILURE: "FETCH_ALL_LEAVE_STARTING_BALANCE_FAILURE",
  FETCH_ALL_LEAVE_STARTING_BALANCE_SUCCESS: "FETCH_ALL_LEAVE_STARTING_BALANCE_SUCCESS",

  FETCH_ONE_LEAVE_STARTING_BALANCE: "FETCH_ONE_LEAVE_STARTING_BALANCE",
  FETCH_ONE_LEAVE_STARTING_BALANCE_RESET: "FETCH_ONE_LEAVE_STARTING_BALANCE_RESET",
  FETCH_ONE_LEAVE_STARTING_BALANCE_FAILURE: "FETCH_ONE_LEAVE_STARTING_BALANCE_FAILURE",
  FETCH_ONE_LEAVE_STARTING_BALANCE_SUCCESS: "FETCH_ONE_LEAVE_STARTING_BALANCE_SUCCESS",
};
