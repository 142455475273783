import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialTransferActions } from "./MaterialTransfer.type";

export function* fetchMaterialTransfer(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material_transfer`);
    yield put({
      type: MaterialTransferActions.FETCH_MATERIAL_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialTransferActions.FETCH_MATERIAL_TRANSFER_ERROR,
      payload: error,
    });
  }
}

export function* watcherFetchMaterialTransfer() {
  yield takeLatest(
    MaterialTransferActions.FETCH_MATERIAL_TRANSFER,
    fetchMaterialTransfer
  );
}
