import { ExternaVacancyAnnouncement } from "../ExternaVacancyAnnouncement/ExternaVacancyAnnouncement.type";
import { ApiCallState } from "../Utils";

export type CandidatesResult= {
  id: number;
  external_vacancy_announcement_id:number;
  date:string;
  prepared_by:number;
  user_id:number;
  candidates_result_items:CandidatesResultItem[];
  candidates_result_statuses:CandidatesResultStatus[];
  external_vacancy_announcement:ExternaVacancyAnnouncement;
};

export type CandidatesResultItem ={
    id:number;
    candidates_result_id:number;
    name:string;
    knowledge_about_job:string;
    knowledge:number;
    personality:number;
    communication:number; 
    interest:number;
    remark:string;
}

export type CandidatesResultStatus={
    candidates_result_id:number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    id: number;
}

export type CandidatesResultStateTypes = {
  fetchAll: ApiCallState<CandidatesResult[]>;
  fetchOne: ApiCallState<CandidatesResult | {}>;
};

export const CandidatesResultActionTypes = {
  FETCH_ALL_CANDIDATES_RESULT: "FETCH_ALL_CANDIDATES_RESULT",
  FETCH_ALL_CANDIDATES_RESULT_RESET: "FETCH_ALL_CANDIDATES_RESULT_RESET",
  FETCH_ALL_CANDIDATES_RESULT_FAILURE: "FETCH_ALL_CANDIDATES_RESULT_FAILURE",
  FETCH_ALL_CANDIDATES_RESULT_SUCCESS: "FETCH_ALL_CANDIDATES_RESULT_SUCCESS",

  FETCH_ONE_CANDIDATES_RESULT: "FETCH_ONE_CANDIDATES_RESULT",
  FETCH_ONE_CANDIDATES_RESULT_RESET: "FETCH_ONE_CANDIDATES_RESULT_RESET",
  FETCH_ONE_CANDIDATES_RESULT_FAILURE: "FETCH_ONE_CANDIDATES_RESULT_FAILURE",
  FETCH_ONE_CANDIDATES_RESULT_SUCCESS: "FETCH_ONE_CANDIDATES_RESULT_SUCCESS",
};
