import { ApiCallState } from "../../Utils";
import { ApartmentUnit } from "../ApartmentUnit/ApartmentUnit.type";

export type ApartmentBuilding = {
  id: number;
  name: string;
  location: string;
  area: number;
  units: number;
  floor: number;
  project_id: number;
  apartment_units: ApartmentUnit[]
};

export type ApartmentBuildingStateTypes = {
  fetchAll: ApiCallState<ApartmentBuilding[]>;
  fetchOne: ApiCallState<ApartmentBuilding | {}>;
};

export const ApartmentBuildingActionTypes = {
  FETCH_ALL_APARTMENT_BUILDINGS: "FETCH_ALL_APARTMENT_BUILDINGS",
  FETCH_ALL_APARTMENT_BUILDINGS_RESET: "FETCH_ALL_APARTMENT_BUILDINGS_RESET",
  FETCH_ALL_APARTMENT_BUILDINGS_FAILURE:
    "FETCH_ALL_APARTMENT_BUILDINGS_FAILURE",
  FETCH_ALL_APARTMENT_BUILDINGS_SUCCESS:
    "FETCH_ALL_APARTMENT_BUILDINGS_SUCCESS",

  FETCH_ONE_APARTMENT_BUILDING: "FETCH_ONE_APARTMENT_BUILDING",
  FETCH_ONE_APARTMENT_BUILDING_RESET: "FETCH_ONE_APARTMENT_BUILDING_RESET",
  FETCH_ONE_APARTMENT_BUILDING_FAILURE: "FETCH_ONE_APARTMENT_BUILDING_FAILURE",
  FETCH_ONE_APARTMENT_BUILDING_SUCCESS: "FETCH_ONE_APARTMENT_BUILDING_SUCCESS",
};
