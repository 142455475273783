import { Moment } from "moment";
import React from "react";
import { EquipmentUsagePlan } from "../EquipmentUsagePlan/EquipmentUsagePlan.type";
import { Material } from "../Material/Material.type";
import { ApiCallState } from "../Utils";

export type EquipmentPlan = {
  id: any;
  material_id: any;
  required: number;
  operational: number;
  down: number;
  remark: string;
  project_id: any;
  date: string;
  date_type: string;
  available: number;
  key: React.Key;
  material?: Material;
  equipment_usage_plan?: EquipmentUsagePlan;
};

export type EquipmentPlanStateTypes = {
  fetchAll: ApiCallState<EquipmentPlan[]>;
};

export type EquipmentPlanActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const EquipmentPlanActionTypes = {
  FETCH_ALL_EQUIPMENT_PLAN: "FETCH_ALL_EQUIPMENT_PLAN",
  FETCH_ALL_EQUIPMENT_PLAN_RESET: "FETCH_ALL_EQUIPMENT_PLAN_RESET",
  FETCH_ALL_EQUIPMENT_PLAN_FAILURE: "FETCH_ALL_EQUIPMENT_PLAN_FAILURE",
  FETCH_ALL_EQUIPMENT_PLAN_SUCCESS: "FETCH_ALL_EQUIPMENT_PLAN_SUCCESS",
};
