import { Boq } from "../Boq/Boq.type";
import { Customer } from "../Customer/Customer.type";
import { ApiCallState } from "../Utils";

export type Invoice = {
  id: number;
  customer: Customer;
  invoice_items: Item[];
  date: string;
  due_date: string;
  vat: number;
};

export type Item = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  paid_quantity: number;
  unit_price: number;
  amount: number;
  invoice_payments: PaymentPaid[];
};

export type PaymentPaid = {
  id: number;
  quantity: number;
  date: Date;
};

export type InvoiceStateTypes = {
  fetchAll: ApiCallState<Invoice[]>;
};

export const InvoiceActionTypes = {
  FETCH_ALL_INVOICE: "FETCH_ALL_INVOICE",
  FETCH_ALL_INVOICE_RESET: "FETCH_ALL_INVOICE_RESET",
  FETCH_ALL_INVOICE_FAILURE: "FETCH_ALL_INVOICE_FAILURE",
  FETCH_ALL_INVOICE_SUCCESS: "FETCH_ALL_INVOICE_SUCCESS",
};
