import { ExternaVacancyAnnouncement } from "../ExternaVacancyAnnouncement/ExternaVacancyAnnouncement.type";
import { ApiCallState } from "../Utils";

export type InterviewResult= {
  id: number;
  external_vacancy_announcement_id: number;
  date: string;
  position:string;
  prepared_by:number;
  external_vacancy_announcement:ExternaVacancyAnnouncement;
  interview_result_items:InterviewResultItem[];
};

export type InterviewResultItem= {
    id: number;
    interview_result_id:number;
    name:string;
    knowledge:number;
    general_knowledge:number;
    personality:number;
    ability_to_challenge:number;
    interest:number;
    remark:string;
}



export type InterviewResultStateTypes = {
  fetchAll: ApiCallState<InterviewResult[]>;
  fetchOne: ApiCallState<InterviewResult | {}>;
};

export const InterviewResultActionTypes = {
  FETCH_ALL_INTERVIEW_RESULT: "FETCH_ALL_INTERVIEW_RESULT",
  FETCH_ALL_INTERVIEW_RESULT_RESET: "FETCH_ALL_INTERVIEW_RESULT_RESET",
  FETCH_ALL_INTERVIEW_RESULT_FAILURE: "FETCH_ALL_INTERVIEW_RESULT_FAILURE",
  FETCH_ALL_INTERVIEW_RESULT_SUCCESS: "FETCH_ALL_INTERVIEW_RESULT_SUCCESS",

  FETCH_ONE_INTERVIEW_RESULT: "FETCH_ONE_INTERVIEW_RESULT",
  FETCH_ONE_INTERVIEW_RESULT_RESET: "FETCH_ONE_INTERVIEW_RESULT_RESET",
  FETCH_ONE_INTERVIEW_RESULT_FAILURE: "FETCH_ONE_INTERVIEW_RESULT_FAILURE",
  FETCH_ONE_INTERVIEW_RESULT_SUCCESS: "FETCH_ONE_INTERVIEW_RESULT_SUCCESS",
};
