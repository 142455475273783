import { ApiCallState } from "../../Utils";

export type SupervisorPlan = {
  id: number;
  week: Date;
  prepared_by: string;
  no_of_call: number,
  no_of_interested_client: number,
  no_of_appointment: number,
  no_of_client_visit: number,
  user_id?: number,
};

export type SupervisorPlanStateTypes = {
  fetchAll: ApiCallState<SupervisorPlan[]>;
  fetchOne: ApiCallState<SupervisorPlan | {}>;
};

export const SupervisorPlanActionTypes = {
  FETCH_ALL_SUPERVISOR_PLANS: "FETCH_ALL_SUPERVISOR_PLANS",
  FETCH_ALL_SUPERVISOR_PLANS_RESET: "FETCH_ALL_SUPERVISOR_PLANS_RESET",
  FETCH_ALL_SUPERVISOR_PLANS_FAILURE: "FETCH_ALL_SUPERVISOR_PLANS_FAILURE",
  FETCH_ALL_SUPERVISOR_PLANS_SUCCESS: "FETCH_ALL_SUPERVISOR_PLANS_SUCCESS",

  FETCH_ONE_SUPERVISOR_PLAN: "FETCH_ONE_SUPERVISOR_PLAN",
  FETCH_ONE_SUPERVISOR_PLAN_RESET: "FETCH_ONE_SUPERVISOR_PLAN_RESET",
  FETCH_ONE_SUPERVISOR_PLAN_FAILURE: "FETCH_ONE_SUPERVISOR_PLAN_FAILURE",
  FETCH_ONE_SUPERVISOR_PLAN_SUCCESS: "FETCH_ONE_SUPERVISOR_PLAN_SUCCESS",
};
