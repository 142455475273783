import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type LoanRequest = {
  id: number;
  staff: Staff;
  user: User;
  amount: number;
  to_pay_in_month:number;
  starting_from:string;
  reason:string;
  status: number;
  date: string;
};

export type LoanRequestStateTypes = {
  fetchAll: ApiCallState<LoanRequest[]>;
  fetchOne: ApiCallState<LoanRequest | {}>;
};

export const LoanRequestActionTypes = {
  FETCH_ALL_LOAN_REQUEST: "FETCH_ALL_LOAN_REQUEST",
  FETCH_ALL_LOAN_REQUEST_RESET: "FETCH_ALL_LOAN_REQUEST_RESET",
  FETCH_ALL_LOAN_REQUEST_FAILURE: "FETCH_ALL_LOAN_REQUEST_FAILURE",
  FETCH_ALL_LOAN_REQUEST_SUCCESS: "FETCH_ALL_LOAN_REQUEST_SUCCESS",

  FETCH_ONE_LOAN_REQUEST: "FETCH_ONE_LOAN_REQUEST",
  FETCH_ONE_LOAN_REQUEST_RESET: "FETCH_ONE_LOAN_REQUEST_RESET",
  FETCH_ONE_LOAN_REQUEST_FAILURE: "FETCH_ONE_LOAN_REQUEST_FAILURE",
  FETCH_ONE_LOAN_REQUEST_SUCCESS: "FETCH_ONE_LOAN_REQUEST_SUCCESS",
};
