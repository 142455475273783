import { ApiCallState } from "../Utils";

export type EstimatedBuildingCost= {
  id: number;
  year: string;
  amount: number;
};

export type EstimatedBuildingCostStateTypes = {
  fetchAll: ApiCallState<EstimatedBuildingCost[]>;
  fetchOne: ApiCallState<EstimatedBuildingCost | {}>;
};

export const EstimatedBuildingCostActionTypes = {
  FETCH_ALL_ESTIMATED_BUILDING_COST: "FETCH_ALL_ESTIMATED_BUILDING_COST",
  FETCH_ALL_ESTIMATED_BUILDING_COST_RESET: "FETCH_ALL_ESTIMATED_BUILDING_COST_RESET",
  FETCH_ALL_ESTIMATED_BUILDING_COST_FAILURE: "FETCH_ALL_ESTIMATED_BUILDING_COST_FAILURE",
  FETCH_ALL_ESTIMATED_BUILDING_COST_SUCCESS: "FETCH_ALL_ESTIMATED_BUILDING_COST_SUCCESS",

  FETCH_ONE_ESTIMATED_BUILDING_COST: "FETCH_ONE_ESTIMATED_BUILDING_COST",
  FETCH_ONE_ESTIMATED_BUILDING_COST_RESET: "FETCH_ONE_ESTIMATED_BUILDING_COST_RESET",
  FETCH_ONE_ESTIMATED_BUILDING_COST_FAILURE: "FETCH_ONE_ESTIMATED_BUILDING_COST_FAILURE",
  FETCH_ONE_ESTIMATED_BUILDING_COST_SUCCESS: "FETCH_ONE_ESTIMATED_BUILDING_COST_SUCCESS",
};
