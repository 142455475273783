import {
  ManpowerOvertimeStateTypes,
  ManpowerOvertimeActionTypes,
} from "./ManpowerOvertime.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ManpowerOvertimeStateTypes = {
  fetchAll: resetApiCallState([]),
};

const ManpowerOvertimeReducer = (
  state: ManpowerOvertimeStateTypes = INITIAL_STATE,
  action: any
): ManpowerOvertimeStateTypes => {
  switch (action.type) {
    case ManpowerOvertimeActionTypes.FETCH_ALL_MANPOWER_OVERTIME:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ManpowerOvertimeActionTypes.FETCH_ALL_MANPOWER_OVERTIME_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ManpowerOvertimeActionTypes.FETCH_ALL_MANPOWER_OVERTIME_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ManpowerOvertimeActionTypes.FETCH_ALL_MANPOWER_OVERTIME_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ManpowerOvertimeReducer;
