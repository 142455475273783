import { LoanStateTypes, LoanActionTypes } from "./Loan.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: LoanStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchStatus: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const LoanReducer = (
  state: LoanStateTypes = INITIAL_STATE,
  action: any
): LoanStateTypes => {
  switch (action.type) {
    case LoanActionTypes.FETCH_ALL_LOAN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case LoanActionTypes.FETCH_ALL_LOAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LoanActionTypes.FETCH_ALL_LOAN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LoanActionTypes.FETCH_ALL_LOAN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case LoanActionTypes.FETCH_LOAN_STATUS:
      return {
        ...state,
        fetchStatus: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case LoanActionTypes.FETCH_LOAN_STATUS_RESET:
      return {
        ...state,
        fetchStatus: resetApiCallState([]),
      };
    case LoanActionTypes.FETCH_LOAN_STATUS_FAILURE:
      return {
        ...state,
        fetchStatus: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LoanActionTypes.FETCH_LOAN_STATUS_SUCCESS:
      return {
        ...state,
        fetchStatus: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case LoanActionTypes.FETCH_ONE_LOAN:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case LoanActionTypes.FETCH_ONE_LOAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LoanActionTypes.FETCH_ONE_LOAN_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LoanActionTypes.FETCH_ONE_LOAN_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default LoanReducer;
