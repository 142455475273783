import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MiscellaneousBudgetActionTypes } from "./MiscellaneousBudget.type";
import { formatQuery } from "../Utils";

export function* fetchAllMiscellaneousBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/miscellaneous-budget?${formatQuery(action)}`
    );
    yield put({
      type: MiscellaneousBudgetActionTypes.FETCH_ALL_MISCELLANEOUS_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MiscellaneousBudgetActionTypes.FETCH_ALL_MISCELLANEOUS_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMiscellaneousBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/miscellaneous-budget/${action.payload}`
    );
    yield put({
      type: MiscellaneousBudgetActionTypes.FETCH_ONE_MISCELLANEOUS_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MiscellaneousBudgetActionTypes.FETCH_ONE_MISCELLANEOUS_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMiscellaneousBudgets() {
  yield takeLatest(
    MiscellaneousBudgetActionTypes.FETCH_ALL_MISCELLANEOUS_BUDGET,
    fetchAllMiscellaneousBudgets
  );
}

export function* watcherFetchOneMiscellaneousBudgets() {
  yield takeLatest(
    MiscellaneousBudgetActionTypes.FETCH_ONE_MISCELLANEOUS_BUDGET,
    fetchOneMiscellaneousBudgets
  );
}
