import { HRAllowance } from "../HRAllowance/HRAllowance.type";
import { ApiCallState } from "../Utils";

export type AllowableScaleItem = {
  id: number;
  description: string;
  percentage: number;
  primary_scale: number;
  hr_allowance_id:number;
  hr_allowance:HRAllowance;
};

export type AllowableScale = {
  id: number;
  date: string;
  scales: number;
  allowable_scale_items?:AllowableScaleItem[]
};

export type AllowableScaleStateTypes = {
  fetchAll: ApiCallState<AllowableScale[]>;
  fetchOne: ApiCallState<AllowableScale | {}>;
};

export const AllowableScaleActionTypes = {
  FETCH_ALL_ALLOWABLE_SCALE: "FETCH_ALL_ALLOWABLE_SCALE",
  FETCH_ALL_ALLOWABLE_SCALE_RESET: "FETCH_ALL_ALLOWABLE_SCALE_RESET",
  FETCH_ALL_ALLOWABLE_SCALE_FAILURE: "FETCH_ALL_ALLOWABLE_SCALE_FAILURE",
  FETCH_ALL_ALLOWABLE_SCALE_SUCCESS: "FETCH_ALL_ALLOWABLE_SCALE_SUCCESS",

  FETCH_ONE_ALLOWABLE_SCALE: "FETCH_ONE_ALLOWABLE_SCALE",
  FETCH_ONE_ALLOWABLE_SCALE_RESET: "FETCH_ONE_ALLOWABLE_SCALE_RESET",
  FETCH_ONE_ALLOWABLE_SCALE_FAILURE: "FETCH_ONE_ALLOWABLE_SCALE_FAILURE",
  FETCH_ONE_ALLOWABLE_SCALE_SUCCESS: "FETCH_ONE_ALLOWABLE_SCALE_SUCCESS",
};
