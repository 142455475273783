import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentWOActionTypes } from "./EquipmentWO.type";

export function* fetchAllEquipmentWO(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment_work_order`);
    yield put({
      type: EquipmentWOActionTypes.FETCH_ALL_EQUIPMENT_WO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentWOActionTypes.FETCH_ALL_EQUIPMENT_WO_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentWO() {
  yield takeLatest(
    EquipmentWOActionTypes.FETCH_ALL_EQUIPMENT_WO,
    fetchAllEquipmentWO
  );
}
