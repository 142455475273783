import { ApiCallState } from "../Utils";
import { Receivable } from "../Receivable/Receivable.type";
import { User } from "../User/User.type";

export type Attachment = {
  id: number;
  receivable_id: number;
  prepared_by_id: number;

  fs_number: string;
  buyer_name: string;
  buyer_trade_name: string;
  buyer_tin: string;
  buyer_vat: string;
  address: string;
  house_number: string;
  telephone: string;

  sub_total: number;
  vat: number;
  grand_total: number;
  with_hold: number;
  fuel: number;
  oil_and_lubricant: number;
  maintenance: number;
  advance: number;
  other: number;
  retention: number;
  net_payment: number;

  date: string;
  time: string;

  attachment_items: AttachmentItem[];
  receivable: Receivable;
  user: User;
};

export type AttachmentItem = {
  id: number;
  attachment_id: number;

  code_number: string;
  description: string;
  unit: string;
  packing: string;
  size: string;

  quantity: number;
  unit_price: number;
  total_amount: number;
};

export type AttachmentStateTypes = {
  fetchAll: ApiCallState<Attachment[]>;
};

export const AttachmentActionTypes = {
  FETCH_ALL_ATTACHMENT: "FETCH_ALL_ATTACHMENT",
  FETCH_ALL_ATTACHMENT_RESET: "FETCH_ALL_ATTACHMENT_RESET",
  FETCH_ALL_ATTACHMENT_FAILURE: "FETCH_ALL_ATTACHMENT_FAILURE",
  FETCH_ALL_ATTACHMENT_SUCCESS: "FETCH_ALL_ATTACHMENT_SUCCESS",
};
