import {
  FuelReceivingStateTypes,
  FuelReceivingActionTypes,
} from "./FuelReceiving.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FuelReceivingStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
};

const FuelReceivingReducer = (
  state: FuelReceivingStateTypes = INITIAL_STATE,
  action: any
): FuelReceivingStateTypes => {
  switch (action.type) {
    case FuelReceivingActionTypes.FETCH_ALL_FUEL_RECEIVING:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelReceivingActionTypes.FETCH_ALL_FUEL_RECEIVING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FuelReceivingActionTypes.FETCH_ALL_FUEL_RECEIVING_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelReceivingActionTypes.FETCH_ALL_FUEL_RECEIVING_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---

    case FuelReceivingActionTypes.FETCH_FUEL_RECEIVING_REPORT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelReceivingActionTypes.FETCH_FUEL_RECEIVING_REPORT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case FuelReceivingActionTypes.FETCH_FUEL_RECEIVING_REPORT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelReceivingActionTypes.FETCH_FUEL_RECEIVING_REPORT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FuelReceivingReducer;
