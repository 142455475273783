import { TeamMember } from "../TeamMember/TeamMember.type";
import { ApiCallState, PagedData } from "../Utils";

export type Team = {
  id: number;
  user_id: number;

  name: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  team_members: TeamMember[];
};

export type TeamStateTypes = {
  fetchAll: ApiCallState<Team[]>;
  fetchAllWithMembers: ApiCallState<Team[]>;
  fetchOne: ApiCallState<Team | {}>;
  fetchPaged: ApiCallState<PagedData<Team[]>>;
};

export const TeamActionTypes = {
  FETCH_ALL_TEAM: "FETCH_ALL_TEAM",
  FETCH_ALL_TEAM_RESET: "FETCH_ALL_TEAM_RESET",
  FETCH_ALL_TEAM_FAILURE: "FETCH_ALL_TEAM_FAILURE",
  FETCH_ALL_TEAM_SUCCESS: "FETCH_ALL_TEAM_SUCCESS",

  FETCH_ALL_TEAM_WITH_MEMBERS: "FETCH_ALL_TEAM_WITH_MEMBERS",
  FETCH_ALL_TEAM_WITH_MEMBERS_RESET: "FETCH_ALL_TEAM_WITH_MEMBERS_RESET",
  FETCH_ALL_TEAM_WITH_MEMBERS_FAILURE: "FETCH_ALL_TEAM_WITH_MEMBERS_FAILURE",
  FETCH_ALL_TEAM_WITH_MEMBERS_SUCCESS: "FETCH_ALL_TEAM_WITH_MEMBERS_SUCCESS",

  FETCH_PAGED_TEAM: "FETCH_PAGED_TEAM",
  FETCH_PAGED_TEAM_RESET: "FETCH_PAGED_TEAM_RESET",
  FETCH_PAGED_TEAM_FAILURE: "FETCH_PAGED_TEAM_FAILURE",
  FETCH_PAGED_TEAM_SUCCESS: "FETCH_PAGED_TEAM_SUCCESS",

  FETCH_ONE_TEAM: "FETCH_ONE_TEAM",
  FETCH_ONE_TEAM_RESET: "FETCH_ONE_TEAM_RESET",
  FETCH_ONE_TEAM_FAILURE: "FETCH_ONE_TEAM_FAILURE",
  FETCH_ONE_TEAM_SUCCESS: "FETCH_ONE_TEAM_SUCCESS",
};
