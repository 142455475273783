import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { InventoryActionTypes } from "./Inventory.type";
import { formatQuery } from "../Utils";

export function* fetchAllInventories(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory?filter=${action.payload?.filter}&date=${action.payload?.date}&inventory_type=${action.payload?.inventory_type}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_ALL_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ALL_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneInventory(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/${action.payload}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_ONE_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ONE_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchStockMovement(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/stock-movement-report?${formatQuery(action)}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_STOCK_MOVEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_STOCK_MOVEMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllInventories() {
  yield takeLatest(
    InventoryActionTypes.FETCH_ALL_INVENTORY,
    fetchAllInventories
  );
}

export function* watcherFetchOneInventory() {
  yield takeLatest(InventoryActionTypes.FETCH_ONE_INVENTORY, fetchOneInventory);
}

export function* watcherFetchStockMovement() {
  yield takeLatest(
    InventoryActionTypes.FETCH_STOCK_MOVEMENT,
    fetchStockMovement
  );
}
