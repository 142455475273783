import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ExternalBudgetActionTypes } from "./ExternalBudget.type";
import { formatQuery } from "../Utils";

export function* fetchAllExternalBudgets(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/external-budget?${query}`
    );
    yield put({
      type: ExternalBudgetActionTypes.FETCH_ALL_EXTERNAL_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExternalBudgetActionTypes.FETCH_ALL_EXTERNAL_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneExternalBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/external-budget/${action.payload}`
    );
    yield put({
      type: ExternalBudgetActionTypes.FETCH_ONE_EXTERNAL_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExternalBudgetActionTypes.FETCH_ONE_EXTERNAL_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllExternalBudgets() {
  yield takeLatest(
    ExternalBudgetActionTypes.FETCH_ALL_EXTERNAL_BUDGET,
    fetchAllExternalBudgets
  );
}

export function* watcherFetchOneExternalBudgets() {
  yield takeLatest(
    ExternalBudgetActionTypes.FETCH_ONE_EXTERNAL_BUDGET,
    fetchOneExternalBudgets
  );
}
