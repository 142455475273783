import { ApartmentExpenseStateTypes, ApartmentExpenseActionTypes } from "./ApartmentExpense.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ApartmentExpenseStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ApartmentExpenseReducer = (
  state: ApartmentExpenseStateTypes = INITIAL_STATE,
  action: any
): ApartmentExpenseStateTypes => {
  switch (action.type) {
    case ApartmentExpenseActionTypes.FETCH_ALL_APARTMENT_EXPENSE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ApartmentExpenseActionTypes.FETCH_ALL_APARTMENT_EXPENSE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ApartmentExpenseActionTypes.FETCH_ALL_APARTMENT_EXPENSE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ApartmentExpenseActionTypes.FETCH_ALL_APARTMENT_EXPENSE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ApartmentExpenseActionTypes.FETCH_ONE_APARTMENT_EXPENSE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ApartmentExpenseActionTypes.FETCH_ONE_APARTMENT_EXPENSE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ApartmentExpenseActionTypes.FETCH_ONE_APARTMENT_EXPENSE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ApartmentExpenseActionTypes.FETCH_ONE_APARTMENT_EXPENSE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ApartmentExpenseReducer;
