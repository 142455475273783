import { InitPagedData } from './../Utils';
import {CommissionSettingStateTypes,CommissionSettingActionTypes } from "./CommissionSetting.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: CommissionSettingStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged:resetApiCallState(InitPagedData)
};

const CommissionSettingReducer = (
  state: CommissionSettingStateTypes = INITIAL_STATE,
  action: any
): CommissionSettingStateTypes => {
  switch (action.type) {
    case CommissionSettingActionTypes.FETCH_ALL_COMMISSION_SETTING:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case CommissionSettingActionTypes.FETCH_ALL_COMMISSION_SETTING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CommissionSettingActionTypes.FETCH_ALL_COMMISSION_SETTING_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CommissionSettingActionTypes.FETCH_ALL_COMMISSION_SETTING_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
      case CommissionSettingActionTypes.FETCH_PAGED_COMMISSION_SETTING:
        return {
          ...state,
          fetchPaged: {
            error: null,
            payload: InitPagedData,
            isPending: true,
            isSuccessful: false,
          },
        };
      case CommissionSettingActionTypes.FETCH_PAGED_COMMISSION_SETTING_RESET:
        return {
          ...state,
          fetchPaged: resetApiCallState(InitPagedData),
        };
      case CommissionSettingActionTypes.FETCH_PAGED_COMMISSION_SETTING_FAILURE:
        return {
          ...state,
          fetchPaged: {
            payload: InitPagedData,
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case CommissionSettingActionTypes.FETCH_PAGED_COMMISSION_SETTING_SUCCESS:
        return {
          ...state,
          fetchPaged: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };

    case CommissionSettingActionTypes.FETCH_ONE_COMMISSION_SETTING:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case CommissionSettingActionTypes.FETCH_ONE_COMMISSION_SETTING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CommissionSettingActionTypes.FETCH_ONE_COMMISSION_SETTING_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CommissionSettingActionTypes.FETCH_ONE_COMMISSION_SETTING_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CommissionSettingReducer;
