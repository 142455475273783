import { StaffEmergencyContactStateTypes, StaffEmergencyContactActionTypes } from "./StaffEmergencyContact.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StaffEmergencyContactStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const StaffEmergencyContactReducer = (
  state: StaffEmergencyContactStateTypes = INITIAL_STATE,
  action: any
): StaffEmergencyContactStateTypes => {
  switch (action.type) {
    case StaffEmergencyContactActionTypes.FETCH_ALL_STAFF_EMERGENCY_CONTACT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffEmergencyContactActionTypes.FETCH_ALL_STAFF_EMERGENCY_CONTACT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffEmergencyContactActionTypes.FETCH_ALL_STAFF_EMERGENCY_CONTACT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffEmergencyContactActionTypes.FETCH_ALL_STAFF_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case StaffEmergencyContactActionTypes.FETCH_ONE_STAFF_EMERGENCY_CONTACT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffEmergencyContactActionTypes.FETCH_ONE_STAFF_EMERGENCY_CONTACT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffEmergencyContactActionTypes.FETCH_ONE_STAFF_EMERGENCY_CONTACT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffEmergencyContactActionTypes.FETCH_ONE_STAFF_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StaffEmergencyContactReducer;
