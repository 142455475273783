import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ScaleItemActionTypes } from "./ScaleItem.type";

export function* fetchAllScaleItems(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/scale-item?grade_id=${action.payload?.grade_id}`);
    yield put({
      type: ScaleItemActionTypes.FETCH_ALL_SCALE_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ScaleItemActionTypes.FETCH_ALL_SCALE_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneScaleItems(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/scale-item/${action.payload}`
    );
    yield put({
      type: ScaleItemActionTypes.FETCH_ONE_SCALE_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ScaleItemActionTypes.FETCH_ONE_SCALE_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllScaleItems() {
  yield takeLatest(ScaleItemActionTypes.FETCH_ALL_SCALE_ITEM, fetchAllScaleItems);
}

export function* watcherFetchOneScaleItems() {
  yield takeLatest(ScaleItemActionTypes.FETCH_ONE_SCALE_ITEM, fetchOneScaleItems);
}
