import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PriceEscalationActionTypes } from "./PriceEscalation.type";

export function* fetchAllPriceEscalations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/price_escalation?project_id=${action.payload.project_id}&pid=${action.payload.pid}`
    );
    yield put({
      type: PriceEscalationActionTypes.FETCH_ALL_PRICE_ESCALATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PriceEscalationActionTypes.FETCH_ALL_PRICE_ESCALATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPriceEscalations() {
  yield takeLatest(
    PriceEscalationActionTypes.FETCH_ALL_PRICE_ESCALATION,
    fetchAllPriceEscalations
  );
}
