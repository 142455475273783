import { InventoryStateTypes, InventoryActionTypes } from "./Inventory.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: InventoryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchStockMovement: resetApiCallState([]),
};

const InventoryReducer = (
  state: InventoryStateTypes = INITIAL_STATE,
  action: any
): InventoryStateTypes => {
  switch (action.type) {
    case InventoryActionTypes.FETCH_ALL_INVENTORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_ALL_INVENTORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_ALL_INVENTORY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_ALL_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case InventoryActionTypes.FETCH_ONE_INVENTORY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_ONE_INVENTORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_ONE_INVENTORY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_ONE_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case InventoryActionTypes.FETCH_STOCK_MOVEMENT:
      return {
        ...state,
        fetchStockMovement: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_STOCK_MOVEMENT_RESET:
      return {
        ...state,
        fetchStockMovement: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_STOCK_MOVEMENT_FAILURE:
      return {
        ...state,
        fetchStockMovement: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_STOCK_MOVEMENT_SUCCESS:
      return {
        ...state,
        fetchStockMovement: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default InventoryReducer;
