import { FixedAssetReturn } from "../FixedAssetReturn/FixedAssetReturn.type";
import { FixedAssetTransferItem } from "../FixedAssetTransfer/FixedAssetTransfer.type";
import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type FixedAssetIssue = {
  id: number;
  date: string;
  warehouse_id: number;
  project_id: number;
  material_requisition_id: number;
  user_id: number;
  warehouse: Store;
  project: Store;
  received_by: string;
  issued_by: string;
  purpose: string;
  employee: string;
  name_of_supplies: string;
  invoice_number: string;
  approved_by: string;
  checked_by: number;
  pad_ref: string;
  fixed_asset_issue_remarks: FixedAssetIssueRemark[];
  fixed_asset_issue_statuses: FixedAssetIssueStatus[];
  status: FixedAssetIssueStatus[];
  fixed_asset_issue_items: FixedAssetIssueItem[];
  fixed_asset_returns: FixedAssetReturn[];
  fixed_asset_issue_prepared_by: User;
  fixed_asset_issue_approved_by: User;
  fixed_asset_issue_checked_by: User;
  fixed_asset_issue_received_by: User;
  updatedAt: string;
  is_void: boolean;
};

export type FixedAssetIssueRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type FixedAssetIssueReport = {
  approved_by: string;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: any;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by: string;
  remark: string;
  total: number;
  unit: string;
};

export type FixedAssetIssueStatus = {
  fixed_asset_issue_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type FixedAssetIssueItem = {
  material_requisition_item_id: number;
  fixed_asset_issue_id?: number;
  material_id?: number;
  tag_number: string;
  serial_number: string;
  quantity_issued: number;
  quantity_received: number;
  unit_price: number;
  remark: string;
  available: number;
  material?: Material;
  key: number;
  id?: number;
  fixed_asset_transfer_items: FixedAssetTransferItem[];
};

export type FixedAssetIssueStateTypes = {
  fetchAll: ApiCallState<FixedAssetIssue[]>;
  fetchAllDetailed: ApiCallState<FixedAssetIssue[]>;
  fetchOne: ApiCallState<FixedAssetIssue | {}>;
  fetchPaged: ApiCallState<PagedData<FixedAssetIssue[]>>;
  fetchReport: ApiCallState<FixedAssetIssueReport[]>;
};

export const FixedAssetIssueActionTypes = {
  FETCH_ALL_FIXED_ASSET_ISSUE: "FETCH_ALL_FIXED_ASSET_ISSUE",
  FETCH_ALL_FIXED_ASSET_ISSUE_RESET: "FETCH_ALL_FIXED_ASSET_ISSUE_RESET",
  FETCH_ALL_FIXED_ASSET_ISSUE_FAILURE: "FETCH_ALL_FIXED_ASSET_ISSUE_FAILURE",
  FETCH_ALL_FIXED_ASSET_ISSUE_SUCCESS: "FETCH_ALL_FIXED_ASSET_ISSUE_SUCCESS",

  FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE: "FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE",
  FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_RESET:
    "FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_RESET",
  FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_FAILURE:
    "FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_FAILURE",
  FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_SUCCESS:
    "FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_SUCCESS",

  FETCH_REPORT_FIXED_ASSET_ISSUE: "FETCH_REPORT_FIXED_ASSET_ISSUE",
  FETCH_REPORT_FIXED_ASSET_ISSUE_RESET: "FETCH_REPORT_FIXED_ASSET_ISSUE_RESET",
  FETCH_REPORT_FIXED_ASSET_ISSUE_FAILURE:
    "FETCH_REPORT_FIXED_ASSET_ISSUE_FAILURE",
  FETCH_REPORT_FIXED_ASSET_ISSUE_SUCCESS:
    "FETCH_REPORT_FIXED_ASSET_ISSUE_SUCCESS",

  FETCH_PAGED_FIXED_ASSET_ISSUE: "FETCH_PAGED_FIXED_ASSET_ISSUE",
  FETCH_PAGED_FIXED_ASSET_ISSUE_RESET: "FETCH_PAGED_FIXED_ASSET_ISSUE_RESET",
  FETCH_PAGED_FIXED_ASSET_ISSUE_FAILURE:
    "FETCH_PAGED_FIXED_ASSET_ISSUE_FAILURE",
  FETCH_PAGED_FIXED_ASSET_ISSUE_SUCCESS:
    "FETCH_PAGED_FIXED_ASSET_ISSUE_SUCCESS",

  FETCH_ONE_FIXED_ASSET_ISSUE: "FETCH_ONE_FIXED_ASSET_ISSUE",
  FETCH_ONE_FIXED_ASSET_ISSUE_RESET: "FETCH_ONE_FIXED_ASSET_ISSUE_RESET",
  FETCH_ONE_FIXED_ASSET_ISSUE_FAILURE: "FETCH_ONE_FIXED_ASSET_ISSUE_FAILURE",
  FETCH_ONE_FIXED_ASSET_ISSUE_SUCCESS: "FETCH_ONE_FIXED_ASSET_ISSUE_SUCCESS",
};
