import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialUsageActionTypes } from "./MaterialUsage.type";

export function* fetchAllMaterialUsages(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-usage`);
    yield put({
      type: MaterialUsageActionTypes.FETCH_ALL_MATERIAL_USAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialUsageActionTypes.FETCH_ALL_MATERIAL_USAGE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialUsages(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-usage/${action.payload}`
    );
    yield put({
      type: MaterialUsageActionTypes.FETCH_ONE_MATERIAL_USAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialUsageActionTypes.FETCH_ONE_MATERIAL_USAGE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialUsages() {
  yield takeLatest(
    MaterialUsageActionTypes.FETCH_ALL_MATERIAL_USAGE,
    fetchAllMaterialUsages
  );
}

export function* watcherFetchOneMaterialUsages() {
  yield takeLatest(
    MaterialUsageActionTypes.FETCH_ONE_MATERIAL_USAGE,
    fetchOneMaterialUsages
  );
}
