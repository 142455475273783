import { ApiCallState } from "../../Utils";
import { User } from "../../User/User.type";

export type WeeklyPlanDetailData = {
  monday: number,
  tuesday: number,
  wednesday: number,
  thursday: number,
  friday: number,
  saturday: number,
}

export type WeeklyPlanDetail = {
  no_of_call: WeeklyPlanDetailData,
  no_of_interested_client: WeeklyPlanDetailData,
  no_of_appointment: WeeklyPlanDetailData,
  no_of_client_visit: WeeklyPlanDetailData,
  no_of_site_visit: WeeklyPlanDetailData,
}

export type WeeklyPlan = {
  id: number;
  week: Date;
  reporting_date: Date;
  sales_id: number;
  no_of_call: number,
  no_of_interested_client: number,
  no_of_appointment: number,
  no_of_client_visit: number,
  detail: WeeklyPlanDetail,
  user_id?: number,
  user?: User,
};

export type WeeklyPlanStateTypes = {
  fetchAll: ApiCallState<WeeklyPlan[]>;
  fetchOne: ApiCallState<WeeklyPlan | {}>;
};

export const WeeklyPlanActionTypes = {
  FETCH_ALL_WEEKLY_PLANS: "FETCH_ALL_WEEKLY_PLANS",
  FETCH_ALL_WEEKLY_PLANS_RESET: "FETCH_ALL_WEEKLY_PLANS_RESET",
  FETCH_ALL_WEEKLY_PLANS_FAILURE: "FETCH_ALL_WEEKLY_PLANS_FAILURE",
  FETCH_ALL_WEEKLY_PLANS_SUCCESS: "FETCH_ALL_WEEKLY_PLANS_SUCCESS",

  FETCH_ONE_WEEKLY_PLAN: "FETCH_ONE_WEEKLY_PLAN",
  FETCH_ONE_WEEKLY_PLAN_RESET: "FETCH_ONE_WEEKLY_PLAN_RESET",
  FETCH_ONE_WEEKLY_PLAN_FAILURE: "FETCH_ONE_WEEKLY_PLAN_FAILURE",
  FETCH_ONE_WEEKLY_PLAN_SUCCESS: "FETCH_ONE_WEEKLY_PLAN_SUCCESS",
};
