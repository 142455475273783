import { IdStateTypes, IdActionTypes } from "./Id.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: IdStateTypes = {
  type: "",
  labour: null,
  staff: null,
};

const IdReducer = (
  state: IdStateTypes = INITIAL_STATE,
  action: any
): IdStateTypes => {
  switch (action.type) {
    case IdActionTypes.SET_ID:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default IdReducer;
