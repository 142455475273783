import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type Usage = {
  id: number;
  quantity: number;
  date: Date;
  material_inventory: { material: Material; unit_price: number };
  remark: string;
  work_description: string;
  project: Project;
  unit: string;
  project_id: number;
};

export type UsageStateTypes = {
  fetchAll: ApiCallState<Usage[]>;
};

export const UsageActionTypes = {
  FETCH_ALL_USAGE: "FETCH_ALL_USAGE",
  FETCH_ALL_USAGE_RESET: "FETCH_ALL_USAGE_RESET",
  FETCH_ALL_USAGE_FAILURE: "FETCH_ALL_USAGE_FAILURE",
  FETCH_ALL_USAGE_SUCCESS: "FETCH_ALL_USAGE_SUCCESS",
};
