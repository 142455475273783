import { Boq } from "../Boq/Boq.type";
import { MasterScheduleList } from "../MasterScheduleList/MasterScheduleList.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type WeekReport = {
  id: number;
  master_schedule_list_id: number;
  reporting_date: string;
  reporting_week: string;
  master_schedule_list?: MasterScheduleList;
  week_end: string;
  description: string;
  etb_works_executed: number;
  percentage_of_skeleton_work: number;
  technical_challenge: string;
  administrative_challenge: string;
  incidents: string;
  on_progress_tasks: string;
  plan_for_next_week: string;
  planned_executed: string;
  not_planned_executed: string;
  planned_not_executed: string;
  urgent_attention: string;
  attachement: string;
  contract_amount: number;
  etbWorksPlannedThisWeek: number;
  etbWorksExecutedThisWeek: number;
  percentOfWorksPlannedThisWeek: number;
  percentOfWorksExecutedThisWeek: number;
  percentOfWorkExecutedThisWeek: number;
  percentOfWorkPlannedButNotExecuted: number;
  comment: string;
  prepared_by: string;
  approved_by: string;
  wr_prepared_by: User;
  wr_checked_by: User;
  wr_approved_by: User;
  on_revision: boolean;
  is_approved: boolean;
  is_checked: boolean;
  weekly_report_items: WeeklyReportItem[];
};

export type WeeklyReportItem = {
  boq: Boq;
  quantity: number;
  planned: number;
  unit_price: number;
  key: number;
  flag?: string;
  id?: number;
};

export type WeekReportStateTypes = {
  fetchAll: ApiCallState<WeekReport[]>;
  fetchOne: ApiCallState<WeekReport | null>;
};

export const WeekReportActionTypes = {
  FETCH_ALL_WEEK_REPORT: "FETCH_ALL_WEEK_REPORT",
  FETCH_ALL_WEEK_REPORT_RESET: "FETCH_ALL_WEEK_REPORT_RESET",
  FETCH_ALL_WEEK_REPORT_FAILURE: "FETCH_ALL_WEEK_REPORT_FAILURE",
  FETCH_ALL_WEEK_REPORT_SUCCESS: "FETCH_ALL_WEEK_REPORT_SUCCESS",

  FETCH_ONE_WEEK_REPORT: "FETCH_ONE_WEEK_REPORT",
  FETCH_ONE_WEEK_REPORT_RESET: "FETCH_ONE_WEEK_REPORT_RESET",
  FETCH_ONE_WEEK_REPORT_FAILURE: "FETCH_ONE_WEEK_REPORT_FAILURE",
  FETCH_ONE_WEEK_REPORT_SUCCESS: "FETCH_ONE_WEEK_REPORT_SUCCESS",
};
