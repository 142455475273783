import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type AdvanceSalary= {
  id: number;
  date: string;
  
  advance_salary_items: AdvanceSalaryItem[]
};

export type AdvanceSalaryItem = {
    id?: number;
    amount: number;
    staff_id: number;
    staff: Staff
}

export type AdvanceSalaryStateTypes = {
  fetchAll: ApiCallState<AdvanceSalary[]>;
  fetchOne: ApiCallState<AdvanceSalary | {}>;
};

export const AdvanceSalaryActionTypes = {
  FETCH_ALL_ADVANCE_SALARY: "FETCH_ALL_ADVANCE_SALARY",
  FETCH_ALL_ADVANCE_SALARY_RESET: "FETCH_ALL_ADVANCE_SALARY_RESET",
  FETCH_ALL_ADVANCE_SALARY_FAILURE: "FETCH_ALL_ADVANCE_SALARY_FAILURE",
  FETCH_ALL_ADVANCE_SALARY_SUCCESS: "FETCH_ALL_ADVANCE_SALARY_SUCCESS",

  FETCH_ONE_ADVANCE_SALARY: "FETCH_ONE_ADVANCE_SALARY",
  FETCH_ONE_ADVANCE_SALARY_RESET: "FETCH_ONE_ADVANCE_SALARY_RESET",
  FETCH_ONE_ADVANCE_SALARY_FAILURE: "FETCH_ONE_ADVANCE_SALARY_FAILURE",
  FETCH_ONE_ADVANCE_SALARY_SUCCESS: "FETCH_ONE_ADVANCE_SALARY_SUCCESS",
};
