import { Department } from "../Department/Department.type";
import { HRAllowance } from "../HRAllowance/HRAllowance.type";
import { ApiCallState } from "../Utils";

export type HRRequest= {
  id: number;
  date:string;
  department_id:number;
  no_of_personnel_required:number;
  source_of_recruitment:string;
  education:string;
  training:string;
  experience:string;
  skill:string;
  position:string;
  grade:string
  step:string;
  reason_for_request:string;
  place_of_work:string;
  date_of_employment:string;
  duration_of_employment:string;
  salary:number;
  peridium:string;
  hr_request_items:HRRequestItem[];
  hr_request_statuses:HRRequestStatus[];
  requested_by:number;
  approved_by:number;
  user_id:number;
  department:Department;
};

export type HRRequestItem={
    id:number;
    hr_request_id:number;
    hr_allowance_id:number;
    amount:number;
    hr_allowance:HRAllowance;
}

export type HRRequestStatus = {
    hr_request_id:number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    id: number;
  };

export type HRRequestStateTypes = {
  fetchAll: ApiCallState<HRRequest[]>;
  fetchOne: ApiCallState<HRRequest | {}>;
};

export const HRRequestActionTypes = {
  FETCH_ALL_HR_REQUEST: "FETCH_ALL_HR_REQUEST",
  FETCH_ALL_HR_REQUEST_RESET: "FETCH_ALL_HR_REQUEST_RESET",
  FETCH_ALL_HR_REQUEST_FAILURE: "FETCH_ALL_HR_REQUEST_FAILURE",
  FETCH_ALL_HR_REQUEST_SUCCESS: "FETCH_ALL_HR_REQUEST_SUCCESS",

  FETCH_ONE_HR_REQUEST: "FETCH_ONE_HR_REQUEST",
  FETCH_ONE_HR_REQUEST_RESET: "FETCH_ONE_HR_REQUEST_RESET",
  FETCH_ONE_HR_REQUEST_FAILURE: "FETCH_ONE_HR_REQUEST_FAILURE",
  FETCH_ONE_HR_REQUEST_SUCCESS: "FETCH_ONE_HR_REQUEST_SUCCESS",
};
