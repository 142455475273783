import { InitPagedData } from "./../Utils";
import {
  MonthlyReportV2StateTypes,
  MonthlyReportV2ActionTypes,
} from "./MonthlyReportV2.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MonthlyReportV2StateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
  fetchReport: resetApiCallState({}),
};

const MonthlyReportV2Reducer = (
  state: MonthlyReportV2StateTypes = INITIAL_STATE,
  action: any
): MonthlyReportV2StateTypes => {
  switch (action.type) {
    case MonthlyReportV2ActionTypes.FETCH_ALL_MONTHLY_REPORT_V2:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_ALL_MONTHLY_REPORT_V2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthlyReportV2ActionTypes.FETCH_ALL_MONTHLY_REPORT_V2_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_ALL_MONTHLY_REPORT_V2_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlyReportV2ActionTypes.FETCH_MONTHLY_REPORT_V2:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_MONTHLY_REPORT_V2_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState({}),
      };
    case MonthlyReportV2ActionTypes.FETCH_MONTHLY_REPORT_V2_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_MONTHLY_REPORT_V2_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlyReportV2ActionTypes.FETCH_PAGED_MONTHLY_REPORT_V2:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_PAGED_MONTHLY_REPORT_V2_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case MonthlyReportV2ActionTypes.FETCH_PAGED_MONTHLY_REPORT_V2_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_PAGED_MONTHLY_REPORT_V2_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlyReportV2ActionTypes.FETCH_ONE_MONTHLY_REPORT_V2:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_ONE_MONTHLY_REPORT_V2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MonthlyReportV2ActionTypes.FETCH_ONE_MONTHLY_REPORT_V2_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlyReportV2ActionTypes.FETCH_ONE_MONTHLY_REPORT_V2_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MonthlyReportV2Reducer;
