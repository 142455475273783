import { InitPagedData } from "./../Utils";
import {
  FixedAssetTransferStateTypes,
  FixedAssetTransferActionTypes,
} from "./FixedAssetTransfer.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FixedAssetTransferStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const FixedAssetTransferReducer = (
  state: FixedAssetTransferStateTypes = INITIAL_STATE,
  action: any
): FixedAssetTransferStateTypes => {
  switch (action.type) {
    case FixedAssetTransferActionTypes.FETCH_ALL_FIXED_ASSET_TRANSFER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetTransferActionTypes.FETCH_ALL_FIXED_ASSET_TRANSFER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FixedAssetTransferActionTypes.FETCH_ALL_FIXED_ASSET_TRANSFER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetTransferActionTypes.FETCH_ALL_FIXED_ASSET_TRANSFER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case FixedAssetTransferActionTypes.FETCH_PAGED_FIXED_ASSET_TRANSFER:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetTransferActionTypes.FETCH_PAGED_FIXED_ASSET_TRANSFER_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case FixedAssetTransferActionTypes.FETCH_PAGED_FIXED_ASSET_TRANSFER_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetTransferActionTypes.FETCH_PAGED_FIXED_ASSET_TRANSFER_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FixedAssetTransferActionTypes.FETCH_ONE_FIXED_ASSET_TRANSFER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetTransferActionTypes.FETCH_ONE_FIXED_ASSET_TRANSFER_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState(null),
      };
    case FixedAssetTransferActionTypes.FETCH_ONE_FIXED_ASSET_TRANSFER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetTransferActionTypes.FETCH_ONE_FIXED_ASSET_TRANSFER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FixedAssetTransferReducer;
