import { InitPagedData } from "./../Utils";
import {
  AccountChartStateTypes,
  AccountChartActionTypes,
} from "./AccountChart.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AccountChartStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const AccountChartReducer = (
  state: AccountChartStateTypes = INITIAL_STATE,
  action: any
): AccountChartStateTypes => {
  switch (action.type) {
    case AccountChartActionTypes.FETCH_ALL_ACCOUNT_CHART:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountChartActionTypes.FETCH_ALL_ACCOUNT_CHART_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AccountChartActionTypes.FETCH_ALL_ACCOUNT_CHART_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountChartActionTypes.FETCH_ALL_ACCOUNT_CHART_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case AccountChartActionTypes.FETCH_PAGED_ACCOUNT_CHART:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountChartActionTypes.FETCH_PAGED_ACCOUNT_CHART_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case AccountChartActionTypes.FETCH_PAGED_ACCOUNT_CHART_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountChartActionTypes.FETCH_PAGED_ACCOUNT_CHART_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AccountChartActionTypes.FETCH_ONE_ACCOUNT_CHART:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountChartActionTypes.FETCH_ONE_ACCOUNT_CHART_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AccountChartActionTypes.FETCH_ONE_ACCOUNT_CHART_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountChartActionTypes.FETCH_ONE_ACCOUNT_CHART_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AccountChartReducer;
