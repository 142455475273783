import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PerformancePointPolicyActionTypes } from "./PerformancePointPolicy.type";

export function* fetchAllPerformancePointPolicies(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/performance-point-policy${query}`
    );
    yield put({
      type: PerformancePointPolicyActionTypes.FETCH_ALL_PERFORMANCE_POINT_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PerformancePointPolicyActionTypes.FETCH_ALL_PERFORMANCE_POINT_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePerformancePointPolicy(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/performance-point-policy/${action.payload}`
    );
    yield put({
      type: PerformancePointPolicyActionTypes.FETCH_ONE_PERFORMANCE_POINT_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PerformancePointPolicyActionTypes.FETCH_ONE_PERFORMANCE_POINT_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPerformancePointPolicies() {
  yield takeLatest(
    PerformancePointPolicyActionTypes.FETCH_ALL_PERFORMANCE_POINT_POLICY,
    fetchAllPerformancePointPolicies
  );
}

export function* watcherFetchOnePerformancePointPolicy() {
  yield takeLatest(
    PerformancePointPolicyActionTypes.FETCH_ONE_PERFORMANCE_POINT_POLICY,
    fetchOnePerformancePointPolicy
  );
}
