import { ApiCallState } from "../../../Utils";

export type OpportunityUpdate = {
  id: number;
  opportunity: number;
  date: Date;
  type: string;
  op_update: string;
  next_step: string;
  schedule: Date;
};

export type OpportunityUpdateStateTypes = {
  fetchByOpportunity: ApiCallState<OpportunityUpdate[]>;
  fetchAll: ApiCallState<OpportunityUpdate[]>;
  fetchOne: ApiCallState<OpportunityUpdate | {}>;
};

export const OpportunityUpdateActionTypes = {
  FETCH_OPPORTUNITY_UPDATES: "FETCH_OPPORTUNITY_UPDATES",
  FETCH_OPPORTUNITY_UPDATES_RESET: "FETCH_OPPORTUNITY_UPDATES_RESET",
  FETCH_OPPORTUNITY_UPDATES_FAILURE: "FETCH_OPPORTUNITY_UPDATES_FAILURE",
  FETCH_OPPORTUNITY_UPDATES_SUCCESS: "FETCH_OPPORTUNITY_UPDATES_SUCCESS",

  FETCH_ALL_OPPORTUNITY_UPDATES: "FETCH_ALL_OPPORTUNITY_UPDATES",
  FETCH_ALL_OPPORTUNITY_UPDATES_RESET: "FETCH_ALL_OPPORTUNITY_UPDATES_RESET",
  FETCH_ALL_OPPORTUNITY_UPDATES_FAILURE: "FETCH_ALL_OPPORTUNITY_UPDATES_FAILURE",
  FETCH_ALL_OPPORTUNITY_UPDATES_SUCCESS: "FETCH_ALL_OPPORTUNITY_UPDATES_SUCCESS",

  FETCH_ONE_OPPORTUNITY_UPDATE: "FETCH_ONE_OPPORTUNITY_UPDATE",
  FETCH_ONE_OPPORTUNITY_UPDATE_RESET: "FETCH_ONE_OPPORTUNITY_UPDATE_RESET",
  FETCH_ONE_OPPORTUNITY_UPDATE_FAILURE: "FETCH_ONE_OPPORTUNITY_UPDATE_FAILURE",
  FETCH_ONE_OPPORTUNITY_UPDATE_SUCCESS: "FETCH_ONE_OPPORTUNITY_UPDATE_SUCCESS",
};
