import { WalkInStateTypes, WalkInActionTypes } from "./WalkIn.type";
import { resetApiCallState } from "../../Utils";

const INITIAL_STATE: WalkInStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const WalkInReducer = (
  state: WalkInStateTypes = INITIAL_STATE,
  action: any
): WalkInStateTypes => {
  switch (action.type) {
    case WalkInActionTypes.FETCH_ALL_WALK_IN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WalkInActionTypes.FETCH_ALL_WALK_IN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WalkInActionTypes.FETCH_ALL_WALK_IN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WalkInActionTypes.FETCH_ALL_WALK_IN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WalkInActionTypes.FETCH_ONE_WALK_IN:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WalkInActionTypes.FETCH_ONE_WALK_IN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WalkInActionTypes.FETCH_ONE_WALK_IN_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WalkInActionTypes.FETCH_ONE_WALK_IN_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WalkInReducer;
