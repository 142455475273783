import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffBioActionTypes } from "./StaffBio.type";

export function* fetchAllStaffBios(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-bio`);
    yield put({
      type: StaffBioActionTypes.FETCH_ALL_STAFF_BIO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffBioActionTypes.FETCH_ALL_STAFF_BIO_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffBios(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-bio/${action.payload}`
    );
    yield put({
      type: StaffBioActionTypes.FETCH_ONE_STAFF_BIO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffBioActionTypes.FETCH_ONE_STAFF_BIO_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffBios() {
  yield takeLatest(StaffBioActionTypes.FETCH_ALL_STAFF_BIO, fetchAllStaffBios);
}

export function* watcherFetchOneStaffBios() {
  yield takeLatest(StaffBioActionTypes.FETCH_ONE_STAFF_BIO, fetchOneStaffBios);
}
