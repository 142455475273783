import { TaskMeetingStateTypes, TaskMeetingActionTypes } from "./TaskMeeting.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TaskMeetingStateTypes = {
	fetchAll: resetApiCallState([]),
	fetchOne: resetApiCallState({}),
};

const TaskMeetingReducer = (
	state: TaskMeetingStateTypes = INITIAL_STATE,
	action: any
): TaskMeetingStateTypes => {
	switch (action.type) {
		case TaskMeetingActionTypes.FETCH_ALL_TASK_MEETING:
			return {
				...state,
				fetchAll: {
					error: null,
					payload: [],
					isPending: true,
					isSuccessful: false,
				},
			};
		case TaskMeetingActionTypes.FETCH_ALL_TASK_MEETING_RESET:
			return {
				...state,
				fetchAll: resetApiCallState([]),
			};
		case TaskMeetingActionTypes.FETCH_ALL_TASK_MEETING_FAILURE:
			return {
				...state,
				fetchAll: {
					payload: [],
					isPending: false,
					isSuccessful: false,
					error: action.payload,
				},
			};
		case TaskMeetingActionTypes.FETCH_ALL_TASK_MEETING_SUCCESS:
			return {
				...state,
				fetchAll: {
					error: null,
					isPending: false,
					isSuccessful: true,
					payload: action.payload,
				},
			};

		case TaskMeetingActionTypes.FETCH_ONE_TASK_MEETING:
			return {
				...state,
				fetchOne: {
					error: null,
					payload: {},
					isPending: true,
					isSuccessful: false,
				},
			};
		case TaskMeetingActionTypes.FETCH_ONE_TASK_MEETING_RESET:
			return {
				...state,
				fetchAll: resetApiCallState([]),
			};
		case TaskMeetingActionTypes.FETCH_ONE_TASK_MEETING_FAILURE:
			return {
				...state,
				fetchOne: {
					payload: {},
					isPending: false,
					isSuccessful: false,
					error: action.payload,
				},
			};
		case TaskMeetingActionTypes.FETCH_ONE_TASK_MEETING_SUCCESS:
			return {
				...state,
				fetchOne: {
					error: null,
					isPending: false,
					isSuccessful: true,
					payload: action.payload,
				},
			};

		default:
			return state;
	}
};

export default TaskMeetingReducer;
