import { PettyCash } from "../PettyCash/PettyCash.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type ReplenishmentRequest = {
  id: number;

  amount: number;
  request_number: string;

  prepared_by: number;
  checked_by: number;
  authorized_by: number;

  requested_by: number;

  is_checked: boolean;
  is_approved: boolean;
  is_replenished: boolean;

  date: Date;

  rr_requested_by: User;
  rr_prepared_by: User;
  rr_checked_by: User;
  rr_authorized_by: User;

  petty_cash: PettyCash;
};

export type ReplenishmentRequestStateTypes = {
  fetchAll: ApiCallState<ReplenishmentRequest[]>;
  fetchOne: ApiCallState<ReplenishmentRequest | {}>;
};

export const ReplenishmentRequestActionTypes = {
  FETCH_ALL_REPLENISHMENT_REQUEST: "FETCH_ALL_REPLENISHMENT_REQUEST",
  FETCH_ALL_REPLENISHMENT_REQUEST_RESET:
    "FETCH_ALL_REPLENISHMENT_REQUEST_RESET",
  FETCH_ALL_REPLENISHMENT_REQUEST_FAILURE:
    "FETCH_ALL_REPLENISHMENT_REQUEST_FAILURE",
  FETCH_ALL_REPLENISHMENT_REQUEST_SUCCESS:
    "FETCH_ALL_REPLENISHMENT_REQUEST_SUCCESS",

  FETCH_ONE_REPLENISHMENT_REQUEST: "FETCH_ONE_REPLENISHMENT_REQUEST",
  FETCH_ONE_REPLENISHMENT_REQUEST_RESET:
    "FETCH_ONE_REPLENISHMENT_REQUEST_RESET",
  FETCH_ONE_REPLENISHMENT_REQUEST_FAILURE:
    "FETCH_ONE_REPLENISHMENT_REQUEST_FAILURE",
  FETCH_ONE_REPLENISHMENT_REQUEST_SUCCESS:
    "FETCH_ONE_REPLENISHMENT_REQUEST_SUCCESS",
};
