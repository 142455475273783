import { PayableStateTypes, PayableActionTypes } from "./Payable.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PayableStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PayableReducer = (
  state: PayableStateTypes = INITIAL_STATE,
  action: any
): PayableStateTypes => {
  switch (action.type) {
    case PayableActionTypes.FETCH_ALL_PAYABLE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayableActionTypes.FETCH_ALL_PAYABLE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PayableActionTypes.FETCH_ALL_PAYABLE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayableActionTypes.FETCH_ALL_PAYABLE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayableActionTypes.FETCH_ONE_PAYABLE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayableActionTypes.FETCH_ONE_PAYABLE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PayableActionTypes.FETCH_ONE_PAYABLE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayableActionTypes.FETCH_ONE_PAYABLE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PayableReducer;
