import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqActionTypes } from "./Boq.type";

export function* fetchAllBoqs(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_ALL_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_ALL_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoqs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/boq/${action.payload}`);
    yield put({
      type: BoqActionTypes.FETCH_ONE_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_ONE_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchSheetNames(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq/sheet-name?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_SHEET_NAME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_SHEET_NAME_FAILURE,
      payload: error,
    });
  }
}

export function* fetchBoqTotal(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq/total?${query}`);
    yield put({
      type: BoqActionTypes.FETCH_BOQ_TOTAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActionTypes.FETCH_BOQ_TOTAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqs() {
  yield takeLatest(BoqActionTypes.FETCH_ALL_BOQ, fetchAllBoqs);
}

export function* watcherFetchOneBoqs() {
  yield takeLatest(BoqActionTypes.FETCH_ONE_BOQ, fetchOneBoqs);
}

export function* watcherFetchSheetNames() {
  yield takeLatest(BoqActionTypes.FETCH_SHEET_NAME, fetchSheetNames);
}

export function* watcherFetchBoqTotal() {
  yield takeLatest(BoqActionTypes.FETCH_BOQ_TOTAL, fetchBoqTotal);
}
