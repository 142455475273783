import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type WorkOvertime= {
  id: number;
  date: string;
  work_overtime_items: WorkOvertimeItem[]
};

export type WorkOvertimeItem = {
  id: number;
  staff_id: number;
  type: string;
  meter: number;
  multiplier: number;
  staff: Staff
}

export type WorkOvertimeStateTypes = {
  fetchAll: ApiCallState<WorkOvertime[]>;
  fetchOne: ApiCallState<WorkOvertime | {}>;
};

export const WorkOvertimeActionTypes = {
  FETCH_ALL_WORK_OVERTIME: "FETCH_ALL_WORK_OVERTIME",
  FETCH_ALL_WORK_OVERTIME_RESET: "FETCH_ALL_WORK_OVERTIME_RESET",
  FETCH_ALL_WORK_OVERTIME_FAILURE: "FETCH_ALL_WORK_OVERTIME_FAILURE",
  FETCH_ALL_WORK_OVERTIME_SUCCESS: "FETCH_ALL_WORK_OVERTIME_SUCCESS",

  FETCH_ONE_WORK_OVERTIME: "FETCH_ONE_WORK_OVERTIME",
  FETCH_ONE_WORK_OVERTIME_RESET: "FETCH_ONE_WORK_OVERTIME_RESET",
  FETCH_ONE_WORK_OVERTIME_FAILURE: "FETCH_ONE_WORK_OVERTIME_FAILURE",
  FETCH_ONE_WORK_OVERTIME_SUCCESS: "FETCH_ONE_WORK_OVERTIME_SUCCESS",
};
