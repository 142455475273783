import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type UserControl = {
  id: number;
  key: number;
  user: User;
};

export type UserControlStateTypes = {
  fetchAll: ApiCallState<UserControl[]>;
  fetchOne: ApiCallState<UserControl | {}>;
  fetchPaged: ApiCallState<PagedData<UserControl[]>>;
};

export const UserControlActionTypes = {
  FETCH_ALL_USER_CONTROL: "FETCH_ALL_USER_CONTROL",
  FETCH_ALL_USER_CONTROL_RESET: "FETCH_ALL_USER_CONTROL_RESET",
  FETCH_ALL_USER_CONTROL_FAILURE: "FETCH_ALL_USER_CONTROL_FAILURE",
  FETCH_ALL_USER_CONTROL_SUCCESS: "FETCH_ALL_USER_CONTROL_SUCCESS",

  FETCH_PAGED_USER_CONTROL: "FETCH_PAGED_USER_CONTROL",
  FETCH_PAGED_USER_CONTROL_RESET: "FETCH_PAGED_USER_CONTROL_RESET",
  FETCH_PAGED_USER_CONTROL_FAILURE: "FETCH_PAGED_USER_CONTROL_FAILURE",
  FETCH_PAGED_USER_CONTROL_SUCCESS: "FETCH_PAGED_USER_CONTROL_SUCCESS",

  FETCH_ONE_USER_CONTROL: "FETCH_ONE_USER_CONTROL",
  FETCH_ONE_USER_CONTROL_RESET: "FETCH_ONE_USER_CONTROL_RESET",
  FETCH_ONE_USER_CONTROL_FAILURE: "FETCH_ONE_USER_CONTROL_FAILURE",
  FETCH_ONE_USER_CONTROL_SUCCESS: "FETCH_ONE_USER_CONTROL_SUCCESS",
};
