import { ApiCallState } from "../Utils";

export type EmployeeAccommodationPlan = {
  key: number;
  project_id: number;
  id: any;
  description: string;
  unit: string;
  quantity: number;
  unit_price: number;
  total: number;
  date: string;
  date_type: string;
};

export type EmployeeAccommodationPlanStateTypes = {
  fetchAll: ApiCallState<EmployeeAccommodationPlan[]>;
  fetchOne: ApiCallState<EmployeeAccommodationPlan | {}>;
};

export const EmployeeAccommodationPlanActionTypes = {
  FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN:
    "FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN",
  FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_RESET:
    "FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_RESET",
  FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE:
    "FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE",
  FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS:
    "FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS",

  FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN:
    "FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN",
  FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_RESET:
    "FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_RESET",
  FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE:
    "FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE",
  FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS:
    "FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS",
};
