import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LabourBudgetActionTypes } from "./LabourBudget.type";
import { formatQuery } from "../Utils";

export function* fetchAllLabourBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/labour-budget?${formatQuery(action)}`
    );
    yield put({
      type: LabourBudgetActionTypes.FETCH_ALL_LABOUR_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourBudgetActionTypes.FETCH_ALL_LABOUR_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLabourBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/labour-budget/${action.payload}`
    );
    yield put({
      type: LabourBudgetActionTypes.FETCH_ONE_LABOUR_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourBudgetActionTypes.FETCH_ONE_LABOUR_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLabourBudgets() {
  yield takeLatest(
    LabourBudgetActionTypes.FETCH_ALL_LABOUR_BUDGET,
    fetchAllLabourBudgets
  );
}

export function* watcherFetchOneLabourBudgets() {
  yield takeLatest(
    LabourBudgetActionTypes.FETCH_ONE_LABOUR_BUDGET,
    fetchOneLabourBudgets
  );
}
