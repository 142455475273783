import {
  EmployeeAccommodationPlanStateTypes,
  EmployeeAccommodationPlanActionTypes,
} from "./EmployeeAccommodationPlan.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EmployeeAccommodationPlanStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const EmployeeAccommodationPlanReducer = (
  state: EmployeeAccommodationPlanStateTypes = INITIAL_STATE,
  action: any
): EmployeeAccommodationPlanStateTypes => {
  switch (action.type) {
    case EmployeeAccommodationPlanActionTypes.FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeAccommodationPlanActionTypes.FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeAccommodationPlanActionTypes.FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeAccommodationPlanActionTypes.FETCH_ALL_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EmployeeAccommodationPlanActionTypes.FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeAccommodationPlanActionTypes.FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeAccommodationPlanActionTypes.FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeAccommodationPlanActionTypes.FETCH_ONE_EMPLOYEE_ACCOMMODATION_PLAN_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EmployeeAccommodationPlanReducer;
