import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { VoucherAuthorizerActionTypes } from "./VoucherAuthorizer.type";

export function* fetchAllVoucherAuthorizers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/voucher-authorizer`);
    yield put({
      type: VoucherAuthorizerActionTypes.FETCH_ALL_VOUCHER_AUTHORIZER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VoucherAuthorizerActionTypes.FETCH_ALL_VOUCHER_AUTHORIZER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneVoucherAuthorizers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/voucher-authorizer/${action.payload}`
    );
    yield put({
      type: VoucherAuthorizerActionTypes.FETCH_ONE_VOUCHER_AUTHORIZER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VoucherAuthorizerActionTypes.FETCH_ONE_VOUCHER_AUTHORIZER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllVoucherAuthorizers() {
  yield takeLatest(
    VoucherAuthorizerActionTypes.FETCH_ALL_VOUCHER_AUTHORIZER,
    fetchAllVoucherAuthorizers
  );
}

export function* watcherFetchOneVoucherAuthorizers() {
  yield takeLatest(
    VoucherAuthorizerActionTypes.FETCH_ONE_VOUCHER_AUTHORIZER,
    fetchOneVoucherAuthorizers
  );
}
