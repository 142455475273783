import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type SubContractPlan = {
  project_id: number | null;
  id: number | null;
  type: string;
  unit: string;
  quantity: number;
  unit_price: number;
  amount: number;
  description: string;
  date_type: string;
  date: string;
  key: number;
};

export type SubContractPlanStateTypes = {
  fetchAll: ApiCallState<SubContractPlan[]>;
};

export type SubContractPlanActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const SubContractPlanActionTypes = {
  FETCH_ALL_SUB_CONTRACT_PLAN: "FETCH_ALL_SUB_CONTRACT_PLAN",
  FETCH_ALL_SUB_CONTRACT_PLAN_RESET: "FETCH_ALL_SUB_CONTRACT_PLAN_RESET",
  FETCH_ALL_SUB_CONTRACT_PLAN_FAILURE: "FETCH_ALL_SUB_CONTRACT_PLAN_FAILURE",
  FETCH_ALL_SUB_CONTRACT_PLAN_SUCCESS: "FETCH_ALL_SUB_CONTRACT_PLAN_SUCCESS",
};
