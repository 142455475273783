import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffTaxInformationActionTypes } from "./StaffTaxInformation.type";

export function* fetchAllStaffTaxInformations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-tax-information?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffTaxInformationActionTypes.FETCH_ALL_STAFF_TAX_INFORMATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffTaxInformationActionTypes.FETCH_ALL_STAFF_TAX_INFORMATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffTaxInformations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-tax-information/${action.payload}`
    );
    yield put({
      type: StaffTaxInformationActionTypes.FETCH_ONE_STAFF_TAX_INFORMATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffTaxInformationActionTypes.FETCH_ONE_STAFF_TAX_INFORMATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffTaxInformations() {
  yield takeLatest(StaffTaxInformationActionTypes.FETCH_ALL_STAFF_TAX_INFORMATION, fetchAllStaffTaxInformations);
}

export function* watcherFetchOneStaffTaxInformations() {
  yield takeLatest(StaffTaxInformationActionTypes.FETCH_ONE_STAFF_TAX_INFORMATION, fetchOneStaffTaxInformations);
}
