import { ApiCallState } from "../Utils";

export type MasterScheduleForecast= {
    type: string;
    project_name: string;
    expense_total: number;
    project_id: number;
    start_date: string;
    end_date: string;
    revenue_total:number
};

export type MasterScheduleForecastDetail= {
    description: string;
    quantity: number;
    unit_price: number;
    expense_total: number;
};



export type ForecastStateTypes = {
  fetchMasterSchedule: ApiCallState<MasterScheduleForecast[]>;
  fetchMasterScheduleDetail: ApiCallState<MasterScheduleForecastDetail[]>;
  
};

export const ForecastActionTypes = {
  FETCH_MASTER_SCHEDULE_FORECAST: "FETCH_MASTER_SCHEDULE_FORECAST",
  FETCH_MASTER_SCHEDULE_FORECAST_RESET: "FETCH_MASTER_SCHEDULE_FORECAST_RESET",
  FETCH_MASTER_SCHEDULE_FORECAST_FAILURE: "FETCH_MASTER_SCHEDULE_FORECAST_FAILURE",
  FETCH_MASTER_SCHEDULE_FORECAST_SUCCESS: "FETCH_MASTER_SCHEDULE_FORECAST_SUCCESS",


  FETCH_MASTER_SCHEDULE_DETAILS_FORECAST: "FETCH_MASTER_SCHEDULE_DETAILS_FORECAST",
  FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_RESET: "FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_RESET",
  FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_FAILURE: "FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_FAILURE",
  FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_SUCCESS: "FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_SUCCESS",
};
