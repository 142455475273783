import {
  EmployeeExtraStateTypes,
  EmployeeExtraActionTypes,
} from "./EmployeeExtra.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EmployeeExtraStateTypes = {
  fetchAll: resetApiCallState([]),
};

const EmployeeExtraReducer = (
  state: EmployeeExtraStateTypes = INITIAL_STATE,
  action: any
): EmployeeExtraStateTypes => {
  switch (action.type) {
    case EmployeeExtraActionTypes.FETCH_ALL_EMPLOYEE_EXTRA:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeExtraActionTypes.FETCH_ALL_EMPLOYEE_EXTRA_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeExtraActionTypes.FETCH_ALL_EMPLOYEE_EXTRA_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeExtraActionTypes.FETCH_ALL_EMPLOYEE_EXTRA_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EmployeeExtraReducer;
