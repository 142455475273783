import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AccountChartBalanceActionTypes } from "./AccountChartBalance.type";

export function* fetchAllAccountChartBalances(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/account-chart-balance?${query}`
    );
    yield put({
      type: AccountChartBalanceActionTypes.FETCH_ALL_ACCOUNT_CHART_BALANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountChartBalanceActionTypes.FETCH_ALL_ACCOUNT_CHART_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedAccountChartBalances(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/account-chart-balance?${query}`
    );
    yield put({
      type: AccountChartBalanceActionTypes.FETCH_PAGED_ACCOUNT_CHART_BALANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountChartBalanceActionTypes.FETCH_PAGED_ACCOUNT_CHART_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAccountChartBalances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/account-chart-balance/${action.payload}`
    );
    yield put({
      type: AccountChartBalanceActionTypes.FETCH_ONE_ACCOUNT_CHART_BALANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountChartBalanceActionTypes.FETCH_ONE_ACCOUNT_CHART_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAccountChartBalances() {
  yield takeLatest(
    AccountChartBalanceActionTypes.FETCH_ALL_ACCOUNT_CHART_BALANCE,
    fetchAllAccountChartBalances
  );
}

export function* watcherFetchPagedAccountChartBalances() {
  yield takeLatest(
    AccountChartBalanceActionTypes.FETCH_PAGED_ACCOUNT_CHART_BALANCE,
    fetchPagedAccountChartBalances
  );
}

export function* watcherFetchOneAccountChartBalances() {
  yield takeLatest(
    AccountChartBalanceActionTypes.FETCH_ONE_ACCOUNT_CHART_BALANCE,
    fetchOneAccountChartBalances
  );
}
