import { ApiCallState } from "../Utils";

export type BankContact= {
  id: number;
  description: string,
  name: string,
  email: string
};

export type BankContactStateTypes = {
  fetchAll: ApiCallState<BankContact[]>;
  fetchOne: ApiCallState<BankContact | {}>;
};

export const BankContactActionTypes = {
  FETCH_ALL_BANK_CONTACT: "FETCH_ALL_BANK_CONTACT",
  FETCH_ALL_BANK_CONTACT_RESET: "FETCH_ALL_BANK_CONTACT_RESET",
  FETCH_ALL_BANK_CONTACT_FAILURE: "FETCH_ALL_BANK_CONTACT_FAILURE",
  FETCH_ALL_BANK_CONTACT_SUCCESS: "FETCH_ALL_BANK_CONTACT_SUCCESS",

  FETCH_ONE_BANK_CONTACT: "FETCH_ONE_BANK_CONTACT",
  FETCH_ONE_BANK_CONTACT_RESET: "FETCH_ONE_BANK_CONTACT_RESET",
  FETCH_ONE_BANK_CONTACT_FAILURE: "FETCH_ONE_BANK_CONTACT_FAILURE",
  FETCH_ONE_BANK_CONTACT_SUCCESS: "FETCH_ONE_BANK_CONTACT_SUCCESS",
};
