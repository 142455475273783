import { Moment } from "moment";
import { Contractor } from "../Contractor/Contractor.type";
import { Project } from "../Project/Project.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type ProjectStaff = {
  project: Project;
  staff: Staff;
  role: string;
  status: string;
  start_date: Date;
  end_date: Date;
};

export type ProjectStaffItem = {
  item_no: string;
  task_name: string;
  unit: string;
  quantity: number;
  unit_price: number;
  total: number;
  sub_contract_id: number;
  key: number;
};

export type ProjectStaffStateTypes = {
  fetchAll: ApiCallState<ProjectStaff[]>;
};

export type ProjectStaffActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const ProjectStaffActionTypes = {
  FETCH_ALL_PROJECT_STAFF: "FETCH_ALL_PROJECT_STAFF",
  FETCH_ALL_PROJECT_STAFF_RESET: "FETCH_ALL_PROJECT_STAFF_RESET",
  FETCH_ALL_PROJECT_STAFF_FAILURE: "FETCH_ALL_PROJECT_STAFF_FAILURE",
  FETCH_ALL_PROJECT_STAFF_SUCCESS: "FETCH_ALL_PROJECT_STAFF_SUCCESS",
};
