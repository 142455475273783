import {
  MaterialRequisitionStateTypes,
  MaterialRequisitionActionTypes,
} from "./MaterialRequisition.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialRequisitionStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchStatus: resetApiCallState([]),
};

const MaterialRequisitionReducer = (
  state: MaterialRequisitionStateTypes = INITIAL_STATE,
  action: any
): MaterialRequisitionStateTypes => {
  switch (action.type) {
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_STATUS:
      return {
        ...state,
        fetchStatus: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_STATUS_RESET:
      return {
        ...state,
        fetchStatus: resetApiCallState([]),
      };
    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_STATUS_FAILURE:
      return {
        ...state,
        fetchStatus: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_STATUS_SUCCESS:
      return {
        ...state,
        fetchStatus: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialRequisitionReducer;
