import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { ProjectSiteActionTypes } from "./ProjectSite.type";

export function* fetchAllProjectSites(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/crm/project-site?${query}`);
    yield put({
      type: ProjectSiteActionTypes.FETCH_ALL_PROJECT_SITES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectSiteActionTypes.FETCH_ALL_PROJECT_SITES_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectSite(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/project-site/${action.payload}`
    );
    yield put({
      type: ProjectSiteActionTypes.FETCH_ONE_PROJECT_SITE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectSiteActionTypes.FETCH_ONE_PROJECT_SITE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectSites() {
  yield takeLatest(ProjectSiteActionTypes.FETCH_ALL_PROJECT_SITES, fetchAllProjectSites);
}

export function* watcherFetchOneProjectSite() {
  yield takeLatest(ProjectSiteActionTypes.FETCH_ONE_PROJECT_SITE, fetchOneProjectSite);
}
