import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type MonthlyOvertime = {
  id: number;
  month: string;

  monthly_overtime_items: MonthlyOvertimeItem[];
};

export type MonthlyOvertimeItem = {
  id: number;
  staff: Staff;
  normal_overtime: number;
  night_overtime: number;
  holiday_overtime: number;
  sunday_overtime: number;
  remark: string;
};

export type MonthlyOvertimeStateTypes = {
  fetchAll: ApiCallState<MonthlyOvertime[]>;
  fetchOne: ApiCallState<MonthlyOvertime | {}>;
};

export const MonthlyOvertimeActionTypes = {
  FETCH_ALL_MONTHLY_OVERTIME: "FETCH_ALL_MONTHLY_OVERTIME",
  FETCH_ALL_MONTHLY_OVERTIME_RESET: "FETCH_ALL_MONTHLY_OVERTIME_RESET",
  FETCH_ALL_MONTHLY_OVERTIME_FAILURE: "FETCH_ALL_MONTHLY_OVERTIME_FAILURE",
  FETCH_ALL_MONTHLY_OVERTIME_SUCCESS: "FETCH_ALL_MONTHLY_OVERTIME_SUCCESS",

  FETCH_ONE_MONTHLY_OVERTIME: "FETCH_ONE_MONTHLY_OVERTIME",
  FETCH_ONE_MONTHLY_OVERTIME_RESET: "FETCH_ONE_MONTHLY_OVERTIME_RESET",
  FETCH_ONE_MONTHLY_OVERTIME_FAILURE: "FETCH_ONE_MONTHLY_OVERTIME_FAILURE",
  FETCH_ONE_MONTHLY_OVERTIME_SUCCESS: "FETCH_ONE_MONTHLY_OVERTIME_SUCCESS",
};
