import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentTransferActions } from "./EquipmentTransfer.type";

export function* fetchEquipmentTransfer(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment_transfer`);
    yield put({
      type: EquipmentTransferActions.FETCH_EQUIPMENT_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentTransferActions.FETCH_EQUIPMENT_TRANSFER_ERROR,
      payload: error,
    });
  }
}

export function* watcherFetchEquipmentTransfer() {
  yield takeLatest(
    EquipmentTransferActions.FETCH_EQUIPMENT_TRANSFER,
    fetchEquipmentTransfer
  );
}
