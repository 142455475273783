import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type LeaveSummary = {
  id: number;
  user_id: number;
  staff_id: number;
  balance: number;
  used: number;
  date: string;
  staff: Staff;
};


export type LeaveSummaryStateTypes = {
  fetchAll: ApiCallState<LeaveSummary[]>;
  fetchOne: ApiCallState<LeaveSummary | {}>;
};

export const LeaveSummaryActionTypes = {
  FETCH_ALL_LEAVE_SUMMARY: "FETCH_ALL_LEAVE_SUMMARY",
  FETCH_ALL_LEAVE_SUMMARY_RESET: "FETCH_ALL_LEAVE_SUMMARY_RESET",
  FETCH_ALL_LEAVE_SUMMARY_FAILURE: "FETCH_ALL_LEAVE_SUMMARY_FAILURE",
  FETCH_ALL_LEAVE_SUMMARY_SUCCESS: "FETCH_ALL_LEAVE_SUMMARY_SUCCESS",

  FETCH_ONE_LEAVE_SUMMARY: "FETCH_ONE_LEAVE_SUMMARY",
  FETCH_ONE_LEAVE_SUMMARY_RESET: "FETCH_ONE_LEAVE_SUMMARY_RESET",
  FETCH_ONE_LEAVE_SUMMARY_FAILURE: "FETCH_ONE_LEAVE_SUMMARY_FAILURE",
  FETCH_ONE_LEAVE_SUMMARY_SUCCESS: "FETCH_ONE_LEAVE_SUMMARY_SUCCESS",
};
