import { Boq } from "../Boq/Boq.type";
import { Labour } from "../Labour/Labour.type";
import { Material } from "../Material/Material.type";
import { MaterialInventory } from "../MaterialInventory/MaterialInventory.type";
import { Project } from "../Project/Project.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type ManpowerOvertime = {
  id: number;
  start_time: string;
  end_time: string;
  total_time: number;
  date: Date;
  labour?: Labour;
  staff?: Staff;
  project: Project;
  boq_id: number;
};

export type ManpowerOvertimeStateTypes = {
  fetchAll: ApiCallState<ManpowerOvertime[]>;
};

export const ManpowerOvertimeActionTypes = {
  FETCH_ALL_MANPOWER_OVERTIME: "FETCH_ALL_MANPOWER_OVERTIME",
  FETCH_ALL_MANPOWER_OVERTIME_RESET: "FETCH_ALL_MANPOWER_OVERTIME_RESET",
  FETCH_ALL_MANPOWER_OVERTIME_FAILURE: "FETCH_ALL_MANPOWER_OVERTIME_FAILURE",
  FETCH_ALL_MANPOWER_OVERTIME_SUCCESS: "FETCH_ALL_MANPOWER_OVERTIME_SUCCESS",
};
