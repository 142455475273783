import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GoodOutActionTypes } from "./GoodOut.type";

export function* fetchAllGoodOut(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-out?inventory_type=${action.payload.inventory_type}`
    );
    yield put({
      type: GoodOutActionTypes.FETCH_ALL_GOOD_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodOutActionTypes.FETCH_ALL_GOOD_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchGoodOutReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-out/report?start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}&sub_contractor=${action.payload?.sub_contractor}&materials=${action.payload?.materials}`
    );
    yield put({
      type: GoodOutActionTypes.FETCH_GOOD_OUT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodOutActionTypes.FETCH_GOOD_OUT_REPORT_FAILURE,
      payload: error,
    });
  }
}
 
export function* watcherFetchAllGoodOut() {
  yield takeLatest(GoodOutActionTypes.FETCH_ALL_GOOD_OUT, fetchAllGoodOut);
}

export function* watcherFetchGoodOutReport() {
  yield takeLatest(GoodOutActionTypes.FETCH_GOOD_OUT_REPORT, fetchGoodOutReport);
}
