import { MaterialBudgetStateTypes, MaterialBudgetActionTypes } from "./MaterialBudget.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialBudgetStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MaterialBudgetReducer = (
  state: MaterialBudgetStateTypes = INITIAL_STATE,
  action: any
): MaterialBudgetStateTypes => {
  switch (action.type) {
    case MaterialBudgetActionTypes.FETCH_ALL_MATERIAL_BUDGET:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialBudgetActionTypes.FETCH_ALL_MATERIAL_BUDGET_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialBudgetActionTypes.FETCH_ALL_MATERIAL_BUDGET_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialBudgetActionTypes.FETCH_ALL_MATERIAL_BUDGET_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialBudgetActionTypes.FETCH_ONE_MATERIAL_BUDGET:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialBudgetActionTypes.FETCH_ONE_MATERIAL_BUDGET_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialBudgetActionTypes.FETCH_ONE_MATERIAL_BUDGET_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialBudgetActionTypes.FETCH_ONE_MATERIAL_BUDGET_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialBudgetReducer;
