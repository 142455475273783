import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { InterviewResultActionTypes } from "./InterviewResult.type";

export function* fetchAllInterviewResults(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/interview-result`);
    yield put({
      type: InterviewResultActionTypes.FETCH_ALL_INTERVIEW_RESULT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InterviewResultActionTypes.FETCH_ALL_INTERVIEW_RESULT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneInterviewResults(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/interview-result/${action.payload}`
    );
    yield put({
      type: InterviewResultActionTypes.FETCH_ONE_INTERVIEW_RESULT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InterviewResultActionTypes.FETCH_ONE_INTERVIEW_RESULT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllInterviewResults() {
  yield takeLatest(InterviewResultActionTypes.FETCH_ALL_INTERVIEW_RESULT, fetchAllInterviewResults);
}

export function* watcherFetchOneInterviewResults() {
  yield takeLatest(InterviewResultActionTypes.FETCH_ONE_INTERVIEW_RESULT, fetchOneInterviewResults);
}
