import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialBudgetActionTypes } from "./MaterialBudget.type";
import { formatQuery } from "../Utils";

export function* fetchAllMaterialBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material_budget?${formatQuery(action)}`
    );
    yield put({
      type: MaterialBudgetActionTypes.FETCH_ALL_MATERIAL_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialBudgetActionTypes.FETCH_ALL_MATERIAL_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material_budget/${action.payload}`
    );
    yield put({
      type: MaterialBudgetActionTypes.FETCH_ONE_MATERIAL_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialBudgetActionTypes.FETCH_ONE_MATERIAL_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialBudgets() {
  yield takeLatest(
    MaterialBudgetActionTypes.FETCH_ALL_MATERIAL_BUDGET,
    fetchAllMaterialBudgets
  );
}

export function* watcherFetchOneMaterialBudgets() {
  yield takeLatest(
    MaterialBudgetActionTypes.FETCH_ONE_MATERIAL_BUDGET,
    fetchOneMaterialBudgets
  );
}
