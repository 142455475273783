import { Moment } from "moment";
import React from "react";
import { EquipmentUsagePlan } from "../EquipmentUsagePlan/EquipmentUsagePlan.type";
import { Material } from "../Material/Material.type";
import { OvertimePlan } from "../StaffPlan/StaffPlan.type";
import { ApiCallState } from "../Utils";

export type LabourPlan = {
  id: any;
  description: string;
  available: number;
  required: number;
  daily_salary: number;
  project_id: any;
  date: string;
  overtime_total: number;
  date_type: string;
  key: any;
  no_month: number;
  temporary_overtime_plan: OvertimePlan;
};

export type LabourPlanStateTypes = {
  fetchAll: ApiCallState<LabourPlan[]>;
};

export type LabourPlanActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const LabourPlanActionTypes = {
  FETCH_ALL_LABOUR_PLAN: "FETCH_ALL_LABOUR_PLAN",
  FETCH_ALL_LABOUR_PLAN_RESET: "FETCH_ALL_LABOUR_PLAN_RESET",
  FETCH_ALL_LABOUR_PLAN_FAILURE: "FETCH_ALL_LABOUR_PLAN_FAILURE",
  FETCH_ALL_LABOUR_PLAN_SUCCESS: "FETCH_ALL_LABOUR_PLAN_SUCCESS",
};
