import { Department } from "../Department/Department.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EmployeeExtra = {
  id: number;
  type: string;
  staff_id: number;
  department_id: number;
  date: Date;
  amount: number;
  staff: Staff;
  department: Department;
};

export type EmployeeExtraStateTypes = {
  fetchAll: ApiCallState<EmployeeExtra[]>;
};

export const EmployeeExtraActionTypes = {
  FETCH_ALL_EMPLOYEE_EXTRA: "FETCH_ALL_EMPLOYEE_EXTRA",
  FETCH_ALL_EMPLOYEE_EXTRA_RESET: "FETCH_ALL_EMPLOYEE_EXTRA_RESET",
  FETCH_ALL_EMPLOYEE_EXTRA_FAILURE: "FETCH_ALL_EMPLOYEE_EXTRA_FAILURE",
  FETCH_ALL_EMPLOYEE_EXTRA_SUCCESS: "FETCH_ALL_EMPLOYEE_EXTRA_SUCCESS",

  FETCH_ONE_EMPLOYEE_EXTRA: "FETCH_ONE_EMPLOYEE_EXTRA",
  FETCH_ONE_EMPLOYEE_EXTRA_RESET: "FETCH_ONE_EMPLOYEE_EXTRA_RESET",
  FETCH_ONE_EMPLOYEE_EXTRA_FAILURE: "FETCH_ONE_EMPLOYEE_EXTRA_FAILURE",
  FETCH_ONE_EMPLOYEE_EXTRA_SUCCESS: "FETCH_ONE_EMPLOYEE_EXTRA_SUCCESS",
};
