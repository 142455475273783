import { BankContactStateTypes, BankContactActionTypes } from "./BankContact.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BankContactStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const BankContactReducer = (
  state: BankContactStateTypes = INITIAL_STATE,
  action: any
): BankContactStateTypes => {
  switch (action.type) {
    case BankContactActionTypes.FETCH_ALL_BANK_CONTACT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BankContactActionTypes.FETCH_ALL_BANK_CONTACT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BankContactActionTypes.FETCH_ALL_BANK_CONTACT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BankContactActionTypes.FETCH_ALL_BANK_CONTACT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BankContactActionTypes.FETCH_ONE_BANK_CONTACT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case BankContactActionTypes.FETCH_ONE_BANK_CONTACT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BankContactActionTypes.FETCH_ONE_BANK_CONTACT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BankContactActionTypes.FETCH_ONE_BANK_CONTACT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BankContactReducer;
