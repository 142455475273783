import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqSummaryActionTypes } from "./BoqSummary.type";

export function* fetchAllBoqSummaries(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq-summary?${query}`);
    yield put({
      type: BoqSummaryActionTypes.FETCH_ALL_BOQ_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqSummaryActionTypes.FETCH_ALL_BOQ_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoqSummary(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq-summary/${action.payload}`
    );
    yield put({
      type: BoqSummaryActionTypes.FETCH_ONE_BOQ_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqSummaryActionTypes.FETCH_ONE_BOQ_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqSummaries() {
  yield takeLatest(
    BoqSummaryActionTypes.FETCH_ALL_BOQ_SUMMARY,
    fetchAllBoqSummaries
  );
}

export function* watcherFetchOneBoqSummaries() {
  yield takeLatest(
    BoqSummaryActionTypes.FETCH_ONE_BOQ_SUMMARY,
    fetchOneBoqSummary
  );
}
