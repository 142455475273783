import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AccountReducer from "./Account/Account.reducer";
import AttendanceReducer from "./Attendance/Attendance.reducer";
import BoqReducer from "./Boq/Boq.reducer";
import ConsultantReducer from "./Consultant/Consultant.reducer";
import ContractReducer from "./Contract/Contract.reducer";
import ContractorReducer from "./Contractor/Contractor.reducer";
import CustomerReducer from "./Customer/Customer.reducer";
import DepartmentReducer from "./Department/Department.reducer";
import DocumentReducer from "./Document/Document.reducer";
import EmployeeExtraReducer from "./EmployeeExtra/EmployeeExtra.reducer";
import EmployeeRequestReducer from "./EmployeeRequest/EmployeeRequest.reducer";
import EquipmentInventoryReducer from "./EquipmentInventory/EquipmentInventory.reducer";
import EquipmentPlanReducer from "./EquipmentPlan/EquipmentPlan.reducer";
import EquipmentTransferReducer from "./EquipmentTransfer/EquipmentTransfer.reducer";
import EquipmentUsageReducer from "./EquipmentUsage/EquipmentUsage.reducer";
import EquipmentUsagePlanReducer from "./EquipmentUsagePlan/EquipmentUsagePlan.reducer";
import ExpenseReducer from "./Expense/Expense.reducer";
import InvoiceReducer from "./Invoice/Invoice.reducer";
import LabourReducer from "./Labour/Labour.reducer";
import LabourPlanReducer from "./LabourPlan/LabourPlan.reducer";
import LabourUsageReducer from "./LabourUsage/LabourUsage.reducer";
import ManpowerOvertimeReducer from "./ManpowerOvertime/ManpowerOvertime.reducer";
import MaterialReducer from "./Material/Material.reducer";
import MaterialInventoryReducer from "./MaterialInventory/MaterialInventory.reducer";
import MaterialPlanReducer from "./MaterialPlan/MaterialPlan.reducer";
import MaterialTransferReducer from "./MaterialTransfer/MaterialTransfer.reducer";
import PaymentReducer from "./Payment/Payment.reducer";
import PaymentRetentionReducer from "./PaymentRetention/PaymentRetention.reducer";
import PayrollReducer from "./Payroll/Payroll.reducer";

import ProjectReducer from "./Project/Project.reducer";
import ProjectStaffReducer from "./ProjectStaff/ProjectStaff.reducer";
import ScheduleReducer from "./Schedule/Schedule.reducer";
import ServiceReducer from "./Service/Service.reducer";
import StaffReducer from "./Staff/Staff.reducer";
import StaffPlanReducer from "./StaffPlan/StaffPlan.reducer";
import SubContractReducer from "./SubContract/SubContract.reducer";
import SubContractPlanReducer from "./SubContractPlan/SubContractPlan.reducer";
import SupplierReducer from "./Supplier/Supplier.reducer";
import TourReducer from "./Tour/Tour.reducer";
import UnitBreakdownReducer from "./UnitBreakdown/UnitBreakdown.reducer";
import UsageReducer from "./Usage/Usage.reducer";
import UserReducer from "./User/User.reducer";
import VariationReducer from "./Variation/Variation.reducer";
import IdReducer from "./ID/Id.reducer";
import MaterialRequisitionReducer from "./MaterialRequisition/MaterialRequisition.reducer";
import PurchaseRequisitionReducer from "./PurchaseRequisition/PurchaseRequisition.reducer";
import PurchaseOrderReducer from "./PurchaseOrder/PurchaseOrder.reducer";
import PurchaseBillingReducer from "./PurchaseBilling/PurchaseBilling.reducer";
import GoodReceivedReducer from "./GoodReceived/GoodReceived.reducer";
import RenterReducer from "./Renter/Renter.reducer";
import GoodOutReducer from "./GoodOut/GoodOut.reducer";
import GoodReturnReducer from "./GoodReturn/GoodReturn.reducer";
import EmployeeHistoryReducer from "./EmployeeHistory/EmployeeHistory.reducer";
import DocumentWorkOrderReducer from "./DocumentWorkOrder/DocumentWorkOrder.reducer";
import EquipmentWOReducer from "./EquipmentWO/EquipmentWO.reducer";
import PriceEscalationReducer from "./PriceEscalation/PriceEscalation.reducer";
import AbsenceReducer from "./Absence/Absence.reducer";
import EmployeeDocumentReducer from "./EmployeeDocument/EmployeeDocument.reducer";
import LogReducer from "./Log/Log.reducer";
import ClientReducer from "./Client/Client.reducer";
import EmployeeAccommodationPlanReducer from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.reducer";
import AllowanceReducer from "./Allowance/Allowance.reducer";
import AllowancePolicyReducer from "./AllowancePolicy/AllowancePolicy.reducer";
import StandardReducer from "./Standard/Standard.reducer";
import BoqStandardReducer from "./BoqStandard/BoqStandard.reducer";
import ResourceReducer from "./Resource/Resource.reducer";
import InspectionFormReducer from "./InspectionForm/InspectionForm.reducer";
import InspectionReducer from "./Inspection/Inspection.reducer";
import CastingReducer from "./Casting/Casting.reducer";
import TestResultReducer from "./TestResult/TestResult.reducer";
import TestRequestReducer from "./TestRequest/TestRequest.reducer";
import SubmittalReducer from "./Submittal/Submittal.reducer";
import MaterialRequestApprovalReducer from "./MaterialRequestApproval/MaterialRequestApproval.reducer";
import SiteDiaryReducer from "./SiteDiary/SiteDiary.reducer";
import WeekReportReducer from "./WeekReport/WeekReport.reducer";
import MeetingReducer from "./Meeting/Meeting.reducer";
import TaskMeetingReducer from "./TaskMeeting/TaskMeeting.reducer";
import QueryReducer from "./Query/Query.reducer";
import MasterScheduleReducer from "./MasterSchedule/MasterSchedule.reducer";
import RFIReducer from "./RFI/RFI.reducer";
import StaffAttendanceReducer from "./StaffAttendance/StaffAttendance.reducer";
import EmployeeUserReducer from "./EmployeeUser/EmployeeUser.reducer";
import ReviewFormReducer from "./ReviewForm/ReviewForm.reducer";
import EmployeeReviewReducer from "./EmployeeReview/EmployeeReview.reducer";
import BonusReducer from "./Bonus/Bonus.reducer";
import VacancyReducer from "./Vacancy/vacancy.reducer";
import ApplicationReducer from "./Application/Application.reducer";
import MediaReducer from "./Media/Media.reducer";
import SHEReducer from "./SHE/SHE.reducer";

import SharedDocumentReducer from "./SharedDocument/SharedDocument.reducer";
import BankAccountReducer from "./BankAccount/BankAccount.reducer";
import LetterReducer from "./Letter/Letter.reducer";
import AccountTransactionReducer from "./AccountTransaction/AccountTransaction.reducer";
import RentContractReducer from "./RentContract/RentContract.reducer";
import RiskLogReducer from "./RiskLog/RiskLog.reducer";
import StaffWorkReducer from "./StaffWork/StaffWork.reducer";
import ReceivableReducer from "./Receivable/Receivable.reducer";
import AttachmentReducer from "./Attachment/Attachment.reducer";
import PettyCashReducer from "./PettyCash/PettyCash.reducer";
import ReplenishmentReducer from "./Replenish/Replenishment.reducer";
import ReplenishmentTransactionReducer from "./ReplenishTransaction/ReplenishmentTransaction.reducer";
import ReplenishmentRequestReducer from "./ReplenishRequest/ReplenishmentRequest.reducer";
import PaymentFileReducer from "./PaymentFile/PaymentFile.reducer";

import TestEvaluationReducer from "./TestEvaluation/TestEvaluation.reducer";
import VariationFileReducer from "./VariationFile/VariationFile.reducer";
import PriceEscalationFileReducer from "./PriceEscalationFile/PriceEscalationFile.reducer";
import CRVPaymentReducer from "./CRVPayment/CRVPayment.reducer";
import PostCheckReducer from "./PostCheck/PostCheck.reducer";
import SiteBookReducer from "./SiteBook/SiteBook.reducer";
import MonthlyReportReducer from "./MonthlyReport/MonthlyReport.reducer";
import DailyReportReducer from "./DailyReport/DailyReport.reducer";
import MaterialUsageReducer from "./MaterialUsage/MaterialUsage.reducer";
import PayableReducer from "./Payable/Payable.reducer";
import MemoReducer from "./Memo/Memo.reducer";
import BuildingReducer from "./Building/Building.reducer";
import ApartmentReducer from "./Apartment/Apartment.reducer";
import ApartmentExpenseReducer from "./ApartmentExpense/ApartmentExpense.reducer";
import ContractSalesReducer from "./ContractSales/ContractSales.reducer";
import EstimatedBuildingCostReducer from "./EstimatedBuildingCost/EstimatedBuildingCost.reducer";
import ProformaReducer from "./Proforma/Proforma.reducer";
import CurrencyReducer from "./Currency/Currency.reducer";
import ApartmentEstimateCostReducer from "./ApartmentEstimateCost/ApartmentEstimateCost.reducer";
import ProcurementPlanReducer from "./ProcurementPlan/ProcurementPlan.reducer";
import StoreReducer from "./Store/Store.reducer";
import ProformaComparisonReducer from "./ProformaComparison/ProformaComparison.reducer";
import InventoryReducer from "./Inventory/Inventory.reducer";
import ContractAgreementReducer from "./ContractAgreement/ContractAgreement.reducer";
import AreaProgressReducer from "./AreaProgress/AreaProgress.reducer";
import BoqSummaryReducer from "./BoqSummary/BoqSummary.reducer";

// CRM
import LeadReducer from "./Lead/Lead.reducer";
import ContactReducer from "./Contact/Contact.reducer";
import ActivityReducer from "./Activity/Activity.reducer";

import WalkInReducer from "./Lead/WalkIn/WalkIn.reducer";

import OpportunityReducer from "./CRM/Opportunity/Opportunity.reducer";
import OpportunityUpdateReducer from "./CRM/Opportunity/OpportunityUpdate/OpportunityUpdate.reducer";
import WeeklyPlanReducer from "./CRM/WeeklyPlan/WeeklyPlan.reducer";
import SupervisorPlanReducer from "./CRM/SupervisorPlan/SupervisorPlan.reducer";
import ProjectSiteReducer from "./CRM/ProjectSite/ProjectSite.reducer";
import BookingReducer from "./CRM/Booking/Booking.reducer";
import CRMDailyReportReducer from "./CRM/DailyReport/DailyReport.reducer";

import TeamReducer from "./Team/Team.reducer";
import TeamMemberReducer from "./TeamMember/TeamMember.reducer";

// Apartment Building
import ApartmentBuildingReducer from "./ApartmentBuilding/ApartmentBuilding/ApartmentBuilding.reducer";
import ApartmentUnitReducer from "./ApartmentBuilding/ApartmentUnit/ApartmentUnit.reducer";
import AccountChartReducer from "./AccountChart/AccountChart.reducer";
import AccountChartBalanceReducer from "./AccountChartBalance/AccountChartBalance.reducer";
import MasterScheduleListReducer from "./MasterScheduleList/MasterScheduleList.reducer";
import RecieptReducer from "./Reciept/Reciept.reducer";
import ExchangeRateReducer from "./ExchangeRate/ExchangeRate.reducer";
import QuotationReducer from "./Quotation/Quotation.reducer";

import ProjectContractReducer from "./ProjectContract/ProjectContract.reducer";
import UserControlReducer from "./UserControl/UserControl.reducer";
import DailyTaskReducer from "./Task/DailyTask/DailyTask.reducer";
import IOLetterReducer from "./IOLetter/IOLetter.reducer";
import SalesReportReducer from "./SalesReport/SalesReport.reducer";
import AutomaticMessageReducer from "./Task/AutomaticMessage/AutomaticMessage.reducer";
import PartnerMessageReducer from "./Task/PartnerMessage/PartnerMessage.reducer";
import PartnerReducer from "./Task/Partner/Partner.reducer";
import NewsAndMotivationReducer from "./Task/NewsAndMotivation/NewsAndMotivation.reducer";
import SMSMessageReducer from "./Task/SMSMessage/SMSMessage.reducer";

import WeeklySalesPlanReducer from "./WeeklySalesPlan/WeeklySalesPlan.reducer";
import BoqReviseReducer from "./BoqRevise/BoqRevise.reducer";
import BoqCategoryReducer from "./BoqCategory/BoqCategory.reducer";
import BoqSubCategoryReducer from "./BoqSubCategory/BoqSubCategory.reducer";
import MonthlyReportV2Reducer from "./MonthlyReportV2/MonthlyReportV2.reducer";
import MaterialBudgetReducer from "./MaterialBudget/MaterialBudget.reducer";
import SubContractBudgetReducer from "./SubContractBudget/SubContractBudget.reducer";
import LabourBudgetReducer from "./LabourBudget/LabourBudget.reducer";
import MiscellaneousBudgetReducer from "./MiscellaneousBudget/MiscellaneousBudget.reducer";
import TaskReducer from "./Tasks/Task.reducer";
import TaskCategoryReducer from "./TaskCategory/TaskCategory.reducer";
import TaskScheduleReducer from "./TaskSchedule/TaskSchedule.reducer";
import TaskDepartmentReducer from "./TaskDepartment/TaskDepartment.reducer";
import PaymentRequestReducer from "./PaymentRequest/PaymentRequest.reducer";

import VoucherAuthorizerReducer from "./VoucherAuthorizer/VoucherAuthorizer.reducer";
import MaterialMajorCategoryReducer from "./MaterialMajorCategory/MaterialMajorCategory.reducer";
import MaterialSubCategoryReducer from "./MaterialSubCategory/MaterialSubCategory.reducer";
import InterGoodTransferOutReducer from "./InterGoodTransferOut/InterGoodTransferOut.reducer";
import GoodInReducer from "./GoodIn/GoodIn.reducer";
import StorePhaseReducer from "./StorePhase/StorePhase.reducer";
import StoreBlockReducer from "./StoreBlock/StoreBlock.reducer";
import PreMrReducer from "./PreMr/PreMr.reducer";
import FixedAssetTransferReducer from "./FixedAssetTransfer/FixedAssetTransfer.reducer";
import FixedAssetOutReducer from "./FixedAssetOut/FixedAssetOut.reducer";
import FixedAssetIssueReducer from "./FixedAssetIssue/FixedAssetIssue.reducer";
import FixedAssetInReducer from "./FixedAssetIn/FixedAssetIn.reducer";
import FixedAssetReturnReducer from "./FixedAssetReturn/FixedAssetReturn.reducer";
import FixedAssetReceivedReducer from "./FixedAssetReceived/FixedAssetReceived.reducer";
import FuelIssueReducer from "./FuelIssue/FuelIssue.reducer";
import FuelReceivingReducer from "./FuelReceiving/FuelReceiving.reducer";
import MasterListReducer from "./MasterList/MasterList.reducer";
import StaffBioReducer from "./StaffBio/StaffBio.reducer";
import GradeReducer from "./Grade/Grade.reducer";
import ScaleReducer from "./Scale/Scale.reducer";
import ScaleItemReducer from "./ScaleItem/ScaleItem.reducer";
import SickLeaveReducer from "./SickLeave/SickLeave.reducer";
import HRAllowanceReducer from "./HRAllowance/HRAllowance.reducer";
import AllowableScaleReducer from "./AllowableScale/AllowableScale.reducer";
import PlaceOfWorkReducer from "./PlaceOfWork/PlaceOfWork.reducer";
import LoanReducer from "./Loan/Loan.reducer";
import LoanExtensionReducer from "./LoanExtension/LoanExtension.reducer";
import LoanRequestReducer from "./LoanRequest/LoanRequest.reducer";
import WorkOvertimeReducer from "./WorkOvertime/WorkOvertime.reducer";
import AdvanceSalaryReducer from "./AdvanceSalary/AdvanceSalary.reducer";
import BankContactReducer from "./BankContact/BankContact.reducer";
import MonthlyOvertimeReducer from "./MonthlyOvertime/MonthlyOvertime.reducer";
import HRRequestReducer from "./HRRequest/HRRequest.reducer";
import ExternaVacancyAnnouncementReducer from "./ExternaVacancyAnnouncement/ExternaVacancyAnnouncement.reducer";
import CandidatesResultReducer from "./CandidatesResult/CandidatesResult.reducer";
import EvaluationReducer from "./Evaluation/Evaluation.reducer";
import PerformanceEvaluationFormReducer from "./PerformanceEvaluationForm/PerformanceEvaluationForm.reducer";
import PerformanceEvaluationRecordReducer from "./PerformanceEvaluationRecord/PerformanceEvaluationRecord.reducer";
import ResignationReducer from "./Resignation/Resignation.reducer";
import HRPolicyReducer from "./HRPolicy/HRPolicy.reducer";
import PerformancePointPolicyReducer from "./PerformancePointPolicy/PerformancePointPolicy.reducer";
import LeaveReducer from "./Leave/Leave.reducer";
import LeaveStartingBalanceReducer from "./LeaveStartingBalance/LeaveStartingBalance.reducer";
import LeaveSummaryReducer from "./LeaveSummary/LeaveSummary.reducer";
import ApplicantRecordReducer from "./ApplicantRecord/ApplicantRecord.reducer";
import InterviewResultReducer from "./InterviewResult/InterviewResult.reducer";
import ExamSummaryReducer from "./ExamSummary/ExamSummary.reducer";
import SummaryResultReducer from "./SummaryResult/SummaryResult.reducer";
import EmploymentExamSummaryReducer from "./EmploymentExamSummary/EmploymentExamSummary.reducer";
import SummaryResultForSkilledCandidateReducer from "./SummaryResultForSkilledCandidates/SummaryResultForSkilledCandidates.reducer";
import CostSharingReducer from "./CostSharing/CostSharing.reducer";
import EmployeeAllowanceReducer from "./EmployeeAllowance/EmployeeAllowance.reducer";
import StaffTransferReducer from "./StaffTransfer/StaffTransfer.reducer";
import StaffSalaryAdjustmentReducer from "./StaffSalaryAdjustment/StaffSalaryAdjustment.reducer";
import StaffTaxInformationReducer from "./StaffTaxInformation/StaffTaxInformation.reducer";
import AnnualLeavePaymentReducer from "./AnnualLeavePayment/AnnualLeavePayment.reducer";
import SalesPaymentReducer from "./SalesPayment/SalesPayment.reducer";
import CommissionSettingReducer from "./CommissionSetting/CommissionSetting.reducer";
import CommissionPaymentReducer from "./CommissionPayment/CommissionPayment.reducer";
import ForecastReducer from "./Forecast/Forecast.reducer";
import StaffEducationReducer from "./StaffEducation/StaffEducation.reducer";
import StaffExperienceReducer from "./StaffExperience/StaffExperience.reducer";
import StaffEmergencyContactReducer from "./StaffEmergencyContact/StaffEmergencyContact.reducer";

import ExternalBudgetReducer from "./ExternalBudget/ExternalBudget.reducer";
import SalesScheduleReducer from "./SalesSchedule/SalesSchedule.reducer";
import MarketingSalesScheduleReducer from "./MarketingSalesSchedule/MarketingSalesSchedule.reducer";

import OpportunityV2Reducer from "./OpportunityV2/OpportunityV2.reducer";

const PersistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const RootReducer = combineReducers({
  project: ProjectReducer,
  contractor: ContractorReducer,
  consultant: ConsultantReducer,
  boq: BoqReducer,
  sub_contract_plan: SubContractPlanReducer,
  material: MaterialReducer,
  supplier: SupplierReducer,
  material_inventory: MaterialInventoryReducer,
  material_transfer: MaterialTransferReducer,
  equipment_inventory: EquipmentInventoryReducer,
  equipment_transfer: EquipmentTransferReducer,
  usage: UsageReducer,
  labour: LabourReducer,
  equipment_usage: EquipmentUsageReducer,
  labour_usage: LabourUsageReducer,
  equipment_plan: EquipmentPlanReducer,
  equipment_usage_plan: EquipmentUsagePlanReducer,
  material_plan: MaterialPlanReducer,
  labour_plan: LabourPlanReducer,
  department: DepartmentReducer,
  staff: StaffReducer,
  staff_plan: StaffPlanReducer,
  account: AccountReducer,
  customer: CustomerReducer,
  service: ServiceReducer,
  unit_breakdown: UnitBreakdownReducer,
  variation: VariationReducer,
  sub_contract: SubContractReducer,
  invoice: InvoiceReducer,
  expense: ExpenseReducer,
  payment: PaymentReducer,
  payment_retention: PaymentRetentionReducer,
  document: DocumentReducer,
  project_staff: ProjectStaffReducer,
  manpower_overtime: ManpowerOvertimeReducer,
  payroll: PayrollReducer,
  tour: TourReducer,
  schedule: ScheduleReducer,
  employee_extra: EmployeeExtraReducer,
  user: UserReducer,
  attendance: AttendanceReducer,
  contract: ContractReducer,
  employee_request: EmployeeRequestReducer,
  id: IdReducer,
  material_requisition: MaterialRequisitionReducer,
  purchase_requisition: PurchaseRequisitionReducer,
  purchase_order: PurchaseOrderReducer,
  purchase_billing: PurchaseBillingReducer,
  good_received: GoodReceivedReducer,
  renter: RenterReducer,
  good_out: GoodOutReducer,
  good_return: GoodReturnReducer,
  employee_history: EmployeeHistoryReducer,
  document_work_order: DocumentWorkOrderReducer,
  equipment_work_order: EquipmentWOReducer,
  price_escalation: PriceEscalationReducer,
  hr_policy: HRPolicyReducer,
  absence: AbsenceReducer,
  employee_document: EmployeeDocumentReducer,
  employee_accommodation_plan: EmployeeAccommodationPlanReducer,
  client: ClientReducer,
  allowance: AllowanceReducer,
  allowance_policy: AllowancePolicyReducer,
  standard: StandardReducer,
  log: LogReducer,
  boq_standard: BoqStandardReducer,
  resource: ResourceReducer,
  inspection_form: InspectionFormReducer,
  inspection: InspectionReducer,
  casting: CastingReducer,
  submittal: SubmittalReducer,
  material_request_approval: MaterialRequestApprovalReducer,
  test_result: TestResultReducer,
  test_request: TestRequestReducer,
  meeting: MeetingReducer,
  task_meeting: TaskMeetingReducer,
  site_diary: SiteDiaryReducer,
  week_report: WeekReportReducer,
  query: QueryReducer,
  master_schedule: MasterScheduleReducer,
  rfi: RFIReducer,
  staff_attendance: StaffAttendanceReducer,
  employee_user: EmployeeUserReducer,
  review_form: ReviewFormReducer,
  employee_review: EmployeeReviewReducer,
  bonus: BonusReducer,
  vacancies: VacancyReducer,
  applications: ApplicationReducer,
  media: MediaReducer,
  she: SHEReducer,
  sharedDocument: SharedDocumentReducer,
  bankAccount: BankAccountReducer,
  letter: LetterReducer,
  accountTransaction: AccountTransactionReducer,
  rentContract: RentContractReducer,
  risk_log: RiskLogReducer,
  staff_work: StaffWorkReducer,
  receivable: ReceivableReducer,
  attachment: AttachmentReducer,
  petty_cash: PettyCashReducer,
  replenishment: ReplenishmentReducer,
  replenishment_transaction: ReplenishmentTransactionReducer,
  replenishment_request: ReplenishmentRequestReducer,
  test_evaluation: TestEvaluationReducer,
  payment_file: PaymentFileReducer,
  variation_file: VariationFileReducer,
  price_escalation_file: PriceEscalationFileReducer,
  crv_payment: CRVPaymentReducer,
  post_check: PostCheckReducer,
  site_book: SiteBookReducer,
  monthly_report: MonthlyReportReducer,
  daily_report: DailyReportReducer,
  material_usage: MaterialUsageReducer,
  payable: PayableReducer,
  memo: MemoReducer,
  building: BuildingReducer,
  apartment: ApartmentReducer,
  apartment_expense: ApartmentExpenseReducer,
  contract_sales: ContractSalesReducer,
  estimated_building_cost: EstimatedBuildingCostReducer,
  proforma: ProformaReducer,
  currency: CurrencyReducer,
  estimate_cost: ApartmentEstimateCostReducer,
  procurement_plan: ProcurementPlanReducer,
  store: StoreReducer,
  proforma_comparison: ProformaComparisonReducer,
  inventory: InventoryReducer,
  contract_agreement: ContractAgreementReducer,
  area_progress: AreaProgressReducer,
  boq_summary: BoqSummaryReducer,
  account_chart: AccountChartReducer,
  account_chart_balance: AccountChartBalanceReducer,
  master_schedule_list: MasterScheduleListReducer,

  //  CRM
  lead: LeadReducer,
  contact: ContactReducer,
  activity: ActivityReducer,
  walk_in: WalkInReducer,
  crm_Opportunity: OpportunityReducer,
  crm_OpportunityUpdate: OpportunityUpdateReducer,
  crm_WeeklyPlan: WeeklyPlanReducer,
  crm_Booking: BookingReducer,
  crm_ProjectSite: ProjectSiteReducer,
  crm_DailyReport: CRMDailyReportReducer,
  crm_SupervisorPlan: SupervisorPlanReducer,
  team: TeamReducer,
  team_member: TeamMemberReducer,

  // Apartment Building
  apartment_Building: ApartmentBuildingReducer,
  apartment_Unit: ApartmentUnitReducer,
  reciept: RecieptReducer,
  exchange_rate: ExchangeRateReducer,
  quotation: QuotationReducer,

  project_contract: ProjectContractReducer,
  user_control: UserControlReducer,

  //Task
  daily_task: DailyTaskReducer,

  // IOLetter
  io_letter: IOLetterReducer,

  sales_report: SalesReportReducer,
  automatic_message: AutomaticMessageReducer,
  partner: PartnerReducer,
  partner_message: PartnerMessageReducer,
  news_and_motivation: NewsAndMotivationReducer,
  sms_message: SMSMessageReducer,

  weekly_sales_plan: WeeklySalesPlanReducer,
  boq_revise: BoqReviseReducer,
  boq_category: BoqCategoryReducer,
  boq_sub_category: BoqSubCategoryReducer,
  monthly_report_v2: MonthlyReportV2Reducer,

  material_budget: MaterialBudgetReducer,
  sub_contract_budget: SubContractBudgetReducer,
  labour_budget: LabourBudgetReducer,
  miscellaneous_budget: MiscellaneousBudgetReducer,
  task_schedule: TaskScheduleReducer,
  task: TaskReducer,
  task_category: TaskCategoryReducer,
  task_department: TaskDepartmentReducer,
  payment_request: PaymentRequestReducer,
  voucher_authorizer: VoucherAuthorizerReducer,
  material_major_category: MaterialMajorCategoryReducer,
  material_sub_category: MaterialSubCategoryReducer,
  inter_good_transfer_out: InterGoodTransferOutReducer,
  good_in: GoodInReducer,

  store_phase: StorePhaseReducer,
  store_block: StoreBlockReducer,
  pre_mr: PreMrReducer,
  fixed_asset_transfer: FixedAssetTransferReducer,
  fixed_asset_out: FixedAssetOutReducer,
  fixed_asset_issue: FixedAssetIssueReducer,
  fixed_asset_in: FixedAssetInReducer,
  fixed_asset_return: FixedAssetReturnReducer,
  fixed_asset_received: FixedAssetReceivedReducer,

  fuel_issue: FuelIssueReducer,
  fuel_receiving: FuelReceivingReducer,

  master_list: MasterListReducer,
  staff_bio: StaffBioReducer,
  grade: GradeReducer,
  scale: ScaleReducer,
  scale_item: ScaleItemReducer,
  sick_leave: SickLeaveReducer,
  hr_allowance: HRAllowanceReducer,
  allowable_scale: AllowableScaleReducer,
  place_of_work: PlaceOfWorkReducer,
  staff_transfer: StaffTransferReducer,
  staff_salary_adjustment: StaffSalaryAdjustmentReducer,
  staff_tax_information: StaffTaxInformationReducer,
  loan: LoanReducer,
  loan_extension: LoanExtensionReducer,
  loan_request: LoanRequestReducer,
  work_overtime: WorkOvertimeReducer,
  advance_salary: AdvanceSalaryReducer,
  bank_contact: BankContactReducer,
  monthly_overtime: MonthlyOvertimeReducer,
  hr_request: HRRequestReducer,
  external_vacancy_announcement: ExternaVacancyAnnouncementReducer,
  candidates_result: CandidatesResultReducer,
  evaluation: EvaluationReducer,
  performance_evaluation_form: PerformanceEvaluationFormReducer,
  performance_evaluation_record: PerformanceEvaluationRecordReducer,
  resignation: ResignationReducer,
  performance_point_policy: PerformancePointPolicyReducer,
  leave: LeaveReducer,
  leave_starting_balance: LeaveStartingBalanceReducer,
  leave_summary: LeaveSummaryReducer,
  applicant_record: ApplicantRecordReducer,
  interview_result: InterviewResultReducer,
  exam_summary: ExamSummaryReducer,
  summary_result: SummaryResultReducer,
  employment_exam_summary: EmploymentExamSummaryReducer,
  summary_result_for_skilled_candidate: SummaryResultForSkilledCandidateReducer,
  cost_sharing: CostSharingReducer,
  employee_allowance: EmployeeAllowanceReducer,
  annual_leave_payment: AnnualLeavePaymentReducer,
  sales_payment: SalesPaymentReducer,
  commission_setting: CommissionSettingReducer,
  commission_payment: CommissionPaymentReducer,
  forecast: ForecastReducer,
  staff_education: StaffEducationReducer,
  staff_experience: StaffExperienceReducer,
  staff_emergency_contact: StaffEmergencyContactReducer,
  external_budget: ExternalBudgetReducer,
  sales_schedule: SalesScheduleReducer,
  marketing_sales_schedule: MarketingSalesScheduleReducer,
  opportunity_v2: OpportunityV2Reducer,
});

export default persistReducer(PersistConfig, RootReducer);
