import { ExternaVacancyAnnouncement } from "../ExternaVacancyAnnouncement/ExternaVacancyAnnouncement.type";
import { ApiCallState } from "../Utils";

export type SummaryResult = {
  id: number;
  date: string;
  external_vacancy_announcement_id: number;
  committee_remark:string;
  committee_member:string;
  approved_by:number;
  external_vacancy_announcement: ExternaVacancyAnnouncement;
  summary_result_items: SummaryResultItem[];
};

export type SummaryResultItem = {
  id: number;
  summary_result_id: number;
  name: string;
  qualification: number;
  practical_test: number;
  written_test: number;
  converted_average: number;
  rank: number;
  remark: string;
};

export type SummaryResultStateTypes = {
  fetchAll: ApiCallState<SummaryResult[]>;
  fetchOne: ApiCallState<SummaryResult | {}>;
};

export const SummaryResultActionTypes = {
  FETCH_ALL_SUMMARY_RESULT: "FETCH_ALL_SUMMARY_RESULT",
  FETCH_ALL_SUMMARY_RESULT_RESET: "FETCH_ALL_SUMMARY_RESULT_RESET",
  FETCH_ALL_SUMMARY_RESULT_FAILURE: "FETCH_ALL_SUMMARY_RESULT_FAILURE",
  FETCH_ALL_SUMMARY_RESULT_SUCCESS: "FETCH_ALL_SUMMARY_RESULT_SUCCESS",

  FETCH_ONE_SUMMARY_RESULT: "FETCH_ONE_SUMMARY_RESULT",
  FETCH_ONE_SUMMARY_RESULT_RESET: "FETCH_ONE_SUMMARY_RESULT_RESET",
  FETCH_ONE_SUMMARY_RESULT_FAILURE: "FETCH_ONE_SUMMARY_RESULT_FAILURE",
  FETCH_ONE_SUMMARY_RESULT_SUCCESS: "FETCH_ONE_SUMMARY_RESULT_SUCCESS",
};
