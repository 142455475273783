import { InitPagedData } from "./../Utils";
import {
  AccountChartBalanceStateTypes,
  AccountChartBalanceActionTypes,
} from "./AccountChartBalance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AccountChartBalanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const AccountChartBalanceReducer = (
  state: AccountChartBalanceStateTypes = INITIAL_STATE,
  action: any
): AccountChartBalanceStateTypes => {
  switch (action.type) {
    case AccountChartBalanceActionTypes.FETCH_ALL_ACCOUNT_CHART_BALANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountChartBalanceActionTypes.FETCH_ALL_ACCOUNT_CHART_BALANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AccountChartBalanceActionTypes.FETCH_ALL_ACCOUNT_CHART_BALANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountChartBalanceActionTypes.FETCH_ALL_ACCOUNT_CHART_BALANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case AccountChartBalanceActionTypes.FETCH_PAGED_ACCOUNT_CHART_BALANCE:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountChartBalanceActionTypes.FETCH_PAGED_ACCOUNT_CHART_BALANCE_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case AccountChartBalanceActionTypes.FETCH_PAGED_ACCOUNT_CHART_BALANCE_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountChartBalanceActionTypes.FETCH_PAGED_ACCOUNT_CHART_BALANCE_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AccountChartBalanceActionTypes.FETCH_ONE_ACCOUNT_CHART_BALANCE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountChartBalanceActionTypes.FETCH_ONE_ACCOUNT_CHART_BALANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AccountChartBalanceActionTypes.FETCH_ONE_ACCOUNT_CHART_BALANCE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountChartBalanceActionTypes.FETCH_ONE_ACCOUNT_CHART_BALANCE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AccountChartBalanceReducer;
