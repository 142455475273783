import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { CurrencyApiKey } from "../../config/config";

import { CurrencyActionTypes } from "./Currency.type";

export function* fetchAllCurrencies(action: any): any {
  try {
    const response = yield axios.get(`https://freecurrencyapi.net/api/v2/latest?apikey=${CurrencyApiKey}`);
    yield put({
      type: CurrencyActionTypes.FETCH_ALL_CURRENCY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CurrencyActionTypes.FETCH_ALL_CURRENCY_FAILURE,
      payload: error,
    });
  }
}


export function* watcherFetchAllCurrencies() {
  yield takeLatest(CurrencyActionTypes.FETCH_ALL_CURRENCY, fetchAllCurrencies);
}
