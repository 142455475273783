import { PaymentFile } from "../PaymentFile/PaymentFile.type";
import { ApiCallState } from "../Utils";

export type ProjectContract = {
  id: number;
  user_id: number;
  project_id: number;
  advance_payment: number;
  advance_percent: number;
  contractor_name: string;
  type: string;
  consultant_name: string;
  description: string;
  value_total_amount: number;
  start_date: Date;
  end_date: Date;
  payment_files: PaymentFile[];
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type ProjectContractStateTypes = {
  fetchAll: ApiCallState<ProjectContract[]>;
  fetchAllAll: ApiCallState<ProjectContract[]>;
  fetchOne: ApiCallState<ProjectContract | {}>;
};

export const ProjectContractActionTypes = {
  FETCH_ALL_PROJECT_CONTRACT: "FETCH_ALL_PROJECT_CONTRACT",
  FETCH_ALL_PROJECT_CONTRACT_RESET: "FETCH_ALL_PROJECT_CONTRACT_RESET",
  FETCH_ALL_PROJECT_CONTRACT_FAILURE: "FETCH_ALL_PROJECT_CONTRACT_FAILURE",
  FETCH_ALL_PROJECT_CONTRACT_SUCCESS: "FETCH_ALL_PROJECT_CONTRACT_SUCCESS",

  FETCH_ALL_ALL_PROJECT_CONTRACT: "FETCH_ALL_ALL_PROJECT_CONTRACT",
  FETCH_ALL_ALL_PROJECT_CONTRACT_RESET: "FETCH_ALL_ALL_PROJECT_CONTRACT_RESET",
  FETCH_ALL_ALL_PROJECT_CONTRACT_FAILURE:
    "FETCH_ALL_ALL_PROJECT_CONTRACT_FAILURE",
  FETCH_ALL_ALL_PROJECT_CONTRACT_SUCCESS:
    "FETCH_ALL_ALL_PROJECT_CONTRACT_SUCCESS",

  FETCH_ONE_PROJECT_CONTRACT: "FETCH_ONE_PROJECT_CONTRACT",
  FETCH_ONE_PROJECT_CONTRACT_RESET: "FETCH_ONE_PROJECT_CONTRACT_RESET",
  FETCH_ONE_PROJECT_CONTRACT_FAILURE: "FETCH_ONE_PROJECT_CONTRACT_FAILURE",
  FETCH_ONE_PROJECT_CONTRACT_SUCCESS: "FETCH_ONE_PROJECT_CONTRACT_SUCCESS",
};
