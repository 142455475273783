import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type VoucherAuthorizer = {
  id: number;
  user_id: number;

  action_type: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  user: User;
};

export type VoucherAuthorizerStateTypes = {
  fetchAll: ApiCallState<VoucherAuthorizer[]>;
  fetchOne: ApiCallState<VoucherAuthorizer | {}>;
};

export const VoucherAuthorizerActionTypes = {
  FETCH_ALL_VOUCHER_AUTHORIZER: "FETCH_ALL_VOUCHER_AUTHORIZER",
  FETCH_ALL_VOUCHER_AUTHORIZER_RESET: "FETCH_ALL_VOUCHER_AUTHORIZER_RESET",
  FETCH_ALL_VOUCHER_AUTHORIZER_FAILURE: "FETCH_ALL_VOUCHER_AUTHORIZER_FAILURE",
  FETCH_ALL_VOUCHER_AUTHORIZER_SUCCESS: "FETCH_ALL_VOUCHER_AUTHORIZER_SUCCESS",

  FETCH_ONE_VOUCHER_AUTHORIZER: "FETCH_ONE_VOUCHER_AUTHORIZER",
  FETCH_ONE_VOUCHER_AUTHORIZER_RESET: "FETCH_ONE_VOUCHER_AUTHORIZER_RESET",
  FETCH_ONE_VOUCHER_AUTHORIZER_FAILURE: "FETCH_ONE_VOUCHER_AUTHORIZER_FAILURE",
  FETCH_ONE_VOUCHER_AUTHORIZER_SUCCESS: "FETCH_ONE_VOUCHER_AUTHORIZER_SUCCESS",
};
