import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type DocumentWorkOrder = {
  id: number;
  name: string;
  description: string;
  remark: string;
  type: string;
  assigned_to_staff: Staff[];
  due_date: string;
  assigned_by_staff: Staff;
};

export type DocumentWorkOrderStateTypes = {
  fetchAll: ApiCallState<DocumentWorkOrder[]>;
};

export const DocumentWorkOrderActionTypes = {
  FETCH_ALL_DOCUMENT_WORK_ORDER: "FETCH_ALL_DOCUMENT_WORK_ORDER",
  FETCH_ALL_DOCUMENT_WORK_ORDER_RESET: "FETCH_ALL_DOCUMENT_WORK_ORDER_RESET",
  FETCH_ALL_DOCUMENT_WORK_ORDER_FAILURE:
    "FETCH_ALL_DOCUMENT_WORK_ORDER_FAILURE",
  FETCH_ALL_DOCUMENT_WORK_ORDER_SUCCESS:
    "FETCH_ALL_DOCUMENT_WORK_ORDER_SUCCESS",
};
