import { Apartment } from "../Apartment/Apartment.type";
import { Building } from "../Building/Building.type";
import { ApiCallState } from "../Utils";

export type ContractSales= {
  id: number;
  date: string;
  amount: number;
  type: string;
  apartment_id: number;
  building_id: number;
  buildings?: Building;
  apartment?: Apartment
};

export type ContractSalesStateTypes = {
  fetchAll: ApiCallState<ContractSales[]>;
  fetchOne: ApiCallState<ContractSales | {}>;
};

export const ContractSalesActionTypes = {
  FETCH_ALL_CONTRACT_SALES: "FETCH_ALL_CONTRACT_SALES",
  FETCH_ALL_CONTRACT_SALES_RESET: "FETCH_ALL_CONTRACT_SALES_RESET",
  FETCH_ALL_CONTRACT_SALES_FAILURE: "FETCH_ALL_CONTRACT_SALES_FAILURE",
  FETCH_ALL_CONTRACT_SALES_SUCCESS: "FETCH_ALL_CONTRACT_SALES_SUCCESS",

  FETCH_ONE_CONTRACT_SALES: "FETCH_ONE_CONTRACT_SALES",
  FETCH_ONE_CONTRACT_SALES_RESET: "FETCH_ONE_CONTRACT_SALES_RESET",
  FETCH_ONE_CONTRACT_SALES_FAILURE: "FETCH_ONE_CONTRACT_SALES_FAILURE",
  FETCH_ONE_CONTRACT_SALES_SUCCESS: "FETCH_ONE_CONTRACT_SALES_SUCCESS",
};
