import {
  PurchaseOrderStateTypes,
  PurchaseOrderActionTypes,
} from "./PurchaseOrder.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PurchaseOrderStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PurchaseOrderReducer = (
  state: PurchaseOrderStateTypes = INITIAL_STATE,
  action: any
): PurchaseOrderStateTypes => {
  switch (action.type) {
    case PurchaseOrderActionTypes.FETCH_ALL_PURCHASE_ORDER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PurchaseOrderActionTypes.FETCH_ALL_PURCHASE_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PurchaseOrderActionTypes.FETCH_ALL_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PurchaseOrderActionTypes.FETCH_ALL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PurchaseOrderActionTypes.FETCH_ONE_PURCHASE_ORDER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PurchaseOrderActionTypes.FETCH_ONE_PURCHASE_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PurchaseOrderActionTypes.FETCH_ONE_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PurchaseOrderActionTypes.FETCH_ONE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PurchaseOrderReducer;
