import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { StoreBlockActionTypes } from "./StoreBlock.type";

export function* fetchAllStoreBlocks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-block?${formatQuery(action)}`
    );
    yield put({
      type: StoreBlockActionTypes.FETCH_ALL_STORE_BLOCK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreBlockActionTypes.FETCH_ALL_STORE_BLOCK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStoreBlocks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-block/${action.payload}`
    );
    yield put({
      type: StoreBlockActionTypes.FETCH_ONE_STORE_BLOCK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreBlockActionTypes.FETCH_ONE_STORE_BLOCK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStoreBlocks() {
  yield takeLatest(
    StoreBlockActionTypes.FETCH_ALL_STORE_BLOCK,
    fetchAllStoreBlocks
  );
}

export function* watcherFetchOneStoreBlocks() {
  yield takeLatest(
    StoreBlockActionTypes.FETCH_ONE_STORE_BLOCK,
    fetchOneStoreBlocks
  );
}
