import { EquipmentWO } from "../EquipmentWO/EquipmentWO.type";
import { Project, Store } from "../Project/Project.type";
import { Supplier } from "../Supplier/Supplier.type";
import { ApiCallState } from "../Utils";

export const EquipmentInventoryActions = {
  FETCH_EQUIPMENT_INVENTORY: "FETCH_EQUIPMENT_INVENTORY",
  FETCH_EQUIPMENT_INVENTORY_SUCCESS: "FETCH_EQUIPMENT_INVENTORY_SUCCESS",
  FETCH_EQUIPMENT_INVENTORY_ERROR: "FETCH_EQUIPMENT_INVENTORY_ERROR",
};

export type EquipmentInventory = {
  id: number;
  identification_number: string;
  year_of_manufacture: string;
  unit_price: number;
  type: string;
  load_capacity: string;
  opiating_weight: string;
  operator_name: string;
  engine_model: string;
  engine_serial_number: string;
  engine_capacity: string;
  engine_power: string;
  project: Project | null;
  store: Store | null;
  supplier: Supplier | null;
  project_id: number | null;
  store_id: number | null;
  material: {
    id: number;
    item_category: string;
    sub_category: string;
    description: string;
    unit: string;
  };
  equipment_wos:EquipmentWO[]
};

export type EquipmentInventoryStateTypes = {
  fetchAll: ApiCallState<EquipmentInventory[]>;
};
