import { Standard } from "../Standard/Standard.type";
import { ApiCallState } from "../Utils";

export type BoqStandard = {
  id: number;
  super_title: string;
  title: string;
  sub_title: string;
  task_name: string;
  item_no: string;
  unit: string;
  type: string;
  sheet_name:string|undefined
  standard:Standard
};

export type BoqStandardStateTypes = {
  fetchAll: ApiCallState<BoqStandard[]>;
  fetchOne: ApiCallState<BoqStandard | {}>;
};

export const BoqStandardActionTypes = {
  FETCH_ALL_BOQ_STANDARD: "FETCH_ALL_BOQ_STANDARD",
  FETCH_ALL_BOQ_STANDARD_RESET: "FETCH_ALL_BOQ_STANDARD_RESET",
  FETCH_ALL_BOQ_STANDARD_FAILURE: "FETCH_ALL_BOQ_STANDARD_FAILURE",
  FETCH_ALL_BOQ_STANDARD_SUCCESS: "FETCH_ALL_BOQ_STANDARD_SUCCESS",

  FETCH_ONE_BOQ_STANDARD: "FETCH_ONE_BOQ_STANDARD",
  FETCH_ONE_BOQ_STANDARD_RESET: "FETCH_ONE_BOQ_STANDARD_RESET",
  FETCH_ONE_BOQ_STANDARD_FAILURE: "FETCH_ONE_BOQ_STANDARD_FAILURE",
  FETCH_ONE_BOQ_STANDARD_SUCCESS: "FETCH_ONE_BOQ_STANDARD_SUCCESS",
};
