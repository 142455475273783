import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EvaluationActionTypes } from "./Evaluation.type";

export function* fetchAllEvaluations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/evaluation`);
    yield put({
      type: EvaluationActionTypes.FETCH_ALL_EVALUATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EvaluationActionTypes.FETCH_ALL_EVALUATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEvaluations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/evaluation/${action.payload}`
    );
    yield put({
      type: EvaluationActionTypes.FETCH_ONE_EVALUATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EvaluationActionTypes.FETCH_ONE_EVALUATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEvaluations() {
  yield takeLatest(EvaluationActionTypes.FETCH_ALL_EVALUATION, fetchAllEvaluations);
}

export function* watcherFetchOneEvaluations() {
  yield takeLatest(EvaluationActionTypes.FETCH_ONE_EVALUATION, fetchOneEvaluations);
}
