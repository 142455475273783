import { ApiCallState } from "../Utils";
import { HRAllowance } from "../HRAllowance/HRAllowance.type";

export type EmployeeAllowanceItem = {
  id: number;
  amount: number;
  scale: number;
  allowable_scale_item_id: number;
  allowable_scale_id: number;
  hr_allowance_id: number;
  hr_allowance?: HRAllowance;
};

export type EmployeeAllowance = {
  id: number;
  employee_id: number;
  salary: number;
  type: string;
  date:string;
  employee_allowance_items?: EmployeeAllowanceItem[];
  createdAt: Date;
  updatedAt: Date;
};

export type EmployeeAllowanceStateTypes = {
  fetchAll: ApiCallState<EmployeeAllowance[]>;
  fetchOne: ApiCallState<EmployeeAllowance | {}>;
};

export const EmployeeAllowanceActionTypes = {
  FETCH_ALL_EMPLOYEE_ALLOWANCE: "FETCH_ALL_EMPLOYEE_ALLOWANCE",
  FETCH_ALL_EMPLOYEE_ALLOWANCE_RESET: "FETCH_ALL_EMPLOYEE_ALLOWANCE_RESET",
  FETCH_ALL_EMPLOYEE_ALLOWANCE_FAILURE: "FETCH_ALL_EMPLOYEE_ALLOWANCE_FAILURE",
  FETCH_ALL_EMPLOYEE_ALLOWANCE_SUCCESS: "FETCH_ALL_EMPLOYEE_ALLOWANCE_SUCCESS",

  FETCH_ONE_EMPLOYEE_ALLOWANCE: "FETCH_ONE_EMPLOYEE_ALLOWANCE",
  FETCH_ONE_EMPLOYEE_ALLOWANCE_RESET: "FETCH_ONE_EMPLOYEE_ALLOWANCE_RESET",
  FETCH_ONE_EMPLOYEE_ALLOWANCE_FAILURE: "FETCH_ONE_EMPLOYEE_ALLOWANCE_FAILURE",
  FETCH_ONE_EMPLOYEE_ALLOWANCE_SUCCESS: "FETCH_ONE_EMPLOYEE_ALLOWANCE_SUCCESS",
};
