import _, { groupBy, toNumber } from "lodash";

class BuildingBudgetItem {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];
  final_parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.final_parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    const previous_type = [];
    let start = -1;
    this.data.forEach((col: any[], index) => {
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[10] && col[11]) {
          this.parsed.push({
            budget_item: col[10].split("[")[0],
            quantity: toNumber(col[10].split("[")[1].split("]")[0]),
            amount: col[11],
            key: this.parsed.length,
          });
        }
      }
      if (this.type) previous_type.push(this.type);
    });
    const groupedByBudgetItem = groupBy(this.parsed, (e) => `${e.budget_item}`);

    Object.entries(groupedByBudgetItem).forEach(
      ([budget_item, budget_datas]) => {
        let total_amount = 0;
        let total_quantity = 0;

        budget_datas.forEach((e) => {
          total_amount += toNumber(e.amount);
          total_quantity += toNumber(e.quantity);
        });

        this.final_parsed.push({
          key: this.final_parsed.length,
          budget_item: budget_item,
          quantity: total_quantity,
          unit_price: total_quantity !== 0 ? total_amount / total_quantity : 0,
          amount: total_amount,
        });
      }
    );
    return this.final_parsed;
  }

  private isTableState(col: any[]) {
    return (
      _.isString(col[0]) &&
      _.isString(col[1]) &&
      _.isString(col[2]) &&
      _.isString(col[3]) &&
      _.isString(col[4]) &&
      _.isString(col[5]) &&
      _.isString(col[6]) &&
      _.isString(col[7]) &&
      _.isString(col[8]) &&
      _.isString(col[9]) &&
      _.isString(col[10]) &&
      _.isString(col[11])
    );
  }
}
export default BuildingBudgetItem;
