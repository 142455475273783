import { Account } from "../Account/Account.type";
import { Customer } from "../Customer/Customer.type";
import { Invoice, Item } from "../Invoice/Invoice.type";
import { Project } from "../Project/Project.type";
import { Service } from "../Service/Service.type";
import { ApiCallState } from "../Utils";

export type Payment = {
  id: number;
  date: string;
  invoice: Invoice;
  service: Service;
  account: Account;
  customer: Customer;
  project: Project | null;
  vat: number;
  with_hold: number;
  payment_method: string;
  payment_items: Item[];
  paid: number;
};

export type PaymentStateTypes = {
  fetchAll: ApiCallState<Payment[]>;
};

export const PaymentActionTypes = {
  FETCH_ALL_PAYMENT: "FETCH_ALL_PAYMENT",
  FETCH_ALL_PAYMENT_RESET: "FETCH_ALL_PAYMENT_RESET",
  FETCH_ALL_PAYMENT_FAILURE: "FETCH_ALL_PAYMENT_FAILURE",
  FETCH_ALL_PAYMENT_SUCCESS: "FETCH_ALL_PAYMENT_SUCCESS",
};
