import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PlaceOfWorkActionTypes } from "./PlaceOfWork.type";

export function* fetchAllPlaceOfWorks(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/place-of-work`);
    yield put({
      type: PlaceOfWorkActionTypes.FETCH_ALL_PLACE_OF_WORK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlaceOfWorkActionTypes.FETCH_ALL_PLACE_OF_WORK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePlaceOfWorks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/place-of-work/${action.payload}`
    );
    yield put({
      type: PlaceOfWorkActionTypes.FETCH_ONE_PLACE_OF_WORK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlaceOfWorkActionTypes.FETCH_ONE_PLACE_OF_WORK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPlaceOfWorks() {
  yield takeLatest(
    PlaceOfWorkActionTypes.FETCH_ALL_PLACE_OF_WORK,
    fetchAllPlaceOfWorks
  );
}

export function* watcherFetchOnePlaceOfWorks() {
  yield takeLatest(
    PlaceOfWorkActionTypes.FETCH_ONE_PLACE_OF_WORK,
    fetchOnePlaceOfWorks
  );
}
