import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ApartmentEstimateCostActionTypes } from "./ApartmentEstimateCost.type";

export function* fetchAllApartmentEstimateCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/apartment-estimate-cost`);
    yield put({
      type: ApartmentEstimateCostActionTypes.FETCH_ALL_APARTMENT_ESTIMATE_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentEstimateCostActionTypes.FETCH_ALL_APARTMENT_ESTIMATE_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApartmentEstimateCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/apartment-estimate-cost/${action.payload}`
    );
    yield put({
      type: ApartmentEstimateCostActionTypes.FETCH_ONE_APARTMENT_ESTIMATE_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentEstimateCostActionTypes.FETCH_ONE_APARTMENT_ESTIMATE_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApartmentEstimateCosts() {
  yield takeLatest(ApartmentEstimateCostActionTypes.FETCH_ALL_APARTMENT_ESTIMATE_COST, fetchAllApartmentEstimateCosts);
}

export function* watcherFetchOneApartmentEstimateCosts() {
  yield takeLatest(ApartmentEstimateCostActionTypes.FETCH_ONE_APARTMENT_ESTIMATE_COST, fetchOneApartmentEstimateCosts);
}
