import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Lead = {
  id: number;
  sales_id: number;
  name: string;
  phone_number: string;
  email: string;
  source: string;
  status: string;
  remark: string;
  createdAt?: Date;
  user?: User;
  user_id: number;
};

export type SalesPeopleMetric = {
  id: number;
  full_name: string;
  sales: number;
  booking: number;
  opportunity: number;
  activity: number;
  lead: number;
  total_mark: number;
  ranking: number;
};

export type LeadStateTypes = {
  fetchAll: ApiCallState<Lead[]>;
  fetchOne: ApiCallState<Lead | {}>;
  fetchSalesPeopleMetric: ApiCallState<SalesPeopleMetric[]>;
};

export const LeadActionTypes = {
  FETCH_ALL_LEAD: "FETCH_ALL_LEAD",
  FETCH_ALL_LEAD_RESET: "FETCH_ALL_LEAD_RESET",
  FETCH_ALL_LEAD_FAILURE: "FETCH_ALL_LEAD_FAILURE",
  FETCH_ALL_LEAD_SUCCESS: "FETCH_ALL_LEAD_SUCCESS",

  FETCH_ONE_LEAD: "FETCH_ONE_LEAD",
  FETCH_ONE_LEAD_RESET: "FETCH_ONE_LEAD_RESET",
  FETCH_ONE_LEAD_FAILURE: "FETCH_ONE_LEAD_FAILURE",
  FETCH_ONE_LEAD_SUCCESS: "FETCH_ONE_LEAD_SUCCESS",

  FETCH_SALES_PEOPLE_METRIC: "FETCH_SALES_PEOPLE_METRIC",
  FETCH_SALES_PEOPLE_METRIC_RESET: "FETCH_SALES_PEOPLE_METRIC_RESET",
  FETCH_SALES_PEOPLE_METRIC_FAILURE: "FETCH_SALES_PEOPLE_METRIC_FAILURE",
  FETCH_SALES_PEOPLE_METRIC_SUCCESS: "FETCH_SALES_PEOPLE_METRIC_SUCCESS",
};
