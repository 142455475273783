import { LabourStateTypes, LabourActionTypes } from "./Labour.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: LabourStateTypes = {
  fetchAll: resetApiCallState([]),
};

const LabourReducer = (
  state: LabourStateTypes = INITIAL_STATE,
  action: any
): LabourStateTypes => {
  switch (action.type) {
    case LabourActionTypes.FETCH_ALL_LABOUR:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case LabourActionTypes.FETCH_ALL_LABOUR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LabourActionTypes.FETCH_ALL_LABOUR_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LabourActionTypes.FETCH_ALL_LABOUR_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default LabourReducer;
