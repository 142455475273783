import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ContractAgreementActionTypes } from "./ContractAgreement.type";

export function* fetchAllContractAgreements(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys
        .map((key) =>
          action.payload[key] ? `${key}=${action.payload[key]}` : null
        )
        .join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/contract-agreement?${query}`
    );
    yield put({
      type: ContractAgreementActionTypes.FETCH_ALL_CONTRACT_AGREEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractAgreementActionTypes.FETCH_ALL_CONTRACT_AGREEMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneContractAgreements(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/contract-agreement/${action.payload}`
    );
    yield put({
      type: ContractAgreementActionTypes.FETCH_ONE_CONTRACT_AGREEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractAgreementActionTypes.FETCH_ONE_CONTRACT_AGREEMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllContractAgreements() {
  yield takeLatest(
    ContractAgreementActionTypes.FETCH_ALL_CONTRACT_AGREEMENT,
    fetchAllContractAgreements
  );
}

export function* watcherFetchOneContractAgreements() {
  yield takeLatest(
    ContractAgreementActionTypes.FETCH_ONE_CONTRACT_AGREEMENT,
    fetchOneContractAgreements
  );
}
