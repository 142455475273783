import { ExchangeRateStateTypes, ExchangeRateActionTypes } from "./ExchangeRate.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ExchangeRateStateTypes = {
  fetchExchangeRate: resetApiCallState({}),
};

const ExchangeRateReducer = (
  state: ExchangeRateStateTypes = INITIAL_STATE,
  action: any
): ExchangeRateStateTypes => {
  switch (action.type) {
    case ExchangeRateActionTypes.FETCH_EXCHANGE_RATE:
      return {
        ...state,
        fetchExchangeRate: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_RESET:
      return {
        ...state,
        fetchExchangeRate: resetApiCallState([]),
      };
    case ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_FAILURE:
      return {
        ...state,
        fetchExchangeRate: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        fetchExchangeRate: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ExchangeRateReducer;
