import { resetApiCallState } from "../Utils";
import {
  EquipmentTransferActions,
  EquipmentTransferStateTypes,
} from "./EquipmentTransfer.type";

const INITIAL_STATE: EquipmentTransferStateTypes = {
  fetchAll: resetApiCallState([]),
};

const EquipmentTransferReducer = (
  state: EquipmentTransferStateTypes = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case EquipmentTransferActions.FETCH_EQUIPMENT_TRANSFER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentTransferActions.FETCH_EQUIPMENT_TRANSFER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: action.payload,
          isPending: false,
          isSuccessful: true,
        },
      };

    case EquipmentTransferActions.FETCH_EQUIPMENT_TRANSFER_ERROR:
      return {
        ...state,
        fetchAll: {
          error: action.payload,
          payload: [],
          isPending: false,
          isSuccessful: false,
        },
      };
    default:
      return state;
  }
};

export default EquipmentTransferReducer;
