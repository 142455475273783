import { Boq } from "../Boq/Boq.type";
import { ApiCallState, PagedData } from "../Utils";

export type BoqRevise = {
  id?: number;
  key: number;
  date: string;
  contract_id: number;
  boq_revise_items: BoqReviseItem[];
};

export type BoqReviseItem = {
  boq_id: number;
  boq: Boq;
  unit_price: number;
  quantity: number;
};

export type BoqReviseStateTypes = {
  fetchAll: ApiCallState<BoqRevise[]>;
  fetchOne: ApiCallState<BoqRevise | {}>;
  fetchPaged: ApiCallState<PagedData<BoqRevise[]>>;
};

export const BoqReviseActionTypes = {
  FETCH_ALL_BOQ_REVISE: "FETCH_ALL_BOQ_REVISE",
  FETCH_ALL_BOQ_REVISE_RESET: "FETCH_ALL_BOQ_REVISE_RESET",
  FETCH_ALL_BOQ_REVISE_FAILURE: "FETCH_ALL_BOQ_REVISE_FAILURE",
  FETCH_ALL_BOQ_REVISE_SUCCESS: "FETCH_ALL_BOQ_REVISE_SUCCESS",

  FETCH_PAGED_BOQ_REVISE: "FETCH_PAGED_BOQ_REVISE",
  FETCH_PAGED_BOQ_REVISE_RESET: "FETCH_PAGED_BOQ_REVISE_RESET",
  FETCH_PAGED_BOQ_REVISE_FAILURE: "FETCH_PAGED_BOQ_REVISE_FAILURE",
  FETCH_PAGED_BOQ_REVISE_SUCCESS: "FETCH_PAGED_BOQ_REVISE_SUCCESS",

  FETCH_ONE_BOQ_REVISE: "FETCH_ONE_BOQ_REVISE",
  FETCH_ONE_BOQ_REVISE_RESET: "FETCH_ONE_BOQ_REVISE_RESET",
  FETCH_ONE_BOQ_REVISE_FAILURE: "FETCH_ONE_BOQ_REVISE_FAILURE",
  FETCH_ONE_BOQ_REVISE_SUCCESS: "FETCH_ONE_BOQ_REVISE_SUCCESS",
};
