import { Department } from "../Department/Department.type";
import { ApiCallState } from "../Utils";

export type Grade = {
  id: number;
  department_id: number;
  title: string;
  grade: number;
  department: Department;
};

export type GradeStateTypes = {
  fetchAll: ApiCallState<Grade[]>;
  fetchOne: ApiCallState<Grade | {}>;
};

export const GradeActionTypes = {
  FETCH_ALL_GRADE: "FETCH_ALL_GRADE",
  FETCH_ALL_GRADE_RESET: "FETCH_ALL_GRADE_RESET",
  FETCH_ALL_GRADE_FAILURE: "FETCH_ALL_GRADE_FAILURE",
  FETCH_ALL_GRADE_SUCCESS: "FETCH_ALL_GRADE_SUCCESS",

  FETCH_ONE_GRADE: "FETCH_ONE_GRADE",
  FETCH_ONE_GRADE_RESET: "FETCH_ONE_GRADE_RESET",
  FETCH_ONE_GRADE_FAILURE: "FETCH_ONE_GRADE_FAILURE",
  FETCH_ONE_GRADE_SUCCESS: "FETCH_ONE_GRADE_SUCCESS",
};
