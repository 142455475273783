import { Document } from "../Document/Document.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Letter = {
  id: number;
  date: string;
  subject: string;
  reference_number: string;
  reference_id: number;
  letter_prepared: User;
  letter_from: User;
  letter_to: User;
  letter_users: {
    id: number;
    full_name: string;
    role: string;
    letter_users: { type: "to" | "cc" };
  }[];
  letter_messages: {
    user: User;
    message: string;
    date: string;
  }[];
  document: Document;
};

export type LetterStateTypes = {
  fetchAll: ApiCallState<Letter[]>;
  fetchOne: ApiCallState<Letter | {}>;
};

export const LetterActionTypes = {
  FETCH_ALL_LETTER: "FETCH_ALL_LETTER",
  FETCH_ALL_LETTER_RESET: "FETCH_ALL_LETTER_RESET",
  FETCH_ALL_LETTER_FAILURE: "FETCH_ALL_LETTER_FAILURE",
  FETCH_ALL_LETTER_SUCCESS: "FETCH_ALL_LETTER_SUCCESS",

  FETCH_ONE_LETTER: "FETCH_ONE_LETTER",
  FETCH_ONE_LETTER_RESET: "FETCH_ONE_LETTER_RESET",
  FETCH_ONE_LETTER_FAILURE: "FETCH_ONE_LETTER_FAILURE",
  FETCH_ONE_LETTER_SUCCESS: "FETCH_ONE_LETTER_SUCCESS",
};
