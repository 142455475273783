import { Store } from "../Store/Store.type";
import { ApiCallState } from "../Utils";

export type StorePhase = {
  id: number;
  user_id: number;
  store_id: number;

  phase: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  store: Store;
};

export type StorePhaseStateTypes = {
  fetchAll: ApiCallState<StorePhase[]>;
  fetchOne: ApiCallState<StorePhase | {}>;
};

export const StorePhaseActionTypes = {
  FETCH_ALL_STORE_PHASE: "FETCH_ALL_STORE_PHASE",
  FETCH_ALL_STORE_PHASE_RESET: "FETCH_ALL_STORE_PHASE_RESET",
  FETCH_ALL_STORE_PHASE_FAILURE: "FETCH_ALL_STORE_PHASE_FAILURE",
  FETCH_ALL_STORE_PHASE_SUCCESS: "FETCH_ALL_STORE_PHASE_SUCCESS",

  FETCH_ONE_STORE_PHASE: "FETCH_ONE_STORE_PHASE",
  FETCH_ONE_STORE_PHASE_RESET: "FETCH_ONE_STORE_PHASE_RESET",
  FETCH_ONE_STORE_PHASE_FAILURE: "FETCH_ONE_STORE_PHASE_FAILURE",
  FETCH_ONE_STORE_PHASE_SUCCESS: "FETCH_ONE_STORE_PHASE_SUCCESS",
};
