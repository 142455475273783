import { Account } from "../Account/Account.type";
import { Item } from "../Invoice/Invoice.type";
import { Project } from "../Project/Project.type";
import { Supplier } from "../Supplier/Supplier.type";
import { ApiCallState } from "../Utils";

export type Expense = {
  id: number;
  title: string;
  supplier: Supplier;
  expense_items: Item[];
  expense_type: string;
  vat: number;
  with_hold: number;
  date: string;
  purchase_order_id?: number;
  account: Account;
  project?: Project;
  project_id: number;
};

export type ExpenseStateTypes = {
  fetchAll: ApiCallState<Expense[]>;
};

export const ExpenseActionTypes = {
  FETCH_ALL_EXPENSE: "FETCH_ALL_EXPENSE",
  FETCH_ALL_EXPENSE_RESET: "FETCH_ALL_EXPENSE_RESET",
  FETCH_ALL_EXPENSE_FAILURE: "FETCH_ALL_EXPENSE_FAILURE",
  FETCH_ALL_EXPENSE_SUCCESS: "FETCH_ALL_EXPENSE_SUCCESS",
};
