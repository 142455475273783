import { BoqSummaryStateTypes, BoqSummaryActionTypes } from "./BoqSummary.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BoqSummaryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const BoqSummaryReducer = (
  state: BoqSummaryStateTypes = INITIAL_STATE,
  action: any
): BoqSummaryStateTypes => {
  switch (action.type) {
    case BoqSummaryActionTypes.FETCH_ALL_BOQ_SUMMARY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqSummaryActionTypes.FETCH_ALL_BOQ_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BoqSummaryActionTypes.FETCH_ALL_BOQ_SUMMARY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqSummaryActionTypes.FETCH_ALL_BOQ_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BoqSummaryActionTypes.FETCH_ONE_BOQ_SUMMARY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case BoqSummaryActionTypes.FETCH_ONE_BOQ_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BoqSummaryActionTypes.FETCH_ONE_BOQ_SUMMARY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BoqSummaryActionTypes.FETCH_ONE_BOQ_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BoqSummaryReducer;
