import { resetApiCallState } from "../Utils";
import {
  ApplicationActionTypes,
  ApplicationStateTypes,
} from "./Application.type";

const INITIAL_STATE: ApplicationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ApplicationReducer = (
  state: ApplicationStateTypes = INITIAL_STATE,
  action: any
): ApplicationStateTypes => {
  switch (action.type) {
    case ApplicationActionTypes.FETCH_ALL_APPLICATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ApplicationActionTypes.FETCH_ALL_APPLICATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ApplicationActionTypes.FETCH_ALL_APPLICATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ApplicationActionTypes.FETCH_ALL_APPLICATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ApplicationReducer;
