import { HRAllowanceStateTypes, HRAllowanceActionTypes } from "./HRAllowance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: HRAllowanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const HRAllowanceReducer = (
  state: HRAllowanceStateTypes = INITIAL_STATE,
  action: any
): HRAllowanceStateTypes => {
  switch (action.type) {
    case HRAllowanceActionTypes.FETCH_ALL_HR_ALLOWANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case HRAllowanceActionTypes.FETCH_ALL_HR_ALLOWANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case HRAllowanceActionTypes.FETCH_ALL_HR_ALLOWANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HRAllowanceActionTypes.FETCH_ALL_HR_ALLOWANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case HRAllowanceActionTypes.FETCH_ONE_HR_ALLOWANCE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case HRAllowanceActionTypes.FETCH_ONE_HR_ALLOWANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case HRAllowanceActionTypes.FETCH_ONE_HR_ALLOWANCE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HRAllowanceActionTypes.FETCH_ONE_HR_ALLOWANCE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default HRAllowanceReducer;
