import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SickLeaveActionTypes } from "./SickLeave.type";
import { formatQuery } from "../Utils";

export function* fetchAllSickLeaves(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sick-leave?${formatQuery(action)}`);
    yield put({
      type: SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSickLeaves(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sick-leave/${action.payload}`
    );
    yield put({
      type: SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SickLeaveActionTypes.FETCH_ONE_SICK_LEAVE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSickLeaves() {
  yield takeLatest(SickLeaveActionTypes.FETCH_ALL_SICK_LEAVE, fetchAllSickLeaves);
}

export function* watcherFetchOneSickLeaves() {
  yield takeLatest(SickLeaveActionTypes.FETCH_ONE_SICK_LEAVE, fetchOneSickLeaves);
}
