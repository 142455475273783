import {
  InterGoodTransferOutStateTypes,
  InterGoodTransferOutActionTypes,
} from "./InterGoodTransferOut.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: InterGoodTransferOutStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const InterGoodTransferOutReducer = (
  state: InterGoodTransferOutStateTypes = INITIAL_STATE,
  action: any
): InterGoodTransferOutStateTypes => {
  switch (action.type) {
    case InterGoodTransferOutActionTypes.FETCH_ALL_INTER_GOOD_TRANSFER_OUT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InterGoodTransferOutActionTypes.FETCH_ALL_INTER_GOOD_TRANSFER_OUT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case InterGoodTransferOutActionTypes.FETCH_ALL_INTER_GOOD_TRANSFER_OUT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InterGoodTransferOutActionTypes.FETCH_ALL_INTER_GOOD_TRANSFER_OUT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case InterGoodTransferOutActionTypes.FETCH_ONE_INTER_GOOD_TRANSFER_OUT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case InterGoodTransferOutActionTypes.FETCH_ONE_INTER_GOOD_TRANSFER_OUT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case InterGoodTransferOutActionTypes.FETCH_ONE_INTER_GOOD_TRANSFER_OUT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InterGoodTransferOutActionTypes.FETCH_ONE_INTER_GOOD_TRANSFER_OUT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case InterGoodTransferOutActionTypes.FETCH_REPORT_INTER_GOOD_TRANSFER_OUT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InterGoodTransferOutActionTypes.FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case InterGoodTransferOutActionTypes.FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InterGoodTransferOutActionTypes.FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default InterGoodTransferOutReducer;
