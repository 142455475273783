import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LeaveSummaryActionTypes } from "./LeaveSummary.type";

export function* fetchAllLeaveSummaries(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/leave-summary${query}`);
    yield put({
      type: LeaveSummaryActionTypes.FETCH_ALL_LEAVE_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeaveSummaryActionTypes.FETCH_ALL_LEAVE_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLeaveSummaries(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/leave-summary/${action.payload}`);
    yield put({
      type: LeaveSummaryActionTypes.FETCH_ONE_LEAVE_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeaveSummaryActionTypes.FETCH_ONE_LEAVE_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLeaveSummaries() {
  yield takeLatest(LeaveSummaryActionTypes.FETCH_ALL_LEAVE_SUMMARY, fetchAllLeaveSummaries);
}

export function* watcherFetchOneLeaveSummaries() {
  yield takeLatest(LeaveSummaryActionTypes.FETCH_ONE_LEAVE_SUMMARY, fetchOneLeaveSummaries);
}
