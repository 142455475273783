import { StoreStateTypes, StoreActionTypes } from "./Store.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StoreStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const StoreReducer = (
  state: StoreStateTypes = INITIAL_STATE,
  action: any
): StoreStateTypes => {
  switch (action.type) {
    case StoreActionTypes.FETCH_ALL_STORE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StoreActionTypes.FETCH_ALL_STORE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StoreActionTypes.FETCH_ALL_STORE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StoreActionTypes.FETCH_ALL_STORE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case StoreActionTypes.FETCH_ONE_STORE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case StoreActionTypes.FETCH_ONE_STORE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StoreActionTypes.FETCH_ONE_STORE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StoreActionTypes.FETCH_ONE_STORE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StoreReducer;
