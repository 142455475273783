import { AccountChartBalance } from "../AccountChartBalance/AccountChartBalance.type";
import { ApiCallState, PagedData } from "../Utils";

export type AccountChart = {
  id?: number;
  key: number;
  account_code: string;
  name: string;
  type: string;
  account_chart_balances?: AccountChartBalance[];
};

export type AccountChartStateTypes = {
  fetchAll: ApiCallState<AccountChart[]>;
  fetchOne: ApiCallState<AccountChart | {}>;
  fetchPaged: ApiCallState<PagedData<AccountChart[]>>;
};

export const AccountChartActionTypes = {
  FETCH_ALL_ACCOUNT_CHART: "FETCH_ALL_ACCOUNT_CHART",
  FETCH_ALL_ACCOUNT_CHART_RESET: "FETCH_ALL_ACCOUNT_CHART_RESET",
  FETCH_ALL_ACCOUNT_CHART_FAILURE: "FETCH_ALL_ACCOUNT_CHART_FAILURE",
  FETCH_ALL_ACCOUNT_CHART_SUCCESS: "FETCH_ALL_ACCOUNT_CHART_SUCCESS",

  FETCH_PAGED_ACCOUNT_CHART: "FETCH_PAGED_ACCOUNT_CHART",
  FETCH_PAGED_ACCOUNT_CHART_RESET: "FETCH_PAGED_ACCOUNT_CHART_RESET",
  FETCH_PAGED_ACCOUNT_CHART_FAILURE: "FETCH_PAGED_ACCOUNT_CHART_FAILURE",
  FETCH_PAGED_ACCOUNT_CHART_SUCCESS: "FETCH_PAGED_ACCOUNT_CHART_SUCCESS",

  FETCH_ONE_ACCOUNT_CHART: "FETCH_ONE_ACCOUNT_CHART",
  FETCH_ONE_ACCOUNT_CHART_RESET: "FETCH_ONE_ACCOUNT_CHART_RESET",
  FETCH_ONE_ACCOUNT_CHART_FAILURE: "FETCH_ONE_ACCOUNT_CHART_FAILURE",
  FETCH_ONE_ACCOUNT_CHART_SUCCESS: "FETCH_ONE_ACCOUNT_CHART_SUCCESS",
};
