import { BonusStateTypes, BonusActionTypes } from "./Bonus.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BonusStateTypes = {
  fetchAll: resetApiCallState([]),
};

const BonusReducer = (
  state: BonusStateTypes = INITIAL_STATE,
  action: any
): BonusStateTypes => {
  switch (action.type) {
    case BonusActionTypes.FETCH_ALL_BONUS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BonusActionTypes.FETCH_ALL_BONUS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BonusActionTypes.FETCH_ALL_BONUS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BonusActionTypes.FETCH_ALL_BONUS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BonusReducer;
