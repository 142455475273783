import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllowancePolicyActionTypes } from "./AllowancePolicy.type";

export function* fetchAllAllowancePolicy(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/allowance_policy`);
    yield put({
      type: AllowancePolicyActionTypes.FETCH_ALL_ALLOWANCE_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllowancePolicyActionTypes.FETCH_ALL_ALLOWANCE_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllowancePolicy(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allowance_policy/${action.payload}`
    );
    yield put({
      type: AllowancePolicyActionTypes.FETCH_ONE_ALLOWANCE_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllowancePolicyActionTypes.FETCH_ONE_ALLOWANCE_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllowancePolicy() {
  yield takeLatest(
    AllowancePolicyActionTypes.FETCH_ALL_ALLOWANCE_POLICY,
    fetchAllAllowancePolicy
  );
}

export function* watcherFetchOneAllowancePolicy() {
  yield takeLatest(
    AllowancePolicyActionTypes.FETCH_ONE_ALLOWANCE_POLICY,
    fetchOneAllowancePolicy
  );
}
