import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../../ApiCall";
import { OpportunityUpdateActionTypes } from "./OpportunityUpdate.type";

export function* fetchOpportunityUpdates(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/opportunity/update/op/${action.payload.id}`
    );
    yield put({
      type: OpportunityUpdateActionTypes.FETCH_OPPORTUNITY_UPDATES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OpportunityUpdateActionTypes.FETCH_OPPORTUNITY_UPDATES_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllOpportunityUpdates(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/crm/opportunity/update?${query}`
    );
    yield put({
      type: OpportunityUpdateActionTypes.FETCH_ALL_OPPORTUNITY_UPDATES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OpportunityUpdateActionTypes.FETCH_ALL_OPPORTUNITY_UPDATES_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOpportunityUpdate(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/opportunity/update/${action.payload}`
    );
    yield put({
      type: OpportunityUpdateActionTypes.FETCH_ONE_OPPORTUNITY_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OpportunityUpdateActionTypes.FETCH_ONE_OPPORTUNITY_UPDATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchOpportunityUpdates() {
  yield takeLatest(
    OpportunityUpdateActionTypes.FETCH_OPPORTUNITY_UPDATES,
    fetchOpportunityUpdates
  );
}

export function* watcherFetchAllOpportunityUpdates() {
  yield takeLatest(
    OpportunityUpdateActionTypes.FETCH_ALL_OPPORTUNITY_UPDATES,
    fetchAllOpportunityUpdates
  );
}

export function* watcherFetchOneOpportunityUpdate() {
  yield takeLatest(
    OpportunityUpdateActionTypes.FETCH_ONE_OPPORTUNITY_UPDATE,
    fetchOneOpportunityUpdate
  );
}
