import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { RouteConstants } from "./Constants";
import ErrorBoundary from "../containers/Errors/ErrorBoundary/ErrorBoundary.component";
import { checkAuthorization, isLoggedIn } from "../utilities/utilities";
import Layout from "../containers/Layouts/Layout/Layout.component";
import LoadingIndicator from "../components/common/Loading";

const CRMComponent = lazy(() => import("../containers/CRM/CRM.component"));
const TaskComponent = lazy(() => import("../containers/Task/Task.component"));
const IOLetterComponent = lazy(() => import("../components/IOLetter"));

const OpportunitySubComponent = lazy(
  () => import("../components/CRM/Opportunity/OpportunitySub.component")
);
const LeadSubComponent = lazy(
  () => import("../components/CRM/Lead/LeadSub.component")
);

const MonthlyReport = lazy(() => import("../components/MonthlyReportV2"));

const ClientSummaryComponent = lazy(
  () => import("../containers/ClientSummary/ClientSummary.component")
);

const ConsultantSummaryComponent = lazy(
  () => import("../containers/ConsultantSummary/ConsultantSummary.component")
);

const ProjectListComponent = lazy(
  () => import("../containers/Project/List.component")
);
const EnterpriseLoginComponent = lazy(() => import("../components/Login"));

const SignupComponent = lazy(() => import("../components/Signup/index"));

const SummaryComponent = lazy(
  () => import("../containers/Summary/Summary.component")
);

const RegisterProjectComponent = lazy(
  () => import("../components/Project/ProjectRegistration")
);

const BoqRevisionComponent = lazy(
  () =>
    import(
      "../components/ConsultantSummary/BoqRevise/components/AddBoqRevise/AddBoqRevise.component"
    )
);

const WeeklyReportComponent = lazy(
  () =>
    import(
      "../components/ConsultantSummary/WorkReport/WeeklyReport/AddWeeklyReport/addWeeklyReport"
    )
);

const EditWeeklyReportComponent = lazy(
  () =>
    import(
      "../components/ConsultantSummary/WorkReport/WeeklyReport/EditWeeklyReport/EditWeeklyReport"
    )
);

const InventoryComponent = lazy(
  () => import("../containers/Inventory/Inventory.component")
);

const FinanceComponent = lazy(
  () => import("../containers/Finance/Finance.component")
);
// const ContractsComponent = lazy(
//   () => import("../containers/Contracts/Contracts.component")
// );

const DocumentComponent = lazy(
  () => import("../containers/Documents/Documents.component")
);
const DashboardComponent = lazy(
  () => import("../containers/DashBoard/Dashboard.component")
);
const ProcurementComponent = lazy(
  () => import("../containers/Procurement/Procurement.component")
);

const MasterScheduleComponent = lazy(
  () => import("../containers/MasterSchedule/MasterSchedule.component")
);

const ProjectContractComponent = lazy(
  () => import("../containers/ProjectContract/ProjectContract")
);

const UserManagementComponent = lazy(
  () => import("../components/User/UserManagement")
);

const FixedAssetComponent = lazy(
  () => import("../containers/FixedAsset/FixedAsset.component")
);

const HRComponent = lazy(
  () => import("../containers/HR/HR.component")
);

const EmployeeComponent = lazy(
  () => import("../containers/Employee/Employee.component")
);

const AdministrationComponent = lazy(
  () => import("../containers/Administration/Administration.component")
);

const EngineeringComponent = lazy(
  () => import("../containers/Engineering/Engineering.component")
);
// import TestComponent from "../test";

// Re-Initialize the onbeforeunload event listener

const Routes = () => {
  const PrivateRoute = ({ ...props }: any) => {
    const { children, ...rest } = props;
    const isAuthenticated = isLoggedIn();

    let isAuthorized: any = false;
    if (isAuthenticated) {
      isAuthorized = checkAuthorization(rest.path);
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated && isAuthorized ? (
            children
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
    );
  };

  return (
    <>
      <ErrorBoundary>
        <Switch>
          <Route exact path={RouteConstants.LOGIN}>
            <Suspense fallback={<LoadingIndicator />}>
              <EnterpriseLoginComponent />
            </Suspense>
          </Route>
          <Route exact path={RouteConstants.SIGN_UP}>
            <Suspense fallback={<LoadingIndicator />}>
              <SignupComponent />
            </Suspense>
          </Route>

          <PrivateRoute exact path={RouteConstants.DASHBOARD}>
            <Redirect to={{ pathname: RouteConstants.DASHBOARDS }} />
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.DASHBOARDS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DashboardComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* FINANCE */}
          <PrivateRoute exact path={RouteConstants.FINANCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <FinanceComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* CONTRACTS */}
          {/* <PrivateRoute exact path={RouteConstants.CONTRACTS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ContractsComponent />
              </Suspense>
            </Layout>
          </PrivateRoute> */}

          <PrivateRoute exact path={RouteConstants.BOQ_REVISION}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <BoqRevisionComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          {/* Project Manager */}
          <PrivateRoute exact path={RouteConstants.DASHBOARDS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DashboardComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.MONTHLY_REPORT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <MonthlyReport />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.DASHBOARDS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DashboardComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.SUMMARY}>
            <Layout></Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PROJECT_LIST}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="1" project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PROJECT_CONTRACT_TAB}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ClientSummaryComponent key="100" location="" />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PROJECT_CONTRACT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectContractComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CONSULTANT_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ConsultantSummaryComponent key="15" location="" />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CONTRACTOR_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <SummaryComponent key="15" location="" />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PROJECTS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="9" project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.REGISTER_PROJECT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterProjectComponent project_type="post-contract" />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.DOCUMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <DocumentComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.INVENTORY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <InventoryComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROCUREMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProcurementComponent />{" "}
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.MASTER_SCHEDULE_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <MasterScheduleComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CRM_OPPORTUNITY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <OpportunitySubComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CRM_LEAD}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <LeadSubComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute path={RouteConstants.CRM}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <CRMComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path={RouteConstants.TASK}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <TaskComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute path={RouteConstants.IOLetter}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <IOLetterComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.WEEKLY_REPORT_ADD}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <WeeklyReportComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.WEEKLY_REPORT_EDIT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <EditWeeklyReportComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.FIXED_ASSET}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <FixedAssetComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.EMPLOYEE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <EmployeeComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.ADMINISTRATION}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <AdministrationComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.ENGINEERING}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <EngineeringComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <Route exact path={RouteConstants.USER_MANAGEMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <UserManagementComponent />
              </Suspense>
            </Layout>
          </Route>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Routes);
