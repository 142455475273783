import { ApiCallState } from "../Utils";

export type Store = {
  id: number;
  project_id?: number;
  name: string;
  code: string;
  type: string;
  address: string;
};

export type StoreStateTypes = {
  fetchAll: ApiCallState<Store[]>;
  fetchOne: ApiCallState<Store | {}>;
};

export const StoreActionTypes = {
  FETCH_ALL_STORE: "FETCH_ALL_STORE",
  FETCH_ALL_STORE_RESET: "FETCH_ALL_STORE_RESET",
  FETCH_ALL_STORE_FAILURE: "FETCH_ALL_STORE_FAILURE",
  FETCH_ALL_STORE_SUCCESS: "FETCH_ALL_STORE_SUCCESS",

  FETCH_ONE_STORE: "FETCH_ONE_STORE",
  FETCH_ONE_STORE_RESET: "FETCH_ONE_STORE_RESET",
  FETCH_ONE_STORE_FAILURE: "FETCH_ONE_STORE_FAILURE",
  FETCH_ONE_STORE_SUCCESS: "FETCH_ONE_STORE_SUCCESS",
};
