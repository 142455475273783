import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { SupervisorPlanActionTypes } from "./SupervisorPlan.type";

export function* fetchAllSupervisorPlans(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/crm/supervisor-plan?${query}`);
    yield put({
      type: SupervisorPlanActionTypes.FETCH_ALL_SUPERVISOR_PLANS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupervisorPlanActionTypes.FETCH_ALL_SUPERVISOR_PLANS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSupervisorPlan(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/supervisor-plan/${action.payload}`
    );
    yield put({
      type: SupervisorPlanActionTypes.FETCH_ONE_SUPERVISOR_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupervisorPlanActionTypes.FETCH_ONE_SUPERVISOR_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSupervisorPlans() {
  yield takeLatest(SupervisorPlanActionTypes.FETCH_ALL_SUPERVISOR_PLANS, fetchAllSupervisorPlans);
}

export function* watcherFetchOneSupervisorPlan() {
  yield takeLatest(SupervisorPlanActionTypes.FETCH_ONE_SUPERVISOR_PLAN, fetchOneSupervisorPlan);
}
