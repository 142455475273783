import { ApiCallState } from "../Utils";

export type MarketingSalesSchedule = {
  id: number;
  user_id: number;

  month: string;
  description: string;
  amount: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type MarketingSalesScheduleStateTypes = {
  fetchAll: ApiCallState<MarketingSalesSchedule[]>;
  fetchOne: ApiCallState<MarketingSalesSchedule | {}>;
};

export const MarketingSalesScheduleActionTypes = {
  FETCH_ALL_MARKETING_SALES_SCHEDULE: "FETCH_ALL_MARKETING_SALES_SCHEDULE",
  FETCH_ALL_MARKETING_SALES_SCHEDULE_RESET:
    "FETCH_ALL_MARKETING_SALES_SCHEDULE_RESET",
  FETCH_ALL_MARKETING_SALES_SCHEDULE_FAILURE:
    "FETCH_ALL_MARKETING_SALES_SCHEDULE_FAILURE",
  FETCH_ALL_MARKETING_SALES_SCHEDULE_SUCCESS:
    "FETCH_ALL_MARKETING_SALES_SCHEDULE_SUCCESS",

  FETCH_ONE_MARKETING_SALES_SCHEDULE: "FETCH_ONE_MARKETING_SALES_SCHEDULE",
  FETCH_ONE_MARKETING_SALES_SCHEDULE_RESET:
    "FETCH_ONE_MARKETING_SALES_SCHEDULE_RESET",
  FETCH_ONE_MARKETING_SALES_SCHEDULE_FAILURE:
    "FETCH_ONE_MARKETING_SALES_SCHEDULE_FAILURE",
  FETCH_ONE_MARKETING_SALES_SCHEDULE_SUCCESS:
    "FETCH_ONE_MARKETING_SALES_SCHEDULE_SUCCESS",
};
