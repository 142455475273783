import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { DailyReportActionTypes } from "./DailyReport.type";

export function* fetchAllDailyReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/crm/daily-report?${query}`);
    yield put({
      type: DailyReportActionTypes.FETCH_ALL_DAILY_REPORTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportActionTypes.FETCH_ALL_DAILY_REPORTS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/daily-report/${action.payload}`
    );
    yield put({
      type: DailyReportActionTypes.FETCH_ONE_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyReportActionTypes.FETCH_ONE_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCRMDailyReports() {
  yield takeLatest(DailyReportActionTypes.FETCH_ALL_DAILY_REPORTS, fetchAllDailyReports);
}

export function* watcherFetchOneCRMDailyReport() {
  yield takeLatest(DailyReportActionTypes.FETCH_ONE_DAILY_REPORT, fetchOneDailyReport);
}
