import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectStaffActionTypes } from "./ProjectStaff.type";

export function* fetchAllProjectStaffs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project_staff`);
    yield put({
      type: ProjectStaffActionTypes.FETCH_ALL_PROJECT_STAFF_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectStaffActionTypes.FETCH_ALL_PROJECT_STAFF_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectStaffs() {
  yield takeLatest(
    ProjectStaffActionTypes.FETCH_ALL_PROJECT_STAFF,
    fetchAllProjectStaffs
  );
}
