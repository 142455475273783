import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentInventoryActions } from "./EquipmentInventory.type";

export function* fetchEquipmentInventories(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment_inventory`);
    yield put({
      type: EquipmentInventoryActions.FETCH_EQUIPMENT_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentInventoryActions.FETCH_EQUIPMENT_INVENTORY_ERROR,
      payload: error,
    });
  }
}

export function* watcherFetchEquipmentInventories() {
  yield takeLatest(
    EquipmentInventoryActions.FETCH_EQUIPMENT_INVENTORY,
    fetchEquipmentInventories
  );
}
