import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ScaleActionTypes } from "./Scale.type";

export function* fetchAllScales(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/scale`);
    yield put({
      type: ScaleActionTypes.FETCH_ALL_SCALE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ScaleActionTypes.FETCH_ALL_SCALE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneScales(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/scale/${action.payload}`
    );
    yield put({
      type: ScaleActionTypes.FETCH_ONE_SCALE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ScaleActionTypes.FETCH_ONE_SCALE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllScales() {
  yield takeLatest(ScaleActionTypes.FETCH_ALL_SCALE, fetchAllScales);
}

export function* watcherFetchOneScales() {
  yield takeLatest(ScaleActionTypes.FETCH_ONE_SCALE, fetchOneScales);
}
