import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type Building= {
  id: number;
  name: string;
  square_meter: number;
  total_budget: number;
  estimated_compound_cost: number;
  estimated_administration_cost: number;
  estimated_commission_cost: number;
  estimated_contingency_cost: number;
  other: number;
  project: Project
};

export type BuildingStateTypes = {
  fetchAll: ApiCallState<Building[]>;
  fetchOne: ApiCallState<Building | {}>;
};

export const BuildingActionTypes = {
  FETCH_ALL_BUILDING: "FETCH_ALL_BUILDING",
  FETCH_ALL_BUILDING_RESET: "FETCH_ALL_BUILDING_RESET",
  FETCH_ALL_BUILDING_FAILURE: "FETCH_ALL_BUILDING_FAILURE",
  FETCH_ALL_BUILDING_SUCCESS: "FETCH_ALL_BUILDING_SUCCESS",

  FETCH_ONE_BUILDING: "FETCH_ONE_BUILDING",
  FETCH_ONE_BUILDING_RESET: "FETCH_ONE_BUILDING_RESET",
  FETCH_ONE_BUILDING_FAILURE: "FETCH_ONE_BUILDING_FAILURE",
  FETCH_ONE_BUILDING_SUCCESS: "FETCH_ONE_BUILDING_SUCCESS",
};
