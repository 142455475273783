import { Project } from "../Project/Project.type";
import { ProjectContract } from "../ProjectContract/ProjectContract.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type SubContractBudget = {
  id: number;
  budget_month: string;
  project_id: number;
  project: Project;
  user: User;
  sub_contract_budget_items: SubContractBudgetItem[];
};

export type SubContractBudgetItem = {
  id: number;
  sub_contract_budget_id: number;
  description:string;
  project_contract_id:number;
  quantity:number;
  actual_quantity:number;
  unit_rate:number;
  remark:string;
  project_contract:ProjectContract;
};

export type SubContractBudgetStateTypes = {
  fetchAll: ApiCallState<SubContractBudget[]>;
  fetchOne: ApiCallState<SubContractBudget | {}>;
};

export const SubContractBudgetActionTypes = {
  FETCH_ALL_SUB_CONTRACT_BUDGET: "FETCH_ALL_SUB_CONTRACT_BUDGET",
  FETCH_ALL_SUB_CONTRACT_BUDGET_RESET: "FETCH_ALL_SUB_CONTRACT_BUDGET_RESET",
  FETCH_ALL_SUB_CONTRACT_BUDGET_FAILURE:
    "FETCH_ALL_SUB_CONTRACT_BUDGET_FAILURE",
  FETCH_ALL_SUB_CONTRACT_BUDGET_SUCCESS:
    "FETCH_ALL_SUB_CONTRACT_BUDGET_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_BUDGET: "FETCH_ONE_SUB_CONTRACT_BUDGET",
  FETCH_ONE_SUB_CONTRACT_BUDGET_RESET: "FETCH_ONE_SUB_CONTRACT_BUDGET_RESET",
  FETCH_ONE_SUB_CONTRACT_BUDGET_FAILURE:
    "FETCH_ONE_SUB_CONTRACT_BUDGET_FAILURE",
  FETCH_ONE_SUB_CONTRACT_BUDGET_SUCCESS:
    "FETCH_ONE_SUB_CONTRACT_BUDGET_SUCCESS",
};
