import { ApiCallState } from "../Utils";

export type EmployeeUser = {
  id: number;
  email: string;
  password: string;
  status: string;
  staff_id: number;
  createdAt: any;
  updatedAt: any;
  staff: Staff;
};

export type Staff = {
  id: number;
  unique_uuid: string;
  department_id: number;
  name: string;
  sex: string;
  description: string;
  salary: number;
  email: string;
  type: string;
  document_id: number;
  date: any;
  status: string;
  termination_date: any;
  user_id: number;
  photo: string;
  government_id: any;
  emergency_contact_id: number;
  phone_number: string;
  city: string;
  woreda: string;
  education_level: string;
  createdAt: any;
  updatedAt: any;
};

export type EmployeeUserStateTypes = {
  fetchAll: ApiCallState<EmployeeUser[]>;
  fetchOne: ApiCallState<EmployeeUser | {}>;
};

export const EmployeeUserActionTypes = {
  FETCH_ALL_EMPLOYEE_USER: "FETCH_ALL_EMPLOYEE_USER",
  FETCH_ALL_EMPLOYEE_USER_RESET: "FETCH_ALL_EMPLOYEE_USER_RESET",
  FETCH_ALL_EMPLOYEE_USER_FAILURE: "FETCH_ALL_EMPLOYEE_USER_FAILURE",
  FETCH_ALL_EMPLOYEE_USER_SUCCESS: "FETCH_ALL_EMPLOYEE_USER_SUCCESS",

  FETCH_ONE_EMPLOYEE_USER: "FETCH_ONE_EMPLOYEE_USER",
  FETCH_ONE_EMPLOYEE_USER_RESET: "FETCH_ONE_EMPLOYEE_USER_RESET",
  FETCH_ONE_EMPLOYEE_USER_FAILURE: "FETCH_ONE_EMPLOYEE_USER_FAILURE",
  FETCH_ONE_EMPLOYEE_USER_SUCCESS: "FETCH_ONE_EMPLOYEE_USER_SUCCESS",
};
