import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffPlanActionTypes } from "./StaffPlan.type";

export function* fetchAllStaffPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff_plan?project_id=${action.payload.project_id}&date_type=${action.payload.date_type}&date=${action.payload.date}`
    );
    yield put({
      type: StaffPlanActionTypes.FETCH_ALL_STAFF_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffPlanActionTypes.FETCH_ALL_STAFF_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffPlans() {
  yield takeLatest(
    StaffPlanActionTypes.FETCH_ALL_STAFF_PLAN,
    fetchAllStaffPlans
  );
}
