import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GoodReceivedActionTypes } from "./GoodReceived.type";

export function* fetchAllGoodReceived(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-received?inventory_type=${action.payload?.inventory_type}`
    );
    yield put({
      type: GoodReceivedActionTypes.FETCH_ALL_GOOD_RECEIVED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReceivedActionTypes.FETCH_ALL_GOOD_RECEIVED_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodReceived() {
  yield takeLatest(
    GoodReceivedActionTypes.FETCH_ALL_GOOD_RECEIVED,
    fetchAllGoodReceived
  );
}
