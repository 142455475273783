import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SummaryResultActionTypes } from "./SummaryResult.type";

export function* fetchAllSummaryResults(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/summary-result`);
    yield put({
      type: SummaryResultActionTypes.FETCH_ALL_SUMMARY_RESULT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryResultActionTypes.FETCH_ALL_SUMMARY_RESULT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSummaryResults(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/summary-result/${action.payload}`
    );
    yield put({
      type: SummaryResultActionTypes.FETCH_ONE_SUMMARY_RESULT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryResultActionTypes.FETCH_ONE_SUMMARY_RESULT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSummaryResults() {
  yield takeLatest(SummaryResultActionTypes.FETCH_ALL_SUMMARY_RESULT, fetchAllSummaryResults);
}

export function* watcherFetchOneSummaryResults() {
  yield takeLatest(SummaryResultActionTypes.FETCH_ONE_SUMMARY_RESULT, fetchOneSummaryResults);
}
