import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { InvoiceActionTypes } from "./Invoice.type";

export function* fetchAllInvoice(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/invoice`);
    yield put({
      type: InvoiceActionTypes.FETCH_ALL_INVOICE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InvoiceActionTypes.FETCH_ALL_INVOICE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllInvoice() {
  yield takeLatest(InvoiceActionTypes.FETCH_ALL_INVOICE, fetchAllInvoice);
}
