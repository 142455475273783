import { Moment } from "moment";
import { Department } from "../Department/Department.type";
import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type StaffPlan = {
  id: number | null;
  description: string;
  available: number;
  department: string;
  required: number;
  monthly_salary: number;
  project_id: any;
  date: string;
  overtime_total: number;
  date_type: string;
  key?: number;
  permanent_overtime_plan: OvertimePlan;
};

export type OvertimePlan = {
  id: any;
  day_hr: number;
  holiday_hr: number;
  night_hr: number;
  sunday_hr: number;
};

export type StaffPlanStateTypes = {
  fetchAll: ApiCallState<StaffPlan[]>;
};

export const StaffPlanActionTypes = {
  FETCH_ALL_STAFF_PLAN: "FETCH_ALL_STAFF_PLAN",
  FETCH_ALL_STAFF_PLAN_RESET: "FETCH_ALL_STAFF_PLAN_RESET",
  FETCH_ALL_STAFF_PLAN_FAILURE: "FETCH_ALL_STAFF_PLAN_FAILURE",
  FETCH_ALL_STAFF_PLAN_SUCCESS: "FETCH_ALL_STAFF_PLAN_SUCCESS",
};
