import { HRPolicyStateTypes, HRPolicyActionTypes } from "./HRPolicy.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: HRPolicyStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchLeaveReport: resetApiCallState({})
};

const HRPolicyReducer = (
  state: HRPolicyStateTypes = INITIAL_STATE,
  action: any
): HRPolicyStateTypes => {
  switch (action.type) {
    case HRPolicyActionTypes.FETCH_ALL_HR_POLICY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case HRPolicyActionTypes.FETCH_ALL_HR_POLICY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case HRPolicyActionTypes.FETCH_ALL_HR_POLICY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HRPolicyActionTypes.FETCH_ALL_HR_POLICY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

      case HRPolicyActionTypes.FETCH_ALL_LEAVE_REPORT:
        return {
          ...state,
          fetchLeaveReport: {
            error: null,
            payload: {},
            isPending: true,
            isSuccessful: false,
          },
        };
      case HRPolicyActionTypes.FETCH_ALL_LEAVE_REPORT_RESET:
        return {
          ...state,
          fetchLeaveReport: resetApiCallState({}),
        };
      case HRPolicyActionTypes.FETCH_ALL_LEAVE_REPORT_FAILURE:
        return {
          ...state,
          fetchLeaveReport: {
            payload: {},
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case HRPolicyActionTypes.FETCH_ALL_LEAVE_REPORT_SUCCESS:
        return {
          ...state,
          fetchLeaveReport: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };

    default:
      return state;
  }
};

export default HRPolicyReducer;
