import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type AnnualLeavePayment = {
  id: number;
  staff_id: number;
  date: string;
  resignation_date: string;
  termination_date: string;
  last_position_held: string;
  department_id: number;
  last_basic_monthly_salary: number;
  total_service_year: number;
  total_service_month: number;
  total_service_day: number;
  total_service_days: number;
  total_leave_due: number;
  total_leave_due_days: number;
  total_annual_leave_used: number;
  total_annual_leave_used_days: number;
  total_annual_leave_balance: number;
  total_annual_leave_balance_days: number;
  prepared_by: number;
  approved_by: number;
  received_by: number;
  remark: string;
  staff: Staff;
};

export type AnnualLeavePaymentStateTypes = {
  fetchAll: ApiCallState<AnnualLeavePayment[]>;
  fetchOne: ApiCallState<AnnualLeavePayment | {}>;
};

export const AnnualLeavePaymentActionTypes = {
  FETCH_ALL_ANNUAL_LEAVE_PAYMENT: "FETCH_ALL_ANNUAL_LEAVE_PAYMENT",
  FETCH_ALL_ANNUAL_LEAVE_PAYMENT_RESET: "FETCH_ALL_ANNUAL_LEAVE_PAYMENT_RESET",
  FETCH_ALL_ANNUAL_LEAVE_PAYMENT_FAILURE: "FETCH_ALL_ANNUAL_LEAVE_PAYMENT_FAILURE",
  FETCH_ALL_ANNUAL_LEAVE_PAYMENT_SUCCESS: "FETCH_ALL_ANNUAL_LEAVE_PAYMENT_SUCCESS",

  FETCH_ONE_ANNUAL_LEAVE_PAYMENT: "FETCH_ONE_ANNUAL_LEAVE_PAYMENT",
  FETCH_ONE_ANNUAL_LEAVE_PAYMENT_RESET: "FETCH_ONE_ANNUAL_LEAVE_PAYMENT_RESET",
  FETCH_ONE_ANNUAL_LEAVE_PAYMENT_FAILURE: "FETCH_ONE_ANNUAL_LEAVE_PAYMENT_FAILURE",
  FETCH_ONE_ANNUAL_LEAVE_PAYMENT_SUCCESS: "FETCH_ONE_ANNUAL_LEAVE_PAYMENT_SUCCESS",
};
