import { Building } from "../Building/Building.type";
import { ApiCallState } from "../Utils";

export type Apartment= {
  id: number;
  name: string;
  square_meter: number;
  // currency: string;
  // date: string;
  building: Building,
  building_id: number
};

export type ApartmentStateTypes = {
  fetchAll: ApiCallState<Apartment[]>;
  fetchOne: ApiCallState<Apartment | {}>;
};

export const ApartmentActionTypes = {
  FETCH_ALL_APARTMENT: "FETCH_ALL_APARTMENT",
  FETCH_ALL_APARTMENT_RESET: "FETCH_ALL_APARTMENT_RESET",
  FETCH_ALL_APARTMENT_FAILURE: "FETCH_ALL_APARTMENT_FAILURE",
  FETCH_ALL_APARTMENT_SUCCESS: "FETCH_ALL_APARTMENT_SUCCESS",

  FETCH_ONE_APARTMENT: "FETCH_ONE_APARTMENT",
  FETCH_ONE_APARTMENT_RESET: "FETCH_ONE_APARTMENT_RESET",
  FETCH_ONE_APARTMENT_FAILURE: "FETCH_ONE_APARTMENT_FAILURE",
  FETCH_ONE_APARTMENT_SUCCESS: "FETCH_ONE_APARTMENT_SUCCESS",
};
