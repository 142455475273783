import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentActionTypes } from "./Payment.type";

export function* fetchAllPayment(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment`);
    yield put({
      type: PaymentActionTypes.FETCH_ALL_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentActionTypes.FETCH_ALL_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayment() {
  yield takeLatest(PaymentActionTypes.FETCH_ALL_PAYMENT, fetchAllPayment);
}
