import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqReviseActionTypes } from "./BoqRevise.type";

export function* fetchAllBoqRevises(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq-revise?${query}`);
    yield put({
      type: BoqReviseActionTypes.FETCH_ALL_BOQ_REVISE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqReviseActionTypes.FETCH_ALL_BOQ_REVISE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedBoqRevises(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq-revise?${query}`);
    yield put({
      type: BoqReviseActionTypes.FETCH_PAGED_BOQ_REVISE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqReviseActionTypes.FETCH_PAGED_BOQ_REVISE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoqRevises(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq-revise/${action.payload}`
    );
    yield put({
      type: BoqReviseActionTypes.FETCH_ONE_BOQ_REVISE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqReviseActionTypes.FETCH_ONE_BOQ_REVISE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqRevises() {
  yield takeLatest(
    BoqReviseActionTypes.FETCH_ALL_BOQ_REVISE,
    fetchAllBoqRevises
  );
}

export function* watcherFetchPagedBoqRevises() {
  yield takeLatest(
    BoqReviseActionTypes.FETCH_PAGED_BOQ_REVISE,
    fetchPagedBoqRevises
  );
}

export function* watcherFetchOneBoqRevises() {
  yield takeLatest(
    BoqReviseActionTypes.FETCH_ONE_BOQ_REVISE,
    fetchOneBoqRevises
  );
}
