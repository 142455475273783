import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AttachmentActionTypes } from "./Attachment.type";

export function* fetchAllAttachments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/attachment`);
    yield put({
      type: AttachmentActionTypes.FETCH_ALL_ATTACHMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttachmentActionTypes.FETCH_ALL_ATTACHMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAttachments() {
  yield takeLatest(
    AttachmentActionTypes.FETCH_ALL_ATTACHMENT,
    fetchAllAttachments
  );
}
