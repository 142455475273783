import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ExchangeRateActionTypes } from "./ExchangeRate.type";

export function* fetchExchangeRate(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/exchange-rate`);
    yield put({
      type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExchangeRateActionTypes.FETCH_EXCHANGE_RATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchExchangeRates() {
  yield takeLatest(ExchangeRateActionTypes.FETCH_EXCHANGE_RATE, fetchExchangeRate);
}

