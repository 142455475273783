import { ApiCallState } from "../Utils";

export type PerformancePointPolicy = {
  id: number;

  user_id: number;
  description: string;
  point: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type PerformancePointPolicyStateTypes = {
  fetchAll: ApiCallState<PerformancePointPolicy[]>;
  fetchOne: ApiCallState<PerformancePointPolicy | {}>;
};

export const PerformancePointPolicyActionTypes = {
  FETCH_ALL_PERFORMANCE_POINT_POLICY: "FETCH_ALL_PERFORMANCE_POINT_POLICY",
  FETCH_ALL_PERFORMANCE_POINT_POLICY_RESET:
    "FETCH_ALL_PERFORMANCE_POINT_POLICY_RESET",
  FETCH_ALL_PERFORMANCE_POINT_POLICY_FAILURE:
    "FETCH_ALL_PERFORMANCE_POINT_POLICY_FAILURE",
  FETCH_ALL_PERFORMANCE_POINT_POLICY_SUCCESS:
    "FETCH_ALL_PERFORMANCE_POINT_POLICY_SUCCESS",

  FETCH_ONE_PERFORMANCE_POINT_POLICY: "FETCH_ONE_PERFORMANCE_POINT_POLICY",
  FETCH_ONE_PERFORMANCE_POINT_POLICY_RESET:
    "FETCH_ONE_PERFORMANCE_POINT_POLICY_RESET",
  FETCH_ONE_PERFORMANCE_POINT_POLICY_FAILURE:
    "FETCH_ONE_PERFORMANCE_POINT_POLICY_FAILURE",
  FETCH_ONE_PERFORMANCE_POINT_POLICY_SUCCESS:
    "FETCH_ONE_PERFORMANCE_POINT_POLICY_SUCCESS",
};
