import { ApartmentBuilding } from "../ApartmentBuilding/ApartmentBuilding/ApartmentBuilding.type";
import { ApiCallState } from "../Utils";

export type Quotation= {
  id: number;
  date: string;
  building_id: number;
  user_id: number;
  apartment_building: ApartmentBuilding,
  quotation_items: QuotationItem[],
  quotation_statuses: { id: number; type: "View" | "Check" | "Approve"; status: number; assigned_by: number; user_id: number; }[]
};

export type QuotationItem = {
  id?: number,
  description: string,
  percentage: number
}

export type QuotationStateTypes = {
  fetchAll: ApiCallState<Quotation[]>;
  fetchOne: ApiCallState<Quotation | {}>;
};

export const QuotationActionTypes = {
  FETCH_ALL_QUOTATION: "FETCH_ALL_QUOTATION",
  FETCH_ALL_QUOTATION_RESET: "FETCH_ALL_QUOTATION_RESET",
  FETCH_ALL_QUOTATION_FAILURE: "FETCH_ALL_QUOTATION_FAILURE",
  FETCH_ALL_QUOTATION_SUCCESS: "FETCH_ALL_QUOTATION_SUCCESS",

  FETCH_ONE_QUOTATION: "FETCH_ONE_QUOTATION",
  FETCH_ONE_QUOTATION_RESET: "FETCH_ONE_QUOTATION_RESET",
  FETCH_ONE_QUOTATION_FAILURE: "FETCH_ONE_QUOTATION_FAILURE",
  FETCH_ONE_QUOTATION_SUCCESS: "FETCH_ONE_QUOTATION_SUCCESS",
};
