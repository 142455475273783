import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PreMrActionTypes } from "./PreMr.type";
import { formatQuery } from "../Utils";

export function* fetchAllPreMrs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/pre-mr?${formatQuery(action)}`
    );
    yield put({
      type: PreMrActionTypes.FETCH_ALL_PRE_MR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PreMrActionTypes.FETCH_ALL_PRE_MR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePreMrs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/pre-mr/${action.payload}`
    );
    yield put({
      type: PreMrActionTypes.FETCH_ONE_PRE_MR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PreMrActionTypes.FETCH_ONE_PRE_MR_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPreMrs() {
  yield takeLatest(PreMrActionTypes.FETCH_ALL_PRE_MR, fetchAllPreMrs);
}

export function* watcherFetchOnePreMrs() {
  yield takeLatest(PreMrActionTypes.FETCH_ONE_PRE_MR, fetchOnePreMrs);
}
