import { ExternaVacancyAnnouncement } from "../ExternaVacancyAnnouncement/ExternaVacancyAnnouncement.type";
import { ApiCallState } from "../Utils";

export type ApplicantRecord = {
  id: number;
  external_vacancy_announcement_id: number;
  date: string;
  external_vacancy_announcement:ExternaVacancyAnnouncement;
  applicant_record_items: ApplicantRecordItem[];
};

export type ApplicantRecordItem = {
  id: number;
  applicant_record_id: number;
  name: string;
  field_of_study:string;
  experience:string;
  type: string;
  phone_no: string;
  status: string;
};

export type ApplicantRecordStateTypes = {
  fetchAll: ApiCallState<ApplicantRecord[]>;
  fetchOne: ApiCallState<ApplicantRecord | {}>;
};

export const ApplicantRecordActionTypes = {
  FETCH_ALL_APPLICANT_RECORD: "FETCH_ALL_APPLICANT_RECORD",
  FETCH_ALL_APPLICANT_RECORD_RESET: "FETCH_ALL_APPLICANT_RECORD_RESET",
  FETCH_ALL_APPLICANT_RECORD_FAILURE: "FETCH_ALL_APPLICANT_RECORD_FAILURE",
  FETCH_ALL_APPLICANT_RECORD_SUCCESS: "FETCH_ALL_APPLICANT_RECORD_SUCCESS",

  FETCH_ONE_APPLICANT_RECORD: "FETCH_ONE_APPLICANT_RECORD",
  FETCH_ONE_APPLICANT_RECORD_RESET: "FETCH_ONE_APPLICANT_RECORD_RESET",
  FETCH_ONE_APPLICANT_RECORD_FAILURE: "FETCH_ONE_APPLICANT_RECORD_FAILURE",
  FETCH_ONE_APPLICANT_RECORD_SUCCESS: "FETCH_ONE_APPLICANT_RECORD_SUCCESS",
};
