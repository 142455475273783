import { ApiCallState } from "../../Utils";

export type NewsAndMotivation = {
  id: number;
  date: string;
  message: string;
  type: string;
};

export type NewsAndMotivationStateTypes = {
  fetchAll: ApiCallState<NewsAndMotivation[]>;
  fetchOne: ApiCallState<NewsAndMotivation | {}>;
};

export const NewsAndMotivationActionTypes = {
  FETCH_ALL_NEWS_AND_MOTIVATION: "FETCH_ALL_NEWS_AND_MOTIVATION",
  FETCH_ALL_NEWS_AND_MOTIVATION_RESET: "FETCH_ALL_NEWS_AND_MOTIVATION_RESET",
  FETCH_ALL_NEWS_AND_MOTIVATION_FAILURE:
    "FETCH_ALL_NEWS_AND_MOTIVATION_FAILURE",
  FETCH_ALL_NEWS_AND_MOTIVATION_SUCCESS:
    "FETCH_ALL_NEWS_AND_MOTIVATION_SUCCESS",

  FETCH_ONE_NEWS_AND_MOTIVATION: "FETCH_ONE_NEWS_AND_MOTIVATION",
  FETCH_ONE_NEWS_AND_MOTIVATION_RESET: "FETCH_ONE_NEWS_AND_MOTIVATION_RESET",
  FETCH_ONE_NEWS_AND_MOTIVATION_FAILURE:
    "FETCH_ONE_NEWS_AND_MOTIVATION_FAILURE",
  FETCH_ONE_NEWS_AND_MOTIVATION_SUCCESS:
    "FETCH_ONE_NEWS_AND_MOTIVATION_SUCCESS",
};
