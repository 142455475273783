import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AccountChartActionTypes } from "./AccountChart.type";

export function* fetchAllAccountCharts(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/account-chart?${query}`);
    yield put({
      type: AccountChartActionTypes.FETCH_ALL_ACCOUNT_CHART_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountChartActionTypes.FETCH_ALL_ACCOUNT_CHART_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedAccountCharts(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/account-chart?${query}`);
    yield put({
      type: AccountChartActionTypes.FETCH_PAGED_ACCOUNT_CHART_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountChartActionTypes.FETCH_PAGED_ACCOUNT_CHART_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAccountCharts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/account-chart/${action.payload}`
    );
    yield put({
      type: AccountChartActionTypes.FETCH_ONE_ACCOUNT_CHART_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountChartActionTypes.FETCH_ONE_ACCOUNT_CHART_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAccountCharts() {
  yield takeLatest(
    AccountChartActionTypes.FETCH_ALL_ACCOUNT_CHART,
    fetchAllAccountCharts
  );
}

export function* watcherFetchPagedAccountCharts() {
  yield takeLatest(
    AccountChartActionTypes.FETCH_PAGED_ACCOUNT_CHART,
    fetchPagedAccountCharts
  );
}

export function* watcherFetchOneAccountCharts() {
  yield takeLatest(
    AccountChartActionTypes.FETCH_ONE_ACCOUNT_CHART,
    fetchOneAccountCharts
  );
}
