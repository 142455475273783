import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeHistoryActions } from "./EmployeeHistory.type";

export function* fetchEmployeeHistory(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/employee_history`);
    yield put({
      type: EmployeeHistoryActions.FETCH_EMPLOYEE_HISTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeHistoryActions.FETCH_EMPLOYEE_HISTORY_ERROR,
      payload: error,
    });
  }
}

export function* watcherFetchEmployeeHistory() {
  yield takeLatest(
    EmployeeHistoryActions.FETCH_EMPLOYEE_HISTORY,
    fetchEmployeeHistory
  );
}
