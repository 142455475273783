import { Replenishment } from "../Replenish/Replenishment.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PettyCash = {
  id: number;

  user_id: number;
  user: User;

  current_replenishment_id: number | null;
  current_replenishment: any;

  amount: number;
  current_amount: number;
  replenishment_level: number;

  replenishments: Replenishment[];

  date: Date;
};

export type PettyCashStateTypes = {
  fetchAll: ApiCallState<PettyCash[]>;
  fetchOne: ApiCallState<PettyCash | {}>;
};

export const PettyCashActionTypes = {
  FETCH_ALL_PETTY_CASH: "FETCH_ALL_PETTY_CASH",
  FETCH_ALL_PETTY_CASH_RESET: "FETCH_ALL_PETTY_CASH_RESET",
  FETCH_ALL_PETTY_CASH_FAILURE: "FETCH_ALL_PETTY_CASH_FAILURE",
  FETCH_ALL_PETTY_CASH_SUCCESS: "FETCH_ALL_PETTY_CASH_SUCCESS",

  FETCH_ONE_PETTY_CASH: "FETCH_ONE_PETTY_CASH",
  FETCH_ONE_PETTY_CASH_RESET: "FETCH_ONE_PETTY_CASH_RESET",
  FETCH_ONE_PETTY_CASH_FAILURE: "FETCH_ONE_PETTY_CASH_FAILURE",
  FETCH_ONE_PETTY_CASH_SUCCESS: "FETCH_ONE_PETTY_CASH_SUCCESS",
};
