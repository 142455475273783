import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ForecastActionTypes } from "./Forecast.type";


export function* fetchMasterScheduleForecasts(action: any): any {
  try {
 let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/forecast/master-schedule?${query}`);
    yield put({
      type: ForecastActionTypes.FETCH_MASTER_SCHEDULE_FORECAST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type:  ForecastActionTypes.FETCH_MASTER_SCHEDULE_FORECAST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchMasterScheduleDetailForecasts(action: any): any {
  try {
 let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/forecast/master-schedule/detail?${query}`);
    yield put({
      type: ForecastActionTypes.FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type:  ForecastActionTypes.FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_FAILURE,
      payload: error,
    });
  }
}






export function* watcherFetchMasterScheduleForecasts() {
  yield takeLatest( ForecastActionTypes.FETCH_MASTER_SCHEDULE_FORECAST, fetchMasterScheduleForecasts);
}


export function* watcherFetchMasterScheduleDetailsForecasts() {
  yield takeLatest( ForecastActionTypes.FETCH_MASTER_SCHEDULE_DETAILS_FORECAST, fetchMasterScheduleDetailForecasts);
}


