import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type LoanExtension = {
  id: number;
  user_id: number;
  staff_id: number;

  date: string;
  reason: string;

  staff: Staff;
};

export type LoanExtensionStateTypes = {
  fetchAll: ApiCallState<LoanExtension[]>;
  fetchOne: ApiCallState<LoanExtension | {}>;
};

export const LoanExtensionActionTypes = {
  FETCH_ALL_LOAN_EXTENSION: "FETCH_ALL_LOAN_EXTENSION",
  FETCH_ALL_LOAN_EXTENSION_RESET: "FETCH_ALL_LOAN_EXTENSION_RESET",
  FETCH_ALL_LOAN_EXTENSION_FAILURE: "FETCH_ALL_LOAN_EXTENSION_FAILURE",
  FETCH_ALL_LOAN_EXTENSION_SUCCESS: "FETCH_ALL_LOAN_EXTENSION_SUCCESS",

  FETCH_ONE_LOAN_EXTENSION: "FETCH_ONE_LOAN_EXTENSION",
  FETCH_ONE_LOAN_EXTENSION_RESET: "FETCH_ONE_LOAN_EXTENSION_RESET",
  FETCH_ONE_LOAN_EXTENSION_FAILURE: "FETCH_ONE_LOAN_EXTENSION_FAILURE",
  FETCH_ONE_LOAN_EXTENSION_SUCCESS: "FETCH_ONE_LOAN_EXTENSION_SUCCESS",
};
