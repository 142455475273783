import { Scale } from "../Scale/Scale.type";
import { ApiCallState } from "../Utils";

export type ScaleItem= {
    id: number;
    starting_salary: number;
    grade_id: number;
    scale_id: number;
    scale: Scale
};

export type ScaleItemStateTypes = {
  fetchAll: ApiCallState<ScaleItem[]>;
  fetchOne: ApiCallState<ScaleItem | {}>;
};

export const ScaleItemActionTypes = {
  FETCH_ALL_SCALE_ITEM: "FETCH_ALL_SCALE_ITEM",
  FETCH_ALL_SCALE_ITEM_RESET: "FETCH_ALL_SCALE_ITEM_RESET",
  FETCH_ALL_SCALE_ITEM_FAILURE: "FETCH_ALL_SCALE_ITEM_FAILURE",
  FETCH_ALL_SCALE_ITEM_SUCCESS: "FETCH_ALL_SCALE_ITEM_SUCCESS",

  FETCH_ONE_SCALE_ITEM: "FETCH_ONE_SCALE_ITEM",
  FETCH_ONE_SCALE_ITEM_RESET: "FETCH_ONE_SCALE_ITEM_RESET",
  FETCH_ONE_SCALE_ITEM_FAILURE: "FETCH_ONE_SCALE_ITEM_FAILURE",
  FETCH_ONE_SCALE_ITEM_SUCCESS: "FETCH_ONE_SCALE_ITEM_SUCCESS",
};
