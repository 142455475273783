import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { WeeklyPlanActionTypes } from "./WeeklyPlan.type";

export function* fetchAllWeeklyPlans(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/crm/weekly_plan?${query}`);
    yield put({
      type: WeeklyPlanActionTypes.FETCH_ALL_WEEKLY_PLANS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklyPlanActionTypes.FETCH_ALL_WEEKLY_PLANS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWeeklyPlan(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/weekly_plan/${action.payload}`
    );
    yield put({
      type: WeeklyPlanActionTypes.FETCH_ONE_WEEKLY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklyPlanActionTypes.FETCH_ONE_WEEKLY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWeeklyPlans() {
  yield takeLatest(WeeklyPlanActionTypes.FETCH_ALL_WEEKLY_PLANS, fetchAllWeeklyPlans);
}

export function* watcherFetchOneWeeklyPlan() {
  yield takeLatest(WeeklyPlanActionTypes.FETCH_ONE_WEEKLY_PLAN, fetchOneWeeklyPlan);
}
