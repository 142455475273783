import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LabourPlanActionTypes } from "./LabourPlan.type";

export function* fetchAllLabourPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/labour_plan?project_id=${action.payload.project_id}&date_type=${action.payload.date_type}&date=${action.payload.date}`
    );
    yield put({
      type: LabourPlanActionTypes.FETCH_ALL_LABOUR_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LabourPlanActionTypes.FETCH_ALL_LABOUR_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLabourPlans() {
  yield takeLatest(
    LabourPlanActionTypes.FETCH_ALL_LABOUR_PLAN,
    fetchAllLabourPlans
  );
}
