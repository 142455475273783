import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LeaveActionTypes } from "./Leave.type";
import { formatQuery } from "../Utils";

export function* fetchAllLeaves(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/leave?${formatQuery(action)}`);
    yield put({
      type: LeaveActionTypes.FETCH_ALL_LEAVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeaveActionTypes.FETCH_ALL_LEAVE_FAILURE,
      payload: error,
    });
  }
}
export function* fetchAllFromToLeaves(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/leave/range?${formatQuery(action)}`);
    yield put({
      type: LeaveActionTypes.FETCH_ALL_FROM_TO_LEAVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeaveActionTypes.FETCH_ALL_FROM_TO_LEAVE_FAILURE,
      payload: error,
    });
  }
}
export function* fetchOneLeaves(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/leave/${action.payload}`
    );
    yield put({
      type: LeaveActionTypes.FETCH_ALL_LEAVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeaveActionTypes.FETCH_ONE_LEAVE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLeaves() {
  yield takeLatest(LeaveActionTypes.FETCH_ALL_LEAVE, fetchAllLeaves);
}

export function* watcherFetchAllFromToLeaves() {
  yield takeLatest(LeaveActionTypes.FETCH_ALL_FROM_TO_LEAVE, fetchAllFromToLeaves);
}

export function* watcherFetchOneLeaves() {
  yield takeLatest(LeaveActionTypes.FETCH_ONE_LEAVE, fetchOneLeaves);
}
