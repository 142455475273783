import { Moment } from "moment";
import React from "react";
import { EquipmentUsagePlan } from "../EquipmentUsagePlan/EquipmentUsagePlan.type";
import { Material } from "../Material/Material.type";
import { ApiCallState } from "../Utils";

export type MaterialPlan = {
  id: any;
  material_id: any;
  store_balance: number;
  quantity: number;
  unit_price: number;
  project_id: any;
  date: Moment;
  date_type: string;
  key: React.Key;
  material?: Material;
};

export type MaterialPlanStateTypes = {
  fetchAll: ApiCallState<MaterialPlan[]>;
};

export type MaterialPlanActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const MaterialPlanActionTypes = {
  FETCH_ALL_MATERIAL_PLAN: "FETCH_ALL_MATERIAL_PLAN",
  FETCH_ALL_MATERIAL_PLAN_RESET: "FETCH_ALL_MATERIAL_PLAN_RESET",
  FETCH_ALL_MATERIAL_PLAN_FAILURE: "FETCH_ALL_MATERIAL_PLAN_FAILURE",
  FETCH_ALL_MATERIAL_PLAN_SUCCESS: "FETCH_ALL_MATERIAL_PLAN_SUCCESS",
};
