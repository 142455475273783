import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthlyReportV2ActionTypes } from "./MonthlyReportV2.type";

export function* fetchAllMonthlyReportV2s(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-report-v2?${query}`
    );
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_ALL_MONTHLY_REPORT_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_ALL_MONTHLY_REPORT_V2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchMonthlyReportV2s(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-report-v2/report?${query}`
    );
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_MONTHLY_REPORT_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_MONTHLY_REPORT_V2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMonthlyReportV2s(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-report-v2?${query}`
    );
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_PAGED_MONTHLY_REPORT_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_PAGED_MONTHLY_REPORT_V2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMonthlyReportV2s(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-report-v2/${action.payload}`
    );
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_ONE_MONTHLY_REPORT_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyReportV2ActionTypes.FETCH_ONE_MONTHLY_REPORT_V2_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMonthlyReportV2s() {
  yield takeLatest(
    MonthlyReportV2ActionTypes.FETCH_ALL_MONTHLY_REPORT_V2,
    fetchAllMonthlyReportV2s
  );
}

export function* watcherFetchMonthlyReportV2s() {
  yield takeLatest(
    MonthlyReportV2ActionTypes.FETCH_MONTHLY_REPORT_V2,
    fetchMonthlyReportV2s
  );
}

export function* watcherFetchPagedMonthlyReportV2s() {
  yield takeLatest(
    MonthlyReportV2ActionTypes.FETCH_PAGED_MONTHLY_REPORT_V2,
    fetchPagedMonthlyReportV2s
  );
}

export function* watcherFetchOneMonthlyReportV2s() {
  yield takeLatest(
    MonthlyReportV2ActionTypes.FETCH_ONE_MONTHLY_REPORT_V2,
    fetchOneMonthlyReportV2s
  );
}
