import { Material } from "../Material/Material.type";
import { ApiCallState } from "../Utils";

export type Standard = {
  id: number;
  quantity: number;
  labour_output: number;
  equipment_output: number;
  overhead_cost: number;
  profit_cost: number;
  labour_standards: LabourStandard[];
  equipment_standards: EquipmentStandard[];
  material_standards: MaterialStandard[];
};

export type LabourStandard = {
  name: string;
  quantity: number;
  uf: number;
  rate: number;
};

export type MaterialStandard = {
  material_id: number;
  quantity: number;
  unit_price: number;
  material: Material;
};

export type EquipmentStandard = {
  material_id: number;
  quantity: number;
  uf: number;
  rate: number;
  material: Material;
};

export type StandardStateTypes = {
  fetchAll: ApiCallState<Standard[]>;
  fetchOne: ApiCallState<Standard | null>;
};

export const StandardActionTypes = {
  FETCH_ALL_STANDARD: "FETCH_ALL_STANDARD",
  FETCH_ALL_STANDARD_RESET: "FETCH_ALL_STANDARD_RESET",
  FETCH_ALL_STANDARD_FAILURE: "FETCH_ALL_STANDARD_FAILURE",
  FETCH_ALL_STANDARD_SUCCESS: "FETCH_ALL_STANDARD_SUCCESS",

  FETCH_ONE_STANDARD: "FETCH_ONE_STANDARD",
  FETCH_ONE_STANDARD_RESET: "FETCH_ONE_STANDARD_RESET",
  FETCH_ONE_STANDARD_FAILURE: "FETCH_ONE_STANDARD_FAILURE",
  FETCH_ONE_STANDARD_SUCCESS: "FETCH_ONE_STANDARD_SUCCESS",
};
