import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WeeklySalesPlanActionTypes } from "./WeeklySalesPlan.type";

export function* fetchAllWeeklySalesPlans(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/weekly-sales-plan`);
    yield put({
      type: WeeklySalesPlanActionTypes.FETCH_ALL_WEEKLY_SALES_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklySalesPlanActionTypes.FETCH_ALL_WEEKLY_SALES_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWeeklySalesPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/weekly-sales-plan/${action.payload}`
    );
    yield put({
      type: WeeklySalesPlanActionTypes.FETCH_ONE_WEEKLY_SALES_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklySalesPlanActionTypes.FETCH_ONE_WEEKLY_SALES_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWeeklySalesPlans() {
  yield takeLatest(WeeklySalesPlanActionTypes.FETCH_ALL_WEEKLY_SALES_PLAN, fetchAllWeeklySalesPlans);
}

export function* watcherFetchOneWeeklySalesPlans() {
  yield takeLatest(WeeklySalesPlanActionTypes.FETCH_ONE_WEEKLY_SALES_PLAN, fetchOneWeeklySalesPlans);
}
