import { VariationStateTypes, VariationActionTypes } from "./Variation.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: VariationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchBoq: resetApiCallState([]),
  fetchPID:resetApiCallState([])
};

const VariationReducer = (
  state: VariationStateTypes = INITIAL_STATE,
  action: any
): VariationStateTypes => {
  switch (action.type) {
    case VariationActionTypes.FETCH_ALL_VARIATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };

    case VariationActionTypes.FETCH_ALL_VARIATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case VariationActionTypes.FETCH_ALL_VARIATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VariationActionTypes.FETCH_ALL_VARIATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case VariationActionTypes.FETCH_BOQ_VARIATION:
      return {
        ...state,
        fetchBoq: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case VariationActionTypes.FETCH_BOQ_VARIATION_RESET:
      return {
        ...state,
        fetchBoq: resetApiCallState([]),
      };
    case VariationActionTypes.FETCH_BOQ_VARIATION_FAILURE:
      return {
        ...state,
        fetchBoq: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VariationActionTypes.FETCH_BOQ_VARIATION_SUCCESS:
      return {
        ...state,
        fetchBoq: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

      case VariationActionTypes.FETCH_PID_VARIATION:
        return {
          ...state,
          fetchPID: {
            error: null,
            payload: [],
            isPending: true,
            isSuccessful: false,
          },
        };
      case VariationActionTypes.FETCH_PID_VARIATION_RESET:
        return {
          ...state,
          fetchPID: resetApiCallState([]),
        };
      case VariationActionTypes.FETCH_PID_VARIATION_FAILURE:
        return {
          ...state,
          fetchPID: {
            payload: [],
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case VariationActionTypes.FETCH_PID_VARIATION_SUCCESS:
        return {
          ...state,
          fetchPID: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };




    default:
      return state;
  }
};

export default VariationReducer;
