import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqSubCategoryActionTypes } from "./BoqSubCategory.type";

export function* fetchAllBoqSubCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/boq-sub-category?${query}`
    );
    yield put({
      type: BoqSubCategoryActionTypes.FETCH_ALL_BOQ_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqSubCategoryActionTypes.FETCH_ALL_BOQ_SUB_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedBoqSubCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/boq-sub-category?${query}`
    );
    yield put({
      type: BoqSubCategoryActionTypes.FETCH_PAGED_BOQ_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqSubCategoryActionTypes.FETCH_PAGED_BOQ_SUB_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoqSubCategorys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq-sub-category/${action.payload}`
    );
    yield put({
      type: BoqSubCategoryActionTypes.FETCH_ONE_BOQ_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqSubCategoryActionTypes.FETCH_ONE_BOQ_SUB_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqSubCategorys() {
  yield takeLatest(
    BoqSubCategoryActionTypes.FETCH_ALL_BOQ_SUB_CATEGORY,
    fetchAllBoqSubCategorys
  );
}

export function* watcherFetchPagedBoqSubCategorys() {
  yield takeLatest(
    BoqSubCategoryActionTypes.FETCH_PAGED_BOQ_SUB_CATEGORY,
    fetchPagedBoqSubCategorys
  );
}

export function* watcherFetchOneBoqSubCategorys() {
  yield takeLatest(
    BoqSubCategoryActionTypes.FETCH_ONE_BOQ_SUB_CATEGORY,
    fetchOneBoqSubCategorys
  );
}
