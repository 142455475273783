import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeRequestActionTypes } from "./EmployeeRequest.type";

export function* fetchAllEmployeeRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/employee_request`);
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeeRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee_request/${action.payload}`
    );
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeRequests() {
  yield takeLatest(
    EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST,
    fetchAllEmployeeRequests
  );
}

export function* watcherFetchOneEmployeeRequests() {
  yield takeLatest(
    EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST,
    fetchOneEmployeeRequests
  );
}
