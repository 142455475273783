import { PayrollStateTypes, PayrollActionTypes } from "./Payroll.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PayrollStateTypes = {
  fetchAll: resetApiCallState([]),
  calculate: resetApiCallState([]),
  report: resetApiCallState([]),
  staff_report: resetApiCallState([]),
};

const PayrollReducer = (
  state: PayrollStateTypes = INITIAL_STATE,
  action: any
): PayrollStateTypes => {
  switch (action.type) {
    case PayrollActionTypes.FETCH_ALL_PAYROLL:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.FETCH_ALL_PAYROLL_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.CALCULATE_PAYROLL:
      return {
        ...state,
        calculate: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.CALCULATE_PAYROLL_RESET:
      return {
        ...state,
        calculate: resetApiCallState([]),
      };
    case PayrollActionTypes.CALCULATE_PAYROLL_FAILURE:
      return {
        ...state,
        calculate: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.CALCULATE_PAYROLL_SUCCESS:
      return {
        ...state,
        calculate: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.PAYROLL_REPORT:
      return {
        ...state,
        report: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.PAYROLL_REPORT_RESET:
      return {
        ...state,
        report: resetApiCallState([]),
      };
    case PayrollActionTypes.PAYROLL_REPORT_FAILURE:
      return {
        ...state,
        report: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.PAYROLL_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PayrollActionTypes.STAFF_PAYROLL_REPORT:
      return {
        ...state,
        staff_report: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PayrollActionTypes.STAFF_PAYROLL_REPORT_RESET:
      return {
        ...state,
        staff_report: resetApiCallState([]),
      };
    case PayrollActionTypes.STAFF_PAYROLL_REPORT_FAILURE:
      return {
        ...state,
        staff_report: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PayrollActionTypes.STAFF_PAYROLL_REPORT_SUCCESS:
      return {
        ...state,
        staff_report: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PayrollReducer;
