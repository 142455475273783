import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RecieptActionTypes } from "./Reciept.type";

export function* fetchAllReciepts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/reciept?buyer_id=${action.payload?.buyer_id}`);
    yield put({
      type: RecieptActionTypes.FETCH_ALL_RECIEPT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RecieptActionTypes.FETCH_ALL_RECIEPT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneReciepts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/reciept/${action.payload}`
    );
    yield put({
      type: RecieptActionTypes.FETCH_ONE_RECIEPT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RecieptActionTypes.FETCH_ONE_RECIEPT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllReciepts() {
  yield takeLatest(RecieptActionTypes.FETCH_ALL_RECIEPT, fetchAllReciepts);
}

export function* watcherFetchOneReciepts() {
  yield takeLatest(RecieptActionTypes.FETCH_ONE_RECIEPT, fetchOneReciepts);
}
