import { ContractStateTypes, ContractActionTypes } from "./Contract.type";
import { resetApiCallState } from "../Utils";
// import { DASHBOARD_STEPS } from "../../constants/Constants";
// import { RouteConstants } from "../../router/Constants";

const INITIAL_STATE: ContractStateTypes = {
  type: "staff",
  labour: null,
  staff: null,
};

const ContractReducer = (
  state: ContractStateTypes = INITIAL_STATE,
  action: any
): ContractStateTypes => {
  switch (action.type) {
    case ContractActionTypes.SET_CONTRACT:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default ContractReducer;
