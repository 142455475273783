import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { VariationActionTypes } from "./Variation.type";

export function* fetchAllVariation(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/variation?project_id=${action.payload.project_id}&&pid=${action.payload.pid}`
    );
    yield put({
      type: VariationActionTypes.FETCH_ALL_VARIATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VariationActionTypes.FETCH_ALL_VARIATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchBoqVariation(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/variation/boq/${action.payload}`
    );
    yield put({
      type: VariationActionTypes.FETCH_BOQ_VARIATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VariationActionTypes.FETCH_BOQ_VARIATION_FAILURE,
      payload: error,
    });
  }
}


export function* fetchAllPIDVariation(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/variation/pid/${action.payload}`
    );
    yield put({
      type: VariationActionTypes.FETCH_PID_VARIATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VariationActionTypes.FETCH_PID_VARIATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllVariation() {
  yield takeLatest(VariationActionTypes.FETCH_ALL_VARIATION, fetchAllVariation);
}

export function* watcherFetchBoqVariation() {
  yield takeLatest(VariationActionTypes.FETCH_BOQ_VARIATION, fetchBoqVariation);
}

export function* watcherFetchPIDVariation() {
  yield takeLatest(VariationActionTypes.FETCH_PID_VARIATION, fetchAllPIDVariation);
}

