import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Evaluation = {
  id: number;
  date: string;
  period_of_evaluation: string;
  comment: string;
  evaluation_items: EvaluationItem[];
  prepared_by_id: number;
  staff_id: number;
  supervisor_id: number;
  staff:Staff;
  prepared_by: User;
  supervisor: User;
  user_id: number;
};

export type EvaluationItem = {
  id: number;
  evaluation_id: number;
  evaluating_id: string;
  rating: number;
  result:number;
}



export type EvaluationStateTypes = {
  fetchAll: ApiCallState<Evaluation[]>;
  fetchOne: ApiCallState<Evaluation | {}>;
};

export const EvaluationActionTypes = {
  FETCH_ALL_EVALUATION: "FETCH_ALL_EVALUATION",
  FETCH_ALL_EVALUATION_RESET: "FETCH_ALL_EVALUATION_RESET",
  FETCH_ALL_EVALUATION_FAILURE: "FETCH_ALL_EVALUATION_FAILURE",
  FETCH_ALL_EVALUATION_SUCCESS: "FETCH_ALL_EVALUATION_SUCCESS",

  FETCH_ONE_EVALUATION: "FETCH_ONE_EVALUATION",
  FETCH_ONE_EVALUATION_RESET: "FETCH_ONE_EVALUATION_RESET",
  FETCH_ONE_EVALUATION_FAILURE: "FETCH_ONE_EVALUATION_FAILURE",
  FETCH_ONE_EVALUATION_SUCCESS: "FETCH_ONE_EVALUATION_SUCCESS",
};
