import { LeaveStartingBalanceStateTypes, LeaveStartingBalanceActionTypes } from "./LeaveStartingBalance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: LeaveStartingBalanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const LeaveStartingBalanceReducer = (
  state: LeaveStartingBalanceStateTypes = INITIAL_STATE,
  action: any
): LeaveStartingBalanceStateTypes => {
  switch (action.type) {
    case LeaveStartingBalanceActionTypes.FETCH_ALL_LEAVE_STARTING_BALANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case LeaveStartingBalanceActionTypes.FETCH_ALL_LEAVE_STARTING_BALANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LeaveStartingBalanceActionTypes.FETCH_ALL_LEAVE_STARTING_BALANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LeaveStartingBalanceActionTypes.FETCH_ALL_LEAVE_STARTING_BALANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case LeaveStartingBalanceActionTypes.FETCH_ONE_LEAVE_STARTING_BALANCE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case LeaveStartingBalanceActionTypes.FETCH_ONE_LEAVE_STARTING_BALANCE_FAILURE:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case LeaveStartingBalanceActionTypes.FETCH_ONE_LEAVE_STARTING_BALANCE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case LeaveStartingBalanceActionTypes.FETCH_ONE_LEAVE_STARTING_BALANCE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default LeaveStartingBalanceReducer;
