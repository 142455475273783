import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { QuotationActionTypes } from "./Quotation.type";

export function* fetchAllQuotations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/quotation`);
    yield put({
      type: QuotationActionTypes.FETCH_ALL_QUOTATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: QuotationActionTypes.FETCH_ALL_QUOTATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneQuotations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/quotation/${action.payload}`
    );
    yield put({
      type: QuotationActionTypes.FETCH_ONE_QUOTATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: QuotationActionTypes.FETCH_ONE_QUOTATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllQuotations() {
  yield takeLatest(QuotationActionTypes.FETCH_ALL_QUOTATION, fetchAllQuotations);
}

export function* watcherFetchOneQuotations() {
  yield takeLatest(QuotationActionTypes.FETCH_ONE_QUOTATION, fetchOneQuotations);
}
