import { ApiCallState } from "../Utils";

export type CostSharing = {
  id: number;
  date: string;
  description: string;
  amount: number;
  user_id: number;
  staff_id: number;
};

export type CostSharingStateTypes = {
  fetchAll: ApiCallState<CostSharing[]>;
  fetchOne: ApiCallState<CostSharing | {}>;
};

export const CostSharingActionTypes = {
  FETCH_ALL_COST_SHARING: "FETCH_ALL_COST_SHARING",
  FETCH_ALL_COST_SHARING_RESET: "FETCH_ALL_COST_SHARING_RESET",
  FETCH_ALL_COST_SHARING_FAILURE: "FETCH_ALL_COST_SHARING_FAILURE",
  FETCH_ALL_COST_SHARING_SUCCESS: "FETCH_ALL_COST_SHARING_SUCCESS",

  FETCH_ONE_COST_SHARING: "FETCH_ONE_COST_SHARING",
  FETCH_ONE_COST_SHARING_RESET: "FETCH_ONE_COST_SHARING_RESET",
  FETCH_ONE_COST_SHARING_FAILURE: "FETCH_ONE_COST_SHARING_FAILURE",
  FETCH_ONE_COST_SHARING_SUCCESS: "FETCH_ONE_COST_SHARING_SUCCESS",
};
