import {
  ExternalBudgetStateTypes,
  ExternalBudgetActionTypes,
} from "./ExternalBudget.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ExternalBudgetStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ExternalBudgetReducer = (
  state: ExternalBudgetStateTypes = INITIAL_STATE,
  action: any
): ExternalBudgetStateTypes => {
  switch (action.type) {
    case ExternalBudgetActionTypes.FETCH_ALL_EXTERNAL_BUDGET:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExternalBudgetActionTypes.FETCH_ALL_EXTERNAL_BUDGET_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ExternalBudgetActionTypes.FETCH_ALL_EXTERNAL_BUDGET_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExternalBudgetActionTypes.FETCH_ALL_EXTERNAL_BUDGET_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ExternalBudgetActionTypes.FETCH_ONE_EXTERNAL_BUDGET:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExternalBudgetActionTypes.FETCH_ONE_EXTERNAL_BUDGET_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case ExternalBudgetActionTypes.FETCH_ONE_EXTERNAL_BUDGET_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExternalBudgetActionTypes.FETCH_ONE_EXTERNAL_BUDGET_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ExternalBudgetReducer;
