import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GradeActionTypes } from "./Grade.type";

export function* fetchAllGrades(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/grade`);
    yield put({
      type: GradeActionTypes.FETCH_ALL_GRADE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GradeActionTypes.FETCH_ALL_GRADE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGrades(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/grade/${action.payload}`
    );
    yield put({
      type: GradeActionTypes.FETCH_ONE_GRADE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GradeActionTypes.FETCH_ONE_GRADE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGrades() {
  yield takeLatest(GradeActionTypes.FETCH_ALL_GRADE, fetchAllGrades);
}

export function* watcherFetchOneGrades() {
  yield takeLatest(GradeActionTypes.FETCH_ONE_GRADE, fetchOneGrades);
}
