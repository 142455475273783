import { ApiCallState } from "../../Utils";
import { Lead } from "../../Lead/Lead.type";
import { ApartmentUnit } from "../../ApartmentBuilding/ApartmentUnit/ApartmentUnit.type";
import { ApartmentBuilding } from "../../ApartmentBuilding/ApartmentBuilding/ApartmentBuilding.type";
import { User } from "../../User/User.type";

export type Opportunity = {
  id: number;
  name: string;
  user?: User;
  lead?: Lead;
  apartment_building?: ApartmentBuilding;
  apartment_unit?: ApartmentUnit;
  close_date: Date;
  square_meter: number;
  price_per_square: number;
  amount: number;
  advance_percentage: number;
  advance_amount: number;
  discount_amount: number;
  price_after_discount: number;
  exchange_rate: number;
  amount_etb: number;
  probability: number;
  current_stage: string;
  lead_id: number;
  sales_id: number;
  apartment_id: number;
  createdAt: string;
  apartment_unit_id: number | undefined;
  opportunity_items: OpportunityItem[];
};

export type OpportunityItem = {
  id: number;
  opportunity_id: number;
  date: string;
  dollar_amount: number;
  exchange_rate: number;
};

export type OpportunityStateTypes = {
  fetchAll: ApiCallState<Opportunity[]>;
  fetchOne: ApiCallState<Opportunity | {}>;
};

export const OpportunityActionTypes = {
  FETCH_ALL_OPPORTUNITIES: "FETCH_ALL_OPPORTUNITIES",
  FETCH_ALL_OPPORTUNITIES_RESET: "FETCH_ALL_OPPORTUNITIES_RESET",
  FETCH_ALL_OPPORTUNITIES_FAILURE: "FETCH_ALL_OPPORTUNITIES_FAILURE",
  FETCH_ALL_OPPORTUNITIES_SUCCESS: "FETCH_ALL_OPPORTUNITIES_SUCCESS",

  FETCH_ONE_OPPORTUNITY: "FETCH_ONE_OPPORTUNITY",
  FETCH_ONE_OPPORTUNITY_RESET: "FETCH_ONE_OPPORTUNITY_RESET",
  FETCH_ONE_OPPORTUNITY_FAILURE: "FETCH_ONE_OPPORTUNITY_FAILURE",
  FETCH_ONE_OPPORTUNITY_SUCCESS: "FETCH_ONE_OPPORTUNITY_SUCCESS",
};
