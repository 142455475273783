import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CandidatesResultActionTypes } from "./CandidatesResult.type";

export function* fetchAllCandidatesResults(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/candidates-result`);
    yield put({
      type: CandidatesResultActionTypes.FETCH_ALL_CANDIDATES_RESULT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CandidatesResultActionTypes.FETCH_ALL_CANDIDATES_RESULT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCandidatesResults(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/candidates-result/${action.payload}`
    );
    yield put({
      type: CandidatesResultActionTypes.FETCH_ONE_CANDIDATES_RESULT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CandidatesResultActionTypes.FETCH_ONE_CANDIDATES_RESULT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCandidatesResults() {
  yield takeLatest(CandidatesResultActionTypes.FETCH_ALL_CANDIDATES_RESULT, fetchAllCandidatesResults);
}

export function* watcherFetchOneCandidatesResults() {
  yield takeLatest(CandidatesResultActionTypes.FETCH_ONE_CANDIDATES_RESULT, fetchOneCandidatesResults);
}
