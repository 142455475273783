import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type StaffEducation= {
  id: number;
  staff_id:number;
  education_level:string;
  field_of_study:string;
  institution:string;
  graduation_year:string;
  document:Document;
};

export type StaffEducationStateTypes = {
  fetchAll: ApiCallState<StaffEducation[]>;
  fetchOne: ApiCallState<StaffEducation | {}>;
};

export const StaffEducationActionTypes = {
  FETCH_ALL_STAFF_EDUCATION: "FETCH_ALL_STAFF_EDUCATION",
  FETCH_ALL_STAFF_EDUCATION_RESET: "FETCH_ALL_STAFF_EDUCATION_RESET",
  FETCH_ALL_STAFF_EDUCATION_FAILURE: "FETCH_ALL_STAFF_EDUCATION_FAILURE",
  FETCH_ALL_STAFF_EDUCATION_SUCCESS: "FETCH_ALL_STAFF_EDUCATION_SUCCESS",

  FETCH_ONE_STAFF_EDUCATION: "FETCH_ONE_STAFF_EDUCATION",
  FETCH_ONE_STAFF_EDUCATION_RESET: "FETCH_ONE_STAFF_EDUCATION_RESET",
  FETCH_ONE_STAFF_EDUCATION_FAILURE: "FETCH_ONE_STAFF_EDUCATION_FAILURE",
  FETCH_ONE_STAFF_EDUCATION_SUCCESS: "FETCH_ONE_STAFF_EDUCATION_SUCCESS",
};
