import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BonusActionTypes } from "./Bonus.type";
import { authHeader } from "../../utilities/utilities";

export function* fetchAllBonuses(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/bonus?type=${action?.payload?.type}`,
      authHeader()
    );
    yield put({
      type: BonusActionTypes.FETCH_ALL_BONUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BonusActionTypes.FETCH_ALL_BONUS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBonuses() {
  yield takeLatest(BonusActionTypes.FETCH_ALL_BONUS, fetchAllBonuses);
}
