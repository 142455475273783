import { ApiCallState } from "../Utils";

export type StaffBio = {
  id: number;
  user_id: number;
  staff_id: number;
  department_id: string;

  first_name: string;
  middle_name: string;
  last_name: string;

  region: string;
  city: string;
  sub_city: string;
  house_number: string;

  telephone_number: string;
  phone_number: string;
  section: string;

  birth_date: string;
  marital_status: string;

  mother_full_name: string;
  brother_full_name: string;
  sister_full_name: string;
  spouse_first_name: string;
  uncle_full_name: string;
  aunt_full_name: string;

  gender: string;
  how_you_get_your_citizinship: string;
  woreda: string;

  spouse_father_name: string;
  spouse_grandfather_name: string;
  spouse_tin: string;
  spouse_birth_date: string;

  living_father_name: string;
  living_father_grandfather_name: string;
  living_father_great_grandfather_name: string;
  living_mother_name: string;
  living_mother_grandfather_name: string;
  living_mother_great_grandfather_name: string;

  how_parents_supported: string;
  supported_other_than_money: string;

  nature_of_recruitment: string;
  job_description: string;
  recruitment_date: string;
  gross_wage: number;

  religion: string;
  living_status: string;
  hobby: string;

  employee_bio_children: EmployeeBioChildren[];
  employee_bio_educations: EmployeeBioEducation[];
  employee_bio_experiences: EmployeeBioExperience[];
};

export type EmployeeBioChildren = {
  id: number;
  employee_bio_id: number;
  first_name: string;
  father_name: string;
  grandfather_name: string;
  gender: string;
  mother_name: string;
  impairment: string;
  is_adopted: string;
    date_of_birth: string;
};

export type EmployeeBioEducation = {
  id: number;
  employee_bio_id: number;

  education_type: string;
  level: string;
  end_year: string;
};

export type EmployeeBioExperience = {
  id: number;
  employee_bio_id: number;

  post: string;
  start_date: string;
  end_date: string;
  institution: string;

  company_tin: string;
  service_type: string;
  monthly_salary: string;
  reason_employement_terminated: string;
  salary_when_they_left: string;
};

export type StaffBioStateTypes = {
  fetchAll: ApiCallState<StaffBio[]>;
  fetchOne: ApiCallState<StaffBio | {}>;
};

export const StaffBioActionTypes = {
  FETCH_ALL_STAFF_BIO: "FETCH_ALL_STAFF_BIO",
  FETCH_ALL_STAFF_BIO_RESET: "FETCH_ALL_STAFF_BIO_RESET",
  FETCH_ALL_STAFF_BIO_FAILURE: "FETCH_ALL_STAFF_BIO_FAILURE",
  FETCH_ALL_STAFF_BIO_SUCCESS: "FETCH_ALL_STAFF_BIO_SUCCESS",

  FETCH_ONE_STAFF_BIO: "FETCH_ONE_STAFF_BIO",
  FETCH_ONE_STAFF_BIO_RESET: "FETCH_ONE_STAFF_BIO_RESET",
  FETCH_ONE_STAFF_BIO_FAILURE: "FETCH_ONE_STAFF_BIO_FAILURE",
  FETCH_ONE_STAFF_BIO_SUCCESS: "FETCH_ONE_STAFF_BIO_SUCCESS",
};
