import { Boq } from "../Boq/Boq.type";
import { Labour } from "../Labour/Labour.type";
import { Material } from "../Material/Material.type";
import { MaterialInventory } from "../MaterialInventory/MaterialInventory.type";
import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type LabourUsage = {
  id: number;
  start_time: string;
  end_time: string;
  total_time: number;
  date: Date;
  labour: Labour;
  project: Project;
  boq_id: number;
};

export type LabourUsageStateTypes = {
  fetchAll: ApiCallState<LabourUsage[]>;
};

export const LabourUsageActionTypes = {
  FETCH_ALL_LABOUR_USAGE: "FETCH_ALL_LABOUR_USAGE",
  FETCH_ALL_LABOUR_USAGE_RESET: "FETCH_ALL_LABOUR_USAGE_RESET",
  FETCH_ALL_LABOUR_USAGE_FAILURE: "FETCH_ALL_LABOUR_USAGE_FAILURE",
  FETCH_ALL_LABOUR_USAGE_SUCCESS: "FETCH_ALL_LABOUR_USAGE_SUCCESS",
};
