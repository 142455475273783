import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Leave= {
  id: number;
  date: string,
  leave_type: string,
  reason: string,
  leave_duration:string,
  date_of_resumption:string,
  no_of_days:number,
  city:string,
  subcity:string,
  woreda:string,
  house_no:string,
  specific_location:string,
  phone_number:string,
  user_id:number,
  staff_id:number,
  prepared_by:number,
  approved_by:number,
  staff:Staff;
  leave_statuses: LeaveStatus[];
};

export type LeaveStatus = {
  leave_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type LeaveStateTypes = {
  fetchAll: ApiCallState<Leave[]>;
  fetchAllFromTo: ApiCallState<Leave[]>;
  fetchOne: ApiCallState<Leave | {}>;
};

export const LeaveActionTypes = {
  FETCH_ALL_LEAVE: "FETCH_ALL_LEAVE",
  FETCH_ALL_LEAVE_RESET: "FETCH_ALL_LEAVE_RESET",
  FETCH_ALL_LEAVE_FAILURE: "FETCH_ALL_LEAVE_FAILURE",
  FETCH_ALL_LEAVE_SUCCESS: "FETCH_ALL_LEAVE_SUCCESS",

  FETCH_ALL_FROM_TO_LEAVE: "FETCH_ALL_FROM_TO_LEAVE",
  FETCH_ALL_FROM_TO_LEAVE_RESET: "FETCH_ALL_FROM_TO_LEAVE_RESET",
  FETCH_ALL_FROM_TO_LEAVE_FAILURE: "FETCH_ALL_FROM_TO_LEAVE_FAILURE",
  FETCH_ALL_FROM_TO_LEAVE_SUCCESS: "FETCH_ALL_FROM_TO_LEAVE_SUCCESS",


  FETCH_ONE_LEAVE: "FETCH_ONE_LEAVE",
  FETCH_ONE_LEAVE_RESET: "FETCH_ONE_LEAVE_RESET",
  FETCH_ONE_LEAVE_FAILURE: "FETCH_ONE_LEAVE_FAILURE",
  FETCH_ONE_LEAVE_SUCCESS: "FETCH_ONE_LEAVE_SUCCESS",
};
