import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SalesReportActionTypes } from "./SalesReport.type";

export function* fetchAllSalesReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sales-report`);
    yield put({
      type: SalesReportActionTypes.FETCH_ALL_SALES_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SalesReportActionTypes.FETCH_ALL_SALES_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSalesReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sales-report/${action.payload}`
    );
    yield put({
      type: SalesReportActionTypes.FETCH_ONE_SALES_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SalesReportActionTypes.FETCH_ONE_SALES_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSalesReports() {
  yield takeLatest(SalesReportActionTypes.FETCH_ALL_SALES_REPORT, fetchAllSalesReports);
}

export function* watcherFetchOneSalesReports() {
  yield takeLatest(SalesReportActionTypes.FETCH_ONE_SALES_REPORT, fetchOneSalesReports);
}
