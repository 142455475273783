import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialSubCategoryActionTypes } from "./MaterialSubCategory.type";

export function* fetchAllMaterialSubCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-sub-category?${query}`
    );
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_ALL_MATERIAL_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_ALL_MATERIAL_SUB_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllFormMaterialSubCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-sub-category?${query}`
    );
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMaterialSubCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-sub-category?${query}`
    );
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_PAGED_MATERIAL_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_PAGED_MATERIAL_SUB_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialSubCategorys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-sub-category/${action.payload}`
    );
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_ONE_MATERIAL_SUB_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialSubCategoryActionTypes.FETCH_ONE_MATERIAL_SUB_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialSubCategorys() {
  yield takeLatest(
    MaterialSubCategoryActionTypes.FETCH_ALL_MATERIAL_SUB_CATEGORY,
    fetchAllMaterialSubCategorys
  );
}

export function* watcherFetchAllFormMaterialSubCategorys() {
  yield takeLatest(
    MaterialSubCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY,
    fetchAllFormMaterialSubCategorys
  );
}

export function* watcherFetchPagedMaterialSubCategorys() {
  yield takeLatest(
    MaterialSubCategoryActionTypes.FETCH_PAGED_MATERIAL_SUB_CATEGORY,
    fetchPagedMaterialSubCategorys
  );
}

export function* watcherFetchOneMaterialSubCategorys() {
  yield takeLatest(
    MaterialSubCategoryActionTypes.FETCH_ONE_MATERIAL_SUB_CATEGORY,
    fetchOneMaterialSubCategorys
  );
}
