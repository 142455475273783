import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MarketingSalesScheduleActionTypes } from "./MarketingSalesSchedule.type";
import { formatQuery } from "../Utils";

export function* fetchAllMarketingSalesSchedules(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/marketing-sales-schedule?${query}`
    );
    yield put({
      type: MarketingSalesScheduleActionTypes.FETCH_ALL_MARKETING_SALES_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MarketingSalesScheduleActionTypes.FETCH_ALL_MARKETING_SALES_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMarketingSalesSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/marketing-sales-schedule/${action.payload}`
    );
    yield put({
      type: MarketingSalesScheduleActionTypes.FETCH_ONE_MARKETING_SALES_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MarketingSalesScheduleActionTypes.FETCH_ONE_MARKETING_SALES_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMarketingSalesSchedules() {
  yield takeLatest(
    MarketingSalesScheduleActionTypes.FETCH_ALL_MARKETING_SALES_SCHEDULE,
    fetchAllMarketingSalesSchedules
  );
}

export function* watcherFetchOneMarketingSalesSchedules() {
  yield takeLatest(
    MarketingSalesScheduleActionTypes.FETCH_ONE_MARKETING_SALES_SCHEDULE,
    fetchOneMarketingSalesSchedules
  );
}
