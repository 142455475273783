import { BankAccount } from "../BankAccount/BankAccount.type";
import { Project } from "../Project/Project.type";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PaymentRequest = {
  id: number;
  date: string;
  supplier_id: number;
  project_id:number;
  bank_account_id:number;
  payment_method:string;
  transaction_no:string;
  csv_no:number;
  payment_catagory: string;
  purchase_requisition_id: number;
  proforma_id:number;
  user_id: number;
  checked_by_id: number;
  verified_by_id: number;
  approved_by_id: number;
  money_received_by: string;
  status: string;
  with_hold: boolean;
  tax: number;
  user: User;
  checked_by: User;
  verified_by: User;
  approved_by: User;
  purchase_requisition: PurchaseRequisition;
  project:Project;
  supplier: Supplier;
  bank_account:BankAccount;
  payment_request_items: PaymentRequestItem[];
};

export type PaymentRequestItem = {
  id: number;
  payment_request_id: number;
  description: string;
  unit: string;
  quantity: number;
  unit_price: number;
};
export type PaymentRequestStateTypes = {
  fetchAll: ApiCallState<PaymentRequest[]>;
  fetchOne: ApiCallState<PaymentRequest | {}>;
};

export const PaymentRequestActionTypes = {
  FETCH_ALL_PAYMENT_REQUEST: "FETCH_ALL_PAYMENT_REQUEST",
  FETCH_ALL_PAYMENT_REQUEST_RESET: "FETCH_ALL_PAYMENT_REQUEST_RESET",
  FETCH_ALL_PAYMENT_REQUEST_FAILURE: "FETCH_ALL_PAYMENT_REQUEST_FAILURE",
  FETCH_ALL_PAYMENT_REQUEST_SUCCESS: "FETCH_ALL_PAYMENT_REQUEST_SUCCESS",

  FETCH_ONE_PAYMENT_REQUEST: "FETCH_ONE_PAYMENT_REQUEST",
  FETCH_ONE_PAYMENT_REQUEST_RESET: "FETCH_ONE_PAYMENT_REQUEST_RESET",
  FETCH_ONE_PAYMENT_REQUEST_FAILURE: "FETCH_ONE_PAYMENT_REQUEST_FAILURE",
  FETCH_ONE_PAYMENT_REQUEST_SUCCESS: "FETCH_ONE_PAYMENT_REQUEST_SUCCESS",
};
