import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type FixedAssetIn = {
  id: number;
  date: string;
  dispatching_warehouse_id: number;
  receiving_warehouse_id: number;
  project_id: number;
  driver: string;
  plate_no: string;
  reason: string;
  user_id: number;
  received_by: string;
  approved_by: string;
  employee: string;
  department: string;
  pad_ref: string;
  fixed_asset_in_project: Store;
  fixed_asset_in_dispatching_warehouse: Store;
  fixed_asset_in_receiving_warehouse: Store;
  fixed_asset_in_remarks: FixedAssetInRemark[];
  fixed_asset_in_statuses: FixedAssetInStatus[];
  status: FixedAssetInStatus[];
  fixed_asset_in_items: FixedAssetInItem[];
  fixed_asset_in_prepared_by: User;
  fixed_asset_in_approved_by: User;
  fixed_asset_in_received_by: User;

  updatedAt: string;
  is_void: boolean;
};

export type FixedAssetInReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: string;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type FixedAssetInRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type FixedAssetInStatus = {
  fixed_asset_in_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type FixedAssetInItem = {
  id?: number;
  key: number;
  material_id?: number;
  fixed_asset_out_id?: number;
  serial_number: string;
  unit: string;
  unit_price: number;
  quantity: number;
  remark: string;
  material?: Material;
};

export type FixedAssetInStateTypes = {
  fetchAll: ApiCallState<FixedAssetIn[]>;
  fetchOne: ApiCallState<FixedAssetIn | {}>;
  fetchPaged: ApiCallState<PagedData<FixedAssetIn[]>>;
  fetchReport: ApiCallState<FixedAssetInReport[]>;
};

export const FixedAssetInActionTypes = {
  FETCH_ALL_FIXED_ASSET_IN: "FETCH_ALL_FIXED_ASSET_IN",
  FETCH_ALL_FIXED_ASSET_IN_RESET: "FETCH_ALL_FIXED_ASSET_IN_RESET",
  FETCH_ALL_FIXED_ASSET_IN_FAILURE: "FETCH_ALL_FIXED_ASSET_IN_FAILURE",
  FETCH_ALL_FIXED_ASSET_IN_SUCCESS: "FETCH_ALL_FIXED_ASSET_IN_SUCCESS",

  FETCH_PAGED_FIXED_ASSET_IN: "FETCH_PAGED_FIXED_ASSET_IN",
  FETCH_PAGED_FIXED_ASSET_IN_RESET: "FETCH_PAGED_FIXED_ASSET_IN_RESET",
  FETCH_PAGED_FIXED_ASSET_IN_FAILURE: "FETCH_PAGED_FIXED_ASSET_IN_FAILURE",
  FETCH_PAGED_FIXED_ASSET_IN_SUCCESS: "FETCH_PAGED_FIXED_ASSET_IN_SUCCESS",

  FETCH_ONE_FIXED_ASSET_IN: "FETCH_ONE_FIXED_ASSET_IN",
  FETCH_ONE_FIXED_ASSET_IN_RESET: "FETCH_ONE_FIXED_ASSET_IN_RESET",
  FETCH_ONE_FIXED_ASSET_IN_FAILURE: "FETCH_ONE_FIXED_ASSET_IN_FAILURE",
  FETCH_ONE_FIXED_ASSET_IN_SUCCESS: "FETCH_ONE_FIXED_ASSET_IN_SUCCESS",

  FETCH_REPORT_FIXED_ASSET_IN: "FETCH_REPORT_FIXED_ASSET_IN",
  FETCH_REPORT_FIXED_ASSET_IN_RESET: "FETCH_REPORT_FIXED_ASSET_IN_RESET",
  FETCH_REPORT_FIXED_ASSET_IN_FAILURE: "FETCH_REPORT_FIXED_ASSET_IN_FAILURE",
  FETCH_REPORT_FIXED_ASSET_IN_SUCCESS: "FETCH_REPORT_FIXED_ASSET_IN_SUCCESS",
};
