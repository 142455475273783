/**
 * Routes
 */
export const RouteConstants = {
  /**
   * Dashboard
   */
  DASHBOARD: "/",
  DASHBOARDS: "/dashboard",
  PROJECTS: "/project",
  ENGINEERING: "/engineering",
  MONTHLY_REPORT: "/monthly-report",
  PROJECT_LIST: "/project/:type",
  REGISTER_PROJECT: "/register-project",
  PROJECT_SUMMARY: "/project/:id/:tab",
  SUMMARY: "/summary",
  REPORT: "/report",
  DOCUMENT: "/document",
  ADMINISTRATION: "/administration",
  EQUIPMENT: "/fixed-asset",
  INVENTORY: "/inventory",
  HUMAN_RESOURCE_INDEX: "/hr/:id",
  HUMAN_RESOURCE: "/hr",
  EMPLOYEE: "/employee/:id/:tab",
  PROCUREMENT: "/procurement",
  FIXED_ASSET: "/fixed-asset",
  SUB_CONTRACT_LIST: "/sub-contract-list",
  REGISTER_SUB_CONTRACT: "/register-sub-contract",
  FINANCE: "/finance",
  CONTRACTS: "/contracts",
  LOGIN: "/login",
  PRE_CONTRACT: "/pre-contract",
  REGISTER_PRE_CONTRACT: "/register",
  PRE_CONTRACT_SUMMARY: "/pre-contract/summary",
  PAYMENT: "/payment",
  PAYMENT_SUMMARY: "/payment/:id/:tab",
  USER_MANAGEMENT: "/user-management",
  TASK: "/task",
  IOLetter: "/io-letter",

  STANDARDS: "/standard",

  RESET: "/reset",
  GOOGLE_AUTH_FORM: "/google-auth/:userId",
  EMAIL_CONFIRMATION: "/confirmation",
  CHECKER: "/checker/:hash",
  GOOGLE_AUTH: "/google/auth/:token/:userData",
  CONSULTANT_SUMMARY:
    "/project/:project_id/contract/:contract_id/consultant/:tab/:tab1?/:tab2?",
  CONTRACTOR_PAYMENT: "/contractor-payment/:id/:tab",

  WEEKLY_REPORT_ADD:
    "/project/:project_id/contract/:contract_id/:access_type/:tab/:tab1?/:tab2?/add",

  WEEKLY_REPORT_EDIT:
    "/project/:project_id/contract/:contract_id/:access_type/:tab/:tab1?/:tab2?/:id",

  BOQ_REVISION:
    "/project/:project_id/contract/:contract_id/:access_type/boq-revision/add/:id?",

  BOQ_REVISION_LIST:
    "/project/:project_id/contract/:contract_id/:access_type/boq-revision",

  CONTRACTOR_SUMMARY:
    "/project/:project_id/contract/:contract_id/contractor/:tab/:tab1?/:tab2?",
  CLIENT_SUMMARY: "/client-summary/:id/:tab",

  CONTRACT_SUMMARY: "/contract-summary",
  CONTRACT_BOQ: "/contract-boq",
  VARIATION: "/variation",
  WORK_ITEM: "/work-item",
  MASTER_SCHEDULE: "/master-schedule",
  MASTER_SCHEDULE_SUMMARY: "/master-schedule/:id/:tab",
  PLANS: "/plans",
  REPORTS: "/reports",
  CASH_FLOW: "/cash-flow",
  PRICE_ESCALATION: "/price-escalation",
  EXECUTED_SUMMARY: "/executed-summary",
  MEASUREMENT_CERTIFICATE: "/measurement-certificate",
  PAYMENT_CERTIFICATE: "/payment-certificate",
  REQUESTED_PAYMENTS: "/requested-payments",
  ANALYTICS: "/analytics",
  EDIT: "/edit",
  // CONTRACT: "/HR/Contract",
  // ID: "/HR/ID",

  EMPLOYEE_APPLICATION: "/welcome/:jobId",

  EMPLOYEE_APPLICATION2: "/apply/:jobId",

  EMPLOYEE_APPLICATION_SUCCESS: "/application/success",

  /**
   * Error
   */
  INTERNAL_SERVER_ERROR: "500",
  /**
   * Test
  //  */

  SIGN_UP: "/sign_up",
  HOME_PAGE: "/home",
  CRM: "/sales",
  CRM_OPPORTUNITY: "/sales/opportunity/:id",
  CRM_LEAD: "/sales/lead/:id",

  LEADDETAIL: "/lead/:id/:tab",

  PROJECT_CONTRACT: "/project/:project_id/contract",
  PROJECT_CONTRACT_TAB:
    "/project/:project_id/contract/:contract_id/client/:tab/:tab1?/:tab2?",
};

export const ACCOUNT_ROUTE = [
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.FINANCE,
  RouteConstants.STANDARDS,
  RouteConstants.USER_MANAGEMENT,
];
export const HR_ROUTE = [
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.EMPLOYEE,
  RouteConstants.HUMAN_RESOURCE_INDEX,
  RouteConstants.STANDARDS,
  RouteConstants.USER_MANAGEMENT,
];
export const PROCUREMENT_ROUTE = [
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.PROCUREMENT,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.EMPLOYEE,
  RouteConstants.EQUIPMENT,
  RouteConstants.STANDARDS,
  RouteConstants.USER_MANAGEMENT,
];
export const PROJECT_MANAGER_ROUTE = [
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.SUMMARY,
  RouteConstants.REGISTER_PROJECT,
  RouteConstants.SUB_CONTRACT_LIST,
  RouteConstants.REGISTER_SUB_CONTRACT,
  RouteConstants.PROJECT_LIST,
  RouteConstants.PROJECTS,
  RouteConstants.ENGINEERING,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.REPORT,
  RouteConstants.DOCUMENT,
  RouteConstants.ADMINISTRATION,
  RouteConstants.REGISTER_PRE_CONTRACT,
  RouteConstants.PRE_CONTRACT,
  RouteConstants.STANDARDS,
  RouteConstants.PROJECT_CONTRACT,
  RouteConstants.PROJECT_CONTRACT_TAB,
  RouteConstants.USER_MANAGEMENT,
  RouteConstants.MONTHLY_REPORT,
];

export const CLIENT_ROUTE = [
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.PROJECT_CONTRACT,
  RouteConstants.PROJECT_CONTRACT_TAB,
  RouteConstants.CLIENT_SUMMARY,
  RouteConstants.REGISTER_PROJECT,
  RouteConstants.SUB_CONTRACT_LIST,
  RouteConstants.REGISTER_SUB_CONTRACT,
  RouteConstants.PROJECT_LIST,
  RouteConstants.PROJECTS,
  RouteConstants.ENGINEERING,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.DOCUMENT,
  RouteConstants.ADMINISTRATION,
  RouteConstants.REGISTER_PRE_CONTRACT,
  RouteConstants.PRE_CONTRACT,
  RouteConstants.STANDARDS,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.FINANCE,
  RouteConstants.CONTRACTS,
  RouteConstants.PROCUREMENT,
  RouteConstants.CONTRACTOR_PAYMENT,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.HUMAN_RESOURCE_INDEX,
  RouteConstants.EMPLOYEE,
  RouteConstants.MASTER_SCHEDULE_SUMMARY,
  RouteConstants.EQUIPMENT,
  RouteConstants.CRM,
  RouteConstants.LEADDETAIL,
  RouteConstants.CRM_OPPORTUNITY,
  RouteConstants.CRM_LEAD,
  RouteConstants.USER_MANAGEMENT,
  RouteConstants.TASK,
  RouteConstants.IOLetter,
  RouteConstants.BOQ_REVISION,
  RouteConstants.WEEKLY_REPORT_ADD,
  RouteConstants.WEEKLY_REPORT_EDIT,
  RouteConstants.MONTHLY_REPORT,
];

export const CONSULTANT_ROUTE = [
  RouteConstants.PROJECT_LIST,
  RouteConstants.PROJECTS,
  RouteConstants.ENGINEERING,
  RouteConstants.PAYMENT,
  RouteConstants.PAYMENT_SUMMARY,
  RouteConstants.CONSULTANT_SUMMARY,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.EMPLOYEE,
  RouteConstants.MASTER_SCHEDULE_SUMMARY,
  RouteConstants.PROJECT_CONTRACT,
  RouteConstants.PROJECT_CONTRACT_TAB,
  RouteConstants.MONTHLY_REPORT,
  RouteConstants.USER_MANAGEMENT,
  RouteConstants.BOQ_REVISION,
  RouteConstants.WEEKLY_REPORT_ADD,
  RouteConstants.WEEKLY_REPORT_EDIT,
];

export const CONTRACTOR_ROUTE = [
  RouteConstants.PROJECTS,
  RouteConstants.ENGINEERING,
  RouteConstants.PROJECT_LIST,
  RouteConstants.CONTRACTOR_SUMMARY,
  RouteConstants.REGISTER_PROJECT,
  RouteConstants.SUB_CONTRACT_LIST,
  RouteConstants.REGISTER_SUB_CONTRACT,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.REPORT,
  RouteConstants.DOCUMENT,
  RouteConstants.ADMINISTRATION,
  RouteConstants.MONTHLY_REPORT,
  RouteConstants.REGISTER_PRE_CONTRACT,
  RouteConstants.PRE_CONTRACT,
  RouteConstants.STANDARDS,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.EQUIPMENT,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.HUMAN_RESOURCE_INDEX,
  RouteConstants.EMPLOYEE,
  RouteConstants.CONTRACTOR_PAYMENT,
  RouteConstants.MASTER_SCHEDULE_SUMMARY,
  RouteConstants.PROJECT_CONTRACT,
  RouteConstants.PROJECT_CONTRACT_TAB,

  RouteConstants.USER_MANAGEMENT,
  RouteConstants.BOQ_REVISION,
  RouteConstants.WEEKLY_REPORT_ADD,
  RouteConstants.WEEKLY_REPORT_EDIT,
];

export const STORE_ROUTE = [
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.EMPLOYEE,
  RouteConstants.PROCUREMENT,
  RouteConstants.EQUIPMENT,

  RouteConstants.USER_MANAGEMENT,
];

export const PROJECT_ROUTE = [
  RouteConstants.PROJECTS,
  RouteConstants.ENGINEERING,
  RouteConstants.PROJECT_LIST,
  RouteConstants.PROJECT_CONTRACT,
  RouteConstants.PROJECT_CONTRACT_TAB,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.EMPLOYEE,
  RouteConstants.PROCUREMENT,
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.CLIENT_SUMMARY,

  RouteConstants.USER_MANAGEMENT,
];

export const FINANCE_ROUTE = [
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.EMPLOYEE,
  RouteConstants.PROCUREMENT,
  RouteConstants.FINANCE,
];

export const PURCHASER_ROUTE = [
  RouteConstants.PROCUREMENT,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.EMPLOYEE,
];

export const CRM_ROUTE = [
  RouteConstants.CRM,
  RouteConstants.CRM_LEAD,
  RouteConstants.CRM_OPPORTUNITY,
  RouteConstants.LEADDETAIL,

  RouteConstants.USER_MANAGEMENT,
];
