import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { WalkInActionTypes } from "./WalkIn.type";

export function* fetchAllWalkIns(action: any): any {
  try {
 let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/lead/walk-in/?${query}`);
    yield put({
      type: WalkInActionTypes.FETCH_ALL_WALK_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WalkInActionTypes.FETCH_ALL_WALK_IN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWalkIn(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/lead/walk-in/${action.payload}`
    );
    yield put({
      type: WalkInActionTypes.FETCH_ONE_WALK_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WalkInActionTypes.FETCH_ONE_WALK_IN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWalkIns() {
  yield takeLatest(WalkInActionTypes.FETCH_ALL_WALK_IN, fetchAllWalkIns);
}

export function* watcherFetchOneWalkIn() {
  yield takeLatest(WalkInActionTypes.FETCH_ONE_WALK_IN, fetchOneWalkIn);
}
