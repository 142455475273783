import { Boq } from "../Boq/Boq.type";
import { Labour } from "../Labour/Labour.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Contract = {
  labour: Labour | null;
  staff: Staff | null;
  type: string;
};

export type ContractStateTypes = Contract;

export const ContractActionTypes = {
  SET_CONTRACT: "SET_CONTRACT",
};
