import { StorePhase } from "../StorePhase/StorePhase.type";
import { ApiCallState } from "../Utils";

export type StoreBlock = {
  id: number;
  user_id: number;
  store_id: number;
  store_phase_id: number;
  block: string;
  store_phase: StorePhase;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type StoreBlockStateTypes = {
  fetchAll: ApiCallState<StoreBlock[]>;
  fetchOne: ApiCallState<StoreBlock | {}>;
};

export const StoreBlockActionTypes = {
  FETCH_ALL_STORE_BLOCK: "FETCH_ALL_STORE_BLOCK",
  FETCH_ALL_STORE_BLOCK_RESET: "FETCH_ALL_STORE_BLOCK_RESET",
  FETCH_ALL_STORE_BLOCK_FAILURE: "FETCH_ALL_STORE_BLOCK_FAILURE",
  FETCH_ALL_STORE_BLOCK_SUCCESS: "FETCH_ALL_STORE_BLOCK_SUCCESS",

  FETCH_ONE_STORE_BLOCK: "FETCH_ONE_STORE_BLOCK",
  FETCH_ONE_STORE_BLOCK_RESET: "FETCH_ONE_STORE_BLOCK_RESET",
  FETCH_ONE_STORE_BLOCK_FAILURE: "FETCH_ONE_STORE_BLOCK_FAILURE",
  FETCH_ONE_STORE_BLOCK_SUCCESS: "FETCH_ONE_STORE_BLOCK_SUCCESS",
};
