import { ApiCallState } from "../Utils";

export type SummaryResultForSkilledCandidate = {
  id: number;
  date: string,
  vacancy_no: string,
  position: string,
  user_id: number,
  summary_result_for_skilled_candidate_items: SummaryResultForSkilledCandidateItem[]
};

export type SummaryResultForSkilledCandidateItem = {
  id: number;
  summary_for_skilled_candidate_id: number,
  name_of_interviewee: string,
  qualification: string,
  practical_test: string,
  written_test: string,
  total: string,
  average_result: string,
  rank: string,
  remark: string,
  user_id: number,
};

export type SummaryResultForSkilledCandidateStateTypes = {
  fetchAll: ApiCallState<SummaryResultForSkilledCandidate[]>;
  fetchOne: ApiCallState<SummaryResultForSkilledCandidate | {}>;
};

export const SummaryResultForSkilledCandidateActionTypes = {
  FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE: "FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE",
  FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_RESET: "FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_RESET",
  FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE: "FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE",
  FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS: "FETCH_ALL_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS",

  FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE: "FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE",
  FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_RESET: "FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_RESET",
  FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE: "FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_FAILURE",
  FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS: "FETCH_ONE_SUMMARY_RESULT_FOR_SKILLED_CANDIDATE_SUCCESS",
};
