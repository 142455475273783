import {ForecastStateTypes,ForecastActionTypes } from "./Forecast.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ForecastStateTypes = {
  fetchMasterSchedule: resetApiCallState([]),
  fetchMasterScheduleDetail: resetApiCallState([]),
};

const ForecastReducer = (
  state: ForecastStateTypes = INITIAL_STATE,
  action: any
): ForecastStateTypes => {
  switch (action.type) {
    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_FORECAST:
      return {
        ...state,
        fetchMasterSchedule: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_FORECAST_RESET:
      return {
        ...state,
        fetchMasterSchedule: resetApiCallState([]),
      };
    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_FORECAST_FAILURE:
      return {
        ...state,
        fetchMasterSchedule: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_FORECAST_SUCCESS:
      return {
        ...state,
        fetchMasterSchedule: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    

    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_DETAILS_FORECAST:
      return {
        ...state,
        fetchMasterScheduleDetail: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_RESET:
      return {
        ...state,
        fetchMasterScheduleDetail: resetApiCallState([]),
      };
    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_FAILURE:
      return {
        ...state,
        fetchMasterScheduleDetail: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ForecastActionTypes.FETCH_MASTER_SCHEDULE_DETAILS_FORECAST_SUCCESS:
      return {
        ...state,
        fetchMasterScheduleDetail: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ForecastReducer;
