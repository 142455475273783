import { ApiCallState } from "../Utils";

export type HRPolicy = {
  id: number;
  policy_type: string;
  leave_policy: LeavePolicy;
  overtime_policy: OvertimePolicy;
  allowance_policy: AllowancePolicy;
  health_benefit_policy: HealthBenefitPolicy;
  description: string;
};

export type LeavePolicy = {
  id: number;
  type: string;
  days: number;
};

export type OvertimePolicy = {
  id: number;
  over_time_hr: number;
};
export type AllowancePolicy = {
  id: number;
  name: string;
  type: string;
  percentage: number;
  amount: number;
  is_default: number;
};

export type HealthBenefitPolicy = {
  id: number;
  amount_per_year: number;
  description: string;
};

export type HRPolicyStateTypes = {
  fetchAll: ApiCallState<HRPolicy[]>;
  fetchLeaveReport: ApiCallState<LeaveReport | {}>
};

export type LeaveReport = {
  data: {
    type: string;
    typeTotalDays: number;
    balance: number;
  }[],
  totalLeaves: number,
  balance: number
}

export const HRPolicyActionTypes = {
  FETCH_ALL_HR_POLICY: "FETCH_ALL_HR_POLICY",
  FETCH_ALL_HR_POLICY_RESET: "FETCH_ALL_HR_POLICY_RESET",
  FETCH_ALL_HR_POLICY_FAILURE: "FETCH_ALL_HR_POLICY_FAILURE",
  FETCH_ALL_HR_POLICY_SUCCESS: "FETCH_ALL_HR_POLICY_SUCCESS",

  FETCH_ALL_LEAVE_REPORT: "FETCH_ALL_LEAVE_REPORT",
  FETCH_ALL_LEAVE_REPORT_RESET: "FETCH_ALL_LEAVE_REPORT_RESET",
  FETCH_ALL_LEAVE_REPORT_FAILURE: "FETCH_ALL_LEAVE_REPORT_FAILURE",
  FETCH_ALL_LEAVE_REPORT_SUCCESS: "FETCH_ALL_LEAVE_REPORT_SUCCESS",
};
