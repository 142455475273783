import { Department } from "../Department/Department.type";
import { Project, Store } from "../Project/Project.type";
import { Staff } from "../Staff/Staff.type";
import { Supplier } from "../Supplier/Supplier.type";
import { ApiCallState } from "../Utils";

export const EmployeeHistoryActions = {
  FETCH_EMPLOYEE_HISTORY: "FETCH_EMPLOYEE_HISTORY",
  FETCH_EMPLOYEE_HISTORY_SUCCESS: "FETCH_EMPLOYEE_HISTORY_SUCCESS",
  FETCH_EMPLOYEE_HISTORY_ERROR: "FETCH_EMPLOYEE_HISTORY_ERROR",
};

export type EmployeeHistory = {
  id: number;
  staff_id: number;
  staff: Staff;
  current_department: Department;
  previous_department: Department;
  allowance: number;
  overtime_salary: number;
  salary: number;
  role: string;
  previous_role: string;
  previous_allowance: number;
  previous_salary: number;
  previous_overtime_salary: number;
};

export type EmployeeHistoryStateTypes = {
  fetchAll: ApiCallState<EmployeeHistory[]>;
};
