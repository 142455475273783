import { EvaluationStateTypes, EvaluationActionTypes } from "./Evaluation.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EvaluationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const EvaluationReducer = (
  state: EvaluationStateTypes = INITIAL_STATE,
  action: any
): EvaluationStateTypes => {
  switch (action.type) {
    case EvaluationActionTypes.FETCH_ALL_EVALUATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EvaluationActionTypes.FETCH_ALL_EVALUATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EvaluationActionTypes.FETCH_ALL_EVALUATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EvaluationActionTypes.FETCH_ALL_EVALUATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EvaluationActionTypes.FETCH_ONE_EVALUATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EvaluationActionTypes.FETCH_ONE_EVALUATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EvaluationActionTypes.FETCH_ONE_EVALUATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EvaluationActionTypes.FETCH_ONE_EVALUATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EvaluationReducer;
