import { InitPagedData } from "./../Utils";
import { FuelIssueStateTypes, FuelIssueActionTypes } from "./FuelIssue.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FuelIssueStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
  fetchReport: resetApiCallState([]),
};

const FuelIssueReducer = (
  state: FuelIssueStateTypes = INITIAL_STATE,
  action: any
): FuelIssueStateTypes => {
  switch (action.type) {
    case FuelIssueActionTypes.FETCH_ALL_FUEL_ISSUE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelIssueActionTypes.FETCH_ALL_FUEL_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FuelIssueActionTypes.FETCH_ALL_FUEL_ISSUE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelIssueActionTypes.FETCH_ALL_FUEL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FuelIssueActionTypes.FETCH_REPORT_FUEL_ISSUE:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelIssueActionTypes.FETCH_REPORT_FUEL_ISSUE_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case FuelIssueActionTypes.FETCH_REPORT_FUEL_ISSUE_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelIssueActionTypes.FETCH_REPORT_FUEL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FuelIssueActionTypes.FETCH_PAGED_FUEL_ISSUE:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelIssueActionTypes.FETCH_PAGED_FUEL_ISSUE_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case FuelIssueActionTypes.FETCH_PAGED_FUEL_ISSUE_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelIssueActionTypes.FETCH_PAGED_FUEL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FuelIssueActionTypes.FETCH_ONE_FUEL_ISSUE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelIssueActionTypes.FETCH_ONE_FUEL_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FuelIssueActionTypes.FETCH_ONE_FUEL_ISSUE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelIssueActionTypes.FETCH_ONE_FUEL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FuelIssueReducer;
