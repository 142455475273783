import {
  AllowancePolicyStateTypes,
  AllowancePolicyActionTypes,
} from "./AllowancePolicy.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AllowancePolicyStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AllowancePolicyReducer = (
  state: AllowancePolicyStateTypes = INITIAL_STATE,
  action: any
): AllowancePolicyStateTypes => {
  switch (action.type) {
    case AllowancePolicyActionTypes.FETCH_ALL_ALLOWANCE_POLICY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllowancePolicyActionTypes.FETCH_ALL_ALLOWANCE_POLICY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllowancePolicyActionTypes.FETCH_ALL_ALLOWANCE_POLICY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllowancePolicyActionTypes.FETCH_ALL_ALLOWANCE_POLICY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AllowancePolicyActionTypes.FETCH_ONE_ALLOWANCE_POLICY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AllowancePolicyActionTypes.FETCH_ONE_ALLOWANCE_POLICY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AllowancePolicyActionTypes.FETCH_ONE_ALLOWANCE_POLICY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AllowancePolicyActionTypes.FETCH_ONE_ALLOWANCE_POLICY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AllowancePolicyReducer;
