import { ApiCallState } from "../Utils";

export type AreaProgress = {
  id?: number;
  key: number;
  month: string;
  amount: number;
  project_id: number;
  contract_id: number;
};

export type AreaProgressStateTypes = {
  fetchAll: ApiCallState<AreaProgress[]>;
  fetchOne: ApiCallState<AreaProgress | {}>;
};

export const AreaProgressActionTypes = {
  FETCH_ALL_AREA_PROGRESS: "FETCH_ALL_AREA_PROGRESS",
  FETCH_ALL_AREA_PROGRESS_RESET: "FETCH_ALL_AREA_PROGRESS_RESET",
  FETCH_ALL_AREA_PROGRESS_FAILURE: "FETCH_ALL_AREA_PROGRESS_FAILURE",
  FETCH_ALL_AREA_PROGRESS_SUCCESS: "FETCH_ALL_AREA_PROGRESS_SUCCESS",

  FETCH_ONE_AREA_PROGRESS: "FETCH_ONE_AREA_PROGRESS",
  FETCH_ONE_AREA_PROGRESS_RESET: "FETCH_ONE_AREA_PROGRESS_RESET",
  FETCH_ONE_AREA_PROGRESS_FAILURE: "FETCH_ONE_AREA_PROGRESS_FAILURE",
  FETCH_ONE_AREA_PROGRESS_SUCCESS: "FETCH_ONE_AREA_PROGRESS_SUCCESS",
};
