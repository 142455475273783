import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CommissionSettingActionTypes } from "./CommissionSetting.type";

export function* fetchAllCommissionSettings(action: any): any {
  try {
 let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/commission-setting?${query}`);
    yield put({
      type: CommissionSettingActionTypes.FETCH_ALL_COMMISSION_SETTING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type:  CommissionSettingActionTypes.FETCH_ALL_COMMISSION_SETTING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedCommissionSettings(action: any): any {
    try {
   let query = "";
      if (action.payload) {
        const keys = Object.keys(action.payload);
        query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
      }
      const response = yield axios.get(`${API_BASE_URI}/commission-setting?${query}`);
      yield put({
        type:  CommissionSettingActionTypes.FETCH_PAGED_COMMISSION_SETTING_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      yield put({
        type:  CommissionSettingActionTypes.FETCH_PAGED_COMMISSION_SETTING_FAILURE,
        payload: error,
      });
    }
  }

export function* fetchOneCommissionSettings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/commission-setting/${action.payload}`
    );
    yield put({
      type:  CommissionSettingActionTypes.FETCH_ONE_COMMISSION_SETTING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CommissionSettingActionTypes.FETCH_ONE_COMMISSION_SETTING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCommissionSettings() {
  yield takeLatest( CommissionSettingActionTypes.FETCH_ALL_COMMISSION_SETTING, fetchAllCommissionSettings);
}

export function* watcherFetchPagedCommissionSettings() {
    yield takeLatest(CommissionSettingActionTypes.FETCH_PAGED_COMMISSION_SETTING, fetchPagedCommissionSettings);
  }

export function* watcherFetchOneCommissionSettings() {
  yield takeLatest(CommissionSettingActionTypes.FETCH_ONE_COMMISSION_SETTING, fetchOneCommissionSettings);
}
