import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { ProjectContract } from "../ProjectContract/ProjectContract.type";
import { ApiCallState } from "../Utils";

export type PreMr = {
  id: number;
  date: string;
  project_contract_id: number;
  project_id: number;
  project_contract: ProjectContract;
  project: Project;
  pre_mr_items: PreMrItem[];
};

export type PreMrItem = {
  id: number;
  pre_mr_id: number;
  material_id: number;
  quantity: number;
  remark: string;
  material: Material;
};

export type PreMrStateTypes = {
  fetchAll: ApiCallState<PreMr[]>;
  fetchOne: ApiCallState<PreMr | {}>;
};

export const PreMrActionTypes = {
  FETCH_ALL_PRE_MR: "FETCH_ALL_PRE_MR",
  FETCH_ALL_PRE_MR_RESET: "FETCH_ALL_PRE_MR_RESET",
  FETCH_ALL_PRE_MR_FAILURE: "FETCH_ALL_PRE_MR_FAILURE",
  FETCH_ALL_PRE_MR_SUCCESS: "FETCH_ALL_PRE_MR_SUCCESS",

  FETCH_ONE_PRE_MR: "FETCH_ONE_PRE_MR",
  FETCH_ONE_PRE_MR_RESET: "FETCH_ONE_PRE_MR_RESET",
  FETCH_ONE_PRE_MR_FAILURE: "FETCH_ONE_PRE_MR_FAILURE",
  FETCH_ONE_PRE_MR_SUCCESS: "FETCH_ONE_PRE_MR_SUCCESS",
};
