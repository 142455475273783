import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { InterGoodTransferOutActionTypes } from "./InterGoodTransferOut.type";
import { formatQuery } from "../Utils";

export function* fetchAllInterGoodTransferOuts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inter-good-transfer-out?${formatQuery(action)}`
    );
    yield put({
      type: InterGoodTransferOutActionTypes.FETCH_ALL_INTER_GOOD_TRANSFER_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InterGoodTransferOutActionTypes.FETCH_ALL_INTER_GOOD_TRANSFER_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneInterGoodTransferOuts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inter-good-transfer-out/${action.payload}`
    );
    yield put({
      type: InterGoodTransferOutActionTypes.FETCH_ONE_INTER_GOOD_TRANSFER_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InterGoodTransferOutActionTypes.FETCH_ONE_INTER_GOOD_TRANSFER_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchInterGoodTransferOuts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inter-good-transfer-out/report?${formatQuery(action)}`
    );
    yield put({
      type: InterGoodTransferOutActionTypes.FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InterGoodTransferOutActionTypes.FETCH_REPORT_INTER_GOOD_TRANSFER_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllInterGoodTransferOuts() {
  yield takeLatest(
    InterGoodTransferOutActionTypes.FETCH_ALL_INTER_GOOD_TRANSFER_OUT,
    fetchAllInterGoodTransferOuts
  );
}

export function* watcherFetchOneInterGoodTransferOuts() {
  yield takeLatest(
    InterGoodTransferOutActionTypes.FETCH_ONE_INTER_GOOD_TRANSFER_OUT,
    fetchOneInterGoodTransferOuts
  );
}

export function* watcherFetchReportInterGoodTransferOuts() {
  yield takeLatest(
    InterGoodTransferOutActionTypes.FETCH_REPORT_INTER_GOOD_TRANSFER_OUT,
    fetchInterGoodTransferOuts
  );
}
