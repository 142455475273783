import { Boq } from "../Boq/Boq.type";
import { BoqCategory } from "../BoqCategory/BoqCategory.type";
import { ApiCallState, PagedData } from "../Utils";

export type BoqSubCategory = {
  id?: number;
  key: number;
  name: string;
  boq_category_id: number;
  boq_category: BoqCategory;

  boqs: Boq[]
};

export type BoqSubCategoryStateTypes = {
  fetchAll: ApiCallState<BoqSubCategory[]>;
  fetchOne: ApiCallState<BoqSubCategory | {}>;
  fetchPaged: ApiCallState<PagedData<BoqSubCategory[]>>;
};

export const BoqSubCategoryActionTypes = {
  FETCH_ALL_BOQ_SUB_CATEGORY: "FETCH_ALL_BOQ_SUB_CATEGORY",
  FETCH_ALL_BOQ_SUB_CATEGORY_RESET: "FETCH_ALL_BOQ_SUB_CATEGORY_RESET",
  FETCH_ALL_BOQ_SUB_CATEGORY_FAILURE: "FETCH_ALL_BOQ_SUB_CATEGORY_FAILURE",
  FETCH_ALL_BOQ_SUB_CATEGORY_SUCCESS: "FETCH_ALL_BOQ_SUB_CATEGORY_SUCCESS",

  FETCH_PAGED_BOQ_SUB_CATEGORY: "FETCH_PAGED_BOQ_SUB_CATEGORY",
  FETCH_PAGED_BOQ_SUB_CATEGORY_RESET: "FETCH_PAGED_BOQ_SUB_CATEGORY_RESET",
  FETCH_PAGED_BOQ_SUB_CATEGORY_FAILURE: "FETCH_PAGED_BOQ_SUB_CATEGORY_FAILURE",
  FETCH_PAGED_BOQ_SUB_CATEGORY_SUCCESS: "FETCH_PAGED_BOQ_SUB_CATEGORY_SUCCESS",

  FETCH_ONE_BOQ_SUB_CATEGORY: "FETCH_ONE_BOQ_SUB_CATEGORY",
  FETCH_ONE_BOQ_SUB_CATEGORY_RESET: "FETCH_ONE_BOQ_SUB_CATEGORY_RESET",
  FETCH_ONE_BOQ_SUB_CATEGORY_FAILURE: "FETCH_ONE_BOQ_SUB_CATEGORY_FAILURE",
  FETCH_ONE_BOQ_SUB_CATEGORY_SUCCESS: "FETCH_ONE_BOQ_SUB_CATEGORY_SUCCESS",
};
