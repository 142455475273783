import { ExpenseStateTypes, ExpenseActionTypes } from "./Expense.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ExpenseStateTypes = {
  fetchAll: resetApiCallState([]),
};

const ExpenseReducer = (
  state: ExpenseStateTypes = INITIAL_STATE,
  action: any
): ExpenseStateTypes => {
  switch (action.type) {
    case ExpenseActionTypes.FETCH_ALL_EXPENSE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExpenseActionTypes.FETCH_ALL_EXPENSE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ExpenseActionTypes.FETCH_ALL_EXPENSE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExpenseActionTypes.FETCH_ALL_EXPENSE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ExpenseReducer;
