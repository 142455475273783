import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ApplicantRecordActionTypes } from "./ApplicantRecord.type";

export function* fetchAllApplicantRecords(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/applicant-record`);
    yield put({
      type: ApplicantRecordActionTypes.FETCH_ALL_APPLICANT_RECORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicantRecordActionTypes.FETCH_ALL_APPLICANT_RECORD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApplicantRecords(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/applicant-record/${action.payload}`
    );
    yield put({
      type: ApplicantRecordActionTypes.FETCH_ONE_APPLICANT_RECORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicantRecordActionTypes.FETCH_ONE_APPLICANT_RECORD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApplicantRecords() {
  yield takeLatest(ApplicantRecordActionTypes.FETCH_ALL_APPLICANT_RECORD, fetchAllApplicantRecords);
}

export function* watcherFetchOneApplicantRecords() {
  yield takeLatest(ApplicantRecordActionTypes.FETCH_ONE_APPLICANT_RECORD, fetchOneApplicantRecords);
}
