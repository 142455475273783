import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { HRPolicyActionTypes } from "./HRPolicy.type";

export function* fetchAllHRPolicy(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/hr-policy`);
    yield put({
      type: HRPolicyActionTypes.FETCH_ALL_HR_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRPolicyActionTypes.FETCH_ALL_HR_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllHRPolicy() {
  yield takeLatest(HRPolicyActionTypes.FETCH_ALL_HR_POLICY, fetchAllHRPolicy);
}

export function* fetchLeaveReport(action: any): any {
  console.log({action})
  try {
    const response = yield axios.get(`${API_BASE_URI}/hr-policy/leave-report?year=${action.payload?.year}&staff_id=${action.payload?.staff_id}`);
    yield put({
      type: HRPolicyActionTypes.FETCH_ALL_LEAVE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRPolicyActionTypes.FETCH_ALL_LEAVE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchLeaveReport() {
  yield takeLatest(HRPolicyActionTypes.FETCH_ALL_LEAVE_REPORT, fetchLeaveReport);
}
