import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RenterActionTypes } from "./Renter.type";

export function* fetchAllRenters(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/renter`);
    yield put({
      type: RenterActionTypes.FETCH_ALL_RENTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RenterActionTypes.FETCH_ALL_RENTER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRenters() {
  yield takeLatest(RenterActionTypes.FETCH_ALL_RENTER, fetchAllRenters);
}
