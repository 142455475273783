import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ExamSummaryActionTypes } from "./ExamSummary.type";

export function* fetchAllExamSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/exam-summary`);
    yield put({
      type: ExamSummaryActionTypes.FETCH_ALL_EXAM_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExamSummaryActionTypes.FETCH_ALL_EXAM_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneExamSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/exam-summary/${action.payload}`
    );
    yield put({
      type: ExamSummaryActionTypes.FETCH_ONE_EXAM_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExamSummaryActionTypes.FETCH_ONE_EXAM_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllExamSummarys() {
  yield takeLatest(ExamSummaryActionTypes.FETCH_ALL_EXAM_SUMMARY, fetchAllExamSummarys);
}

export function* watcherFetchOneExamSummarys() {
  yield takeLatest(ExamSummaryActionTypes.FETCH_ONE_EXAM_SUMMARY, fetchOneExamSummarys);
}
