import { MaterialMajorCategory } from "../MaterialMajorCategory/MaterialMajorCategory.type";
import { MaterialSubCategory } from "../MaterialSubCategory/MaterialSubCategory.type";
import { ApiCallState } from "../Utils";

export const MaterialActions = {
  FETCH_MATERIAL: "FETCH_MATERIAL",
  FETCH_MATERIAL_SUCCESS: "FETCH_MATERIAL_SUCCESS",
  FETCH_MATERIAL_ERROR: "FETCH_MATERIAL_ERROR",
};

export type Material = {
  id: any;
  item_category: string;
  sub_category: string;
  description: string;
  unit: string;
  major_category_id?: number;
  material_sub_category_id?: number;
  project_ids:string;
  material_sub_category?: MaterialSubCategory;
  material_major_category?: MaterialMajorCategory;
  material_unit_prices: { unit_price: number; quantity: number }[];
};

export type MaterialStateTypes = {
  fetchAll: ApiCallState<Material[]>;
};
