import { ApiCallState } from "../Utils";

export type ExchangeRate= {
  rate: number;
};

export type ExchangeRateStateTypes = {
  fetchExchangeRate: ApiCallState<ExchangeRate | {}>;
};

export const ExchangeRateActionTypes = {
  FETCH_EXCHANGE_RATE: "FETCH_EXCHANGE_RATE",
  FETCH_EXCHANGE_RATE_RESET: "FETCH_EXCHANGE_RATE_RESET",
  FETCH_EXCHANGE_RATE_FAILURE: "FETCH_EXCHANGE_RATE_FAILURE",
  FETCH_EXCHANGE_RATE_SUCCESS: "FETCH_EXCHANGE_RATE_SUCCESS",
};
