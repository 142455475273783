import { StaffStateTypes, StaffActionTypes } from "./Staff.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StaffStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState(null),
  fetchAllDetail: resetApiCallState([]),
};

const StaffReducer = (
  state: StaffStateTypes = INITIAL_STATE,
  action: any
): StaffStateTypes => {
  switch (action.type) {
    case StaffActionTypes.FETCH_ALL_STAFF:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffActionTypes.FETCH_ALL_STAFF_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffActionTypes.FETCH_ALL_STAFF_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffActionTypes.FETCH_ALL_STAFF_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case StaffActionTypes.FETCH_ALL_DETAIL_STAFF:
      return {
        ...state,
        fetchAllDetail: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffActionTypes.FETCH_ALL_STAFF_DETAIL_RESET:
      return {
        ...state,
        fetchAllDetail: resetApiCallState([]),
      };
    case StaffActionTypes.FETCH_ALL_STAFF_DETAIL_FAILURE:
      return {
        ...state,
        fetchAllDetail: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffActionTypes.FETCH_ALL_STAFF_DETAIL_SUCCESS:
      return {
        ...state,
        fetchAllDetail: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case StaffActionTypes.FETCH_ONE_STAFF:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffActionTypes.FETCH_ONE_STAFF_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState(null),
      };
    case StaffActionTypes.FETCH_ONE_STAFF_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffActionTypes.FETCH_ONE_STAFF_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StaffReducer;
