import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FixedAssetInActionTypes } from "./FixedAssetIn.type";

export function* fetchAllFixedAssetIns(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/fixed-asset-in?${query}`);
    yield put({
      type: FixedAssetInActionTypes.FETCH_ALL_FIXED_ASSET_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetInActionTypes.FETCH_ALL_FIXED_ASSET_IN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportFixedAssetIns(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-in/report?${query}`
    );
    yield put({
      type: FixedAssetInActionTypes.FETCH_REPORT_FIXED_ASSET_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetInActionTypes.FETCH_REPORT_FIXED_ASSET_IN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedFixedAssetIns(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/fixed-asset-in?${query}`);
    yield put({
      type: FixedAssetInActionTypes.FETCH_PAGED_FIXED_ASSET_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetInActionTypes.FETCH_PAGED_FIXED_ASSET_IN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFixedAssetIns(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-in/${action.payload}`
    );
    yield put({
      type: FixedAssetInActionTypes.FETCH_ONE_FIXED_ASSET_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetInActionTypes.FETCH_ONE_FIXED_ASSET_IN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFixedAssetIns() {
  yield takeLatest(
    FixedAssetInActionTypes.FETCH_ALL_FIXED_ASSET_IN,
    fetchAllFixedAssetIns
  );
}

export function* watcherFetchReportFixedAssetIns() {
  yield takeLatest(
    FixedAssetInActionTypes.FETCH_REPORT_FIXED_ASSET_IN,
    fetchReportFixedAssetIns
  );
}

export function* watcherFetchPagedFixedAssetIns() {
  yield takeLatest(
    FixedAssetInActionTypes.FETCH_PAGED_FIXED_ASSET_IN,
    fetchPagedFixedAssetIns
  );
}

export function* watcherFetchOneFixedAssetIns() {
  yield takeLatest(
    FixedAssetInActionTypes.FETCH_ONE_FIXED_ASSET_IN,
    fetchOneFixedAssetIns
  );
}
