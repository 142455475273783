import { ApiCallState, PagedData } from "../Utils";

export type UnitBreakdown = {

  material_breakdown:MaterialBreakdown[]
  labour_breakdown:LabourBreakdown[]
  equipment_breakdown:EquipmentBreakdown[]
  contract_id: any;
  key?: number;
  activity:string
  output:number
  contingency:number
  id:any

  
};

export type MaterialBreakdown = {
  key:number
  type:string
  unit:string
  quantity:number
  rate:number
};
export type EquipmentBreakdown = {
  key:number
  type:string
  no:number
  daily_hr:number
  rental_rate:number
}
export type LabourBreakdown = {
  key:number
  type:string
  no:number
  uf:number
  daily_rate:number
  daily_cost:number
}
export type UnitBreakdownStateTypes = {
  fetchAll: ApiCallState<UnitBreakdown[]>;
  fetchOne: ApiCallState<UnitBreakdown| {}>;
  fetchPaged:ApiCallState<PagedData<UnitBreakdown[]>>
};

export const UnitBreakdownActionTypes = {
  FETCH_ALL_UNIT_BREAKDOWN: "FETCH_ALL_UNIT_BREAKDOWN",
  FETCH_ALL_UNIT_BREAKDOWN_RESET: "FETCH_ALL_UNIT_BREAKDOWN_RESET",
  FETCH_ALL_UNIT_BREAKDOWN_FAILURE: "FETCH_ALL_UNIT_BREAKDOWN_FAILURE",
  FETCH_ALL_UNIT_BREAKDOWN_SUCCESS: "FETCH_ALL_UNIT_BREAKDOWN_SUCCESS",

  FETCH_PAGED_UNIT_BREAKDOWN: "FETCH_PAGED_UNIT_BREAKDOWN",
  FETCH_PAGED_UNIT_BREAKDOWN_RESET: "FETCH_PAGED_UNIT_BREAKDOWN_RESET",
  FETCH_PAGED_UNIT_BREAKDOWN_FAILURE: "FETCH_PAGED_UNIT_BREAKDOWN_FAILURE",
  FETCH_PAGED_UNIT_BREAKDOWN_SUCCESS: "FETCH_PAGED_UNIT_BREAKDOWN_SUCCESS",

  FETCH_ONE_UNIT_BREAKDOWN: "FETCH_ONE_UNIT_BREAKDOWN",
  FETCH_ONE_UNIT_BREAKDOWN_RESET: "FETCH_ONE_UNIT_BREAKDOWN_RESET",
  FETCH_ONE_UNIT_BREAKDOWN_FAILURE: "FETCH_ONE_UNIT_BREAKDOWN_FAILURE",
  FETCH_ONE_UNIT_BREAKDOWN_SUCCESS: "FETCH_ONE_UNIT_BREAKDOWN_SUCCESS",
};
