import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OpportunityV2ActionTypes } from "./OpportunityV2.type";
import { formatQuery } from "../Utils";

export function* fetchAllOpportunityV2s(action: any): any {
  try {
    const query = formatQuery(action);
    const response = yield axios.get(`${API_BASE_URI}/opportunity-v2?${query}`);
    yield put({
      type: OpportunityV2ActionTypes.FETCH_ALL_OPPORTUNITY_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OpportunityV2ActionTypes.FETCH_ALL_OPPORTUNITY_V2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOpportunityV2s(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/opportunity-v2/${action.payload}`
    );
    yield put({
      type: OpportunityV2ActionTypes.FETCH_ONE_OPPORTUNITY_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OpportunityV2ActionTypes.FETCH_ONE_OPPORTUNITY_V2_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOpportunityV2s() {
  yield takeLatest(
    OpportunityV2ActionTypes.FETCH_ALL_OPPORTUNITY_V2,
    fetchAllOpportunityV2s
  );
}

export function* watcherFetchOneOpportunityV2s() {
  yield takeLatest(
    OpportunityV2ActionTypes.FETCH_ONE_OPPORTUNITY_V2,
    fetchOneOpportunityV2s
  );
}
