import { ApiCallState } from "../Utils";
import { EquipmentInventory } from "../EquipmentInventory/EquipmentInventory.type";
import {
  RentContract,
  RentContractItem,
} from "../RentContract/RentContract.type";
import { Attachment } from "../Attachment/Attachment.type";

export type Receivable = {
  id: number;
  contract_id: number;
  contract_item_id: number;
  equipment_inventory_id: number;

  reference_number: string;
  payment_month: string;

  working_hour: number;
  price_per_hour: number;
  gross_income: number;
  vat: number;
  total_amount: number;
  total_deduction: number;

  with_hold: number;
  with_hold_deductible: boolean;

  fuel: number;
  fuel_deductible: boolean;

  oil_and_lubricant: number;
  oil_and_lubricant_deductible: boolean;

  maintenance: number;
  maintenance_deductible: boolean;
  maintenance_description: string;

  advance: number;
  advance_deductible: boolean;

  other: number;
  other_deductible: boolean;
  other_description: string;

  retention: number;
  retention_deductible: boolean;

  is_received: boolean;

  attachments: Attachment[];

  contract: RentContract;
  contract_item: RentContractItem;
  equipment_inventory: EquipmentInventory;
};

export type ReceivableStateTypes = {
  fetchAll: ApiCallState<Receivable[]>;
  fetchOne: ApiCallState<Receivable | {}>;
};

export const ReceivableActionTypes = {
  FETCH_ALL_RECEIVABLE: "FETCH_ALL_RECEIVABLE",
  FETCH_ALL_RECEIVABLE_RESET: "FETCH_ALL_RECEIVABLE_RESET",
  FETCH_ALL_RECEIVABLE_FAILURE: "FETCH_ALL_RECEIVABLE_FAILURE",
  FETCH_ALL_RECEIVABLE_SUCCESS: "FETCH_ALL_RECEIVABLE_SUCCESS",

  FETCH_ONE_RECEIVABLE: "FETCH_ONE_RECEIVABLE",
  FETCH_ONE_RECEIVABLE_RESET: "FETCH_ONE_RECEIVABLE_RESET",
  FETCH_ONE_RECEIVABLE_FAILURE: "FETCH_ONE_RECEIVABLE_FAILURE",
  FETCH_ONE_RECEIVABLE_SUCCESS: "FETCH_ONE_RECEIVABLE_SUCCESS",
};
