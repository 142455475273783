import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ContactActionTypes } from "./Contact.type";

export function* fetchAllContacts(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys
        .map((key) => {
          if (action.payload[key]) {
            return `${key}=${action.payload[key]}`;
          }
        })
        .join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/contact?${query}`);
    yield put({
      type: ContactActionTypes.FETCH_ALL_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContactActionTypes.FETCH_ALL_CONTACT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneContact(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/contact/${action.payload}`
    );
    yield put({
      type: ContactActionTypes.FETCH_ONE_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContactActionTypes.FETCH_ONE_CONTACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllContacts() {
  yield takeLatest(ContactActionTypes.FETCH_ALL_CONTACT, fetchAllContacts);
}

export function* watcherFetchOneContacts() {
  yield takeLatest(ContactActionTypes.FETCH_ONE_CONTACT, fetchOneContact);
}
