import {
  RentContractStateTypes,
  RentContractActionTypes,
} from "./RentContract.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: RentContractStateTypes = {
  fetchAll: resetApiCallState([]),
};

const RentContractReducer = (
  state: RentContractStateTypes = INITIAL_STATE,
  action: any
): RentContractStateTypes => {
  switch (action.type) {
    case RentContractActionTypes.FETCH_ALL_RENT_CONTRACT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case RentContractActionTypes.FETCH_ALL_RENT_CONTRACT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case RentContractActionTypes.FETCH_ALL_RENT_CONTRACT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case RentContractActionTypes.FETCH_ALL_RENT_CONTRACT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default RentContractReducer;
