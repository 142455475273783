import { resetApiCallState } from "../Utils";
import {
  EquipmentInventoryActions,
  EquipmentInventoryStateTypes,
} from "./EquipmentInventory.type";

const INITIAL_STATE: EquipmentInventoryStateTypes = {
  fetchAll: resetApiCallState([]),
};

const EquipmentInventoryReducer = (
  state: EquipmentInventoryStateTypes = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case EquipmentInventoryActions.FETCH_EQUIPMENT_INVENTORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentInventoryActions.FETCH_EQUIPMENT_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: action.payload,
          isPending: false,
          isSuccessful: true,
        },
      };

    case EquipmentInventoryActions.FETCH_EQUIPMENT_INVENTORY_ERROR:
      return {
        ...state,
        fetchAll: {
          error: action.payload,
          payload: [],
          isPending: false,
          isSuccessful: false,
        },
      };
    default:
      return state;
  }
};

export default EquipmentInventoryReducer;
