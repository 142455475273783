import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type SubContractStateTypes = {
  fetchAll: ApiCallState<Project[]>;
  fetchOne: ApiCallState<Project | null>;
};

export type SubContractActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const SubContractActionTypes = {
  FETCH_ALL_SUB_CONTRACT: "FETCH_ALL_SUB_CONTRACT",
  FETCH_ALL_SUB_CONTRACT_RESET: "FETCH_ALL_SUB_CONTRACT_RESET",
  FETCH_ALL_SUB_CONTRACT_FAILURE: "FETCH_ALL_SUB_CONTRACT_FAILURE",
  FETCH_ALL_SUB_CONTRACT_SUCCESS: "FETCH_ALL_SUB_CONTRACT_SUCCESS",

  FETCH_ONE_SUB_CONTRACT: "FETCH_ONE_SUB_CONTRACT",
  FETCH_ONE_SUB_CONTRACT_RESET: "FETCH_ONE_SUB_CONTRACT_RESET",
  FETCH_ONE_SUB_CONTRACT_FAILURE: "FETCH_ONE_SUB_CONTRACT_FAILURE",
  FETCH_ONE_SUB_CONTRACT_SUCCESS: "FETCH_ONE_SUB_CONTRACT_SUCCESS",
};
