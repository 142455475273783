import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ApartmentActionTypes } from "./Apartment.type";

export function* fetchAllApartments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/apartment?building_id=${action.payload?.building_id}&year=${action.payload?.year}`);
    yield put({
      type: ApartmentActionTypes.FETCH_ALL_APARTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentActionTypes.FETCH_ALL_APARTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApartments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/apartment/${action.payload}`
    );
    yield put({
      type: ApartmentActionTypes.FETCH_ONE_APARTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentActionTypes.FETCH_ONE_APARTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApartments() {
  yield takeLatest(ApartmentActionTypes.FETCH_ALL_APARTMENT, fetchAllApartments);
}

export function* watcherFetchOneApartments() {
  yield takeLatest(ApartmentActionTypes.FETCH_ONE_APARTMENT, fetchOneApartments);
}
