
export type Tour = {
  key: any;
  run: boolean;
  continuous: boolean;
  loading: boolean;
  stepIndex: number;
  steps: any[];
  route: string;
};

export type TourStateTypes = Tour;

export const TourActionTypes = {
  START: "START",
  RESET: "RESET",
  STOP: "STOP",
  NEXT_OR_PREVIOUS: "NEXT_OR_PREVIOUS",
  RESTART: "RESTART",
  SET_STEP: "SET_STEP",
};
