import { ApiCallState, PagedData } from "../Utils";

export type AccountChartBalance = {
  id?: number;
  key: number;
  account_chart_id: any;
  date: string;
  amount: number;
  description?: string;
};

export type AccountChartBalanceStateTypes = {
  fetchAll: ApiCallState<AccountChartBalance[]>;
  fetchOne: ApiCallState<AccountChartBalance | {}>;
  fetchPaged: ApiCallState<PagedData<AccountChartBalance[]>>;
};

export const AccountChartBalanceActionTypes = {
  FETCH_ALL_ACCOUNT_CHART_BALANCE: "FETCH_ALL_ACCOUNT_CHART_BALANCE",
  FETCH_ALL_ACCOUNT_CHART_BALANCE_RESET:
    "FETCH_ALL_ACCOUNT_CHART_BALANCE_RESET",
  FETCH_ALL_ACCOUNT_CHART_BALANCE_FAILURE:
    "FETCH_ALL_ACCOUNT_CHART_BALANCE_FAILURE",
  FETCH_ALL_ACCOUNT_CHART_BALANCE_SUCCESS:
    "FETCH_ALL_ACCOUNT_CHART_BALANCE_SUCCESS",

  FETCH_PAGED_ACCOUNT_CHART_BALANCE: "FETCH_PAGED_ACCOUNT_CHART_BALANCE",
  FETCH_PAGED_ACCOUNT_CHART_BALANCE_RESET:
    "FETCH_PAGED_ACCOUNT_CHART_BALANCE_RESET",
  FETCH_PAGED_ACCOUNT_CHART_BALANCE_FAILURE:
    "FETCH_PAGED_ACCOUNT_CHART_BALANCE_FAILURE",
  FETCH_PAGED_ACCOUNT_CHART_BALANCE_SUCCESS:
    "FETCH_PAGED_ACCOUNT_CHART_BALANCE_SUCCESS",

  FETCH_ONE_ACCOUNT_CHART_BALANCE: "FETCH_ONE_ACCOUNT_CHART_BALANCE",
  FETCH_ONE_ACCOUNT_CHART_BALANCE_RESET:
    "FETCH_ONE_ACCOUNT_CHART_BALANCE_RESET",
  FETCH_ONE_ACCOUNT_CHART_BALANCE_FAILURE:
    "FETCH_ONE_ACCOUNT_CHART_BALANCE_FAILURE",
  FETCH_ONE_ACCOUNT_CHART_BALANCE_SUCCESS:
    "FETCH_ONE_ACCOUNT_CHART_BALANCE_SUCCESS",
};
