import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { StandardActionTypes } from "./Standard.type";

export function* fetchAllStandard(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/standard`);
    yield put({
      type: StandardActionTypes.FETCH_ALL_STANDARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StandardActionTypes.FETCH_ALL_STANDARD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStandard(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/standard/${action.payload}`
    );
    yield put({
      type: StandardActionTypes.FETCH_ONE_STANDARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StandardActionTypes.FETCH_ONE_STANDARD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStandard() {
  yield takeLatest(StandardActionTypes.FETCH_ALL_STANDARD, fetchAllStandard);
}

export function* watcherFetchOneStandard() {
  yield takeLatest(StandardActionTypes.FETCH_ONE_STANDARD, fetchOneStandard);
}
