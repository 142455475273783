import { Department } from "../Department/Department.type";
import { HRAllowance } from "../HRAllowance/HRAllowance.type";
import { ApiCallState } from "../Utils";

export type StaffTransfer = {
  id: number;
  staff_id: number;
  date: string;
  from_deparment_id: number;
  to_deparment_id: number;
  current_role: string;
  prev_role:string;
  transfer_date: string;
  salary_date: string;
  user_id: number;
  from_deparment:Department;
  to_deparment:Department;
  staff_transfer_items: StaffTransferItem[];
  staff_transfer_details: StaffTransferDetail[];
  createdAt:string;
};

export type StaffTransferItem = {
  id: number;
  staff_transfer_id: number;
  hr_allowance_id: number;
  description:string;
  current_amount: number;
  prev_amount:number;
  hr_allowance: HRAllowance;
  createdAt:Date;
};

export type StaffTransferDetail = {
  id: number;
  staff_transfer_id: number;
  department_to:string;
  department_cc:string;
}

export type StaffTransferStateTypes = {
  fetchAll: ApiCallState<StaffTransfer[]>;
  fetchOne: ApiCallState<StaffTransfer | {}>;
};

export const StaffTransferActionTypes = {
  FETCH_ALL_STAFF_TRANSFER: "FETCH_ALL_STAFF_TRANSFER",
  FETCH_ALL_STAFF_TRANSFER_RESET: "FETCH_ALL_STAFF_TRANSFER_RESET",
  FETCH_ALL_STAFF_TRANSFER_FAILURE: "FETCH_ALL_STAFF_TRANSFER_FAILURE",
  FETCH_ALL_STAFF_TRANSFER_SUCCESS: "FETCH_ALL_STAFF_TRANSFER_SUCCESS",

  FETCH_ONE_STAFF_TRANSFER: "FETCH_ONE_STAFF_TRANSFER",
  FETCH_ONE_STAFF_TRANSFER_RESET: "FETCH_ONE_STAFF_TRANSFER_RESET",
  FETCH_ONE_STAFF_TRANSFER_FAILURE: "FETCH_ONE_STAFF_TRANSFER_FAILURE",
  FETCH_ONE_STAFF_TRANSFER_SUCCESS: "FETCH_ONE_STAFF_TRANSFER_SUCCESS",
};
