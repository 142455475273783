import { BoqReport, MonthlyReport } from "../MonthlyReport/MonthlyReport.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type MonthlyReportV2 = {
  id: number;
  date: string;
  report_no: number;
  monthly_report_projects: MonthlyReportProject[];
  checked_by_user: User;
  prepared_by_user: User;
  work_executed: {
    name: string;
    sub_structure: string;
    super_structure: string;
    key: number;
  }[];
  summary_of_work: {
    name: string;
    total_contract_amount: number;
    total_executed_amount: number;
    percentage_completed: number;
  }[];

  boq_reports: {
    name: string;
    project_id: number;
    boq_report: BoqReport[];
  }[];

  current_stage_of_construction: {
    planned_and_completed: string;
    under_progress: string;
    planned_not_stated: string;
  };
  equipment_deployments: {
    no: string;
    equipment: string;
    deployed_at_the_end_of_last_month: string;
    layed_of_during_reporting_month: string;
    deployed_during_reporting_month: string;
    total_deployed: string;
  }[];
  manpower_deployments: {
    qualification: string;
    permanent: number;
    contract: number;
    temp: number;
    total: number;
    change_from_last_month: number;
  }[];
  quantity_controls: {
    type_of_material: string;
    test_expected: string;
    ordered: string;
    submitted: string;
    accepted: boolean;
    rejected: boolean;
  }[];
  concert_test: {
    cast_date: string;
    type: string;
    grade: string;
    source: string;
    location: string;
    result_day: string;
    test_result: string;
    remark: string;
  }[];

  quality_control_remark: string;
  difficulties: {
    type: string;
    value: string;
  }[];
  instructions: {
    instruction?: string;
    date?: string;
    reference?: string;
  }[];
  variation: { description: string; amount: number; date: string }[];
  problems: {
    problem_identified: string;
    solution_proposed: string;
    responsible_for_action: string;
    remark: string;
  }[];
  clam: {
    description: string;
    duration: string;
  }[];
  correspondence: {
    description: string;
    ref: string;
    date: string;
    no: string;
  }[];
  inspection: {
    request_date: string;
    date_required: string;
    description: string;
    response_date: string;
    grade: string;
  }[];
  inspection_log: {
    description: string;
    date: string;
    fulfilled: boolean;
    not_fulfilled: boolean;
    allowed: boolean;
    refused: boolean;
  }[];
  progress_status: {
    description: string;
    week1: number;
    week2: number;
    week3: number;
    week4: number;
  }[];

  risk_mitigation: {
    category: string;
    risk: string;
    risk_cause: string;
    impact: string;
    raised_by: string;
    date_raised: string;
    cost_impact: string;
    schedule_impact: string;
    response_strategy: string;
    response_plan: string;
    risk_owner: string;
    status: string;
    notes: string;
  }[];
  photos: {
    url: string;
    selected: boolean;
    id: number;
  }[];
  meetings: {
    description: string;
    stakeholder: string;
    subject: string;
    date: string;
  }[];
  conclusion: string;
  is_checked: boolean;
  checked_by: string;
};

export type MonthlyReportProject = {
  name: string;
  price_adjustment: number;
  advance_repayment_percentage: number;
  date_of_contract_signature: string;
  date_of_site_hand_over: string;
  commencement_date: string;
  time_completion_date: string;
  approved_time_extension: string;
  time_given_for_variation: string;
  revised_target_completion_date: string;
  project_value_before: number;
  variation: number;
  total_contract_sum: number;
  advance_payment: number;
  advance_repayment: number;
  work_planned: number;
  percentage_of_work_planned: number;
  work_executed: number;
  total_work_executed: number;
  percentage_of_work_executed: number;
  time_elapsed: number;
  time_elapsed_percentage: number;
  additional_time_granted: string;
  contract_id: number;
  report_no: number;
  monthly_report_id: number;
  project?: Project;
  project_id: number;
};

export type MonthlyReportV2StateTypes = {
  fetchAll: ApiCallState<MonthlyReportV2[]>;
  fetchOne: ApiCallState<MonthlyReportV2 | {}>;
  fetchPaged: ApiCallState<PagedData<MonthlyReportV2[]>>;
  fetchReport: ApiCallState<MonthlyReport | null>;
};

export const MonthlyReportV2ActionTypes = {
  FETCH_ALL_MONTHLY_REPORT_V2: "FETCH_ALL_MONTHLY_REPORT_V2",
  FETCH_ALL_MONTHLY_REPORT_V2_RESET: "FETCH_ALL_MONTHLY_REPORT_V2_RESET",
  FETCH_ALL_MONTHLY_REPORT_V2_FAILURE: "FETCH_ALL_MONTHLY_REPORT_V2_FAILURE",
  FETCH_ALL_MONTHLY_REPORT_V2_SUCCESS: "FETCH_ALL_MONTHLY_REPORT_V2_SUCCESS",

  FETCH_MONTHLY_REPORT_V2: "FETCH_MONTHLY_REPORT_V2",
  FETCH_MONTHLY_REPORT_V2_RESET: "FETCH_MONTHLY_REPORT_V2_RESET",
  FETCH_MONTHLY_REPORT_V2_FAILURE: "FETCH_MONTHLY_REPORT_V2_FAILURE",
  FETCH_MONTHLY_REPORT_V2_SUCCESS: "FETCH_MONTHLY_REPORT_V2_SUCCESS",

  FETCH_PAGED_MONTHLY_REPORT_V2: "FETCH_PAGED_MONTHLY_REPORT_V2",
  FETCH_PAGED_MONTHLY_REPORT_V2_RESET: "FETCH_PAGED_MONTHLY_REPORT_V2_RESET",
  FETCH_PAGED_MONTHLY_REPORT_V2_FAILURE:
    "FETCH_PAGED_MONTHLY_REPORT_V2_FAILURE",
  FETCH_PAGED_MONTHLY_REPORT_V2_SUCCESS:
    "FETCH_PAGED_MONTHLY_REPORT_V2_SUCCESS",

  FETCH_ONE_MONTHLY_REPORT_V2: "FETCH_ONE_MONTHLY_REPORT_V2",
  FETCH_ONE_MONTHLY_REPORT_V2_RESET: "FETCH_ONE_MONTHLY_REPORT_V2_RESET",
  FETCH_ONE_MONTHLY_REPORT_V2_FAILURE: "FETCH_ONE_MONTHLY_REPORT_V2_FAILURE",
  FETCH_ONE_MONTHLY_REPORT_V2_SUCCESS: "FETCH_ONE_MONTHLY_REPORT_V2_SUCCESS",
};
