import { ApiCallState } from "../Utils";

export type TaskDepartment= {
  id: number;
  description:string;
};

export type TaskDepartmentStateTypes = {
  fetchAll: ApiCallState<TaskDepartment[]>;
  fetchOne: ApiCallState<TaskDepartment | {}>;
};

export const TaskDepartmentActionTypes = {
  FETCH_ALL_TASK_DEPARTMENT: "FETCH_ALL_TASK_DEPARTMENT",
  FETCH_ALL_TASK_DEPARTMENT_RESET: "FETCH_ALL_TASK_DEPARTMENT_RESET",
  FETCH_ALL_TASK_DEPARTMENT_FAILURE: "FETCH_ALL_TASK_DEPARTMENT_FAILURE",
  FETCH_ALL_TASK_DEPARTMENT_SUCCESS: "FETCH_ALL_TASK_DEPARTMENT_SUCCESS",

  FETCH_ONE_TASK_DEPARTMENT: "FETCH_ONE_TASK_DEPARTMENT",
  FETCH_ONE_TASK_DEPARTMENT_RESET: "FETCH_ONE_TASK_DEPARTMENT_RESET",
  FETCH_ONE_TASK_DEPARTMENT_FAILURE: "FETCH_ONE_TASK_DEPARTMENT_FAILURE",
  FETCH_ONE_TASK_DEPARTMENT_SUCCESS: "FETCH_ONE_TASK_DEPARTMENT_SUCCESS",
};
