import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { UnitBreakdownActionTypes } from "./UnitBreakdown.type";

export function* fetchAllUnitBreakdowns(action: any): any {
  try {
 let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/unit-breakdown?${query}`);
    yield put({
      type: UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAKDOWN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type:  UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAKDOWN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedUnitBreakdowns(action: any): any {
    try {
   let query = "";
      if (action.payload) {
        const keys = Object.keys(action.payload);
        query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
      }
      const response = yield axios.get(`${API_BASE_URI}/unit-breakdown?${query}`);
      yield put({
        type:  UnitBreakdownActionTypes.FETCH_PAGED_UNIT_BREAKDOWN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      yield put({
        type:  UnitBreakdownActionTypes.FETCH_PAGED_UNIT_BREAKDOWN_FAILURE,
        payload: error,
      });
    }
  }

export function* fetchOneUnitBreakdowns(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/unit-breakdown/${action.payload}`
    );
    yield put({
      type:  UnitBreakdownActionTypes.FETCH_ONE_UNIT_BREAKDOWN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UnitBreakdownActionTypes.FETCH_ONE_UNIT_BREAKDOWN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllUnitBreakdowns() {
  yield takeLatest( UnitBreakdownActionTypes.FETCH_ALL_UNIT_BREAKDOWN, fetchAllUnitBreakdowns);
}

export function* watcherFetchPagedUnitBreakdowns() {
    yield takeLatest(UnitBreakdownActionTypes.FETCH_PAGED_UNIT_BREAKDOWN, fetchPagedUnitBreakdowns);
  }

export function* watcherFetchOneUnitBreakdowns() {
  yield takeLatest(UnitBreakdownActionTypes.FETCH_ONE_UNIT_BREAKDOWN, fetchOneUnitBreakdowns);
}
