import { StaffTransferStateTypes, StaffTransferActionTypes } from "./StaffTransfer.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StaffTransferStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const StaffTransferReducer = (
  state: StaffTransferStateTypes = INITIAL_STATE,
  action: any
): StaffTransferStateTypes => {
  switch (action.type) {
    case StaffTransferActionTypes.FETCH_ALL_STAFF_TRANSFER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffTransferActionTypes.FETCH_ALL_STAFF_TRANSFER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffTransferActionTypes.FETCH_ALL_STAFF_TRANSFER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffTransferActionTypes.FETCH_ALL_STAFF_TRANSFER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case StaffTransferActionTypes.FETCH_ONE_STAFF_TRANSFER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffTransferActionTypes.FETCH_ONE_STAFF_TRANSFER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffTransferActionTypes.FETCH_ONE_STAFF_TRANSFER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffTransferActionTypes.FETCH_ONE_STAFF_TRANSFER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StaffTransferReducer;
