import { OpportunityUpdateStateTypes, OpportunityUpdateActionTypes } from "./OpportunityUpdate.type";
import { resetApiCallState } from "../../../Utils";

const INITIAL_STATE: OpportunityUpdateStateTypes = {
  fetchByOpportunity: resetApiCallState([]),
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const OpportunityUpdateReducer = (
  state: OpportunityUpdateStateTypes = INITIAL_STATE,
  action: any
): OpportunityUpdateStateTypes => {
  switch (action.type) {
    case OpportunityUpdateActionTypes.FETCH_OPPORTUNITY_UPDATES:
      return {
        ...state,
        fetchByOpportunity: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case OpportunityUpdateActionTypes.FETCH_OPPORTUNITY_UPDATES_RESET:
      return {
        ...state,
        fetchByOpportunity: resetApiCallState([]),
      };
    case OpportunityUpdateActionTypes.FETCH_OPPORTUNITY_UPDATES_FAILURE:
      return {
        ...state,
        fetchByOpportunity: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OpportunityUpdateActionTypes.FETCH_OPPORTUNITY_UPDATES_SUCCESS:
      return {
        ...state,
        fetchByOpportunity: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case OpportunityUpdateActionTypes.FETCH_ALL_OPPORTUNITY_UPDATES:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case OpportunityUpdateActionTypes.FETCH_ALL_OPPORTUNITY_UPDATES_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OpportunityUpdateActionTypes.FETCH_ALL_OPPORTUNITY_UPDATES_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OpportunityUpdateActionTypes.FETCH_ALL_OPPORTUNITY_UPDATES_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case OpportunityUpdateActionTypes.FETCH_ONE_OPPORTUNITY_UPDATE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case OpportunityUpdateActionTypes.FETCH_ONE_OPPORTUNITY_UPDATE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OpportunityUpdateActionTypes.FETCH_ONE_OPPORTUNITY_UPDATE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OpportunityUpdateActionTypes.FETCH_ONE_OPPORTUNITY_UPDATE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default OpportunityUpdateReducer;
