import { ApiCallState } from "../Utils";

export type ExternalBudget = {
  id: number;
  user_id: number;

  month: string;
  description: string;
  amount: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type ExternalBudgetStateTypes = {
  fetchAll: ApiCallState<ExternalBudget[]>;
  fetchOne: ApiCallState<ExternalBudget | {}>;
};

export const ExternalBudgetActionTypes = {
  FETCH_ALL_EXTERNAL_BUDGET: "FETCH_ALL_EXTERNAL_BUDGET",
  FETCH_ALL_EXTERNAL_BUDGET_RESET: "FETCH_ALL_EXTERNAL_BUDGET_RESET",
  FETCH_ALL_EXTERNAL_BUDGET_FAILURE: "FETCH_ALL_EXTERNAL_BUDGET_FAILURE",
  FETCH_ALL_EXTERNAL_BUDGET_SUCCESS: "FETCH_ALL_EXTERNAL_BUDGET_SUCCESS",

  FETCH_ONE_EXTERNAL_BUDGET: "FETCH_ONE_EXTERNAL_BUDGET",
  FETCH_ONE_EXTERNAL_BUDGET_RESET: "FETCH_ONE_EXTERNAL_BUDGET_RESET",
  FETCH_ONE_EXTERNAL_BUDGET_FAILURE: "FETCH_ONE_EXTERNAL_BUDGET_FAILURE",
  FETCH_ONE_EXTERNAL_BUDGET_SUCCESS: "FETCH_ONE_EXTERNAL_BUDGET_SUCCESS",
};
