import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DocumentWorkOrderActionTypes } from "./DocumentWorkOrder.type";

export function* fetchAllDocumentWorkOrders(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/document_work_order`);
    yield put({
      type: DocumentWorkOrderActionTypes.FETCH_ALL_DOCUMENT_WORK_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DocumentWorkOrderActionTypes.FETCH_ALL_DOCUMENT_WORK_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDocumentWorkOrders() {
  yield takeLatest(
    DocumentWorkOrderActionTypes.FETCH_ALL_DOCUMENT_WORK_ORDER,
    fetchAllDocumentWorkOrders
  );
}
