import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentUsageActionTypes } from "./EquipmentUsage.type";

export function* fetchAllEquipmentUsage(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment_usage?project_id=${action.payload}`
    );
    yield put({
      type: EquipmentUsageActionTypes.FETCH_ALL_EQUIPMENT_USAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentUsageActionTypes.FETCH_ALL_EQUIPMENT_USAGE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentUsage() {
  yield takeLatest(
    EquipmentUsageActionTypes.FETCH_ALL_EQUIPMENT_USAGE,
    fetchAllEquipmentUsage
  );
}
