import { DailyReportStateTypes, DailyReportActionTypes } from "./DailyReport.type";
import { resetApiCallState } from "../../Utils";

const INITIAL_STATE: DailyReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const DailyReportReducer = (
  state: DailyReportStateTypes = INITIAL_STATE,
  action: any
): DailyReportStateTypes => {
  switch (action.type) {
    case DailyReportActionTypes.FETCH_ALL_DAILY_REPORTS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyReportActionTypes.FETCH_ALL_DAILY_REPORTS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyReportActionTypes.FETCH_ALL_DAILY_REPORTS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyReportActionTypes.FETCH_ALL_DAILY_REPORTS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DailyReportActionTypes.FETCH_ONE_DAILY_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyReportActionTypes.FETCH_ONE_DAILY_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyReportActionTypes.FETCH_ONE_DAILY_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyReportActionTypes.FETCH_ONE_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DailyReportReducer;
