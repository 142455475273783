import { GradeStateTypes, GradeActionTypes } from "./Grade.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: GradeStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const GradeReducer = (
  state: GradeStateTypes = INITIAL_STATE,
  action: any
): GradeStateTypes => {
  switch (action.type) {
    case GradeActionTypes.FETCH_ALL_GRADE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case GradeActionTypes.FETCH_ALL_GRADE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case GradeActionTypes.FETCH_ALL_GRADE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GradeActionTypes.FETCH_ALL_GRADE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case GradeActionTypes.FETCH_ONE_GRADE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case GradeActionTypes.FETCH_ONE_GRADE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case GradeActionTypes.FETCH_ONE_GRADE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case GradeActionTypes.FETCH_ONE_GRADE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default GradeReducer;
