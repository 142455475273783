import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type Variation = {
  id: number;
  description: string;
  reason: string;
  type: string;
  owner: boolean;
  extra: boolean;
  law: boolean;
  contractor: boolean;
  project_id: number;
  variation_no: string;
  variation_items: VariationItem[];
};

export type VariationItem = {
  id: any;
  variation_id: any;
  boq_id: number;
  quantity: number;
  unit_price: number;

  boq: Boq;
};

export type VariationStateTypes = {
  fetchAll: ApiCallState<Variation[]>;
  fetchBoq: ApiCallState<Boq[]>;
  fetchPID:ApiCallState<Boq[]>
};

export const VariationActionTypes = {
  FETCH_ALL_VARIATION: "FETCH_ALL_VARIATION",
  FETCH_ALL_VARIATION_RESET: "FETCH_ALL_VARIATION_RESET",
  FETCH_ALL_VARIATION_FAILURE: "FETCH_ALL_VARIATION_FAILURE",
  FETCH_ALL_VARIATION_SUCCESS: "FETCH_ALL_VARIATION_SUCCESS",

  FETCH_BOQ_VARIATION: "FETCH_BOQ_VARIATION",
  FETCH_BOQ_VARIATION_RESET: "FETCH_BOQ_VARIATION_RESET",
  FETCH_BOQ_VARIATION_FAILURE: "FETCH_BOQ_VARIATION_FAILURE",
  FETCH_BOQ_VARIATION_SUCCESS: "FETCH_BOQ_VARIATION_SUCCESS",

  FETCH_PID_VARIATION: "FETCH_PID_VARIATION",
  FETCH_PID_VARIATION_RESET: "FETCH_PID_VARIATION_RESET",
  FETCH_PID_VARIATION_FAILURE: "FETCH_PID_VARIATION_FAILURE",
  FETCH_PID_VARIATION_SUCCESS: "FETCH_PID_VARIATION_SUCCESS",
};
