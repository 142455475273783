import { DailyTaskStateTypes, DailyTaskActionTypes } from "./DailyTask.type";
import { resetApiCallState } from "../../Utils";

const INITIAL_STATE: DailyTaskStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const DailyTaskReducer = (
  state: DailyTaskStateTypes = INITIAL_STATE,
  action: any
): DailyTaskStateTypes => {
  switch (action.type) {
    case DailyTaskActionTypes.FETCH_ALL_DAILY_TASK:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyTaskActionTypes.FETCH_ALL_DAILY_TASK_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyTaskActionTypes.FETCH_ALL_DAILY_TASK_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyTaskActionTypes.FETCH_ALL_DAILY_TASK_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DailyTaskActionTypes.FETCH_ONE_DAILY_TASK:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyTaskActionTypes.FETCH_ONE_DAILY_TASK_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case DailyTaskActionTypes.FETCH_ONE_DAILY_TASK_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyTaskActionTypes.FETCH_ONE_DAILY_TASK_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DailyTaskReducer;
