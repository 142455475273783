import { PerformanceEvaluationFormStateTypes, PerformanceEvaluationFormActionTypes } from "./PerformanceEvaluationForm.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PerformanceEvaluationFormStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PerformanceEvaluationFormReducer = (
  state: PerformanceEvaluationFormStateTypes = INITIAL_STATE,
  action: any
): PerformanceEvaluationFormStateTypes => {
  switch (action.type) {
    case PerformanceEvaluationFormActionTypes.FETCH_ALL_PERFORMANCE_EVALUATION_FORM:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PerformanceEvaluationFormActionTypes.FETCH_ALL_PERFORMANCE_EVALUATION_FORM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PerformanceEvaluationFormActionTypes.FETCH_ALL_PERFORMANCE_EVALUATION_FORM_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PerformanceEvaluationFormActionTypes.FETCH_ALL_PERFORMANCE_EVALUATION_FORM_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PerformanceEvaluationFormActionTypes.FETCH_ONE_PERFORMANCE_EVALUATION_FORM:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PerformanceEvaluationFormActionTypes.FETCH_ONE_PERFORMANCE_EVALUATION_FORM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PerformanceEvaluationFormActionTypes.FETCH_ONE_PERFORMANCE_EVALUATION_FORM_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PerformanceEvaluationFormActionTypes.FETCH_ONE_PERFORMANCE_EVALUATION_FORM_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PerformanceEvaluationFormReducer;
