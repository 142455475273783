import { MaterialInventory } from "../MaterialInventory/MaterialInventory.type";
import { Project, Store } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export const MaterialTransferActions = {
  FETCH_MATERIAL_TRANSFER: "FETCH_MATERIAL_TRANSFER",
  FETCH_MATERIAL_TRANSFER_SUCCESS: "FETCH_MATERIAL_TRANSFER_SUCCESS",
  FETCH_MATERIAL_TRANSFER_ERROR: "FETCH_MATERIAL_TRANSFER_ERROR",
};

export type MaterialTransfer = {
  id: number;
  quantity: number;
  date: Date;
  from_project: Project | null;
  to_project: Project | null;
  from_store: Store | null;
  to_store: Store | null;
  material_inventory: MaterialInventory;
};

export type MaterialTransferStateTypes = {
  fetchAll: ApiCallState<MaterialTransfer[]>;
};
