import Menu from "antd/lib/menu";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../../router/Constants";
import { useEffect, useState } from "react";
import { checkAuthorization } from "../../../utilities/utilities";
import { Dropdown } from "antd";

const HeaderMenuComponent = () => {
  const history = useHistory();
  const [selected, setSelected] = useState<string | undefined>();
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelected(history.location.pathname);
  }, [history.location.pathname]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const menuItems = [
    checkAuthorization(RouteConstants.DASHBOARDS) && (
      <Menu.Item key={RouteConstants.DASHBOARDS} title="Dashboard">
        Dashboard
      </Menu.Item>
    ),

    checkAuthorization(RouteConstants.ENGINEERING) && (
      <Menu.Item key={RouteConstants.ENGINEERING} title="Engineering">
        Engineering
      </Menu.Item>
    ),
    checkAuthorization(RouteConstants.PROCUREMENT) && (
      <Menu.Item key={RouteConstants.PROCUREMENT} title="Procurement">
        Procurement
      </Menu.Item>
    ),
    checkAuthorization(RouteConstants.INVENTORY) && (
      <Menu.Item key={RouteConstants.INVENTORY} title="Inventory">
        Inventory
      </Menu.Item>
    ),
    // checkAuthorization(RouteConstants.FIXED_ASSET) && (
    //   <Menu.Item key={RouteConstants.FIXED_ASSET} title="Fixed Asset">
    //     Fixed Asset
    //   </Menu.Item>
    // ),
    checkAuthorization(RouteConstants.HUMAN_RESOURCE) && (
      <Menu.Item key={RouteConstants.HUMAN_RESOURCE} title="Human Resource">
        Human Resource
      </Menu.Item>
    ),
    checkAuthorization(RouteConstants.FINANCE) && (
      <Menu.Item key={RouteConstants.FINANCE} title="Finance">
        Finance
      </Menu.Item>
    ),
    checkAuthorization(RouteConstants.CRM) && (
      <Menu.Item key={RouteConstants.CRM} title="Sales">
        Sales
      </Menu.Item>
    ),
    checkAuthorization(RouteConstants.ADMINISTRATION) && (
      <Menu.Item key={RouteConstants.ADMINISTRATION} title="Administration">
        Administration
      </Menu.Item>
    ),
  ].filter(Boolean) as React.ReactElement[];

  const displayedItems = menuItems.slice(0, 8);
  const overflowItems = menuItems.slice(8);

  const moreMenu = (
    <Menu>
      {overflowItems.map((item, index) => (
        <Menu.Item
          key={`more-${item.key || index}`}
          onClick={() => {
            if (item.key) {
              history.push(item.key);
              setSelected(item.key); // Update the selected key
            }
          }}
        >
          {item.props.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const selectedKeys = overflowItems.some((item) => item.key === selected)
    ? ["more"]
    : [selected || "dashboard"];

  return (
    <div className="hidden-print header-menu">
      <Menu
        mode="horizontal"
        selectedKeys={selectedKeys} // Use selectedKeys to manage active states
        defaultSelectedKeys={["dashboard"]}
        onSelect={(e) => setSelected(e.key)}
        theme="light"
        {...(!localStorage.getItem("/")
          ? { openKeys: openKeys, onOpenChange: onOpenChange }
          : null)}
      >
        {displayedItems.map((item) => (
          <Menu.Item
            key={item.key}
            onClick={() => {
              if (item.key) {
                history.push(item.key);
                setSelected(item.key);
              }
            }}
          >
            {item.props.title}
          </Menu.Item>
        ))}

        {overflowItems.length > 0 && (
          <Dropdown overlay={moreMenu} placement="bottomRight">
            <Menu.Item key="more" title="More">
              More
            </Menu.Item>
          </Dropdown>
        )}
      </Menu>
    </div>
  );
};

export default HeaderMenuComponent;
