// export const BASE_URI = "http://localhost:4000";
// export const API_BASE_URI = "http://localhost:4000/api";

export const BASE_URI = "https://project.condigital.cloud:3000";
export const API_BASE_URI = "https://project.condigital.cloud:3000/api";

// export const BASE_URI = "https://hillbottom.condigitaleth.com:3000";
// export const API_BASE_URI = "https://hillbottom.condigitaleth.com:3000/api";

// export const BASE_URI = "https://flower.condigital.cloud:3000";
// export const API_BASE_URI = "https://flower.condigital.cloud:3000/api";
