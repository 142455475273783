import { EmploymentExamSummaryStateTypes, EmploymentExamSummaryActionTypes } from "./EmploymentExamSummary.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EmploymentExamSummaryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const EmploymentExamSummaryReducer = (
  state: EmploymentExamSummaryStateTypes = INITIAL_STATE,
  action: any
): EmploymentExamSummaryStateTypes => {
  switch (action.type) {
    case EmploymentExamSummaryActionTypes.FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmploymentExamSummaryActionTypes.FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmploymentExamSummaryActionTypes.FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmploymentExamSummaryActionTypes.FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EmploymentExamSummaryActionTypes.FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmploymentExamSummaryActionTypes.FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmploymentExamSummaryActionTypes.FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmploymentExamSummaryActionTypes.FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EmploymentExamSummaryReducer;
