import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LoanExtensionActionTypes } from "./LoanExtension.type";

export function* fetchAllLoanExtensions(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/loan-extension${query}`);
    yield put({
      type: LoanExtensionActionTypes.FETCH_ALL_LOAN_EXTENSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LoanExtensionActionTypes.FETCH_ALL_LOAN_EXTENSION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLoanExtensions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/loan-extension/${action.payload}`
    );
    yield put({
      type: LoanExtensionActionTypes.FETCH_ONE_LOAN_EXTENSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LoanExtensionActionTypes.FETCH_ONE_LOAN_EXTENSION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLoanExtensions() {
  yield takeLatest(
    LoanExtensionActionTypes.FETCH_ALL_LOAN_EXTENSION,
    fetchAllLoanExtensions
  );
}

export function* watcherFetchOneLoanExtensions() {
  yield takeLatest(
    LoanExtensionActionTypes.FETCH_ONE_LOAN_EXTENSION,
    fetchOneLoanExtensions
  );
}
