import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { HRAllowanceActionTypes } from "./HRAllowance.type";

export function* fetchAllHRAllowances(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/hr-allowance`);
    yield put({
      type: HRAllowanceActionTypes.FETCH_ALL_HR_ALLOWANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRAllowanceActionTypes.FETCH_ALL_HR_ALLOWANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneHRAllowances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/hr-allowance/${action.payload}`
    );
    yield put({
      type: HRAllowanceActionTypes.FETCH_ONE_HR_ALLOWANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRAllowanceActionTypes.FETCH_ONE_HR_ALLOWANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllHRAllowances() {
  yield takeLatest(HRAllowanceActionTypes.FETCH_ALL_HR_ALLOWANCE, fetchAllHRAllowances);
}

export function* watcherFetchOneHRAllowances() {
  yield takeLatest(HRAllowanceActionTypes.FETCH_ONE_HR_ALLOWANCE, fetchOneHRAllowances);
}
