import { ExternaVacancyAnnouncement } from "../ExternaVacancyAnnouncement/ExternaVacancyAnnouncement.type";
import { ApiCallState } from "../Utils";

export type EmploymentExamSummary= {
  id: number;
  external_vacancy_announcement_id: number;
  date: string;
  committee_proposal:string;
  committee_member:string; 
  managing_director_decision:string;
  approved_by:number;
  external_vacancy_announcement:ExternaVacancyAnnouncement;
  employment_exam_summary_items:EmploymentExamSummaryItem[];
};

export type EmploymentExamSummaryItem ={
    id: number;
    employment_exam_summary_id:number;
    name:string;
    qualification:number;
    interview:number;
    converted_average:number
    rank:number;
    remark:string;
}

export type EmploymentExamSummaryStateTypes = {
  fetchAll: ApiCallState<EmploymentExamSummary[]>;
  fetchOne: ApiCallState<EmploymentExamSummary | {}>;
};

export const EmploymentExamSummaryActionTypes = {
  FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY: "FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY",
  FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_RESET: "FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_RESET",
  FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_FAILURE: "FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_FAILURE",
  FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_SUCCESS: "FETCH_ALL_EMPLOYMENT_EXAM_SUMMARY_SUCCESS",

  FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY: "FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY",
  FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_RESET: "FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_RESET",
  FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_FAILURE: "FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_FAILURE",
  FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_SUCCESS: "FETCH_ONE_EMPLOYMENT_EXAM_SUMMARY_SUCCESS",
};
