import { ApiCallState } from "../Utils";

export type Currency = {
  query: {
    apikey: string;
    timestamp: number;
    base_currency: string;
  };
  data: {
    ETB: number;
  };
};

export type CurrencyStateTypes = {
  fetchAll: ApiCallState<Currency[]>;
  fetchOne: ApiCallState<Currency | {}>;
};

export const CurrencyActionTypes = {
  FETCH_ALL_CURRENCY: "FETCH_ALL_CURRENCY",
  FETCH_ALL_CURRENCY_RESET: "FETCH_ALL_CURRENCY_RESET",
  FETCH_ALL_CURRENCY_FAILURE: "FETCH_ALL_CURRENCY_FAILURE",
  FETCH_ALL_CURRENCY_SUCCESS: "FETCH_ALL_CURRENCY_SUCCESS",

  FETCH_ONE_CURRENCY: "FETCH_ONE_CURRENCY",
  FETCH_ONE_CURRENCY_RESET: "FETCH_ONE_CURRENCY_RESET",
  FETCH_ONE_CURRENCY_FAILURE: "FETCH_ONE_CURRENCY_FAILURE",
  FETCH_ONE_CURRENCY_SUCCESS: "FETCH_ONE_CURRENCY_SUCCESS",
};
