import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { NewsAndMotivationActionTypes } from "./NewsAndMotivation.type";

export function* fetchAllNewsAndMotivations(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/news-and-motivation?${query}`
    );
    yield put({
      type: NewsAndMotivationActionTypes.FETCH_ALL_NEWS_AND_MOTIVATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: NewsAndMotivationActionTypes.FETCH_ALL_NEWS_AND_MOTIVATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneNewsAndMotivations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/news-and-motivation/${action.payload}`
    );
    yield put({
      type: NewsAndMotivationActionTypes.FETCH_ONE_NEWS_AND_MOTIVATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: NewsAndMotivationActionTypes.FETCH_ONE_NEWS_AND_MOTIVATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllNewsAndMotivations() {
  yield takeLatest(
    NewsAndMotivationActionTypes.FETCH_ALL_NEWS_AND_MOTIVATION,
    fetchAllNewsAndMotivations
  );
}

export function* watcherFetchOneNewsAndMotivations() {
  yield takeLatest(
    NewsAndMotivationActionTypes.FETCH_ONE_NEWS_AND_MOTIVATION,
    fetchOneNewsAndMotivations
  );
}
