import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TaskDepartmentActionTypes } from "./TaskDepartment.type";
import { formatQuery } from "../Utils";

export function* fetchAllTaskDepartments(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/task-department?${query}`
    );
    yield put({
      type: TaskDepartmentActionTypes.FETCH_ALL_TASK_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskDepartmentActionTypes.FETCH_ALL_TASK_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTaskDepartments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/task-department/${action.payload}`
    );
    yield put({
      type: TaskDepartmentActionTypes.FETCH_ONE_TASK_DEPARTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskDepartmentActionTypes.FETCH_ONE_TASK_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTaskDepartments() {
  yield takeLatest(
    TaskDepartmentActionTypes.FETCH_ALL_TASK_DEPARTMENT,
    fetchAllTaskDepartments
  );
}

export function* watcherFetchOneTaskDepartments() {
  yield takeLatest(
    TaskDepartmentActionTypes.FETCH_ONE_TASK_DEPARTMENT,
    fetchOneTaskDepartments
  );
}
