import { ApiCallState } from "../../Utils";
import { Lead } from "../Lead.type";

export type WalkIn = {
  id: number;
  date: Date;
  remark: string;

  lead: Lead;
};

export type WalkInStateTypes = {
  fetchAll: ApiCallState<WalkIn[]>;
  fetchOne: ApiCallState<WalkIn | {}>;
};

export const WalkInActionTypes = {
  FETCH_ALL_WALK_IN: "FETCH_ALL_WALK_IN",
  FETCH_ALL_WALK_IN_RESET: "FETCH_ALL_WALK_IN_RESET",
  FETCH_ALL_WALK_IN_FAILURE: "FETCH_ALL_WALK_IN_FAILURE",
  FETCH_ALL_WALK_IN_SUCCESS: "FETCH_ALL_WALK_IN_SUCCESS",

  FETCH_ONE_WALK_IN: "FETCH_ONE_WALK_IN",
  FETCH_ONE_WALK_IN_RESET: "FETCH_ONE_WALK_IN_RESET",
  FETCH_ONE_WALK_IN_FAILURE: "FETCH_ONE_WALK_IN_FAILURE",
  FETCH_ONE_WALK_IN_SUCCESS: "FETCH_ONE_WALK_IN_SUCCESS",
};
