import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type PriceEscalation = {
  id: number;
  project_id: number;
  boq_id: number;
  amount: number;
  approval: number;
  date: string;
  pid: number;
  createdAt: string;
  updatedAt: string;
  boq: Boq;
};

export type PriceEscalationStateTypes = {
  fetchAll: ApiCallState<PriceEscalation[]>;
};

export type PriceEscalationActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const PriceEscalationActionTypes = {
  FETCH_ALL_PRICE_ESCALATION: "FETCH_ALL_PRICE_ESCALATION",
  FETCH_ALL_PRICE_ESCALATION_RESET: "FETCH_ALL_PRICE_ESCALATION_RESET",
  FETCH_ALL_PRICE_ESCALATION_FAILURE: "FETCH_ALL_PRICE_ESCALATION_FAILURE",
  FETCH_ALL_PRICE_ESCALATION_SUCCESS: "FETCH_ALL_PRICE_ESCALATION_SUCCESS",
};
