import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AreaProgressActionTypes } from "./AreaProgress.type";

export function* fetchAllAreaProgresss(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/area-progress?${query}`);
    yield put({
      type: AreaProgressActionTypes.FETCH_ALL_AREA_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AreaProgressActionTypes.FETCH_ALL_AREA_PROGRESS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAreaProgresss(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/area-progress/${action.payload}`
    );
    yield put({
      type: AreaProgressActionTypes.FETCH_ONE_AREA_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AreaProgressActionTypes.FETCH_ONE_AREA_PROGRESS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAreaProgresss() {
  yield takeLatest(
    AreaProgressActionTypes.FETCH_ALL_AREA_PROGRESS,
    fetchAllAreaProgresss
  );
}

export function* watcherFetchOneAreaProgresss() {
  yield takeLatest(
    AreaProgressActionTypes.FETCH_ONE_AREA_PROGRESS,
    fetchOneAreaProgresss
  );
}
