import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { ApiCallState } from "../Utils";

export type ExternaVacancyAnnouncement= {
  id: number;
  vacancy_no:number;
  date:string;
  position:string;
  place_of_work_id:number;
  salary:number;
  terms_of_employment:string;
  required_no_of_personnel:number;
  gender:string;
  type_and_level_of_education:string;
  experience:string;
  special_skill:string;
  no_of_days:number;
  user_id:number;
  place_of_work:PlaceOfWork;
  external_vacancy_announcement_statuses:ExternaVacancyAnnouncementStatus[];
};

export type ExternaVacancyAnnouncementStatus = {
    external_vacancy_announcement_id:number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    id: number;
  };

export type ExternaVacancyAnnouncementStateTypes = {
  fetchAll: ApiCallState<ExternaVacancyAnnouncement[]>;
  fetchOne: ApiCallState<ExternaVacancyAnnouncement | {}>;
};

export const ExternaVacancyAnnouncementActionTypes = {
  FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT: "FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT",
  FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_RESET: "FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_RESET",
  FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE: "FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE",
  FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS: "FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS",

  FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT: "FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT",
  FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_RESET: "FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_RESET",
  FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE: "FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE",
  FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS: "FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS",
};
