import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WorkOvertimeActionTypes } from "./WorkOvertime.type";

export function* fetchAllWorkOvertimes(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/work-overtime`);
    yield put({
      type: WorkOvertimeActionTypes.FETCH_ALL_WORK_OVERTIME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkOvertimeActionTypes.FETCH_ALL_WORK_OVERTIME_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWorkOvertimes(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/work-overtime/${action.payload}`
    );
    yield put({
      type: WorkOvertimeActionTypes.FETCH_ONE_WORK_OVERTIME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkOvertimeActionTypes.FETCH_ONE_WORK_OVERTIME_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWorkOvertimes() {
  yield takeLatest(WorkOvertimeActionTypes.FETCH_ALL_WORK_OVERTIME, fetchAllWorkOvertimes);
}

export function* watcherFetchOneWorkOvertimes() {
  yield takeLatest(WorkOvertimeActionTypes.FETCH_ONE_WORK_OVERTIME, fetchOneWorkOvertimes);
}
