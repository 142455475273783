import axios from "axios";
import { isNil } from "lodash";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ReceivableActionTypes } from "./Receivable.type";

export function* fetchAllReceivables(action: any): any {
  try {
    let query = "";
    if (!isNil(action.payload)) {
      let keys = Object.keys(action.payload);

      let mapped: any = [];

      keys.forEach((key) => mapped.push(`${key}=${action.payload[key]}`));

      query = mapped.join("&&");
    }

    const response = yield axios.get(`${API_BASE_URI}/receivable?${query}`);
    yield put({
      type: ReceivableActionTypes.FETCH_ALL_RECEIVABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReceivableActionTypes.FETCH_ALL_RECEIVABLE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneReceivables(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/receivable/${action.payload}`
    );
    yield put({
      type: ReceivableActionTypes.FETCH_ONE_RECEIVABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ReceivableActionTypes.FETCH_ONE_RECEIVABLE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllReceivables() {
  yield takeLatest(
    ReceivableActionTypes.FETCH_ALL_RECEIVABLE,
    fetchAllReceivables
  );
}

export function* watcherFetchOneReceivables() {
  yield takeLatest(
    ReceivableActionTypes.FETCH_ONE_RECEIVABLE,
    fetchOneReceivables
  );
}
