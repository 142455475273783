import { ApiCallState } from "../Utils";

export const SupplierActions = {
  FETCH_SUPPLIER: "FETCH_SUPPLIER",
  FETCH_SUPPLIER_SUCCESS: "FETCH_SUPPLIER_SUCCESS",
  FETCH_SUPPLIER_ERROR: "FETCH_SUPPLIER_ERROR",
};

export type Supplier = {
  id: number;
  name: string;
  tin_number: string;
  address: string;
  phone_number: string;
  type: string;
};

export type SupplierStateTypes = {
  fetchAll: ApiCallState<Supplier[]>;
};
