import { ApiCallState } from "../Utils";

export type Renter = {
  id: any;
  name: string;
  address: string;
  phone_number: string;
};

export type RenterStateTypes = {
  fetchAll: ApiCallState<Renter[]>;
};

export const RenterActionTypes = {
  FETCH_ALL_RENTER: "FETCH_ALL_RENTER",
  FETCH_ALL_RENTER_RESET: "FETCH_ALL_RENTER_RESET",
  FETCH_ALL_RENTER_FAILURE: "FETCH_ALL_RENTER_FAILURE",
  FETCH_ALL_RENTER_SUCCESS: "FETCH_ALL_RENTER_SUCCESS",
};
