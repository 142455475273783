import axios from "axios";
import { isNil } from "lodash";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PayableActionTypes } from "./Payable.type";

export function* fetchAllPayables(action: any): any {
  try {
    let query = "";
    if (!isNil(action.payload)) {
      let keys = Object.keys(action.payload);

      let mapped: any = [];

      keys.forEach((key) => mapped.push(`${key}=${action.payload[key]}`));

      query = mapped.join("&&");
    }

    const response = yield axios.get(`${API_BASE_URI}/payable?${query}`);
    yield put({
      type: PayableActionTypes.FETCH_ALL_PAYABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayableActionTypes.FETCH_ALL_PAYABLE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePayables(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payable/${action.payload}`
    );
    yield put({
      type: PayableActionTypes.FETCH_ONE_PAYABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayableActionTypes.FETCH_ONE_PAYABLE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayables() {
  yield takeLatest(PayableActionTypes.FETCH_ALL_PAYABLE, fetchAllPayables);
}

export function* watcherFetchOnePayables() {
  yield takeLatest(PayableActionTypes.FETCH_ONE_PAYABLE, fetchOnePayables);
}
