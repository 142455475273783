import { ApiCallState } from "../../Utils";

export type PartnerMessage = {
  id: number;
  user_id: number;

  partner_ids: number[];
  message: string;
  date: Date;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type PartnerMessageStateTypes = {
  fetchAll: ApiCallState<PartnerMessage[]>;
  fetchOne: ApiCallState<PartnerMessage | {}>;
};

export const PartnerMessageActionTypes = {
  FETCH_ALL_PARTNER_MESSAGE: "FETCH_ALL_PARTNER_MESSAGE",
  FETCH_ALL_PARTNER_MESSAGE_RESET: "FETCH_ALL_PARTNER_MESSAGE_RESET",
  FETCH_ALL_PARTNER_MESSAGE_FAILURE: "FETCH_ALL_PARTNER_MESSAGE_FAILURE",
  FETCH_ALL_PARTNER_MESSAGE_SUCCESS: "FETCH_ALL_PARTNER_MESSAGE_SUCCESS",

  FETCH_ONE_PARTNER_MESSAGE: "FETCH_ONE_PARTNER_MESSAGE",
  FETCH_ONE_PARTNER_MESSAGE_RESET: "FETCH_ONE_PARTNER_MESSAGE_RESET",
  FETCH_ONE_PARTNER_MESSAGE_FAILURE: "FETCH_ONE_PARTNER_MESSAGE_FAILURE",
  FETCH_ONE_PARTNER_MESSAGE_SUCCESS: "FETCH_ONE_PARTNER_MESSAGE_SUCCESS",
};
