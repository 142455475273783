import { ApartmentBuilding } from "../ApartmentBuilding/ApartmentBuilding/ApartmentBuilding.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type SalesReport = {
  id: number;
  user_id: number;
  date: string;
  report_period: string;
  phone_calls: number;
  sales_office: number;
  walk_in_customer: number;
  site_visiting: number;
  appointment: number;
  user: User;
  sales_report_items: SalesReportItem[];
  sales_report_potentials: SalesReportPotential[];
  sales_report_Actuals: SalesReportActual[];
};

export type SalesReportItem = {
  id: number;
  sales_report_id: number;
  name: string;
  remark: string;
};

export type SalesReportPotential = {
  id: number;
  sales_report_id: number;
  name: string;
  no_office: number;
  phone_no: string;
  remark: string;
};

export type SalesReportActual = {
  id: number;
  sales_report_id: number;
  name: string;
  sales_amount: number;
  apartment_building_id:number;
  floor:number;
  unit: string;
  apartment_building:ApartmentBuilding;
};

export type SalesReportStateTypes = {
  fetchAll: ApiCallState<SalesReport[]>;
  fetchOne: ApiCallState<SalesReport | {}>;
};

export const SalesReportActionTypes = {
  FETCH_ALL_SALES_REPORT: "FETCH_ALL_SALES_REPORT",
  FETCH_ALL_SALES_REPORT_RESET: "FETCH_ALL_SALES_REPORT_RESET",
  FETCH_ALL_SALES_REPORT_FAILURE: "FETCH_ALL_SALES_REPORT_FAILURE",
  FETCH_ALL_SALES_REPORT_SUCCESS: "FETCH_ALL_SALES_REPORT_SUCCESS",

  FETCH_ONE_SALES_REPORT: "FETCH_ONE_SALES_REPORT",
  FETCH_ONE_SALES_REPORT_RESET: "FETCH_ONE_SALES_REPORT_RESET",
  FETCH_ONE_SALES_REPORT_FAILURE: "FETCH_ONE_SALES_REPORT_FAILURE",
  FETCH_ONE_SALES_REPORT_SUCCESS: "FETCH_ONE_SALES_REPORT_SUCCESS",
};
