import { ApiCallState } from "../../Utils";
import { ApartmentBuilding } from "../ApartmentBuilding/ApartmentBuilding.type";

export type ApartmentUnit = {
  id: number;
  name: string;
  floor: number;
  size: number;
  unit_price: number;
  area_with_corridor: number;
  bedroom: number;
  common_area: number;
  parking: string;
  status: string;
  apartment_id: number;
  sold_date: Date | undefined;
  sold_amount: number | undefined;
  apartment_building: ApartmentBuilding;
};

export type ApartmentUnitStateTypes = {
  fetchAll: ApiCallState<ApartmentUnit[]>;
  fetchOne: ApiCallState<ApartmentUnit | {}>;
};

export const ApartmentUnitActionTypes = {
  FETCH_ALL_APARTMENT_UNITS: "FETCH_ALL_APARTMENT_UNITS",
  FETCH_ALL_APARTMENT_UNITS_RESET: "FETCH_ALL_APARTMENT_UNITS_RESET",
  FETCH_ALL_APARTMENT_UNITS_FAILURE: "FETCH_ALL_APARTMENT_UNITS_FAILURE",
  FETCH_ALL_APARTMENT_UNITS_SUCCESS: "FETCH_ALL_APARTMENT_UNITS_SUCCESS",

  FETCH_ONE_APARTMENT_UNIT: "FETCH_ONE_APARTMENT_UNIT",
  FETCH_ONE_APARTMENT_UNIT_RESET: "FETCH_ONE_APARTMENT_UNIT_RESET",
  FETCH_ONE_APARTMENT_UNIT_FAILURE: "FETCH_ONE_APARTMENT_UNIT_FAILURE",
  FETCH_ONE_APARTMENT_UNIT_SUCCESS: "FETCH_ONE_APARTMENT_UNIT_SUCCESS",
};
