import { Boq } from "../Boq/Boq.type";
import { Labour } from "../Labour/Labour.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Id = {
  labour: Labour | null;
  staff: Staff | null;
  type: string;
};

export type IdStateTypes = Id;

export const IdActionTypes = {
  SET_ID: "SET_ID",
};
