import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AttendanceActionTypes } from "./Attendance.type";

export function* fetchAllAttendances(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/attendance?department_id=${action.payload?.department_id}&date=${action.payload?.date}`);
    yield put({
      type: AttendanceActionTypes.FETCH_ALL_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttendanceActionTypes.FETCH_ALL_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAttendances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/attendance/${action.payload}`
    );
    yield put({
      type: AttendanceActionTypes.FETCH_ONE_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttendanceActionTypes.FETCH_ONE_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAttendances() {
  yield takeLatest(AttendanceActionTypes.FETCH_ALL_ATTENDANCE, fetchAllAttendances);
}

export function* watcherFetchOneAttendances() {
  yield takeLatest(AttendanceActionTypes.FETCH_ONE_ATTENDANCE, fetchOneAttendances);
}
