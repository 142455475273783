import { BankAccount } from "../BankAccount/BankAccount.type";
import { PettyCash } from "../PettyCash/PettyCash.type";
import { ReplenishmentTransaction } from "../ReplenishTransaction/ReplenishmentTransaction.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Replenishment = {
  id: number;

  user_id: number;
  petty_cash_id: number;

  request_number: string;

  bank_account_id: number;
  from: string;

  payment_type: string;
  transaction_number: string;

  amount: number;
  current_amount: number;

  user: User;
  petty_cash: PettyCash;
  bank_account: BankAccount;
  replenishment_transactions: ReplenishmentTransaction[];

  readonly createdAt: Date;
};

export type ReplenishmentStateTypes = {
  fetchAll: ApiCallState<Replenishment[]>;
  fetchOne: ApiCallState<Replenishment | {}>;
};

export const ReplenishmentActionTypes = {
  FETCH_ALL_REPLENISHMENT: "FETCH_ALL_REPLENISHMENT",
  FETCH_ALL_REPLENISHMENT_RESET: "FETCH_ALL_REPLENISHMENT_RESET",
  FETCH_ALL_REPLENISHMENT_FAILURE: "FETCH_ALL_REPLENISHMENT_FAILURE",
  FETCH_ALL_REPLENISHMENT_SUCCESS: "FETCH_ALL_REPLENISHMENT_SUCCESS",

  FETCH_ONE_REPLENISHMENT: "FETCH_ONE_REPLENISHMENT",
  FETCH_ONE_REPLENISHMENT_RESET: "FETCH_ONE_REPLENISHMENT_RESET",
  FETCH_ONE_REPLENISHMENT_FAILURE: "FETCH_ONE_REPLENISHMENT_FAILURE",
  FETCH_ONE_REPLENISHMENT_SUCCESS: "FETCH_ONE_REPLENISHMENT_SUCCESS",
};
