import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentRetentionActionTypes } from "./PaymentRetention.type";

export function* fetchAllPaymentRetention(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment/retention`);
    yield put({
      type: PaymentRetentionActionTypes.FETCH_ALL_PAYMENT_RETENTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentRetentionActionTypes.FETCH_ALL_PAYMENT_RETENTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentRetention() {
  yield takeLatest(
    PaymentRetentionActionTypes.FETCH_ALL_PAYMENT_RETENTION,
    fetchAllPaymentRetention
  );
}
