import { ApiCallState } from "../../Utils";
import { User } from "../../User/User.type";

export type DailyReport = {
  id: number;
  date: Date;
  location: string;
  specific_location?: string;
  no_outbound_calls: number;
  no_inbound_calls: number;
  no_site_visit: number;
  booking_status: string;
  booking: Booking[];
  deals_closed: string;
  customers: Customer[];
  prepared_by: number;
  user?: User;
};

export type Booking = {
  key: number;
  name: string;
  booking_amount: number;
  deadline: Date;
  apartment_building: number;
  floor: number;
  apartment_unit: number;
};
export type Customer = {
  key: number;
  customer_name: string;
  phone_number: string;
  apartment_building: number;
  floor: number;
  apartment_unit: number;
};

export type DailyReportStateTypes = {
  fetchAll: ApiCallState<DailyReport[]>;
  fetchOne: ApiCallState<DailyReport | {}>;
};

export const DailyReportActionTypes = {
  FETCH_ALL_DAILY_REPORTS: "FETCH_ALL_DAILY_REPORTS",
  FETCH_ALL_DAILY_REPORTS_RESET: "FETCH_ALL_DAILY_REPORTS_RESET",
  FETCH_ALL_DAILY_REPORTS_FAILURE: "FETCH_ALL_DAILY_REPORTS_FAILURE",
  FETCH_ALL_DAILY_REPORTS_SUCCESS: "FETCH_ALL_DAILY_REPORTS_SUCCESS",

  FETCH_ONE_DAILY_REPORT: "FETCH_ONE_DAILY_REPORT",
  FETCH_ONE_DAILY_REPORT_RESET: "FETCH_ONE_DAILY_REPORT_RESET",
  FETCH_ONE_DAILY_REPORT_FAILURE: "FETCH_ONE_DAILY_REPORT_FAILURE",
  FETCH_ONE_DAILY_REPORT_SUCCESS: "FETCH_ONE_DAILY_REPORT_SUCCESS",
};
