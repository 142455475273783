import { ExternaVacancyAnnouncementStateTypes, ExternaVacancyAnnouncementActionTypes } from "./ExternaVacancyAnnouncement.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ExternaVacancyAnnouncementStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ExternaVacancyAnnouncementReducer = (
  state: ExternaVacancyAnnouncementStateTypes = INITIAL_STATE,
  action: any
): ExternaVacancyAnnouncementStateTypes => {
  switch (action.type) {
    case ExternaVacancyAnnouncementActionTypes.FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExternaVacancyAnnouncementActionTypes.FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ExternaVacancyAnnouncementActionTypes.FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExternaVacancyAnnouncementActionTypes.FETCH_ALL_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ExternaVacancyAnnouncementActionTypes.FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExternaVacancyAnnouncementActionTypes.FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ExternaVacancyAnnouncementActionTypes.FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExternaVacancyAnnouncementActionTypes.FETCH_ONE_EXTERNAL_VACANCY_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ExternaVacancyAnnouncementReducer;
