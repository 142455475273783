import { AccountStateTypes, AccountActionTypes } from "./Account.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AccountStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AccountReducer = (
  state: AccountStateTypes = INITIAL_STATE,
  action: any
): AccountStateTypes => {
  switch (action.type) {
    case AccountActionTypes.FETCH_ALL_ACCOUNT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountActionTypes.FETCH_ALL_ACCOUNT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AccountActionTypes.FETCH_ALL_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountActionTypes.FETCH_ALL_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AccountActionTypes.FETCH_ONE_ACCOUNT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AccountActionTypes.FETCH_ONE_ACCOUNT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AccountActionTypes.FETCH_ONE_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AccountActionTypes.FETCH_ONE_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AccountReducer;
