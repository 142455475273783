import { TourActionTypes } from "./Tour.type";

/**
 * Start Tours
 *
 * @param payload
 */
export const StartTour = (payload?: any) => ({
  type: TourActionTypes.START,
  payload: payload,
});

/**
 * Reset Tours
 *
 * @param payload
 */
export const ResetTour = (payload?: any) => ({
  type: TourActionTypes.RESET,
  payload: payload,
});

/**
 * Restart Tours
 *
 * @param payload
 */
export const RestartTour = (payload?: any) => ({
  type: TourActionTypes.RESTART,
  payload: payload,
});

/**
 * Reset Tours
 *
 * @param payload
 */
export const NextTour = (payload?: any) => ({
  type: TourActionTypes.NEXT_OR_PREVIOUS,
  payload: payload,
});

/**
 * Stop Tours
 *
 * @param payload
 */
export const StopTour = (payload?: any) => ({
  type: TourActionTypes.STOP,
  payload: payload,
});

/**
 * Stop Tours
 *
 * @param payload
 */
export const setStep = (payload?: any) => ({
  type: TourActionTypes.SET_STEP,
  payload: payload,
});
