import { ApiCallState } from "../Utils";

export type TaskMeeting = {
  id: any;
  date: string;
  type: string;
  location: string;
  company: string;
  title:string;
  meeting_no:number;
  meeting_attendances: MeetingAttendance[];
  task_meeting_agendas: MeetingAgenda[];
  task_meeting_agenda_discussions: MeetingAgendaDiscussion[];
  task_meeting_action_plans: MeetingActionPlan[];
  task_meeting_incoming_documents: MeetingIncomingDocument[];
  task_meeting_participants: MeetingParticipant[];
  task_meeting_previous_corrections: MeetingPreviousCorrection[];
  task_meeting_additional_issues: MeetingAdditionalIssue[];
  next_location: string;
  next_date: string;
  no: any;
  next_time: string;
};

export type TaskMeetingStateTypes = {
  fetchAll: ApiCallState<TaskMeeting[]>;
  fetchOne: ApiCallState<TaskMeeting | {}>;
};

export const TaskMeetingActionTypes = {
  FETCH_ALL_TASK_MEETING: "FETCH_ALL_TASK_MEETING",
  FETCH_ALL_TASK_MEETING_RESET: "FETCH_ALL_TASK_MEETING_RESET",
  FETCH_ALL_TASK_MEETING_FAILURE: "FETCH_ALL_TASK_MEETING_FAILURE",
  FETCH_ALL_TASK_MEETING_SUCCESS: "FETCH_ALL_TASK_MEETING_SUCCESS",

  FETCH_ONE_TASK_MEETING: "FETCH_ONE_TASK_MEETING",
  FETCH_ONE_TASK_MEETING_RESET: "FETCH_ONE_TASK_MEETING_RESET",
  FETCH_ONE_TASK_MEETING_FAILURE: "FETCH_ONE_TASK_MEETING_FAILURE",
  FETCH_ONE_TASK_MEETING_SUCCESS: "FETCH_ONE_TASK_MEETING_SUCCESS",
};

type MeetingDocuments = {
  id: any;
  description: string;
};

type MeetingAttendance = {
  id: number;
  full_name: string;
  role: string;
  task_meeting_attendance: {
    is_approved: boolean;
    id: number;
    user_id: number;
  };
};
type TaskMeetingAttendance = {
  id: number;
  full_name: string;
  role: string;
  meeting_attendance: {
    is_approved: boolean;
    id: number;
    user_id: number;
  };
};

export type MeetingParticipant = {
  id: number;
  name: string;
  position: string;
};

type MeetingActionPlan = {
  id: any;
  schedule_by: string;
  assigned_to: string;
  task: string;
};

type MeetingAgenda = {
  id: any;
  description: string;
};

type MeetingAgendaDiscussion = {
  id: any;
  description: string;
};

type MeetingGeneralWorkProgress = {
  id: any;
  description: string;
  status: string;
};

type MeetingAdditionalIssue = {
  id: any;
  description: string;
};

type MeetingIncomingDocument = {
  id: any;
  description: string;
};

type MeetingOpeningRemarks = {
  id: any;
  description: string;
};

type MeetingPreviousCorrection = {
  id: any;
  description: string;
};
