import {
  VoucherAuthorizerStateTypes,
  VoucherAuthorizerActionTypes,
} from "./VoucherAuthorizer.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: VoucherAuthorizerStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const VoucherAuthorizerReducer = (
  state: VoucherAuthorizerStateTypes = INITIAL_STATE,
  action: any
): VoucherAuthorizerStateTypes => {
  switch (action.type) {
    case VoucherAuthorizerActionTypes.FETCH_ALL_VOUCHER_AUTHORIZER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case VoucherAuthorizerActionTypes.FETCH_ALL_VOUCHER_AUTHORIZER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case VoucherAuthorizerActionTypes.FETCH_ALL_VOUCHER_AUTHORIZER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VoucherAuthorizerActionTypes.FETCH_ALL_VOUCHER_AUTHORIZER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case VoucherAuthorizerActionTypes.FETCH_ONE_VOUCHER_AUTHORIZER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case VoucherAuthorizerActionTypes.FETCH_ONE_VOUCHER_AUTHORIZER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case VoucherAuthorizerActionTypes.FETCH_ONE_VOUCHER_AUTHORIZER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case VoucherAuthorizerActionTypes.FETCH_ONE_VOUCHER_AUTHORIZER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default VoucherAuthorizerReducer;
