import { Document } from "../Document/Document.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type VariationFile = {
  id: number;
  reason: string;
  document: Document;
  project_id: number;
  variation_checked_by: User;
  variation_approved_by: User;
  is_checked: boolean;
  is_approved: boolean;
  checked_by: number;
  approved_by: number;
  date: string;
};

export type VariationFileStateTypes = {
  fetchAll: ApiCallState<VariationFile[]>;
  fetchOne: ApiCallState<VariationFile | {}>;
};

export const VariationFileActionTypes = {
  FETCH_ALL_VARIATION_FILE: "FETCH_ALL_VARIATION_FILE",
  FETCH_ALL_VARIATION_FILE_RESET: "FETCH_ALL_VARIATION_FILE_RESET",
  FETCH_ALL_VARIATION_FILE_FAILURE: "FETCH_ALL_VARIATION_FILE_FAILURE",
  FETCH_ALL_VARIATION_FILE_SUCCESS: "FETCH_ALL_VARIATION_FILE_SUCCESS",

  FETCH_ONE_VARIATION_FILE: "FETCH_ONE_VARIATION_FILE",
  FETCH_ONE_VARIATION_FILE_RESET: "FETCH_ONE_VARIATION_FILE_RESET",
  FETCH_ONE_VARIATION_FILE_FAILURE: "FETCH_ONE_VARIATION_FILE_FAILURE",
  FETCH_ONE_VARIATION_FILE_SUCCESS: "FETCH_ONE_VARIATION_FILE_SUCCESS",
};
