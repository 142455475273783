import { Document } from "../Document/Document.type";
import { Material } from "../Material/Material.type";
import { Staff } from "../Staff/Staff.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Resignation = {
  id: number;
  date: string;
  reason:string;
  document?: Document;
  user_id: number;
  staff_id:number;
  prepared_by:number;
  approved_by:number;
  staff:Staff;
  resignation_statuses: ResignationStatus[];
};

export type ResignationStatus = {
  resignation_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};


export type ResignationStateTypes = {
  fetchAll: ApiCallState<Resignation[]>;
  fetchOne: ApiCallState<Resignation | {}>;
};

export const ResignationActionTypes = {
  FETCH_ALL_RESIGNATION: "FETCH_ALL_RESIGNATION",
  FETCH_ALL_RESIGNATION_RESET: "FETCH_ALL_RESIGNATION_RESET",
  FETCH_ALL_RESIGNATION_FAILURE: "FETCH_ALL_RESIGNATION_FAILURE",
  FETCH_ALL_RESIGNATION_SUCCESS: "FETCH_ALL_RESIGNATION_SUCCESS",

  FETCH_ONE_RESIGNATION: "FETCH_ONE_RESIGNATION",
  FETCH_ONE_RESIGNATION_RESET: "FETCH_ONE_RESIGNATION_RESET",
  FETCH_ONE_RESIGNATION_FAILURE: "FETCH_ONE_RESIGNATION_FAILURE",
  FETCH_ONE_RESIGNATION_SUCCESS: "FETCH_ONE_RESIGNATION_SUCCESS",
};
