import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type WeeklySalesPlan= {
  id: number;
  planned_week:string;
  date:string;
  user_id: number;
  phone_calls: number;
  sales_office: number;
  walk_in_customer: number;
  site_visiting: number;
  appointment: number;
  potential_sale:number;
  actual_sale:number;
  user: User;
};

export type WeeklySalesPlanStateTypes = {
  fetchAll: ApiCallState<WeeklySalesPlan[]>;
  fetchOne: ApiCallState<WeeklySalesPlan | {}>;
};

export const WeeklySalesPlanActionTypes = {
  FETCH_ALL_WEEKLY_SALES_PLAN: "FETCH_ALL_WEEKLY_SALES_PLAN",
  FETCH_ALL_WEEKLY_SALES_PLAN_RESET: "FETCH_ALL_WEEKLY_SALES_PLAN_RESET",
  FETCH_ALL_WEEKLY_SALES_PLAN_FAILURE: "FETCH_ALL_WEEKLY_SALES_PLAN_FAILURE",
  FETCH_ALL_WEEKLY_SALES_PLAN_SUCCESS: "FETCH_ALL_WEEKLY_SALES_PLAN_SUCCESS",

  FETCH_ONE_WEEKLY_SALES_PLAN: "FETCH_ONE_WEEKLY_SALES_PLAN",
  FETCH_ONE_WEEKLY_SALES_PLAN_RESET: "FETCH_ONE_WEEKLY_SALES_PLAN_RESET",
  FETCH_ONE_WEEKLY_SALES_PLAN_FAILURE: "FETCH_ONE_WEEKLY_SALES_PLAN_FAILURE",
  FETCH_ONE_WEEKLY_SALES_PLAN_SUCCESS: "FETCH_ONE_WEEKLY_SALES_PLAN_SUCCESS",
};
