import { InitPagedData } from "../Utils";
import { TeamStateTypes, TeamActionTypes } from "./Team.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TeamStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllWithMembers: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const TeamReducer = (
  state: TeamStateTypes = INITIAL_STATE,
  action: any
): TeamStateTypes => {
  switch (action.type) {
    case TeamActionTypes.FETCH_ALL_TEAM:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TeamActionTypes.FETCH_ALL_TEAM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TeamActionTypes.FETCH_ALL_TEAM_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TeamActionTypes.FETCH_ALL_TEAM_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------

    case TeamActionTypes.FETCH_ALL_TEAM_WITH_MEMBERS:
      return {
        ...state,
        fetchAllWithMembers: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TeamActionTypes.FETCH_ALL_TEAM_WITH_MEMBERS_RESET:
      return {
        ...state,
        fetchAllWithMembers: resetApiCallState([]),
      };
    case TeamActionTypes.FETCH_ALL_TEAM_WITH_MEMBERS_FAILURE:
      return {
        ...state,
        fetchAllWithMembers: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TeamActionTypes.FETCH_ALL_TEAM_WITH_MEMBERS_SUCCESS:
      return {
        ...state,
        fetchAllWithMembers: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------

    case TeamActionTypes.FETCH_PAGED_TEAM:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case TeamActionTypes.FETCH_PAGED_TEAM_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case TeamActionTypes.FETCH_PAGED_TEAM_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TeamActionTypes.FETCH_PAGED_TEAM_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TeamActionTypes.FETCH_ONE_TEAM:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TeamActionTypes.FETCH_ONE_TEAM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TeamActionTypes.FETCH_ONE_TEAM_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TeamActionTypes.FETCH_ONE_TEAM_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TeamReducer;
