import { CandidatesResultStateTypes, CandidatesResultActionTypes } from "./CandidatesResult.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: CandidatesResultStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const CandidatesResultReducer = (
  state: CandidatesResultStateTypes = INITIAL_STATE,
  action: any
): CandidatesResultStateTypes => {
  switch (action.type) {
    case CandidatesResultActionTypes.FETCH_ALL_CANDIDATES_RESULT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case CandidatesResultActionTypes.FETCH_ALL_CANDIDATES_RESULT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CandidatesResultActionTypes.FETCH_ALL_CANDIDATES_RESULT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CandidatesResultActionTypes.FETCH_ALL_CANDIDATES_RESULT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case CandidatesResultActionTypes.FETCH_ONE_CANDIDATES_RESULT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case CandidatesResultActionTypes.FETCH_ONE_CANDIDATES_RESULT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CandidatesResultActionTypes.FETCH_ONE_CANDIDATES_RESULT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CandidatesResultActionTypes.FETCH_ONE_CANDIDATES_RESULT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CandidatesResultReducer;
