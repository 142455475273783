import { Grade } from "../Grade/Grade.type";
import { ApiCallState } from "../Utils";

export type Scale = {
  id: number;
  date: string;
  no: number;
  percentage: number;
  scale_items: ScaleItem[]
};

type ScaleItem = {
  id: number;
  starting_salary: number;
  grade_id: number;
  grade: Grade;
};

export type ScaleStateTypes = {
  fetchAll: ApiCallState<Scale[]>;
  fetchOne: ApiCallState<Scale | {}>;
};

export const ScaleActionTypes = {
  FETCH_ALL_SCALE: "FETCH_ALL_SCALE",
  FETCH_ALL_SCALE_RESET: "FETCH_ALL_SCALE_RESET",
  FETCH_ALL_SCALE_FAILURE: "FETCH_ALL_SCALE_FAILURE",
  FETCH_ALL_SCALE_SUCCESS: "FETCH_ALL_SCALE_SUCCESS",

  FETCH_ONE_SCALE: "FETCH_ONE_SCALE",
  FETCH_ONE_SCALE_RESET: "FETCH_ONE_SCALE_RESET",
  FETCH_ONE_SCALE_FAILURE: "FETCH_ONE_SCALE_FAILURE",
  FETCH_ONE_SCALE_SUCCESS: "FETCH_ONE_SCALE_SUCCESS",
};
