import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeExtraActionTypes } from "./EmployeeExtra.type";

export function* fetchAllEmployeeExtras(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/employee_extra`);
    yield put({
      type: EmployeeExtraActionTypes.FETCH_ALL_EMPLOYEE_EXTRA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeExtraActionTypes.FETCH_ALL_EMPLOYEE_EXTRA_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeExtras() {
  yield takeLatest(
    EmployeeExtraActionTypes.FETCH_ALL_EMPLOYEE_EXTRA,
    fetchAllEmployeeExtras
  );
}
