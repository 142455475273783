import { ApiCallState } from "../Utils";

export type ApartmentEstimateCost= {
  id: number;
  date: string;
  amount: number
};

export type ApartmentEstimateCostStateTypes = {
  fetchAll: ApiCallState<ApartmentEstimateCost[]>;
  fetchOne: ApiCallState<ApartmentEstimateCost | {}>;
};

export const ApartmentEstimateCostActionTypes = {
  FETCH_ALL_APARTMENT_ESTIMATE_COST: "FETCH_ALL_APARTMENT_ESTIMATE_COST",
  FETCH_ALL_APARTMENT_ESTIMATE_COST_RESET: "FETCH_ALL_APARTMENT_ESTIMATE_COST_RESET",
  FETCH_ALL_APARTMENT_ESTIMATE_COST_FAILURE: "FETCH_ALL_APARTMENT_ESTIMATE_COST_FAILURE",
  FETCH_ALL_APARTMENT_ESTIMATE_COST_SUCCESS: "FETCH_ALL_APARTMENT_ESTIMATE_COST_SUCCESS",

  FETCH_ONE_APARTMENT_ESTIMATE_COST: "FETCH_ONE_APARTMENT_ESTIMATE_COST",
  FETCH_ONE_APARTMENT_ESTIMATE_COST_RESET: "FETCH_ONE_APARTMENT_ESTIMATE_COST_RESET",
  FETCH_ONE_APARTMENT_ESTIMATE_COST_FAILURE: "FETCH_ONE_APARTMENT_ESTIMATE_COST_FAILURE",
  FETCH_ONE_APARTMENT_ESTIMATE_COST_SUCCESS: "FETCH_ONE_APARTMENT_ESTIMATE_COST_SUCCESS",
};
