import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BuildingActionTypes } from "./Building.type";

export function* fetchAllBuildings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/building`);
    yield put({
      type: BuildingActionTypes.FETCH_ALL_BUILDING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BuildingActionTypes.FETCH_ALL_BUILDING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBuildings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/building/${action.payload}`
    );
    yield put({
      type: BuildingActionTypes.FETCH_ONE_BUILDING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BuildingActionTypes.FETCH_ONE_BUILDING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBuildings() {
  yield takeLatest(BuildingActionTypes.FETCH_ALL_BUILDING, fetchAllBuildings);
}

export function* watcherFetchOneBuildings() {
  yield takeLatest(BuildingActionTypes.FETCH_ONE_BUILDING, fetchOneBuildings);
}
