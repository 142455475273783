import { ApiCallState } from "../Utils";
import { Staff } from "../Staff/Staff.type";

export type Bonus = {
  id: number;
  date: string;
  bonus_amount: number;
  bonus_type: string;
  remark: string;
  employee_review_id: any;
  staff_id: any;
  staffs: Staff[] | [];
};

export type BonusStateTypes = {
  fetchAll: ApiCallState<Bonus[]>;
};

export const BonusActionTypes = {
  FETCH_ALL_BONUS: "FETCH_ALL_BONUS",
  FETCH_ALL_BONUS_RESET: "FETCH_ALL_BONUS_RESET",
  FETCH_ALL_BONUS_FAILURE: "FETCH_ALL_BONUS_FAILURE",
  FETCH_ALL_BONUS_SUCCESS: "FETCH_ALL_BONUS_SUCCESS",
};
