import {
  MaterialPlanStateTypes,
  MaterialPlanActionTypes,
} from "./MaterialPlan.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialPlanStateTypes = {
  fetchAll: resetApiCallState([]),
};

const MaterialPlanReducer = (
  state: MaterialPlanStateTypes = INITIAL_STATE,
  action: any
): MaterialPlanStateTypes => {
  switch (action.type) {
    case MaterialPlanActionTypes.FETCH_ALL_MATERIAL_PLAN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialPlanActionTypes.FETCH_ALL_MATERIAL_PLAN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialPlanActionTypes.FETCH_ALL_MATERIAL_PLAN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialPlanActionTypes.FETCH_ALL_MATERIAL_PLAN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialPlanReducer;
