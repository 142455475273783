import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqCategoryActionTypes } from "./BoqCategory.type";

export function* fetchAllBoqCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq-category?${query}`);
    yield put({
      type: BoqCategoryActionTypes.FETCH_ALL_BOQ_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqCategoryActionTypes.FETCH_ALL_BOQ_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedBoqCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq-category?${query}`);
    yield put({
      type: BoqCategoryActionTypes.FETCH_PAGED_BOQ_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqCategoryActionTypes.FETCH_PAGED_BOQ_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoqCategorys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq-category/${action.payload}`
    );
    yield put({
      type: BoqCategoryActionTypes.FETCH_ONE_BOQ_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqCategoryActionTypes.FETCH_ONE_BOQ_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqCategorys() {
  yield takeLatest(
    BoqCategoryActionTypes.FETCH_ALL_BOQ_CATEGORY,
    fetchAllBoqCategorys
  );
}

export function* watcherFetchPagedBoqCategorys() {
  yield takeLatest(
    BoqCategoryActionTypes.FETCH_PAGED_BOQ_CATEGORY,
    fetchPagedBoqCategorys
  );
}

export function* watcherFetchOneBoqCategorys() {
  yield takeLatest(
    BoqCategoryActionTypes.FETCH_ONE_BOQ_CATEGORY,
    fetchOneBoqCategorys
  );
}
