import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BankContactActionTypes } from "./BankContact.type";

export function* fetchAllBankContacts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/bank-contact`);
    yield put({
      type: BankContactActionTypes.FETCH_ALL_BANK_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankContactActionTypes.FETCH_ALL_BANK_CONTACT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBankContacts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/bank-contact/${action.payload}`
    );
    yield put({
      type: BankContactActionTypes.FETCH_ONE_BANK_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankContactActionTypes.FETCH_ONE_BANK_CONTACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBankContacts() {
  yield takeLatest(BankContactActionTypes.FETCH_ALL_BANK_CONTACT, fetchAllBankContacts);
}

export function* watcherFetchOneBankContacts() {
  yield takeLatest(BankContactActionTypes.FETCH_ONE_BANK_CONTACT, fetchOneBankContacts);
}
