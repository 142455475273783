import { EquipmentInventory } from "../EquipmentInventory/EquipmentInventory.type";
import { Project, Store } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export const EquipmentTransferActions = {
  FETCH_EQUIPMENT_TRANSFER: "FETCH_EQUIPMENT_TRANSFER",
  FETCH_EQUIPMENT_TRANSFER_SUCCESS: "FETCH_EQUIPMENT_TRANSFER_SUCCESS",
  FETCH_EQUIPMENT_TRANSFER_ERROR: "FETCH_EQUIPMENT_TRANSFER_ERROR",
};

export type EquipmentTransfer = {
  id: number;
  date: Date;
  from_project: Project | null;
  to_project: Project | null;
  from_store: Store | null;
  to_store: Store | null;
  equipment_inventory: EquipmentInventory;
};

export type EquipmentTransferStateTypes = {
  fetchAll: ApiCallState<EquipmentTransfer[]>;
};
