import { Department } from "../Department/Department.type";
import { ApiCallState } from "../Utils";
import { Grade } from "../Grade/Grade.type";
import { ScaleItem } from "../ScaleItem/ScaleItem.type";
import { StaffTaxInformation } from "../StaffTaxInformation/StaffTaxInformation.type";
import { CostSharing } from "../CostSharing/CostSharing.type";
import { Leave } from "../Leave/Leave.type";
import { LeaveStartingBalance } from "../LeaveStartingBalance/LeaveStartingBalance.type";
import { LeaveSummary } from "../LeaveSummary/LeaveSummary.type";
import { User } from "../User/User.type";

export type Staff = {
  id: any;
  department_id: number;
  name: string;
  name_am: string;
  user_name_id:number;
  sex: string;
  description: string;
  salary: number;
  status: string;
  type: string;
  woreda: number;
  email: string;
  city: string;
  termination_date: Date;
  department: Department | null;
  date: string;
  end_date: string;
  birth_day: string;
  key: number;
  grade_id: number;
  // scale_item_id: number;
  emergency_contact: EmergencyContact;
  employee_histories: EmployeeHistory[];
  employee_requests: {
    request_type: string;
    date: string;
    leave_request: {
      paid: number;
      unpaid: number;
      type: string;
      description: string;
      start_date: string;
      end_date: string;
      leave_finance: string;
    } | null;
    loan_request: {
      repayment_type: string;
      repayment_amount: number;
      amount: number;
      paid: number;
      reason: string;
    } | null;
    overtime_request: {
      description: string;
      date: string;
      overtime_start: string;
      overtime_end: string;
    } | null;
    travel_request: null;
    benefit_request: {
      type: string;
      description: string;
      amount: number;
    } | null;
  }[];
  equipment_wo_staff: { task: string };
  phone_number: string;
  period: string;
  createdAt: string;
  photo: string;
  unique_uuid: string;
  work_location: string;

  religion: string;
  marital_status: string;
  blood_type: string;
  mother_full_name: string;
  grade: Grade;

  staff_tax_informations?: StaffTaxInformation[];
  cost_sharings?: CostSharing[]
  // scale_item:ScaleItem;
  leave?: Leave,
  leaves: Leave[],
  leave_starting_balance?: LeaveStartingBalance,
  leave_summaries: LeaveSummary[]
  user_name:User;
  position: string;
};

export type EmergencyContact = {
  id: number;
  name: string;
  phone_number: string;
  relation: string;
};

export type EmployeeHistory = {
  id: number;
  staff_id: number;
  department_id: number;
  salary: number;
  allowance: number;
  overtime_salary: number;
  type: string;
  change: string;
  date: string;
  createdAt: "2021-04-21T15:02:37.000Z";
  updatedAt: "2021-04-21T15:02:37.000Z";
};

export type StaffStateTypes = {
  fetchAll: ApiCallState<Staff[]>;
  fetchOne: ApiCallState<Staff | null>;
  fetchAllDetail: ApiCallState<Staff[]>;
};

export const StaffActionTypes = {
  FETCH_ALL_STAFF: "FETCH_ALL_STAFF",
  FETCH_ALL_STAFF_RESET: "FETCH_ALL_STAFF_RESET",
  FETCH_ALL_STAFF_FAILURE: "FETCH_ALL_STAFF_FAILURE",
  FETCH_ALL_STAFF_SUCCESS: "FETCH_ALL_STAFF_SUCCESS",

  FETCH_ALL_DETAIL_STAFF: "FETCH_ALL_DETAIL_STAFF",
  FETCH_ALL_STAFF_DETAIL_RESET: "FETCH_ALL_STAFF_DETAIL_RESET",
  FETCH_ALL_STAFF_DETAIL_FAILURE: "FETCH_ALL_STAFF_DETAIL_FAILURE",
  FETCH_ALL_STAFF_DETAIL_SUCCESS: "FETCH_ALL_STAFF_DETAIL_SUCCESS",

  FETCH_ONE_STAFF: "FETCH_ONE_STAFF",
  FETCH_ONE_STAFF_RESET: "FETCH_ONE_STAFF_RESET",
  FETCH_ONE_STAFF_FAILURE: "FETCH_ONE_STAFF_FAILURE",
  FETCH_ONE_STAFF_SUCCESS: "FETCH_ONE_STAFF_SUCCESS",
};
