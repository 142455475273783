import {
  AutomaticMessageStateTypes,
  AutomaticMessageActionTypes,
} from "./AutomaticMessage.type";
import { resetApiCallState } from "../../Utils";

const INITIAL_STATE: AutomaticMessageStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AutomaticMessageReducer = (
  state: AutomaticMessageStateTypes = INITIAL_STATE,
  action: any
): AutomaticMessageStateTypes => {
  switch (action.type) {
    case AutomaticMessageActionTypes.FETCH_ALL_AUTOMATIC_MESSAGE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AutomaticMessageActionTypes.FETCH_ALL_AUTOMATIC_MESSAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AutomaticMessageActionTypes.FETCH_ALL_AUTOMATIC_MESSAGE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AutomaticMessageActionTypes.FETCH_ALL_AUTOMATIC_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AutomaticMessageActionTypes.FETCH_ONE_AUTOMATIC_MESSAGE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AutomaticMessageActionTypes.FETCH_ONE_AUTOMATIC_MESSAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case AutomaticMessageActionTypes.FETCH_ONE_AUTOMATIC_MESSAGE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AutomaticMessageActionTypes.FETCH_ONE_AUTOMATIC_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AutomaticMessageReducer;
