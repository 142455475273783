import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { IOLetterActionTypes } from "./IOLetter.type";

export function* fetchAllIOLetters(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/io-letter?start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`
    );
    yield put({
      type: IOLetterActionTypes.FETCH_ALL_IO_LETTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IOLetterActionTypes.FETCH_ALL_IO_LETTER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneIOLetters(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/io-letter/${action.payload}`
    );
    yield put({
      type: IOLetterActionTypes.FETCH_ONE_IO_LETTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IOLetterActionTypes.FETCH_ONE_IO_LETTER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllIOLetters() {
  yield takeLatest(IOLetterActionTypes.FETCH_ALL_IO_LETTER, fetchAllIOLetters);
}

export function* watcherFetchOneIOLetters() {
  yield takeLatest(IOLetterActionTypes.FETCH_ONE_IO_LETTER, fetchOneIOLetters);
}
