import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ApartmentExpenseActionTypes } from "./ApartmentExpense.type";

export function* fetchAllApartmentExpenses(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/apartment-expense?type=${action.payload?.type}&date=${action.payload?.date}&year=${action.payload?.year}&building_id=${action.payload?.building_id}`);
    yield put({
      type: ApartmentExpenseActionTypes.FETCH_ALL_APARTMENT_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentExpenseActionTypes.FETCH_ALL_APARTMENT_EXPENSE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApartmentExpenses(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/apartment-expense/${action.payload}`
    );
    yield put({
      type: ApartmentExpenseActionTypes.FETCH_ONE_APARTMENT_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApartmentExpenseActionTypes.FETCH_ONE_APARTMENT_EXPENSE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApartmentExpenses() {
  yield takeLatest(ApartmentExpenseActionTypes.FETCH_ALL_APARTMENT_EXPENSE, fetchAllApartmentExpenses);
}

export function* watcherFetchOneApartmentExpenses() {
  yield takeLatest(ApartmentExpenseActionTypes.FETCH_ONE_APARTMENT_EXPENSE, fetchOneApartmentExpenses);
}
