import {
  PlaceOfWorkStateTypes,
  PlaceOfWorkActionTypes,
} from "./PlaceOfWork.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PlaceOfWorkStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PlaceOfWorkReducer = (
  state: PlaceOfWorkStateTypes = INITIAL_STATE,
  action: any
): PlaceOfWorkStateTypes => {
  switch (action.type) {
    case PlaceOfWorkActionTypes.FETCH_ALL_PLACE_OF_WORK:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PlaceOfWorkActionTypes.FETCH_ALL_PLACE_OF_WORK_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PlaceOfWorkActionTypes.FETCH_ALL_PLACE_OF_WORK_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PlaceOfWorkActionTypes.FETCH_ALL_PLACE_OF_WORK_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PlaceOfWorkActionTypes.FETCH_ONE_PLACE_OF_WORK:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PlaceOfWorkActionTypes.FETCH_ONE_PLACE_OF_WORK_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PlaceOfWorkActionTypes.FETCH_ONE_PLACE_OF_WORK_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PlaceOfWorkActionTypes.FETCH_ONE_PLACE_OF_WORK_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PlaceOfWorkReducer;
