import { ContractSalesStateTypes, ContractSalesActionTypes } from "./ContractSales.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ContractSalesStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ContractSalesReducer = (
  state: ContractSalesStateTypes = INITIAL_STATE,
  action: any
): ContractSalesStateTypes => {
  switch (action.type) {
    case ContractSalesActionTypes.FETCH_ALL_CONTRACT_SALES:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ContractSalesActionTypes.FETCH_ALL_CONTRACT_SALES_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ContractSalesActionTypes.FETCH_ALL_CONTRACT_SALES_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ContractSalesActionTypes.FETCH_ALL_CONTRACT_SALES_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ContractSalesActionTypes.FETCH_ONE_CONTRACT_SALES:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ContractSalesActionTypes.FETCH_ONE_CONTRACT_SALES_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ContractSalesActionTypes.FETCH_ONE_CONTRACT_SALES_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ContractSalesActionTypes.FETCH_ONE_CONTRACT_SALES_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ContractSalesReducer;
