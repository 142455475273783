import { Task } from "../Tasks/Task.type";
import { TaskSchedule } from "../TaskSchedule/TaskSchedule.type";
import { ApiCallState } from "../Utils";

export type TaskCategory = {
  id: number;
  department_id: number;
  name: string;
  task_schedule?:TaskSchedule

  readonly createdAt: Date;
  readonly updatedAt: Date;

  tasks: Task[];
};

export type TaskCategoryStateTypes = {
  fetchAll: ApiCallState<TaskCategory[]>;
  fetchAllForm: ApiCallState<TaskCategory[]>;
  fetchAllDetailed: ApiCallState<TaskCategory[]>;
  fetchOne: ApiCallState<TaskCategory | {}>;
};

export const TaskCategoryActionTypes = {
  FETCH_ALL_TASK_CATEGORY: "FETCH_ALL_TASK_CATEGORY",
  FETCH_ALL_TASK_CATEGORY_RESET: "FETCH_ALL_TASK_CATEGORY_RESET",
  FETCH_ALL_TASK_CATEGORY_FAILURE: "FETCH_ALL_TASK_CATEGORY_FAILURE",
  FETCH_ALL_TASK_CATEGORY_SUCCESS: "FETCH_ALL_TASK_CATEGORY_SUCCESS",

  FETCH_ALL_FORM_TASK_CATEGORY: "FETCH_ALL_FORM_TASK_CATEGORY",
  FETCH_ALL_FORM_TASK_CATEGORY_RESET: "FETCH_ALL_FORM_TASK_CATEGORY_RESET",
  FETCH_ALL_FORM_TASK_CATEGORY_FAILURE: "FETCH_ALL_FORM_TASK_CATEGORY_FAILURE",
  FETCH_ALL_FORM_TASK_CATEGORY_SUCCESS: "FETCH_ALL_FORM_TASK_CATEGORY_SUCCESS",

  FETCH_ALL_DETAILED_TASK_CATEGORY: "FETCH_ALL_DETAILED_TASK_CATEGORY",
  FETCH_ALL_DETAILED_TASK_CATEGORY_RESET:
    "FETCH_ALL_DETAILED_TASK_CATEGORY_RESET",
  FETCH_ALL_DETAILED_TASK_CATEGORY_FAILURE:
    "FETCH_ALL_DETAILED_TASK_CATEGORY_FAILURE",
  FETCH_ALL_DETAILED_TASK_CATEGORY_SUCCESS:
    "FETCH_ALL_DETAILED_TASK_CATEGORY_SUCCESS",

  FETCH_ONE_TASK_CATEGORY: "FETCH_ONE_TASK_CATEGORY",
  FETCH_ONE_TASK_CATEGORY_RESET: "FETCH_ONE_TASK_CATEGORY_RESET",
  FETCH_ONE_TASK_CATEGORY_FAILURE: "FETCH_ONE_TASK_CATEGORY_FAILURE",
  FETCH_ONE_TASK_CATEGORY_SUCCESS: "FETCH_ONE_TASK_CATEGORY_SUCCESS",
};
