import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { BookingActionTypes } from "./Booking.type";

export function* fetchAllBookings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/crm/booking?${query}`);
    yield put({
      type: BookingActionTypes.FETCH_ALL_BOOKINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BookingActionTypes.FETCH_ALL_BOOKINGS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBooking(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/crm/booking/${action.payload}`
    );
    yield put({
      type: BookingActionTypes.FETCH_ONE_BOOKING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BookingActionTypes.FETCH_ONE_BOOKING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBookings() {
  yield takeLatest(BookingActionTypes.FETCH_ALL_BOOKINGS, fetchAllBookings);
}

export function* watcherFetchOneBooking() {
  yield takeLatest(BookingActionTypes.FETCH_ONE_BOOKING, fetchOneBooking);
}
