import { Lead } from "../Lead/Lead.type";
import { ApiCallState } from "../Utils";

export type Activity = {
  id: number;
  date: Date;
  type: string;
  updates: string;
  next_steps: string;
  schedule: Date;
  user_id: number;
  lead_id: number;
  lead: Lead;
};

export type ActivityStateTypes = {
  fetchAll: ApiCallState<Activity[]>;
  fetchOne: ApiCallState<Activity | {}>;
};

export const ActivityActionTypes = {
  FETCH_ALL_ACTIVITY: "FETCH_ALL_ACTIVITY",
  FETCH_ALL_ACTIVITY_RESET: "FETCH_ALL_ACTIVITY_RESET",
  FETCH_ALL_ACTIVITY_FAILURE: "FETCH_ALL_ACTIVITY_FAILURE",
  FETCH_ALL_ACTIVITY_SUCCESS: "FETCH_ALL_ACTIVITY_SUCCESS",

  FETCH_ONE_ACTIVITY: "FETCH_ONE_ACTIVITY",
  FETCH_ONE_ACTIVITY_RESET: "FETCH_ONE_ACTIVITY_RESET",
  FETCH_ONE_ACTIVITY_FAILURE: "FETCH_ONE_ACTIVITY_FAILURE",
  FETCH_ONE_ACTIVITY_SUCCESS: "FETCH_ONE_ACTIVITY_SUCCESS",
};
