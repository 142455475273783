import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { BoqStandardActionTypes } from "./BoqStandard.type";

export function* fetchAllBoqStandard(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq_standard?type=${action.payload.type}`
    );
    yield put({
      type: BoqStandardActionTypes.FETCH_ALL_BOQ_STANDARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqStandardActionTypes.FETCH_ALL_BOQ_STANDARD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoqStandard(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq_standard/${action.payload}`
    );
    yield put({
      type: BoqStandardActionTypes.FETCH_ONE_BOQ_STANDARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqStandardActionTypes.FETCH_ONE_BOQ_STANDARD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqStandard() {
  yield takeLatest(
    BoqStandardActionTypes.FETCH_ALL_BOQ_STANDARD,
    fetchAllBoqStandard
  );
}

export function* watcherFetchOneBoqStandard() {
  yield takeLatest(
    BoqStandardActionTypes.FETCH_ONE_BOQ_STANDARD,
    fetchOneBoqStandard
  );
}
