import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AccountTransactionActionTypes } from "./AccountTransaction.type";

export function* fetchAllAccountTransactions(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/account_transaction?${query}`
    );
    yield put({
      type: AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllAllAccountTransactions(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/account_transaction?${query}`
    );
    yield put({
      type: AccountTransactionActionTypes.FETCH_ALL_ALL_ACCOUNT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountTransactionActionTypes.FETCH_ALL_ALL_ACCOUNT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAccountTransactionsAnalysis(action: any): any {
  try {
    const response = yield axios.post(
      `${API_BASE_URI}/account_transaction/analysis`,
      action.payload
    );
    yield put({
      type: AccountTransactionActionTypes.FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountTransactionActionTypes.FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAccountTransactions() {
  yield takeLatest(
    AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION,
    fetchAllAccountTransactions
  );
}

export function* watcherFetchAllAllAccountTransactions() {
  yield takeLatest(
    AccountTransactionActionTypes.FETCH_ALL_ALL_ACCOUNT_TRANSACTION,
    fetchAllAllAccountTransactions
  );
}

export function* watcherFetchAccountTransactionsAnalysis() {
  yield takeLatest(
    AccountTransactionActionTypes.FETCH_ACCOUNT_TRANSACTIONS_ANALYSIS,
    fetchAccountTransactionsAnalysis
  );
}
