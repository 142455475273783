import { ApiCallState } from "../Utils";

export type Department = {
  id: number;
  name: string;
  parent: Department | null;
  key: number | null;
};

export type DepartmentStateTypes = {
  fetchAll: ApiCallState<Department[]>;
};

export const DepartmentActionTypes = {
  FETCH_ALL_DEPARTMENT: "FETCH_ALL_DEPARTMENT",
  FETCH_ALL_DEPARTMENT_RESET: "FETCH_ALL_DEPARTMENT_RESET",
  FETCH_ALL_DEPARTMENT_FAILURE: "FETCH_ALL_DEPARTMENT_FAILURE",
  FETCH_ALL_DEPARTMENT_SUCCESS: "FETCH_ALL_DEPARTMENT_SUCCESS",
};
