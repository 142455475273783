import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MiscellaneousBudget= {
  id: number;
  budget_month: string;
  project_id: number;
  project: Project;
  user: User;
  miscellaneous_budget_items: MiscellaneousBudgetItem[];
};

export type MiscellaneousBudgetItem ={
    id: number;
    miscellaneous_budget_id:number;
    description:string;
    pcs:number;
    actual_pcs:number;
    unit_price:number;
    remark:string;
}

export type MiscellaneousBudgetStateTypes = {
  fetchAll: ApiCallState<MiscellaneousBudget[]>;
  fetchOne: ApiCallState<MiscellaneousBudget | {}>;
};

export const MiscellaneousBudgetActionTypes = {
  FETCH_ALL_MISCELLANEOUS_BUDGET: "FETCH_ALL_MISCELLANEOUS_BUDGET",
  FETCH_ALL_MISCELLANEOUS_BUDGET_RESET: "FETCH_ALL_MISCELLANEOUS_BUDGET_RESET",
  FETCH_ALL_MISCELLANEOUS_BUDGET_FAILURE: "FETCH_ALL_MISCELLANEOUS_BUDGET_FAILURE",
  FETCH_ALL_MISCELLANEOUS_BUDGET_SUCCESS: "FETCH_ALL_MISCELLANEOUS_BUDGET_SUCCESS",

  FETCH_ONE_MISCELLANEOUS_BUDGET: "FETCH_ONE_MISCELLANEOUS_BUDGET",
  FETCH_ONE_MISCELLANEOUS_BUDGET_RESET: "FETCH_ONE_MISCELLANEOUS_BUDGET_RESET",
  FETCH_ONE_MISCELLANEOUS_BUDGET_FAILURE: "FETCH_ONE_MISCELLANEOUS_BUDGET_FAILURE",
  FETCH_ONE_MISCELLANEOUS_BUDGET_SUCCESS: "FETCH_ONE_MISCELLANEOUS_BUDGET_SUCCESS",
};
