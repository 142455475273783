import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { HRRequestActionTypes } from "./HRRequest.type";

export function* fetchAllHRRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/hr-request`);
    yield put({
      type: HRRequestActionTypes.FETCH_ALL_HR_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRRequestActionTypes.FETCH_ALL_HR_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneHRRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/hr-request/${action.payload}`
    );
    yield put({
      type: HRRequestActionTypes.FETCH_ONE_HR_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: HRRequestActionTypes.FETCH_ONE_HR_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllHRRequests() {
  yield takeLatest(HRRequestActionTypes.FETCH_ALL_HR_REQUEST, fetchAllHRRequests);
}

export function* watcherFetchOneHRRequests() {
  yield takeLatest(HRRequestActionTypes.FETCH_ONE_HR_REQUEST, fetchOneHRRequests);
}
