import {
  OpportunityV2StateTypes,
  OpportunityV2ActionTypes,
} from "./OpportunityV2.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: OpportunityV2StateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const OpportunityV2Reducer = (
  state: OpportunityV2StateTypes = INITIAL_STATE,
  action: any
): OpportunityV2StateTypes => {
  switch (action.type) {
    case OpportunityV2ActionTypes.FETCH_ALL_OPPORTUNITY_V2:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case OpportunityV2ActionTypes.FETCH_ALL_OPPORTUNITY_V2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OpportunityV2ActionTypes.FETCH_ALL_OPPORTUNITY_V2_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OpportunityV2ActionTypes.FETCH_ALL_OPPORTUNITY_V2_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case OpportunityV2ActionTypes.FETCH_ONE_OPPORTUNITY_V2:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case OpportunityV2ActionTypes.FETCH_ONE_OPPORTUNITY_V2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case OpportunityV2ActionTypes.FETCH_ONE_OPPORTUNITY_V2_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OpportunityV2ActionTypes.FETCH_ONE_OPPORTUNITY_V2_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default OpportunityV2Reducer;
